import { csrfTokenRvar } from "./apolloGQL/ReactiveVars";

async function authedFetch(url: string, method: string) {
    if (method.toUpperCase() === "GET") {
        const fetchRes = await fetch(url, {
            method: method,
            credentials: "include",
            // referrerPolicy: "unsafe-url",
        });
        if (fetchRes.ok) {
            return await fetchRes.json();
        }
    } else if (method.toUpperCase() === "POST") {
        console.log("csrfTokenVar()?.csrfToken as string=", csrfTokenRvar()?.csrfToken as string);
        const fetchRes = await fetch(url, {
            method: method,
            // keepalive:true,
            credentials: "include",
            headers: [
                ["Content-Type", "application/json"],
                ["CSRF-Token", csrfTokenRvar()?.csrfToken as string],
            ],
        });
        if (fetchRes.ok) {
            return await fetchRes.json();
        }
    }
    return undefined;
}

export { authedFetch };
