import { useMutation } from "@apollo/client";
import React, { FormEvent, useRef, useState } from "react";
import { NavLink, useOutletContext } from "react-router-dom";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { FormCmps } from "../commonComp/FormComp";
import { ModalComp } from "../commonComp/ModalComp";

export default function ForgotPass(
    props: React.PropsWithChildren<{
        /* no need for now */
    }>
) {
    console.log("First line - ForgotPass Component");

    const [forgotPass, { error, data, loading }] = useMutation(getGqlStmt().mutations.FORGOT_PASS, {
        fetchPolicy: "no-cache",
    });
    //   const {setTitle} = useOutletContext<any>();
    const submitBtnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [emailSent, setEmailSent] = useState(false);

    const onSubmitLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const modalOk = new ModalComp.OkModal(
            { isPortal: false },
            { msg: "Sorry, Error in sending reset link, please check your email and try again" }
        );

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });

        if (userEmailSt.errorMsg?.trim() != "") return;

        await spinner.showAsync();
        //call backen service to generate new token and include it in email then send the email, no info should be sent back except success or failure
        try {
            const sendForgotPass = (
                await forgotPass({
                    variables: {
                        email: userEmailSt.value,
                    },
                })
            ).data.forgotPass;

            if (!sendForgotPass) {
                //error in validating your request, please check your code is entered correctly
                await spinner.hideAsync();
                await modalOk.showAsync();
                return;
            }
        } catch (err) {
            console.log("sendForgotPass Err-", err);
            await spinner.hideAsync();
            await modalOk.showAsync();
            return;
        }
        setEmailSent(true);
        submitBtnRef.current?.classList.add("disabled");
        // event.currentTarget.classList.add(" disabled");
        await spinner.hideAsync();
    };

    const {
        JsxComp: UserEmail,
        compReducer: [userEmailSt, userEmailDis],
        compRef: userEmailRef,
    } = FormCmps.Email.getComp();

    return (
        <>
            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                {/* <button onClick={()=>{setTitle("FFFFFFFFFFFF")}} >AAA</button> */}
                <form className="container d-flex flex-column " onSubmit={onSubmitLogin}>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <label className="form-label" htmlFor="email">
                                {ReadTextResource.getTextById("EMAIL_FORGOT_PASS")}
                            </label>
                            {/* <input
                                ref={emailRef}
                                className="form-control"
                                type="email"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                size={30}
                                required
                                id="email"
                            /> */}
                            <UserEmail
                                id="userEmailId"
                                inputRef={userEmailRef}
                                label={ReadTextResource.getTextById("EMAIL")}
                                //  defaultValue={getAccessInfo()?.user?.email}
                                required={true}
                                // validationsMsg="Email is not valid"
                                emailRdc={[userEmailSt, userEmailDis]}
                                valueTrakingOnChange={true}
                            ></UserEmail>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3 d-flex justify-content-center">
                            <input
                                className="btn btn-primary flex-fill"
                                type="submit"
                                value={ReadTextResource.getTextById("SEND")}
                                ref={submitBtnRef}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-12 mb-3 d-flex justify-content-center text-primary">
                            {emailSent ? ReadTextResource.getTextById("EMAIL_SENT_SUCC") : ""}
                        </div>
                        {emailSent && (
                            <div className="col-sm-9 col-12 mb-3 d-flex justify-content-center text-primary">
                                <NavLink replace={true} to="/">
                                    Login
                                </NavLink>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}
