import React, { FormEvent, useRef, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
// import {moda} from "bootstrap";
import { Modal } from "bootstrap";
import reactDom from "react-dom";
import { ModalComp } from "../commonComp/ModalComp";
import { PortalComp } from "../commonComp/PortalComp";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import InvalidURL from "./InvalidURL";
import jwtDecode from "jwt-decode";
import { useValidateToken } from "../commonComp/customHooks/useValidateToken";
import { SpinnerComp } from "../commonComp/SpinnerComp";
import { getGraphQlError } from "../../commonSrc/commonFns";

export default function AccountActivation(
    props: React.PropsWithChildren<{
        /* no need for now */
    }>
) {
    console.log("First line - AccountActivation Component");

    const submitBtnRef = useRef<HTMLInputElement>(null);

    const [mismatchPass, setMismatchPass] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const activateAccountToken = searchParams.get("token");

    const [expiredToken, validateTokenData, validateTokenLoading, validateTokenError] = useValidateToken(
        activateAccountToken as string,
        "activateAccount"
    );

    const [validateAccountActivation, { error, data, loading }] = useMutation(getGqlStmt().mutations.VALIDATE_ACCOUNT_ACTIVATION, {
        fetchPolicy: "no-cache",
    });

    const onSubmitLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const validateResult = (
                await validateAccountActivation({
                    variables: {
                        token: activateAccountToken,
                    },
                })
            ).data.validateActivateAccount;

            if (!validateResult) {
                //error in validating your request, please check your code is entered correctly
                return;
            }
        } catch (err) {
            console.log("validateForgotPass Err-", err);
        }

        // ModalComp.getModalInstance("mianModalBackdrop_id_0").show();

        // const okModal=ModalComp.OkModal;
        // okModal.params={msg:"Activated Successfully", navTo:"/"}
        // ModalComp.show(okModal);
        // ModalComp.show(new ModalComp.OkModal({ msg: "Activated Successfully", navTo: "/" }));
        const okModal = new ModalComp.OkModal( {isPortal: false},{ msg: "Activated Successfully", navTo: "/" });
        okModal.show();
        // ModalComp.show(aa);
        // nav("/");
    };

    if (validateTokenLoading) return <SpinnerComp />;

    if (expiredToken) return <InvalidURL reason="EXPIRED_TOKEN" />;

    if (validateTokenError) {
        console.log("validateTokenError=", (validateTokenError.graphQLErrors[0] as any).extensions.errorId);
        if (getGraphQlError(validateTokenError).errorId === "TOKEN_EXPIRED") return <InvalidURL reason="EXPIRED_TOKEN" />;
        else return <InvalidURL />;
    }

    if (!activateAccountToken || validateTokenError || !validateTokenData?.validateToken) return <InvalidURL />;

    return (
        <>
            <div>
                <form className="container d-flex flex-column" onSubmit={onSubmitLogin}>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <p className="form-label">{ReadTextResource.getTextById("ACTVT_ACC_NOTE")}</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3 d-flex justify-content-center">
                            <input className="btn btn-primary flex-fill" type="submit" value={ReadTextResource.getTextById("ACTVT_ACC_BTN")} ref={submitBtnRef} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
