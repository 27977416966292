import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ModalComp } from "../commonComp/ModalComp";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import InvalidURL from "./InvalidURL";
import jwtDecode from "jwt-decode";
import { FormCmps } from "../commonComp/FormComp";
import { useValidateToken } from "../commonComp/customHooks/useValidateToken";
import { SpinnerComp } from "../commonComp/SpinnerComp";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
export default function ResetPass(
    props: React.PropsWithChildren<{
        /* no need for now */
    }>
) {
    console.log("First line - ResetPass Component");
    const confirmPassRef = useRef<HTMLInputElement>(null);
    // const newPassRef = useRef<HTMLInputElement>(null);
    const tempPassRef = useRef<HTMLInputElement>(null);
    const submitBtnRef = useRef<HTMLInputElement>(null);

    const [mismatchPass, setMismatchPass] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const forgotPassToken = searchParams.get("token");

    const {
        JsxComp: Username,
        compReducer: [newPassSt, newPassDis],
        compRef: newPassRef,
    } = FormCmps.Passrd.getComp();

    const {
        JsxComp: UserPassword,
        compReducer: [userPassSt, userPassStDis],
        compRef: userPassRef,
    } = FormCmps.Passrd.getComp();

    const {
        JsxComp: UserConfirmPassword,
        compReducer: [userConfirmPassSt, userConfirmPassStDis],
        compRef: userConfirmPassRef,
    } = FormCmps.Passrd.getComp();

    const [expiredToken, validateTokenData, validateTokenLoading, validateTokenError] = useValidateToken(
        forgotPassToken as string,
        "resetPass"
    );

    const [validateForgotPass, { error, data, loading }] = useMutation(getGqlStmt().mutations.VALIDATE_FORGOT_PASS, {
        fetchPolicy: "no-cache",
    });

    const onSubmitLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: "Please make sure you entered tha values correctly!" });
        if (userConfirmPassSt?.value !== newPassSt?.value) {
            setMismatchPass(true);
            return;
        }
        setMismatchPass(false);

        try {
            const validateForgotPassRes = (
                await validateForgotPass({
                    variables: {
                        token: forgotPassToken,
                        tempPass: tempPassRef.current?.value,
                        newPass: userPassRef.current?.value,
                    },
                })
            ).data.validateForgotPassReq;
            console.log("validateForgotPassResXXXXXXXX=", validateForgotPassRes);
            if (!validateForgotPassRes) {
                //error in validating your request, please check your code is entered correctly
                console.log("validateForgotPassRes =", validateForgotPassRes);
                await okModal.showAsync();
                return;
            }
        } catch (err) {
            console.log("validateForgotPass Err-", err);
            await okModal.showAsync();
            return;
        }

        // ModalComp.getModalInstance("mianModalBackdrop_id_0").show();
        // const resetModal = new ModalComp.ResetPassModal({ isPortal: false }, {});
        // resetModal.show();
        // ModalComp.show(new ModalComp.ResetPassModal({}));//commneted recentrly and not tested

        await okModal.setProps({ msg: "Password reset successfully", navTo: "/" });
        await okModal.showAsync();
        // nav("/");
        submitBtnRef.current?.classList.add("disabled");
    };

    if (expiredToken) return <InvalidURL reason="EXPIRED_TOKEN" />;
    if (validateTokenLoading) return <SpinnerComp />;

    if (validateTokenError) {
        if ((validateTokenError.graphQLErrors[0] as any).extensions.errorId === "TOKEN_EXPIRED")
            return <InvalidURL reason="EXPIRED_TOKEN" />;
        else return <InvalidURL />;
    }

    if (!forgotPassToken || validateTokenError || !validateTokenData?.validateToken) return <InvalidURL />;

    return (
        <>
            {/*       <PortalComp elementId={ModalComp.IDs.ROOT_MODAL_ID}>
        <ModalComp.ModalContainer modalId={ModalComp.IDs.RESET_PASS_MODAL } nonStaticModal="static" modalTitle={ReadTextResource.getTextById("PASS_RESET")}>
          <ModalComp.ModalBody>
            <div className="">{ReadTextResource.getTextById("PASS_RESET_SUCC")}</div>
          </ModalComp.ModalBody>
          <ModalComp.ModalFooter>
            <div className="">
              <button type="button" className="btn btn-primary">
                <NavLink
                  to="/"
                  onClick={() => {
                    ModalComp.getModalInstance("mianModalBackdrop_id_0").hide();
                  }}
                  className={() => "unselected text-white"}
                  replace
                >
                  {ReadTextResource.getTextById("HOME_PAGE")}
                </NavLink>
              </button>
            </div>
          </ModalComp.ModalFooter>
        </ModalComp.ModalContainer>
      </PortalComp> */}

            <div>
                <form className="container d-flex flex-column" onSubmit={onSubmitLogin}>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <label className="form-label" htmlFor="tempPass">
                                {ReadTextResource.getTextById("TEMP_PASS")}
                            </label>
                            <input ref={tempPassRef} className="form-control" type="input" size={10} required autoFocus id="tempPass" />
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <UserPassword
                                id="ResetPass"
                                inputRef={userPassRef}
                                // required={true}
                                label={ReadTextResource.getTextById("NEW_PASS")}
                                // validationsMsg={ReadTextResource.getTextById("PASS_REQUIREMENT")}
                                passrdRdc={[userPassSt, userPassStDis]}
                                options={{ showPassHint: false, showPassTooltip: true, autoComplete: "new-password" }}
                            ></UserPassword>

                            {/* <Username
                                id="ResetPass"
                                // inputType={InputTypes.Password}
                                passrdRdc={[newPassSt, newPassDis]}
                                label={}
                                validationsMsg="PASS_REQUIREMENT"
                                options={{ showPassHint: false, showPassTooltip: true, autoComplete: "new-password" }}
                                inputRef={newPassRef}
                            ></Username> */}
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <label className="form-label" htmlFor="confirmPass">
                                {ReadTextResource.getTextById("CONFIRM_PASS")}
                            </label>

                            <UserConfirmPassword
                                id="confirmPass"
                                label={ReadTextResource.getTextById("CONFIRM_PASS")}
                                // validationsMsg="PASS_REQUIREMENT"
                                // required={true}
                                inputRef={userConfirmPassRef}
                                passrdRdc={[userConfirmPassSt, userConfirmPassStDis]}
                                passToConfirm={FormCmps.Passrd.getValue(userPassRef)}
                                valueTrakingOnChange={false}
                            ></UserConfirmPassword>

                            {/* <input
                                ref={confirmPassRef}
                                className="form-control"
                                type="password"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$"
                                size={30}
                                required
                                id="confirmPass"
                                placeholder={}
                                title={ReadTextResource.getTextById("PASS_REQUIREMENT")}
                                // onInvalid={(e) => {
                                //   console.log("validity=", e.currentTarget.validity);
                                //   if (e.currentTarget.validity.patternMismatch)
                                //     e.currentTarget.setCustomValidity(ReadTextResource.getTextById("PASS_REQUIREMENT"));
                                //   else e.currentTarget.setCustomValidity("");
                                // }}
                                onChange={(e) => {
                                    if (e.currentTarget.validity.patternMismatch)
                                        e.currentTarget.setCustomValidity(ReadTextResource.getTextById("PASS_REQUIREMENT"));
                                    else e.currentTarget.setCustomValidity("");
                                }}
                            /> */}
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3 d-flex justify-content-center">
                            <input
                                className="btn btn-primary flex-fill"
                                type="submit"
                                value={ReadTextResource.getTextById("SEND")}
                                ref={submitBtnRef}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-12 mb-3 d-flex justify-content-center text-primary">
                            {mismatchPass ? ReadTextResource.getTextById("MISMATCH_PASS") : ""}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
