import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { AppRoutes } from "./components/commonComp/AppRoutes";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { apolloClient } from "./commonSrc/apolloGQL/ApolloConfig";
import historyObject from "./commonSrc/HistoryRouterObject";
// import { Lang } from "./components/common/Lang";
import { Lang } from "./components/commonComp/Lang";
import { ReadTextResource } from "./commonSrc/ReadTextResource";
import { Welcome } from "./Welcome";
import { MuiLang } from "./components/commonComp/MuiLang";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CheckPermissions } from "./components/commonComp/CheckPermissions";
import { ErrorBoundary } from "./components/commonComp/ErrorBoundary";
// import ar from "date-fns/locale/ar";
// import { dirAndLangRvar } from "./commonSrc/apolloGQL/ReactiveVars";
console.log("Is it first line in the whole app");

try {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <ApolloProvider client={apolloClient}>
            {/* <React.StrictMode> */}
            <HistoryRouter history={historyObject}>
                {/* <BrowserRouter> */}
                {/* has been moved to MuiLang <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dirAndLangRvar().lang === "ar" ? ar : undefined}> */}
                {/* CheckPermissions should not have AppRoutes as a direct route, but if it a must, then read the comment in CheckPermissions component*/}
                <CheckPermissions>
                    <MuiLang>
                        <Lang>
                            <ErrorBoundary fallbackComponent={<div>Something went wrong</div>}>
                                <AppRoutes />
                            </ErrorBoundary>
                        </Lang>
                    </MuiLang>
                </CheckPermissions>
                {/* </LocalizationProvider> */}
            </HistoryRouter>
            {/* </React.StrictMode> */}
            {/* </BrowserRouter> */}
        </ApolloProvider>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    // alert("index.tsx register 2()");
    serviceWorkerRegistration.register();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
} catch (err: any) {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(<div>Clear cache and try again - abd - </div>);
}
