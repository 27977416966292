import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { useNavigate } from "react-router-dom";

export function PsgrDashboardComp(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrMainComp Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    // let dropInInstance: Dropin;

   








    return (
        <>
            {/* <div className="d-flex flex-column align-items-center text-center"> */}
            <div className="align-content-center d-flex flex-column flex-lg-row flex-md-row flex-wrap justify-content-lg-around justify-content-md-around">
                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/psgrTraspReq");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/PsgrReserveTrip.png")`,
                            width: "75px",
                            height: "62px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div className=" mt-3 rounded-4 text-center" style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}>
                        {ReadTextResource.getTextById("ADD_REQUEST")}
                    </div>
                </div>


                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/psgrTrips");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/taxiCorrect.png")`,
                            width: "75px",
                            height: "62px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div
                        className=" mt-3 rounded-4  text-center"
                        style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}
                    >
                        {ReadTextResource.getTextById("PSGR_JOINED_TRIPS")}
                    </div>
                </div>

                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/psgrSuspendedReqs");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/QuestionTaxi.png")`,
                            width: "75px",
                            height: "62px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div
                        className=" mt-3 rounded-4  text-center"
                        style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}
                    >
                        {ReadTextResource.getTextById("PSGR_SUSPENDED_REQS")}
                    </div>
                </div>

                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/psgrFindTrip");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/FindOnEarth4.png")`,
                            width: "75px",
                            height: "62px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div
                        className=" mt-3 rounded-4  text-center"
                        style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}
                    >
                        {ReadTextResource.getTextById("PSGR_SEARCH_TRIP")}
                    </div>
                </div>

                {/* <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons style={{ layout: "horizontal" }} />
                </PayPalScriptProvider> */}
            </div>
        </>
    );
}
