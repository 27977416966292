import { useReactiveVar } from "@apollo/client";
import { debug } from "console";
import jwtDecode from "jwt-decode";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isNewUpdate } from "../../commonSrc/apolloGQL/ReactiveVars";
import { getAccessInfo } from "../../commonSrc/commonFns";
import { invokeServiceWorkerUpdateFlow } from "../../serviceWorkerRegistration";
import { ModalComp } from "./ModalComp";
export function CheckPermissions(props: PropsWithChildren<{}>) {
    console.log("First line - CheckPermissions Component");
    const navigate = useNavigate();

    // useReactiveVar(isNewUpdate);
    console.log("Notification.permission=", Notification.permission);

    const [isMissingPermission, setIsMissingPermission] = useState(
        Notification.permission !== "granted" && Notification.permission !== "default"
    );

    const [offline, setOffline] = useState(false);

    function askNotificationPermission() {
        if (Notification.permission === "granted") {
            setIsMissingPermission(false);
            return Notification.permission;
        } else if (Notification.permission === "denied") {
            setIsMissingPermission(true);
            return Notification.permission;
        } else {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    setIsMissingPermission(false);
                    return Notification.permission;
                } else if (permission === "denied") {
                    setIsMissingPermission(true);
                    return Notification.permission;
                } else {
                    setIsMissingPermission(true);
                    return Notification.permission;
                }
            });
        }
    }

    useEffect(() => {
        window.addEventListener("offline", async (e) => {
            setOffline(true);
        });

        window.addEventListener("online", async (e) => {
            setOffline(false);
        });

        const visibilityChangerHandler = async (e: any) => {
            console.log("visibilitychange", document.visibilityState);
            if (document.visibilityState === "visible") {
                const registration = await navigator.serviceWorker.getRegistration();
                if (registration?.waiting) {
                    // isNewUpdate(true);
                    invokeServiceWorkerUpdateFlow(registration);
                } else if (Notification.permission !== "granted") {
                    askNotificationPermission();
                }

                try {
                    let decodedToken: any = null;
                    if (getAccessInfo()?.token)
                        decodedToken = jwtDecode(getAccessInfo()?.token as string);

                    // if (!decodedToken)
                    //     navigate("/login");


                    if (decodedToken) {
                        //check if expired
                        if (Date.now() / 1000 > decodedToken.exp) {
                            navigate("/login");
                        }
                    }
                    else {
                        //newly added on 25May2024
                        if (window.location.pathname.includes("/app"))
                            navigate("/login");
                    }
                } catch (err) {
                    console.log("error in CheckPermissions check jwt, will be redirected to login", err);
                    // navigate("/login");


                }

            }
            // alert("visibilitychange " + document.visibilityState);
        }

        const visChangeLsnr = document.addEventListener("visibilitychange", visibilityChangerHandler);

        navigator.permissions.query({ name: "notifications" }).then((permissionValue) => {
            permissionValue.onchange = (e) => {
                // alert("Notification permission state is " + permissionValue.state);
                // alert("notification listener on change");
                // if (permissionValue.state !== "granted")
                askNotificationPermission();
            };
        });

        return () => {
            document.removeEventListener("visibilitychange", visibilityChangerHandler)
        }
    }, []);

    if (isMissingPermission)
        return (
            <div>
                <div>There is missing permission, please allow notification permission then click Ok</div>
                <div>
                    <button
                        type="button"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Ok
                    </button>
                </div>
            </div>
        );
    // if (offline) {
    //     return <div>There is no internet connection</div>;
    // }
    /**
     * If the children is AppRoutes (the components that contains the application Routes), then
     * it will not consistanly, sometimes it will render nothing.
     * but if it a must that the direct children of this component is AppRoutes then you can get it
     * to wotk by using:
     * 
      const childrenWithProps = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child);
        }
        return child;
    });

    return childrenWithProps;
    *
    **/

    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className={offline ? "fixed-top opacity-75" : "d-none"} style={{ backgroundColor: "red" }}>
                No internet connection
            </div>
            {props.children}
        </div>
    );
}
