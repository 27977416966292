import React, { ReactNode } from "react";
import { Button } from "./Button";
import historyObject from "../../commonSrc/HistoryRouterObject";

export class ErrorBoundary extends React.Component<{
  fallbackComponent?: ReactNode;
  children?: React.ReactNode
}, { hasError: boolean; }> {
  // state: { hasError: false };
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.log("#C# getDerivedStateFromError");
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //   logErrorToMyService(error, info.componentStack);
    console.log("#C# send log to server to check what happened here");

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const f = <div>
        {this.props.fallbackComponent}
        <Button id="dda" label="back" onOkClickHandler={() => {
          window.history.back();
          setTimeout(() => {
            window.location.reload();
          }, 100);

          // historyObject.go(-1)

        }} >Back</Button>
      </div>
      return f;
    }

    return this.props.children;
  }
}