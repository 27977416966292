import { DocumentNode, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { apolloClient } from "../../../commonSrc/apolloGQL/ApolloConfig";
import { dirAndLangRvar } from "../../../commonSrc/apolloGQL/ReactiveVars";
import { getGqlStmt } from "../../../commonSrc/graphQL/Queries";

export function TestLazyQ() {
    const [s, setS] = useState("");
    const l = getGqlStmt().queries.GET_COMPANY;
    // console.log("query##",l)
    const [getCompany, { error: getCompanyError, data, loading: getCompanyLoading, refetch, updateQuery }] = useLazyQuery(l, {
        fetchPolicy: "no-cache",
        // notifyOnNetworkStatusChange: true,
        // onCompleted: (data) => {
        //     console.log("onCompleted##", data);
        // },
        // variables: { username: s },
    });

    useEffect(() => {
        console.log("lazy useEffect in App##");
        // const asyncBlock = async () => {
        console.log("lazy ayncBlock App2##");

        // try {
        console.log("l##", l);
        // const { data:asa, loading:sww, error:vv } =
        // apolloClient
        //     .query({
        //         query: getGqlByLang(dirAndLangVar().lang),
        //         fetchPolicy: "no-cache",
        //         variables: {
        //             username: "Com40",
        //         },
        //     })
        //     .then((d) => {
        //         console.log("then##", d.data.getCompany.compName);
        //         console.log("App Component inside##=", d.data.getCompany.compName);
        //         setS(d.data.getCompany.compName);
        //     });

        const g = getCompany({
            variables: {
                username: "Com40",
            },
        });
        // .then((d) => {
        //     console.log("then##", d.data.getCompany.compName);
        //     console.log("App Component inside##=", d.data.getCompany.compName);
        //     setS(d.data.getCompany.compName);
        // });

        // refetch({ username: "Com40" }).then((d) => {
        //     console.log("then##", d.data.getCompany.compName);
        //     console.log("App Component inside##=", d.data.getCompany.compName);
        //     setS(d.data.getCompany.compName);
        // });

        console.log("lazy useEffect in AppAfterGetCom## ", data);

        // console.log("App Component inside##=", g.data.getCompany.compName);
        // } catch (err) {
        //     console.log("lazy err2##", err);
        // }

        // setCompName(getCompanyResponse.data.getCompany.compName);
        // getCompanyResponse.data.getCompany.compName
        // };

        // asyncBlock().catch((err) => {
        //     console.log("lazy err2##", err);
        // });
        // const g =  getCompany({
        //     variables: {
        //         username: "Com40",
        //     },
        // }).then((d)=>{
        //     console.log("then##",d.data.getCompany.compName);

        // });
        // console.log("before Out of async##",g)
        // console.log("Out of async##");
    }, [dirAndLangRvar().lang]);

    console.log("data=", data);
    // if (compData) return <p>{compData}</p>;
    return <p>LayzQuery Test {data?.getCompany.compName}</p>;
}
