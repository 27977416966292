import { User, UserNotification } from "../Types";

/**
 * all code regarding indexDb is here, and it has been separated in independent file of commonFns
 * to enable service-worker to import it on build using the command "npm run build" to avoid any problem
 */

export async function openIDb(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const dbVersion = 9;
        const request = indexedDB.open("chatDb", dbVersion);

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;

            /**
             * we need to find a way to upgrade the idb in case of new structre updates, so that we can keep old data and move it to new database structure
             */
            // Create an object store (if it doesn't exist)
            if (!db.objectStoreNames.contains("chattingUsers")) {
                db.createObjectStore("chattingUsers", { keyPath: "userId" });
            }

            // Create an object store (if it doesn't exist)
            if (!db.objectStoreNames.contains("userNotifications")) {
                db.createObjectStore("userNotifications", { keyPath: "notificationId" });
            }

            // how to delete a db object
            // if (event.oldVersion <= 8/* keyPath !== "notificationId" */) {
            //     db.deleteObjectStore("userNotifications");
            //     db.createObjectStore("userNotifications", { keyPath: "notificationId" });
            // }
        };

        request.onsuccess = (event) => {
            /**
             * if you need to change some structure of the idb, you can check what is the current structure or key path by below
             */

            // const transaction = db.transaction(["userNotifications"], "readwrite");
            // const objectStore = transaction.objectStore("userNotifications");
            // const keyPath = objectStore.keyPath;
            // console.log("keyPath=", keyPath);
            const db = (event.target as IDBOpenDBRequest).result;
            resolve(db);
        };

        request.onerror = (event) => {
            reject((event.target as IDBOpenDBRequest).error);
        };
    });
}

export async function addUserToIDb(user: User): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["chattingUsers"], "readwrite");
        const objectStore = transaction.objectStore("chattingUsers");

        const request = objectStore.add(user);

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}

export async function addUserNtfToIDb(userNtf: UserNotification): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["userNotifications"], "readwrite");
        const objectStore = transaction.objectStore("userNotifications");

        const request = objectStore.add(userNtf);

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}

export async function getUserFromIDb(userId: number): Promise<User | undefined> {
    const db = await openIDb();
    return new Promise<User | undefined>((resolve, reject) => {
        const transaction = db.transaction(["chattingUsers"], "readonly");
        const objectStore = transaction.objectStore("chattingUsers");

        const request = objectStore.get(userId);

        request.onsuccess = () => {
            resolve(request.result as User);
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}

export async function updateUserHasNewMsgIDb(userId: number, hasNewMsg: boolean): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["chattingUsers"], "readwrite");
        const objectStore = transaction.objectStore("chattingUsers");

        const request = objectStore.get(userId);

        request.onsuccess = () => {
            const user: User = request.result as User;
            user.hasNewMsg = hasNewMsg;

            const updateRequest = objectStore.put(user);

            updateRequest.onsuccess = () => {
                resolve();
            };

            updateRequest.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        };

        request.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}

export async function setIDbChattingUsers(users: User[]): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["chattingUsers"], "readwrite");
        const objectStore = transaction.objectStore("chattingUsers");

        transaction.oncomplete = () => {
            resolve();
        };

        transaction.onerror = (event) => {
            reject((event.target as IDBTransaction).error);
        };

        objectStore.clear();
        for (const user of users) {
            const request = objectStore.put(user);

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        }
    });
}

export async function getIDbChattingUsers(): Promise<User[]> {
    const db = await openIDb();
    return new Promise<User[]>((resolve, reject) => {
        const transaction = db.transaction(["chattingUsers"], "readonly");
        const objectStore = transaction.objectStore("chattingUsers");
        const users: User[] = [];

        transaction.oncomplete = () => {
            resolve(users);
        };

        transaction.onerror = (event) => {
            reject((event.target as IDBTransaction).error);
        };

        const cursorRequest = objectStore.openCursor();

        cursorRequest.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor) {
                users.push(cursor.value as User);
                cursor.continue();
            }
        };

        cursorRequest.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}


export async function setAllNewNtfsReadIDb(): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["userNotifications"], "readwrite");
        const objectStore = transaction.objectStore("userNotifications");

        const cursorRequest = objectStore.openCursor();

        cursorRequest.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor) {
                const record = cursor.value as UserNotification;
                record.isNewNtf = false;
                const updateRequest = cursor.update(record);

                updateRequest.onerror = (event: any) => {
                    console.error('Error updating record:', event);
                };
                cursor.continue();
            }
        };


        cursorRequest.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}


export async function setNtfReadIDb(ntfId: number): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["userNotifications"], "readwrite");
        const objectStore = transaction.objectStore("userNotifications");

        const getReq = objectStore.get(ntfId);

        getReq.onsuccess = (event) => {
            const rec: UserNotification = (event.target as IDBRequest).result;
            if (rec) {
                rec.isNtfRead = true;
                const updateReq = objectStore.put(rec)

                updateReq.onerror = (event: any) => {
                    console.error('Error updating record:', event);
                };
            }
        };


        getReq.onerror = (event) => {
            reject((event.target as IDBRequest).error);
        };
    });
}

export async function setIDbUserNotifications(userNtfs: UserNotification[]): Promise<void> {
    const db = await openIDb();
    return new Promise<void>((resolve, reject) => {
        const transaction = db.transaction(["userNotifications"], "readwrite");
        const objectStore = transaction.objectStore("userNotifications");

        transaction.oncomplete = () => {
            resolve();
        };

        transaction.onerror = (event) => {
            reject((event.target as IDBTransaction).error);
        };

        objectStore.clear();
        for (const userNtf of userNtfs) {
            const request = objectStore.put(userNtf);

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        }
    });
}


export async function getIDbUserNotifications(): Promise<UserNotification[]> {
    const db = await openIDb();
    return new Promise<UserNotification[]>((resolve, reject) => {
        const transaction = db.transaction(["userNotifications"], "readonly");
        const objectStore = transaction.objectStore("userNotifications");
        const userNtfs: UserNotification[] = [];

        transaction.oncomplete = () => {
            resolve(userNtfs);
        };

        transaction.onerror = (event) => {
            console.log("error in getting IDbUserNotifications transaction");
            reject((event.target as IDBTransaction).error);
        };

        const cursorRequest = objectStore.openCursor();

        cursorRequest.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor) {
                userNtfs.push(cursor.value as UserNotification);
                cursor.continue();
            }
        };

        cursorRequest.onerror = (event) => {
            console.log("error in getting IDbUserNotifications");

            reject((event.target as IDBRequest).error);
        };
    });
}

