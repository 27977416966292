import { PropsWithChildren } from "react";

export default function InvalidURL(props: PropsWithChildren<{ reason?: string }>) {
  return (
    <div>
      <div className="container d-flex flex-column">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            {/* <label className="form-label" htmlFor="email"> */}
            <div className="fs-1">{props.reason === "EXPIRED_TOKEN" ? "Expired URL" : " Not Found !"}</div>
            <div className="fs-3">{props.reason !== "EXPIRED_TOKEN" ? "Please make sure that you entered a valid URL" : ""}</div>
            {/* </label> */}
          </div>
        </div>
      </div>
    </div>
  );
}
