import React, { CSSProperties, FormEvent, PropsWithChildren, Ref } from "react";

export function Button(
    props: PropsWithChildren<{
        id: string;
        label: string;
        icon?: React.ReactElement;
        inputRef?: Ref<any>;
        classNames?: string;
        styles?: CSSProperties | undefined; //{ [key: string]: string };
        onOkClickHandler?: (event?: FormEvent<HTMLButtonElement>) => void;
    }>
) {
    return (
        <>
            {/* <div className=""> */}
            <button
                type="button"
                style={props.styles}
                className={`btn btn-primary ${props.classNames ? props.classNames : ""}`}
                onClick={async () => {
                    props.onOkClickHandler?.();
                }}
            >
                {props.icon} {props.label}
            </button>
            {/* </div> */}
        </>
    );
}
