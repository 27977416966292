import { useMutation } from "@apollo/client";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "./FormComp";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { getGraphQlError } from "../../commonSrc/commonFns";
import { ModalComp } from "./ModalComp";
import { accessInfoRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { useId } from "react";
import { useNavigate } from "react-router-dom";
export function GoodsDtlsCmp(props: React.PropsWithChildren<{
    pGoodsData: any[];
    showApproval: boolean;
    displayType?: "AcceptPsgrReq"
    onAcceptGoods?: () => Promise<void>;
    onRejectGoods?: () => Promise<void>
}>) {

    const navigate = useNavigate();

    const {
        JsxComp: GoodsSrcLocComp,
        compReducer: [goodsSrcLocSt, goodsSrcLocDis],
        compRef: goodsSrcLocRef,
    } = FormCmps.LocationCmp.getComp();

    const {
        JsxComp: GoodsDestLocComp,
        compReducer: [goodsDestLocSt, goodsDestLocDis],
        compRef: goodsDestLocRef,
    } = FormCmps.LocationCmp.getComp();

    const [
        drvrAcceptNewGdsOnHisReq,
        { error: drvrAcceptNewGdsOnHisReqErr, data: drvrAcceptNewGdsOnHisReqData, loading: drvrAcceptNewGdsOnHisReqLding },
    ] = useMutation(getGqlStmt().mutations.DRVR_ACCEPT_GDS_TO_HIS_REQ, {
        fetchPolicy: "no-cache",
    });

    const [
        drvrRejectNewGdsOnHisReq,
        { error: drvrRejectNewGdsOnHisReqErr, data: drvrRejectNewGdsOnHisReqData, loading: drvrRejectNewGdsOnHisReqLding },
    ] = useMutation(getGqlStmt().mutations.DRVR_REJECT_GDS_TO_HIS_REQ, {
        fetchPolicy: "no-cache",
    });

    const id = useId();
    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row row-cols-1 row-cols-lg-2" key={"aaa"}>
                {
                    props.pGoodsData?.map((e: any, i) => {
                        let goodsSrcLoc = e?.psgrSrcLoc;
                        let goodsDestLoc = e?.psgrDestLoc;
                        const userGoods = e?.tripGoods?.tripGoodsDtl?.map((goods: any, i: number) => {
                            // goodsSrcLoc = goods.srcLoc;
                            // goodsDestLoc = goods.destLoc;
                            return (
                                <div key={"f" + i}>
                                    <li key={"userGds" + i}>
                                        {ReadTextResource.getTextById("GOOD_WGHT") +
                                            " : " +
                                            goods.goodWeight +
                                            " " +
                                            ReadTextResource.getTextById("KGM")}
                                        <div key={"g" + i}>
                                            {ReadTextResource.getTextById("SIZE") + " : "}
                                            <span key={"g" + i} dir="ltr">
                                                {ReadTextResource.getTextById("CM") +
                                                    " " +
                                                    goods.goodHeight +
                                                    " x " +
                                                    goods.goodWidth +
                                                    " x " +
                                                    goods.goodLength}
                                            </span>
                                        </div>
                                        <div key={"f" + i}>{ReadTextResource.getTextById("NOTE") + " : " + goods.goodNote}</div>
                                    </li>
                                </div>
                            );
                        });
                        if (userGoods)
                            return (
                                <div key={"fg" + i}>
                                    <div key={"e" + i} className="position-relative py-3 px-2 rounded shadow border border-1 border-danger mt-3 my-2">
                                        {e.status === "accepted" && (
                                            <>
                                                <div key={"cir" + i} className={`position-absolute p-2 top-0 end-0 fs-4 `} >
                                                    <i
                                                        className={`bi bi-chat-dots-fill text-danger ${accessInfoRvar()?.user?.userType === "Psgr" ? "d-none" : ""}`}
                                                        onClick={async () => {
                                                            navigate("/app/drvrChat", { state: { toUserId: e.sourceUser?.userId } });
                                                        }}>
                                                    </i>
                                                    <i key={"d" + i} style={{ color: "green" }} className={`bi bi-check-circle p-2`} ></i>
                                                </div>
                                            </>
                                        )}
                                        {e.status === "pending" && (
                                            <div key={"pend" + i} className="position-absolute p-2 top-0 end-0 fs-1" style={{ color: "red" }}>
                                                <i key={"c" + i} className="bi bi-question-circle"></i>
                                            </div>
                                        )}
                                        {accessInfoRvar()?.user?.driver?.drvrId && <div key={"usrNme" + i}>{ReadTextResource.getTextById("USERNAME") + " " + e?.sourceUser?.userName}</div>}
                                        {/* <div>{ReadTextResource.getTextById("GOOD_SIZE")}</div> */}
                                        <ol key={"usrGds" + i}>{userGoods}</ol>
                                        {e.price && <div key={"f2" + i}> <b> {ReadTextResource.getTextById("TOT_PRICE") + " : " + e.price}</b></div>}

                                        {props.displayType !== "AcceptPsgrReq" &&
                                            <div key={"locDiv" + i}>
                                                {goodsSrcLoc ?
                                                    <GoodsSrcLocComp
                                                        key={"srcLoc" + i}
                                                        id="goodsSrcLocId01"
                                                        locationRdc={[goodsSrcLocSt, goodsSrcLocDis]}
                                                        defaultValue={goodsSrcLoc}
                                                        label={<b>{ReadTextResource.getTextById("GDS_PICKUP_LOC")}: </b>}
                                                        inputRef={goodsSrcLocRef}
                                                        readOnly={true}
                                                    ></GoodsSrcLocComp>
                                                    : (
                                                        <div className="">
                                                            <b>{ReadTextResource.getTextById("PSGR_SRC_LOC")}</b>: {ReadTextResource.getTextById("FROM_DRVR_LOC")}
                                                        </div>
                                                    )}
                                                {goodsDestLoc ?
                                                    <GoodsDestLocComp
                                                        key={"destLoc" + i}
                                                        id="goodsDestLocId01"
                                                        locationRdc={[goodsDestLocSt, goodsDestLocDis]}
                                                        defaultValue={goodsDestLoc}
                                                        label={<b>{ReadTextResource.getTextById("GOODS_DEST_LOC")}: </b>}
                                                        inputRef={goodsDestLocRef}
                                                        readOnly={true}
                                                    ></GoodsDestLocComp>
                                                    : (
                                                        <div className="">
                                                            <b> {ReadTextResource.getTextById("GOODS_DEST_LOC")} :</b>
                                                            {ReadTextResource.getTextById("TO_DRVR_LOC")}
                                                        </div>
                                                    )}
                                            </div>}
                                        {e.status === "pending" && props.showApproval && (
                                            <div key={"apprvBtn" + i}>
                                                <button key={"b" + i}
                                                    className="btn btn-primary px-3 me-1"
                                                    type="button"
                                                    onClick={async () => {
                                                        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: "" });
                                                        await drvrAcceptNewGdsOnHisReq({
                                                            variables: {
                                                                reqId: +e.reqId,
                                                                goodsId: e.tripGoods.goodsReqId,
                                                            },
                                                            onCompleted: async (data) => {
                                                                okModal.setProps({
                                                                    msg: ReadTextResource.getTextById("SAVED_SUCCES"),
                                                                });
                                                                if (data.drvrAcceptNewGdsOnHisReq.success) {
                                                                    await okModal.showAsync();
                                                                    // await getReqDtlReftch();
                                                                    await props.onAcceptGoods?.();
                                                                }
                                                            },
                                                            onError: async (err) => {
                                                                const errorId = getGraphQlError(err).errorId;
                                                                okModal.setProps({
                                                                    msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US"),
                                                                });
                                                                if (errorId === "ERR_ACTION_ALRDY_TAKEN")
                                                                    okModal.setProps({
                                                                        msg: ReadTextResource.getTextById("ERR_ACTION_ALRDY_TAKEN"),
                                                                    });
                                                                await okModal.showAsync();
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {ReadTextResource.getTextById("APPROVE")}
                                                </button>
                                                <button type="button" key={"a" + i} className="btn btn-danger px-3 me-1"
                                                    onClick={async () => {
                                                        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: "" });
                                                        await drvrRejectNewGdsOnHisReq({
                                                            variables: {
                                                                reqId: +e.reqId,
                                                                goodsId: e.tripGoods.goodsReqId,
                                                            },
                                                            onCompleted: async (data) => {
                                                                okModal.setProps({
                                                                    msg: ReadTextResource.getTextById("SAVED_SUCCES"),
                                                                });
                                                                if (data.drvrRejectNewGdsOnHisReq.success) {
                                                                    await okModal.showAsync();
                                                                    // await getReqDtlReftch();
                                                                    await props.onAcceptGoods?.();
                                                                }
                                                            },
                                                            onError: async (err) => {
                                                                const errorId = getGraphQlError(err).errorId;
                                                                okModal.setProps({
                                                                    msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US"),
                                                                });
                                                                if (errorId === "ERR_ACTION_ALRDY_TAKEN")
                                                                    okModal.setProps({
                                                                        msg: ReadTextResource.getTextById("ERR_ACTION_ALRDY_TAKEN"),
                                                                    });
                                                                await okModal.showAsync();
                                                            },
                                                        });
                                                    }}>{ReadTextResource.getTextById("REJECT")}</button>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            );
                        else
                            return ""
                    })
                }
                {
                    (!props.pGoodsData || props.pGoodsData?.length === 0 || (props.pGoodsData?.length === 1 && !props.pGoodsData[0].tripGoods))
                    && <div>{ReadTextResource.getTextById("NO_DATA")}</div>
                }
            </div>
        </div>
    )
}