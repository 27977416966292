import { height, width } from "@mui/system";
import { PropsWithChildren, useEffect, useState } from "react";
import { SeatSelectionStatus } from "../../../commonSrc/Types";

export function Seat(
    props: PropsWithChildren<{
        status: SeatSelectionStatus;
        onSelectUnselect: (pSeatStatus: SeatSelectionStatus, pSeatRow: number, pSeatNo: number, pSeatId?: number) => void;
        seatId: number;
        seatNoInRow: number;
        seatRow: number;
        isEmpty?: boolean;
    }>
) {
    const [seatVisible, setSeatVisible] = useState(true);
    const [seatStyle, setSeatStyle] = useState({});
    // DrvrSteering.png

    useEffect(() => {
        if (seatVisible) {
            if (props.seatNoInRow === 1 && props.seatRow === 1) {
                setSeatStyle({ backgroundImage: `url("/DrvrSteering.png")`, width: "95px", height: "95px" });
            } else {
                setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
            }
        } else {
            setSeatStyle({});
        }
    }, [seatVisible]);

    return (
        <div className="col-4 justify-content-center row text-center pt-4">
        {/* it was this for correct alignemnt of seats in screen sizes <div className="col-4 col-md-auto justify-content-center row text-center pt-4"> */}
            {/* <div>{`S${props.seatId}`}</div> */}
            {!props.isEmpty ? (
                <>
                    <div
                        onClick={() => {
                            if (!seatVisible) {
                                // props.status = SeatSelectionStatus.SELECTED;
                                props.onSelectUnselect(SeatSelectionStatus.SELECTED, props.seatRow, props.seatNoInRow);
                                setSeatVisible(true);
                            }
                        }}
                        className={`position-relative ${seatVisible ? "d-block" : "bi bi-plus-circle"}`}
                        style={{
                            ...seatStyle,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div
                            className={`${props.seatNoInRow === 1 && props.seatRow === 1 ? "d-block text-center" : "d-none"}`}
                            style={{
                                position: "absolute",
                                bottom: "0",
                                // bottom: 0,
                                left: 0,
                                right: 0,
                                // height: "100%",
                                // width: "100%",
                                // opacity: "0.5",
                                // background: "gray",
                            }}
                        >
                            السائق
                        </div>
                        <div
                            className=""
                            style={{
                                position: "absolute",
                                top: "0",
                                // bottom: 0,
                                // left: 0,
                                right: 0,
                                // height: "100%",
                                // width: "100%",
                                // opacity: "0.5",
                                // background: "gray",
                            }}
                        >
                            <div
                                className={`${!seatVisible || (props.seatRow === 1 && props.seatNoInRow === 1) ? "d-none" : "d-blcok"}`}
                                style={{ opacity: "1", fontSize: "15px", border: "1px 1px", cursor: "pointer", color: "red" }}
                                onClick={() => {
                                    // props.status = SeatSelectionStatus.UNSELECTED;
                                    props.onSelectUnselect(SeatSelectionStatus.UNSELECTED, props.seatRow, props.seatNoInRow);
                                    setSeatVisible(false);
                                }}
                            >
                                X
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div style={{ width: "75px", height: "75px" }}></div>
            )}
        </div>
    );
}
