import { Route, Routes } from "react-router-dom";
import MyAccounts from "../acc/MyAccounts";
import TestGraphComp from "../TestGraphComp";
import RequireAuth from "./RequireAuth";
import { AppSettings } from "./AppSettings";
import { PsgrDashboardComp } from "../passenger/PsgrDashboardComp";
import { PsgrTransportReq } from "../passenger/PsgrTransportReq";
import { JoinTransportReqComp } from "../passenger/JoinTransportReqComp";
import { AcceptingDrvrTrip } from "../passenger/AcceptingDrvrTrip";
import { PsgrTripDtls } from "../passenger/PsgrTripDtls";
import { TakeDecisionOnDrvrReq } from "../passenger/TakeDecisionOnDrvrReq";
import { PsgrChat } from "../passenger/PsgrChat";
import { PortalComp } from "./PortalComp";
import { AllChat } from "../chat/AllChat";
import { PsgrSuspendedReqs } from "../passenger/PsgrSuspendedReqs";
import { PsgrTrips } from "../passenger/PsgrTrips";
import { PsgrFindTrip } from "../passenger/PsgrFindTrip";
import { PsgrAddGoods } from "../passenger/PsgrAddGoods";
import { PsgrGdsDtls } from "../passenger/PsgrGdsDtls";
import { NotificationsCmp } from "../notifications/NotificationsCmp";
import { RateDrvrCmp } from "../passenger/RateDrvrCmp";
import NotFound from "./NotFound";
import { PsgrPayment } from "../passenger/PsgrPayment";

export const PsgrRoutes = () => {
    console.log("First line - PsgrRoutes Component");

    return (
        <>
            <Routes>
                <Route path="dashboard" element={<PsgrDashboardComp></PsgrDashboardComp>} />
                <Route
                    path="psgrTraspReq"
                    element={
                        <RequireAuth>
                            <PsgrTransportReq />
                        </RequireAuth>
                    }
                />
                <Route
                    path="psgrJoinTraspReq/:pReqId/:pDrvrUserId/:pDrvrDestLoc"
                    element={
                        <RequireAuth>
                            <JoinTransportReqComp />
                        </RequireAuth>
                    }
                />
                <Route
                    path="psgrAddGoods/:pReqId/:pDrvrId/:pDrvrDestLoc"
                    element={
                        <RequireAuth>
                            <PsgrAddGoods />
                        </RequireAuth>
                    }
                />
                <Route
                    path="acceptingDrvrTrip/:pReqId/:pDrvrId"
                    element={
                        <RequireAuth>
                            <AcceptingDrvrTrip />
                        </RequireAuth>
                    }
                />

                <Route
                    path="psgrGdsDtl/:pReqId"
                    element={
                        <RequireAuth>
                            <PsgrGdsDtls />
                        </RequireAuth>
                    }
                />

                <Route
                    path="psgrTripDtls/:pReqId/:pDrvrUserId"
                    element={
                        <RequireAuth>
                            <PsgrTripDtls />
                        </RequireAuth>
                    }
                />
                <Route
                    path="takeDecisionOnDrvrReq"
                    element={
                        <RequireAuth>
                            <TakeDecisionOnDrvrReq />
                        </RequireAuth>
                    }
                />
                <Route
                    path="psgrChat"
                    element={
                        <RequireAuth>
                            {/* <PortalComp elementId="chatRoot"> */}
                            <PsgrChat />
                            {/* </PortalComp> */}
                        </RequireAuth>
                    }
                />
                <Route
                    path="allChat"
                    element={
                        <RequireAuth>
                            {/* <PortalComp elementId="chatRoot"> */}
                            <AllChat />
                            {/* </PortalComp> */}
                        </RequireAuth>
                    }
                />
                <Route
                    path="notifications"
                    element={
                        <RequireAuth>
                            {/* <PortalComp elementId="chatRoot"> */}
                            <NotificationsCmp />
                            {/* </PortalComp> */}
                        </RequireAuth>
                    }
                />
                <Route
                    path="testGraph"
                    element={
                        <RequireAuth>
                            <TestGraphComp />
                        </RequireAuth>
                    }
                />
                <Route
                    path="myAccounts"
                    element={
                        <RequireAuth>
                            <MyAccounts />
                        </RequireAuth>
                    }
                />
                <Route
                    path="appSettings"
                    element={
                        <RequireAuth>
                            <AppSettings />
                        </RequireAuth>
                    }
                />
                <Route
                    path="psgrSuspendedReqs"
                    element={
                        <RequireAuth>
                            <PsgrSuspendedReqs />
                        </RequireAuth>
                    }
                />

                <Route
                    path="psgrTrips"
                    element={
                        <RequireAuth>
                            <PsgrTrips />
                        </RequireAuth>
                    }
                />

                <Route
                    path="psgrFindTrip"
                    element={
                        <RequireAuth>
                            <PsgrFindTrip />
                        </RequireAuth>
                    }
                />
                <Route
                    path="rateDrvr/:tripId/:ratee"
                    element={
                        <RequireAuth>
                            <RateDrvrCmp />
                        </RequireAuth>
                    }
                />
                <Route
                    path="psgrPay/:tripId"
                    element={
                        <RequireAuth>
                            <PsgrPayment />
                        </RequireAuth>
                    }
                />

                <Route
                    path="*"
                    element={
                        <NotFound></NotFound>
                    }
                />
            </Routes>
        </>
    );
};
