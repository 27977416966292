import { gql, useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { getGqlStmt } from "../commonSrc/graphQL/Queries";

const TestComponent = () => {
    const client = useApolloClient();
    const [result, setResult]: any = useState();
    //   console.log("ReadQueryResult", result?.getAccount.accNameAr);
    return (
        <>
            <div>Test Component</div>
            <div>{result?.getAccount.accNameAr}</div>
            <input
                type="submit"
                value="ReadQueryInTestComponent"
                onClick={() => {
                    const result = client.readQuery({
                        query: getGqlStmt().queries.GQL_QUERY,
                        // query: gql`
                        //   query aaa {
                        //     getAccount {
                        //       accId
                        //       accNameAr
                        //     }
                        //   }
                        // `,
                        variables: { id: 5 },
                    });
                    console.log("ReadQueryInTestComponent=", result);
                    console.log("ReadQueryInTestComponent, Cache=", (client.extract() as any).ROOT_QUERY);
                    // ['customObject({"param1":"1"})'].att2
                    setResult(result);
                }}
            />

            <input
                type="submit"
                value="ReadFragmentInTestComponent"
                onClick={() => {
                    const fragResult = client.readFragment({
                        id: 'GlAccount:{"accId":5}', // The value of the to-do item's cache ID
                        fragment: gql`
                            fragment MyGlFrag on GlAccount {
                                accNameAr
                            }
                        `,
                    });
                    console.log("fragResult=", fragResult);
                }}
            ></input>

            <input
                type="submit"
                value="WriteFragmentInTestComponent"
                onClick={() => {
                    const fragResult = client.writeFragment({
                        id: 'GlAccount:{"accId":5}', // The value of the to-do item's cache ID
                        fragment: gql`
                            fragment MyGlFrag on GlAccount {
                                accNameAr
                            }
                        `,
                        data: {
                            accNameAr: "Fragment Updated Account",
                        },
                    });
                    console.log("fragResult=", fragResult);
                }}
            ></input>

            <input
                type="submit"
                value="UpdateQueryInTestComponent"
                onClick={() => {
                    client.cache.updateQuery(
                        {
                            query: getGqlStmt().queries.GQL_QUERY,
                            // id: 'GlAccount:{"accId":5}',
                            variables: { id: 5 },
                        },
                        (data) => {
                            console.log("ddd=", data);

                            return {
                                // console.log("AllCache=", client.cache.extract());
                                // return

                                getAccount: {
                                    ...data.getAccount,
                                    accId: 5,
                                },
                            };
                        }
                    );
                }}
            ></input>

            <input
                type="submit"
                value="CashModifyInTestComponent"
                onClick={() => {
                    console.log("CashModify Button");
                    //client.cache.gc();
                    client.cache.modify({
                        id: 'GlAccount:{"accId":5}',
                        fields: {
                            accNameAr(cachedName, { DELETE }) {
                                console.log("cachedName=", cachedName);
                                // return cachedName + " OK";
                                return DELETE;
                            },
                        },
                    });
                }}
            ></input>

            <input
                type="submit"
                value="EvictnTestComponent"
                onClick={() => {
                    console.log("CashModify Button");
                    //client.cache.gc();
                    client.cache.evict({
                        id: "ROOT_QUERY",
                        // fieldName: "accNameAr",
                    });
                }}
            ></input>
        </>
    );
};

export default TestComponent;
