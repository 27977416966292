import { PropsWithChildren, useEffect, useState } from "react";
import { ReservedSeatsT, SeatSelectionStatus } from "../../../commonSrc/Types";
import { PsgrSeat } from "./PsgrSeat";
import { PsgrVclSeatSelector } from "./PsgrVclSeatSelector";

export function PsgrVclSeats(
    props: PropsWithChildren<{
        seatRdc: [PsgrVclSeatSelector.SeatStT, PsgrVclSeatSelector.SeatDisT];
        vclStructure: any[];
        alreadyReservedSeats: ReservedSeatsT[];
        reqId: number;
        drvrUserId: number;
        onChange?: () => void;
    }>
) {
    console.log("First line - VehicleSeats Component");

    const vclStructure = [
        {
            id: 11,
            reqId: null,
            vclId: null,
            y: 1,
            x1: "Y",
            x2: "N",
            x3: "Y",
        },
        {
            id: 12,
            reqId: null,
            vclId: null,
            y: 2,
            x1: "Y",
            x2: "Y",
            x3: "Y",
        },
        {
            id: 13,
            reqId: null,
            vclId: null,
            y: 3,
            x1: "Y",
            x2: "Y",
            x3: "N",
        },
    ];

    const psgrsInVehicle = [
        {
            id: 11,
            reqId: 103,
            userId: 30,
            y: 1,
            x: 3,
            gender: "M",
            join_time: "2023-08-04 15:24:40",
            src_position: "",
            dest_position: "",
        },
        {
            id: 11,
            reqId: 103,
            userId: 17,
            y: 2,
            x: 1,
            gender: "F",
            join_time: "2023-08-04 15:24:40",
            src_position: "",
            dest_position: "",
        },
        {
            id: 11,
            reqId: 103,
            userId: 31,
            y: 2,
            x: 2,
            gender: "F",
            join_time: "2023-08-04 15:24:40",
            src_position: "",
            dest_position: "",
        },
    ];

    let [eachDiv, setEachDiv] = useState<JSX.Element[]>([]);

    let seatsSt: PsgrVclSeatSelector.SeatStT = {} as PsgrVclSeatSelector.SeatStT;
    let seatDis: PsgrVclSeatSelector.SeatDisT = () => {};

    [seatsSt, seatDis] = props.seatRdc;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        eachDiv = [];

        props.vclStructure?.map((eachThree, vclStrucIndx) => {
            eachDiv.push(
                <div key={eachThree.vclId+eachThree.y} className="row justify-content-center">
                    {[1, 2, 3].map((seatNo) => {
                        const psgrInfo = props.alreadyReservedSeats?.find((psgr, idx) => {
                            return psgr.y === eachThree.y && psgr.x === seatNo;
                        });

                        return (
                            <PsgrSeat
                                seatId={seatNo + vclStrucIndx}
                                seatRow={eachThree.y}
                                seatNoInRow={seatNo}
                                key={"seatNo" + seatNo + "Y" + vclStrucIndx}
                                psgrInfo={psgrInfo ?? { reqId: props.reqId }}
                                seatRdc={[seatsSt, seatDis]}
                                alreadyReservedSeats={props.alreadyReservedSeats}
                                isEmpty={(eachThree.y === 1 && seatNo === 2) || eachThree[("x" + seatNo) as keyof typeof eachThree] === "N"}
                                onChange={props.onChange}
                            />
                        );
                    })}
                </div>
            );
        });

        setEachDiv(eachDiv);
        setLoading(false);
    }, []);

    //below works fine as well

    if (loading) return <div>loading</div>;
    return (
        <div dir="ltr" className="">
            {eachDiv.map((v, i) => v)}
        </div>
    );
}
