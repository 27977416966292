import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { createElement, FormEvent, MouseEvent, ReactElement, ReactNode, useEffect, useReducer, useRef, useState } from "react";
import { createPortal, flushSync, render } from "react-dom";
import { createRoot } from "react-dom/client";
import { NavLink, useOutletContext } from "react-router-dom";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { ImageComp } from "../commonComp/ImageComp";
import { ModalComp } from "../commonComp/ModalComp";
import { PortalComp } from "../commonComp/PortalComp";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { SERVER_NAME } from "../../commonSrc/EnvVariables";
import { getAccessInfo, getGraphQlError, refreshRootComponent } from "../../commonSrc/commonFns";
import { useRadioGroup } from "@mui/material";
import { country_cities } from "../../commonSrc/textResources/country_cities";
import { vehicleMakes } from "../../commonSrc/textResources/vehicleMakes";
import { vehicleModels } from "../../commonSrc/textResources/vehicleModels";
import { StickyBar } from "../commonComp/StickyBar";
import { CrudTypes } from "../../commonSrc/Constants";
import { SelectValueType } from "../../commonSrc/Types";
import { VehicleSeatSelector } from "../commonComp/vehicle/VehicleSeatSelector";
import { GraphQLErrors } from "@apollo/client/errors";

export function UserCrud(
    props: React.PropsWithChildren<{
        crudType: CrudTypes;
    }>
) {
    console.log("First line - NewUser Component");
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    //useReactiveVar(accessTokenRvar);
    /**
     * another way to upload image
     */
    /* 
    let imagea: any;
    const [imageContent, setImageContent] = useState("");
    function onImageUpload(this: any, e: Event) {
        // const image_input = document.querySelector("#image-input");
        // image_input.addEventListener("change", function() {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imgBase64 = reader.result;
            setImageContent(imgBase64 as string);
            //   if (g) {
            //     g.style.backgroundImage = `url(${aa})`;
            //     // g.style.backgroundAttachment = "fixed";
            //     g.style.backgroundRepeat = "no-repeat";
            //     g.style.backgroundSize = "contain";
            //   }
        });
        reader.readAsDataURL(imageRef.current?.files?.item(0) as Blob) as any;
    }
    //   imageRef.current.onchange = onImageUpload;
    //   setImageContent()  */
    /* const [emailSt, emialDipatch] = useReducer(
        (state: any, action: any) => {
            if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
            if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
            return state;
        },
        { value: "", errorMsg: "" }
    ); */
    const [vehicleYearsArrSt, setVehicleYearsArrSt] = useState<SelectValueType>([]);
    const [userVehicleYear, setUserVehicleYear] = useState("");
    const [vclLoadArrSt, setVclLoadArrSt] = useState<SelectValueType>([]);

    const [emailSent, setEmailSent] = useState(false);
    const vehicleYears: SelectValueType = [];
    const vehicleLoad: SelectValueType = [];

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        JsxComp: UserEmail,
        compReducer: [userEmailSt, userEmailDis],
        compRef: userEmailRef,
    } = FormCmps.Email.getComp();

    const {
        JsxComp: VclOwnerEmailCmp,
        compReducer: [vclOwnerEmailSt, vclOwnerEmailDis],
        compRef: vclOwnerEmailRef,
    } = FormCmps.Email.getComp();

    const {
        JsxComp: UserType,
        compReducer: [userTypeSt, userTypeDis],
        compRef: userTypeRef,
    } = FormCmps.RadioCmp.getComp();
    // const [userTypeSt, userTypeDis] = useState("");

    const {
        JsxComp: UserGender,
        compReducer: [userGenderSt, userGenderDis],
        compRef: userGenderRef,
    } = FormCmps.RadioCmp.getComp();

    const {
        JsxComp: UserPhone,
        compReducer: [userPhoneSt, userPhoneDis],
        compRef: psgrDrvrPhoneNoRef,
    } = FormCmps.Phone.getComp();

    const {
        JsxComp: UserPassword,
        compReducer: [userPassSt, userPassStDis],
        compRef: userPassRef,
    } = FormCmps.Passrd.getComp();

    const {
        JsxComp: UserConfirmPassword,
        compReducer: [userConfirmPassSt, userConfirmPassStDis],
        compRef: userConfirmPassRef,
    } = FormCmps.Passrd.getComp();

    const {
        JsxComp: Username1,
        compReducer: [username1St, username1Dis],
        compRef: psgrDrvrName1Ref,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: Username2,
        compReducer: [username2St, username2Dis],
        compRef: psgrDrvrName2Ref,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: Username3,
        compReducer: [username3St, username3Dis],
        compRef: psgrDrvrName3Ref,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: Username4,
        compReducer: [username4St, username4Dis],
        compRef: psgrDrvrName4Ref,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: UserNationalIdCmp,
        compReducer: [userNationalIdSt, userNationalIdDis],
        compRef: nationalNoRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: ColorInput,
        compRef: vclColorRef,
        compReducer: [colorInputSt, colorInputDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: PlateId,
        compRef: vclPlatIdRef,
        compReducer: [plateIdSt, plateIdDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: IsVclOwner,
        compReducer: [isVclOwnerSt, isVclOwnerDis],
        compRef: isVclOwnerRef,
    } = FormCmps.RadioCmp.getComp();

    const {
        JsxComp: VclOwnerNationalIdCmp,
        compRef: vclOwnerNationalNoRef,
        compReducer: [vclOwnerNationalIdSt, vclOwnerNationalIdDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: VclOwnerName1,
        compRef: vclOwnerName1Ref,
        compReducer: [vclOwner1NameSt, vlcOwner1NameDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: VclOwnerName2,
        compRef: vclOwnerName2Ref,
        compReducer: [vclOwner2NameSt, vlcOwner2NameDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: VclOwnerName3,
        compRef: vclOwnerName3Ref,
        compReducer: [vclOwner3NameSt, vlcOwner3NameDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: VclOwnerName4,
        compRef: vclOwnerName4Ref,
        compReducer: [vclOwner4NameSt, vlcOwner4NameDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: VclRegNoCmp,
        compRef: vclRegNoRef,
        compReducer: [vclRegNoCmpSt, vlcRegNoCmpDis],
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: UserCountryCmp,
        compReducer: [userCountrySt, userCountryDis],
        compRef: userCountryRef,
    } = FormCmps.CountryCmp.getComp();

    const {
        JsxComp: VclOwnerPhoneNo,
        compReducer: [vclOwnerPhoneNoSt, vclOwnerPhoneNoDis],
        compRef: vclOwnerPhoneNoRef,
    } = FormCmps.Phone.getComp();

    const {
        JsxComp: UserCityAutoComplete,
        compReducer: [userCityAutoCompleteSt, userCityAutoCompleteDis],
        compRef: userCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: VclMakeAutoComplete,
        compReducer: [vclMakeAutoCompleteSt, vclMakeAutoCompleteDis],
        compRef: vclMakeAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: VclModelAutoComplete,
        compReducer: [vclModelAutoCompleteSt, vclModelAutoCompleteDis],
        compRef: vclModelAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: UploadDrvrLicPhoto,
        compReducer: [uploadDrvrLicPhotoSt, uploadDrvrLicPhotoDis],
        compRef: drvrLicPhotoRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: UploadIdFrEnd,
        compReducer: [uploadIdFrEndSt, uploadIdFrEndDis],
        compRef: idFronEndPicRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: UploadIdBkEnd,
        compReducer: [uploadIdBkEndSt, uploadIdBkEndDis],
        compRef: idBackEndPicRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: UploadPrsnlPic,
        // compReducer: [uploadIdFrEndSt, uploadIdFrEndDis],
        compRef: prsnlPicRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: UploadVclLicensePhotoId,
        compReducer: [uploadVclLicensePhotoIdSt, uploadVclLicensePhotoIdDis],
        compRef: vclLicesnePhotoRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: UploadVclPhoto,
        // compReducer: [uploadIdFrEndSt, uploadIdFrEndDis],
        compRef: vclPhotoRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: WebsiteCmp,
        // compReducer: [uploadIdFrEndSt, uploadIdFrEndDis],
        compRef: websiteRef,
    } = FormCmps.UploadCmp.getComp();

    const {
        JsxComp: SelectUserVehicleYear,
        compReducer: [userVehicleYearSt, setUserVehicleYearDis],
        compRef: vehicleYearRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: SelectCompanyType,
        compReducer: [selectCompanyTypeSt, selectCompanyTypeDis],
        compRef: companyTypeRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: SelectVclLoad,
        compReducer: [selectVclLoadSt, selectVclLoadDis],
        compRef: vclLoadRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: VclSeatSelector,
        compReducer: [vclSeatSelectorSt, vclSeatSelectorStDis],
        compRef: vclSeatSelectorStRef,
    } = VehicleSeatSelector.getComp();

    const {
        JsxComp: Lang,
        compReducer: [langSt, langDis],
        compRef: langRef,
    } = FormCmps.SelectCmp.getComp();
    /////////////////////////////////////////////////// END ////////////////////////////////////////////////////////////////////////////////////////////////

    //no more used
    /*     const [getCompany, { error: getCompanyError, data: getCompanyData, loading: getCompanyLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_COMPANY,
        {
            onCompleted(data) {
                userInfoRvar(data.getCompany);
            },
            variables: {
                username: getAccessInfo()?.username,
            },
            fetchPolicy: "no-cache",
        }
    ); */
    //no more used
    useEffect(() => {
        if (props.crudType === CrudTypes.UPDATE) {
            //no need, we already call it in App component when we change the lang to get the company name in the header
            // getCompany();
        }

        for (let i: number = 0; i < 34; i++) {
            const currYear = new Date().getFullYear();
            vehicleYears[i] = { selectLableAndId: (currYear - i).toString(), valueKey: (currYear - i).toString() };
        }
        setVehicleYearsArrSt(vehicleYears);

        for (let i: number = 0; i < 75; i++) {
            vehicleLoad[i] = { selectLableAndId: (i + 1).toString(), valueKey: (i + 1).toString() };
        }
        setVclLoadArrSt(vehicleLoad);
    }, [dirAndLangRvar().lang]);

    const [createPassenger, { error: createPsgrError, data: createPsgrData, loading: createPsgrLoading }] = useMutation(
        getGqlStmt().mutations.CREATE_PASSENGER,
        {
            fetchPolicy: "no-cache",
        }
    );

    const [createDrvrAndVcl, { error: createDrvrAndVclError, data: createDrvrAndVclData, loading: createDrvrAndVclLoading }] = useMutation(
        getGqlStmt().mutations.CREATE_DRIVER_AND_VEHICLE,
        {
            fetchPolicy: "no-cache",
        }
    );
    // const [createDriver, { error: createDrvrError, data: createDrvrData, loading: createDrvrLoading }] = useMutation(
    //     getGqlStmt().mutations.CREATE_DRIVERR,
    //     {
    //         fetchPolicy: "no-cache",
    //     }
    // );

    const [updateCompany, { error: updateCompanyError, data: updateCompanyData, loading: updateCompanyLoading }] = useMutation(
        getGqlStmt().mutations.UPDATE_COMPANY,
        {
            fetchPolicy: "no-cache",
        }
    );

    async function isFormValid(): Promise<boolean> {
        let isFormValid: boolean = true;
        const okModalInstance = new ModalComp.OkModal({ isPortal: false }, { msg: "Please fill all required fields in red", navTo: "" });

        if (FormCmps.Email.getValue(userEmailRef)?.length == 0) {
            userEmailDis({ type: FormCmps.Email.emailActDisTypesT.ErrorMsg, errorMsg: "Invalid Email" });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        userEmailRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        isFormValid = await FormCmps.RadioCmp.validate(
            userTypeSt,
            userTypeDis,
            isFormValid,
            okModalInstance,
            userTypeRef,
            "select user type"
        );

        isFormValid = await FormCmps.RadioCmp.validate(
            userGenderSt,
            userGenderDis,
            isFormValid,
            okModalInstance,
            userGenderRef,
            "select gender"
        );

        if (!FormCmps.Passrd.getValue(userPassRef)) {
            userPassStDis({
                type: FormCmps.Passrd.passrdActDisTypesT.ErrorMsg,
                errorMsg: "type a password",
            });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        userPassRef.current?.scrollIntoView({ block: "center" });
                    },
                });

                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        if (!FormCmps.Passrd.getValue(userConfirmPassRef) || userConfirmPassSt.errorMsg) {
            if (!FormCmps.Passrd.getValue(userConfirmPassRef))
                userConfirmPassStDis({
                    type: FormCmps.Passrd.passrdActDisTypesT.ErrorMsg,
                    errorMsg: "Please confirm your password",
                });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        userConfirmPassRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        if ((FormCmps.Phone.getValue(userPhoneSt)?.length ?? 0) < 5) {
            userPhoneDis({
                type: FormCmps.Phone.phoneActDisTypesT.ErrorMsg,
                errorMsg: "Please enter the phone number",
            });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        psgrDrvrPhoneNoRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        if (!FormCmps.TextCmp.getValue(psgrDrvrName1Ref)) {
            username1Dis({ type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg, errorMsg: "Please fill your name" });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        psgrDrvrName1Ref.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        isFormValid = await FormCmps.CountryCmp.validate(userCountryRef, userCountryDis, isFormValid, okModalInstance);

        /* if (!FormCmps.CountryCmp.getValue(userCountryRef)) {
            userCountryDis({ type: FormCmps.CountryCmp.countryActDisTypesT.ErrorMsg, errorMsg: "Please select country" });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        userCountryRef.current?.scrollIntoView({ block: "center" });
                    },
                });
              // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        } */

        isFormValid = await FormCmps.AutoCompleteCmp.validate(
            userCityAutoCompleteRef,
            userCityAutoCompleteSt,
            userCityAutoCompleteDis,
            isFormValid,
            okModalInstance,
            "Please select city"
        );

        if (!FormCmps.TextCmp.getValue(nationalNoRef)) {
            userNationalIdDis({ type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg, errorMsg: "Please enter national number" });
            if (isFormValid) {
                okModalInstance.setProps({
                    okClickHandler: () => {
                        nationalNoRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
            }
            isFormValid = false;
        }

        if (FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR") {
            isFormValid = await FormCmps.AutoCompleteCmp.validate(
                vclMakeAutoCompleteRef,
                vclMakeAutoCompleteSt,
                vclMakeAutoCompleteDis,
                isFormValid,
                okModalInstance,
                "Please select vehicle make"
            );

            isFormValid = await FormCmps.AutoCompleteCmp.validate(
                vclModelAutoCompleteRef,
                vclModelAutoCompleteSt,
                vclModelAutoCompleteDis,
                isFormValid,
                okModalInstance,
                "Please select vehicle model"
            );

            if (!userVehicleYearSt.value) {
                setUserVehicleYearDis({
                    type: FormCmps.SelectCmp.selectActDisTypesT.ErrorMsg,
                    errorMsg: "Please select vehicle year",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vehicleYearRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!vclColorRef.current?.value) {
                colorInputDis({
                    type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                    errorMsg: "Please enter the vehicle color",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vclColorRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!selectVclLoadSt.value) {
                selectVclLoadDis({
                    type: FormCmps.SelectCmp.selectActDisTypesT.ErrorMsg,
                    errorMsg: "Please select vehicle load",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vclLoadRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            console.log(VehicleSeatSelector.getPsgrSeatsCount(vclSeatSelectorSt.value ?? []));

            if (VehicleSeatSelector.getPsgrSeatsCount(vclSeatSelectorSt.value ?? []) !== +(selectVclLoadSt?.value ?? 0)) {
                vclSeatSelectorStDis({
                    type: VehicleSeatSelector.SeatActDisTypesT.ErrorMsg,
                    errorMsg: "The number of selected seats doesn't match the vehicle load",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vclSeatSelectorStRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!vclPlatIdRef.current?.value) {
                plateIdDis({
                    type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                    errorMsg: "Please enter the vehicle plate's number",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vclPlatIdRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!FormCmps.UploadCmp.getValue(uploadDrvrLicPhotoSt)) {
                uploadDrvrLicPhotoDis({
                    type: FormCmps.UploadCmp.uploadActDisTypesT.ErrorMsg,
                    errorMsg: "This field is required",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            idFronEndPicRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!FormCmps.UploadCmp.getValue(uploadIdFrEndSt)) {
                uploadIdFrEndDis({
                    type: FormCmps.UploadCmp.uploadActDisTypesT.ErrorMsg,
                    errorMsg: "This field is required",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            idFronEndPicRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!FormCmps.UploadCmp.getValue(uploadIdBkEndSt)) {
                uploadIdBkEndDis({
                    type: FormCmps.UploadCmp.uploadActDisTypesT.ErrorMsg,
                    errorMsg: "This field is required",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            idBackEndPicRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (!FormCmps.UploadCmp.getValue(uploadVclLicensePhotoIdSt)) {
                uploadVclLicensePhotoIdDis({
                    type: FormCmps.UploadCmp.uploadActDisTypesT.ErrorMsg,
                    errorMsg: "This field is required",
                });
                if (isFormValid) {
                    okModalInstance.setProps({
                        okClickHandler: () => {
                            vclLicesnePhotoRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await okModalInstance.showAsync();
                }
                isFormValid = false;
            }

            if (FormCmps.RadioCmp.getValue(isVclOwnerSt) === "NO") {
                if (vclOwnerNationalNoRef.current?.value.length === 0) {
                    vclOwnerNationalIdDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the owner national number",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclOwnerNationalNoRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }

                if (vclPlatIdRef.current?.value.length === 0) {
                    plateIdDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the vehicle plate number",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclPlatIdRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }

                if (!vclOwnerName1Ref.current?.value) {
                    vlcOwner1NameDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the owner name",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclOwnerName1Ref.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }

                if (!vclRegNoRef.current?.value) {
                    vlcRegNoCmpDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the vehicle registration number",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclRegNoRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }

                if (!vclOwnerPhoneNoSt.value) {
                    vclOwnerPhoneNoDis({
                        type: FormCmps.Phone.phoneActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the vehicle owner phone number",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclOwnerPhoneNoRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }

                if (!vclOwnerEmailRef.current?.value) {
                    vclOwnerEmailDis({
                        type: FormCmps.Email.emailActDisTypesT.ErrorMsg,
                        errorMsg: "Enter the vehicle owner email",
                    });
                    if (isFormValid) {
                        okModalInstance.setProps({
                            okClickHandler: () => {
                                vclOwnerEmailRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await okModalInstance.showAsync();
                    }
                    isFormValid = false;
                }
            }
        }

        return isFormValid;
    }

    async function onSubmitHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        // await ModalComp.showAsync(spinner);//commented recently and not tested
        await spinner.showAsync();

        if (props.crudType === CrudTypes.CREATE) {
            let createPsgrDrvrRes;
            const okModalInstance = new ModalComp.OkModal({ isPortal: false }, { msg: "Please fill all required fields ", navTo: "" });
            try {
                if (FormCmps.RadioCmp.getValue(userTypeSt) === "PSGR") {
                    console.log("prsnlPicRef.current?.files?.item(0)", prsnlPicRef.current?.files?.item(0));
                    console.log(
                        "FormCmps.AutoCompleteCmp.getValue(userCountryRef).code",
                        FormCmps.AutoCompleteCmp.getValue(userCountryRef).code
                    );
                    createPsgrDrvrRes = await createPassenger({
                        errorPolicy: "all",
                        variables: {
                            user: {
                                userType: FormCmps.RadioCmp.getValue(userTypeSt),
                                email: FormCmps.Email.getValue(userEmailRef),
                                password: FormCmps.Passrd.getValue(userPassRef),
                                phoneNo: FormCmps.Phone.getValue(psgrDrvrPhoneNoRef),
                                userName1Ar: FormCmps.TextCmp.getValue(psgrDrvrName1Ref),
                                userName2Ar: FormCmps.TextCmp.getValue(psgrDrvrName2Ref),
                                userName3Ar: FormCmps.TextCmp.getValue(psgrDrvrName3Ref),
                                userName4Ar: FormCmps.TextCmp.getValue(psgrDrvrName4Ref),
                                country: FormCmps.CountryCmp.getValue(userCountrySt).code,
                                city: FormCmps.AutoCompleteCmp.getValue(userCityAutoCompleteSt).id,
                                gender: FormCmps.RadioCmp.getValue(userGenderSt),
                                nationalNo: +(FormCmps.TextCmp.getValue(nationalNoRef) as string),
                                // psgrPhoto: FormCmps.UploadCmp.getValue(prsnlPicRef),
                            },

                            userPhoto: prsnlPicRef.current?.files?.item(0),
                            // file: { isAuthenticated: true, token: "ttttttttttttttt", username: "uuuuuuuuuuuuuuuuu" },
                            // id: +(accIdRef.current?.value||-1),
                        },
                        context: {
                            headers: {
                                /* no need to pass this explicity, Graphql will append the necessary content-type based on the input
                                 *  it may be application/json or multipart/form-data with auto generated boundary */
                                // "content-type": "multipart/form-data; boundary=----MyBoundary",

                                //this header is required in case of sending binary file
                                "apollo-require-preflight": true,
                            },
                        },
                        onCompleted: async (data) => {
                            // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);//commented recently and not tested
                            await spinner.hideAsync();
                            if (data?.createUser?.success) {
                                okModalInstance.setProps({
                                    msg: (
                                        <p>
                                            {ReadTextResource.getTextById("SAVED_SUCCES")} <br />
                                            {ReadTextResource.getTextById("ACTV_CODE_SENT")}
                                        </p>
                                    ),
                                    //commented temporarily
                                    navTo: "/",
                                });
                            } else {
                                console.log("createPassenger mutation is not successfull -abd-");
                                if (data?.createUser?.errorId === "USER_EXIST")
                                    okModalInstance.setProps({
                                        msg: "Passenger already exist!",
                                    });
                                else
                                    okModalInstance.setProps({
                                        msg: "Sorry, an error occured, please call the administrator",
                                    });
                            }
                            // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                            await okModalInstance.showAsync();
                        },
                        onError: async (err) => {
                            const errorId = getGraphQlError(err).errorId;
                            console.log("createPassenger1 mutation is not successfull -abd- err=", getGraphQlError(err).gqlErrors);
                            // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);//commented recently and not tested
                            await spinner.hideAsync();
                            if (errorId === "USER_EXIST")
                                okModalInstance.setProps({
                                    msg: "Passenger already exist!",
                                });
                            else {
                                okModalInstance.setProps({
                                    msg: "Sorry, an error occured, please call the administrator",
                                });
                            }

                            // await ModalComp.showAsync(okModalInstance); //commented recently and not tested
                            await okModalInstance.showAsync();
                        },
                    });
                } else if (FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR") {

                    createPsgrDrvrRes = await createDrvrAndVcl({
                        // errorPolicy: "all",
                        variables: {
                            user: {
                                userType: FormCmps.RadioCmp.getValue(userTypeSt),
                                email: FormCmps.Email.getValue(userEmailRef),
                                password: FormCmps.Passrd.getValue(userPassRef),
                                phoneNo: FormCmps.Phone.getValue(psgrDrvrPhoneNoRef),
                                userName1Ar: FormCmps.TextCmp.getValue(psgrDrvrName1Ref),
                                userName2Ar: FormCmps.TextCmp.getValue(psgrDrvrName2Ref),
                                userName3Ar: FormCmps.TextCmp.getValue(psgrDrvrName3Ref),
                                userName4Ar: FormCmps.TextCmp.getValue(psgrDrvrName4Ref),
                                country: FormCmps.CountryCmp.getValue(userCountrySt).code,
                                city: FormCmps.AutoCompleteCmp.getValue(userCityAutoCompleteSt).id,
                                gender: FormCmps.RadioCmp.getValue(userGenderSt),
                                nationalNo: +(FormCmps.TextCmp.getValue(nationalNoRef) as string),
                                // drvrNationalIdFrEndPhoto: FormCmps.UploadCmp.getValue(idFronEndPicRef),
                                // drvrNationalIdBkEndPhoto: FormCmps.UploadCmp.getValue(idBackEndPicRef),
                                // drvrPhoto: FormCmps.UploadCmp.getValue(prsnlPicRef),
                                // drvrLicPhoto: FormCmps.UploadCmp.getValue(drvrLicPhotoRef),
                            },
                            vcl: {
                                // vclOwnerNationalNo: +(FormCmps.TextCmp.getValue(vclOwnerNationalNoRef) as string),
                                // vclOwnerPhoneNo: FormCmps.Phone.getValue(vclOwnerPhoneNoRef) as string,
                                // vclOwnerEmail: FormCmps.Email.getValue(vclOwnerEmailRef),
                                vclReglNo: +(FormCmps.TextCmp.getValue(vclRegNoRef) as string),
                                vclMake: FormCmps.AutoCompleteCmp.getValue(vclMakeAutoCompleteSt),
                                vclModel: FormCmps.AutoCompleteCmp.getValue(vclModelAutoCompleteSt),
                                year: +(FormCmps.SelectCmp.getValue(vehicleYearRef) as string),
                                plateId: FormCmps.TextCmp.getValue(vclPlatIdRef) as string,
                                vclType: "Sedan",
                                vclColor: FormCmps.TextCmp.getValue(vclColorRef),
                                psgrsWithoutDrvr: +(FormCmps.SelectCmp.getValue(vclLoadRef) as string),
                                // vclPhoto: FormCmps.UploadCmp.getValue(vclPhotoRef),
                                // vclLicPhoto: FormCmps.UploadCmp.getValue(vclLicesnePhotoRef),
                            },
                            vclOwner: {
                                vclOwnerNationalNo: isVclOwnerSt.value === "YES" ? +(FormCmps.TextCmp.getValue(nationalNoRef) as string) : +(FormCmps.TextCmp.getValue(vclOwnerNationalNoRef) as string),
                                vclOwnerPhoneNo: isVclOwnerSt.value === "YES" ? userPhoneSt.value : FormCmps.Phone.getValue(vclOwnerPhoneNoRef) as string,
                                vclOwnerEmail: isVclOwnerSt.value === "YES" ? FormCmps.Email.getValue(userEmailRef) : FormCmps.Email.getValue(vclOwnerEmailRef),
                                ownerName1Ar: isVclOwnerSt.value === "YES" ? FormCmps.TextCmp.getValue(psgrDrvrName1Ref) : vclOwner1NameSt.value,
                                ownerName2Ar: isVclOwnerSt.value === "YES" ? FormCmps.TextCmp.getValue(psgrDrvrName2Ref) : vclOwner2NameSt.value,
                                ownerName3Ar: isVclOwnerSt.value === "YES" ? FormCmps.TextCmp.getValue(psgrDrvrName3Ref) : vclOwner3NameSt.value,
                                ownerName4Ar: isVclOwnerSt.value === "YES" ? FormCmps.TextCmp.getValue(psgrDrvrName4Ref) : vclOwner4NameSt.value,
                            },
                            vclSeats: vclSeatSelectorSt.value,
                            userNationalIdFrEndPhoto: idFronEndPicRef.current?.files?.item(0),
                            userNationalIdBkEndPhoto: idBackEndPicRef.current?.files?.item(0),
                            userPhoto: prsnlPicRef.current?.files?.item(0),
                            drvrLicPhoto: drvrLicPhotoRef.current?.files?.item(0),
                            vclLicPhoto: vclLicesnePhotoRef.current?.files?.item(0),
                            vclPhoto: vclPhotoRef.current?.files?.item(0),
                        },
                        context: {
                            headers: {
                                /* no need to pass this explicity, Graphql will append the necessary content-type based on the input
                                 *  it may be application/json or multipart/form-data with auto generated boundary */
                                // "content-type": "multipart/form-data; boundary=----MyBoundary",

                                //this header is required in case of sending binary file
                                "apollo-require-preflight": true,
                            },
                        },
                        onCompleted: async (data) => {
                            console.log("data=", data);
                            // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);//commented recently and not tested
                            await spinner.hideAsync();
                            try {
                                if (
                                    data?.createUser?.success &&
                                    data?.createUser?.mutation?.getOrCreateVclOwner?.success &&
                                    data?.createUser?.mutation?.getOrCreateVclOwner?.mutation?.createVcl?.success &&
                                    data?.createUser?.mutation?.getOrCreateVclOwner?.mutation?.createVcl?.mutation?.createDrvr?.success
                                ) {
                                    okModalInstance.setProps({
                                        msg: (
                                            <p>
                                                {ReadTextResource.getTextById("SAVED_SUCCES")} <br />
                                                {ReadTextResource.getTextById("ACTV_CODE_SENT")}
                                            </p>
                                        ),
                                        navTo: "/",
                                    });
                                } else {
                                    console.log("createDrvrAndVcl mutation is not successfull -abd-", data);
                                    if (data?.createUser?.errorId === "USER_EXIST")
                                        okModalInstance.setProps({
                                            msg: "Driver already exist!",
                                        });
                                    else if (data?.createUser?.errorId === "VCL_EXIST_ERR")
                                        okModalInstance.setProps({
                                            msg: "Vehicle already exist!",
                                        });
                                    else
                                        okModalInstance.setProps({
                                            msg: "Sorry3, an error occured, please call the administrator abd-5",
                                        });
                                }
                            } catch (err) {
                                okModalInstance.setProps({
                                    msg: <p>Sorry, an error occured in OnComplete abd-9.</p>,
                                    //commneted temporarily
                                    // navTo: "/",
                                });
                            } finally {
                                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                                await okModalInstance.showAsync();
                            }
                        },
                        onError: async (err) => {
                            console.log("createDrvrAndVcl mutation is not successfull -abd2- onError", err.graphQLErrors);
                            const errorId = getGraphQlError(err).errorId;
                            // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);//commented recently and not tested
                            await spinner.hideAsync();
                            if (errorId === "USER_EXIST")
                                okModalInstance.setProps({
                                    msg: "Driver already exist!",
                                });
                            else if (errorId === "VCL_EXIST_ERR")
                                okModalInstance.setProps({
                                    msg: "Vehicle already exist!",
                                });
                            else {
                                okModalInstance.setProps({
                                    msg: "Sorry, an error occurred, abd-4",
                                });
                            }
                            // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                            await okModalInstance.showAsync();
                        },
                    });
                }
            } catch (err) {
                //OK
                // const okModal = ModalComp.OkModal;
                // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);//commented recently and not tested
                await spinner.hideAsync();

                console.log("errrrrrrrrrrrrrrrrrrr2=", err);
                okModalInstance.setProps({ msg: "Sorry1, an error occured, please call the administrator, abd-10" + err });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await okModalInstance.showAsync();
                return;
            }
            //duplicate
            // await ModalComp.hideAsync(ModalComp.IDs.SPINNER_MODAL);
        }

        if (props.crudType === CrudTypes.UPDATE) {
            const okModalInnstance = new ModalComp.OkModal({ isPortal: false }, { msg: "", navTo: "" });
            try {
                await updateCompany({
                    variables: {
                        username: getAccessInfo()?.username,
                        company: {
                            ...(dirAndLangRvar().lang === "en" && { companyNameLng1: userGenderSt }),
                            ...(dirAndLangRvar().lang === "ar" && { companyNameLng2: userGenderSt }),
                            // companyNameLng2 : companyNameLng2,
                            // companyType: companyType,
                            email: FormCmps.Email.getValue(userEmailRef),
                            // phoneNumber: userPhoneNumber,
                            // phoneNumber2: compPhoneNumber1,
                            // phoneNumber3: compPhoneNumber2,
                            // website: website,
                        },
                        file: prsnlPicRef.current?.files?.item(0),
                    },
                    context: {
                        headers: {
                            /* no need to pass this explicity, Graphql will append the necessary content-type based on the input
                             *  it may be application/json or multipart/form-data with auto generated boundary */
                            // "content-type": "multipart/form-data",

                            //this header is required in case of sending binary file
                            "apollo-require-preflight": true,
                        },
                    },
                    onCompleted(data) {
                        okModalInnstance.setProps({
                            msg: <div>{ReadTextResource.getTextById("SAVED_SUCCES")}</div>,
                            refreshRootComponent: true,
                        });
                    },
                });
            } catch (err) {
                //OK
                console.log("errrrrrrrrrrrrrrrrrrr=", err);
                // const okModal = ModalComp.OkModal;
                okModalInnstance.setProps({ msg: "Sorry, an error occured, please call the administrator" });
            }
        }
    }

    // if (updateCompanyLoading) return <ModalComp.SpinnerPortalModal.JsxComp autoShow={true}></ModalComp.SpinnerPortalModal.JsxComp>;

    if (props.crudType === CrudTypes.CREATE) {
        return (
            <>
                <div className="p-3">
                    <StickyBar id="mainStickBar1">
                        <FormCmps.FormSubmitBtn
                            id="submitBtn"
                            label={ReadTextResource.getTextById("SAVE")}
                            onOkClickHandler={onSubmitHandler}
                        ></FormCmps.FormSubmitBtn>
                    </StickyBar>

                    <FormCmps.ContainerNoFormCmp
                        id="c_form1"
                        // submitCancelBtns={
                        //     <FormCmps.FormSubmitBtn id="submitBtn" label={ReadTextResource.getTextById("SAVE")}></FormCmps.FormSubmitBtn>
                        // }
                        // onSubmitHandler={onSubmitHandler}
                        onInvalid={(e) => {
                            // e.stopPropagation();
                            // IsFormValid();
                            // if (!IsFormValid()) {
                            //     const okModalInstance = new ModalComp.OkModal({ msg: "Please fill all required fields ", navTo: "" });
                            //     await ModalComp.showAsync(okModalInstance);
                            // }
                        }}
                    >
                        <UserType
                            radioRdc={[userTypeSt, userTypeDis]}
                            id="userType"
                            label={ReadTextResource.getTextById("USER_TYPE")}
                            inputRef={userTypeRef}
                            radioDir={"horizontal"}
                        >
                            <FormCmps.RadioItem
                                id="drvrRadio"
                                label={ReadTextResource.getTextById("DRIVER")}
                                value="DRVR"
                            ></FormCmps.RadioItem>
                            <FormCmps.RadioItem
                                id="psgrRadio"
                                label={ReadTextResource.getTextById("PASSENGER")}
                                value="PSGR"
                            ></FormCmps.RadioItem>
                        </UserType>

                        <UserGender
                            radioRdc={[userGenderSt, userGenderDis]}
                            id="userType"
                            label={ReadTextResource.getTextById("GENDER")}
                            radioDir={"horizontal"}
                            inputRef={userGenderRef}
                        >
                            <FormCmps.RadioItem
                                id="drvrRadio"
                                // required={true}
                                label={ReadTextResource.getTextById("MALE")}
                                value="M"
                            ></FormCmps.RadioItem>
                            <FormCmps.RadioItem
                                id="psgrRadio"
                                label={ReadTextResource.getTextById("FEMALE")}
                                value="F"
                            ></FormCmps.RadioItem>
                        </UserGender>

                        <div>{ReadTextResource.getTextById("EMAIL_ACCESS_NOTE")}</div>
                        <UserEmail
                            id="userEmailId"
                            inputRef={userEmailRef}
                            label={ReadTextResource.getTextById("EMAIL")}
                            defaultValue={getAccessInfo()?.user?.email}
                            // required={true}
                            emailRdc={[userEmailSt, userEmailDis]}
                            valueTrakingOnChange={false}
                        ></UserEmail>

                        <UserPassword
                            id="newPass"
                            inputRef={userPassRef}
                            // required={true}
                            label={ReadTextResource.getTextById("PASSWORD")}
                            // validationsMsg={ReadTextResource.getTextById("PASS_REQUIREMENT")}
                            passrdRdc={[userPassSt, userPassStDis]}
                            options={{ showPassHint: false, showPassTooltip: true, autoComplete: "new-password" }}
                        ></UserPassword>

                        <UserConfirmPassword
                            id="confirmPass"
                            label={ReadTextResource.getTextById("CONFIRM_PASS")}
                            // validationsMsg="PASS_REQUIREMENT"
                            // required={true}
                            inputRef={userConfirmPassRef}
                            passrdRdc={[userConfirmPassSt, userConfirmPassStDis]}
                            passToConfirm={FormCmps.Passrd.getValue(userPassRef)}
                            valueTrakingOnChange={false}
                        ></UserConfirmPassword>

                        <Lang id="lang" label="Language"
                            selectRdc={[langSt, langDis]}
                            selectValues={[
                                { selectLableAndId: ReadTextResource.getTextById("AR_LANG"), valueKey: "ar" },
                                { selectLableAndId: ReadTextResource.getTextById("EN_LANG"), valueKey: "en" },
                            ]}
                            inputRef={langRef}
                        ></Lang>

                        <UserPhone
                            id="phoneNumber"
                            // required={true}
                            label={ReadTextResource.getTextById("PHONE_NUMBER")}
                            inputRef={psgrDrvrPhoneNoRef}
                            phoneRdc={[userPhoneSt, userPhoneDis]}
                        ></UserPhone>

                        <Username1
                            id="drvrName1Ref"
                            inputRef={psgrDrvrName1Ref}
                            textRdc={[username1St, username1Dis]}
                            label={ReadTextResource.getTextById("NAME_1")}
                            validateEmpty={true}
                            options={{ readOnly: true }}
                        ></Username1>

                        <Username2
                            id="drvrName2Ref"
                            inputRef={psgrDrvrName2Ref}
                            label={ReadTextResource.getTextById("NAME_2")}
                            options={{ readOnly: true }}
                        ></Username2>

                        <Username3
                            id="drvrName3Ref"
                            inputRef={psgrDrvrName3Ref}
                            label={ReadTextResource.getTextById("NAME_3")}
                            options={{ readOnly: true }}
                        ></Username3>

                        <Username4
                            id="drvrName4Ref"
                            inputRef={psgrDrvrName4Ref}
                            label={ReadTextResource.getTextById("NAME_4")}
                            options={{ readOnly: true }}
                        ></Username4>

                        <UserCountryCmp
                            id="userCoutry"
                            label={ReadTextResource.getTextById("COUNTRY")}
                            inputRef={userCountryRef}
                            validateEmpty={true}
                            countryRdc={[userCountrySt, userCountryDis]}
                            onChange={() => {
                                userCityAutoCompleteDis({
                                    type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                                    value: "",
                                });
                            }}
                        ></UserCountryCmp>

                        <UserCityAutoComplete
                            id="userCity"
                            label={ReadTextResource.getTextById("CITY")}
                            inputRef={userCityAutoCompleteRef}
                            validateEmpty={true}
                            autoCompleteRdc={[userCityAutoCompleteSt, userCityAutoCompleteDis]}
                            optionLable={(option) => {
                                return option?.name_ar ? option?.name_ar : option?.name || "";
                            }}
                            inputOptions={
                                // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                                (() => {
                                    // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                                    if (userCountrySt.value?.code) {
                                        const e = country_cities?.find((e) => {
                                            return e.iso2 === userCountrySt.value?.code;
                                            //|| e.phone_code == ("+" || countryValSt.phone)
                                        });
                                        if (e) return e.states;
                                        else return [];
                                    }
                                    return [];
                                })()
                            }
                        ></UserCityAutoComplete>

                        <UserNationalIdCmp
                            id="userNationalId"
                            inputRef={nationalNoRef}
                            validateEmpty={true}
                            label={ReadTextResource.getTextById("NATIONAL_NO")}
                            options={{ readOnly: true }}
                            textRdc={[userNationalIdSt, userNationalIdDis]}
                            validationsMsg="Only numbers allowed here"
                            regExpPattern="^\d+$"
                        ></UserNationalIdCmp>

                        <UploadPrsnlPic
                            id="prsnlPicId"
                            inputRef={prsnlPicRef}
                            label={ReadTextResource.getTextById("PRSNL_PIC")}
                        // options={{ onchage: onImageUpload }}
                        >
                            {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                        </UploadPrsnlPic>

                        {FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR" &&
                            <>
                                <UploadDrvrLicPhoto
                                    id="drvrLicId"
                                    inputRef={drvrLicPhotoRef}
                                    label={ReadTextResource.getTextById("DRVR_LIC_FTO")}
                                    uploadRdc={[uploadDrvrLicPhotoSt, uploadDrvrLicPhotoDis]}
                                    validateEmpty={true}
                                // options={{ onchage: onImageUpload }}
                                >
                                    {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                                </UploadDrvrLicPhoto>

                                <UploadIdFrEnd
                                    id="idFrontEndId"
                                    inputRef={idFronEndPicRef}
                                    uploadRdc={[uploadIdFrEndSt, uploadIdFrEndDis]}
                                    label={ReadTextResource.getTextById("FR_ID_FTO")}
                                    validateEmpty={FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR"}
                                // options={{ onchage: onImageUpload }}
                                >
                                    {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                                </UploadIdFrEnd>

                                <UploadIdBkEnd
                                    id="idBackEndId"
                                    inputRef={idBackEndPicRef}
                                    uploadRdc={[uploadIdBkEndSt, uploadIdBkEndDis]}
                                    label={ReadTextResource.getTextById("BK_ID_FTO")}
                                    validateEmpty={FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR"}
                                // options={{ onchage: onImageUpload }}
                                >
                                    {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                                </UploadIdBkEnd>

                                <FormCmps.LabelComp.JsxComp id="wekomeCaptinId1">
                                    <strong> {ReadTextResource.getTextById("VCL_INFO")}</strong>
                                </FormCmps.LabelComp.JsxComp>

                                <IsVclOwner
                                    radioRdc={[isVclOwnerSt, isVclOwnerDis]}
                                    inputRef={isVclOwnerRef}
                                    id="isVclOwner"
                                    label={ReadTextResource.getTextById("R_U_VCL_OWNER")}
                                    radioDir={"horizontal"}
                                >
                                    <FormCmps.RadioItem
                                        id="vlcOwnerYes"
                                        label={ReadTextResource.getTextById("YES")}
                                        value="YES"
                                    ></FormCmps.RadioItem>
                                    <FormCmps.RadioItem id="vlcOwnerNo" label={ReadTextResource.getTextById("NO")} value="NO"></FormCmps.RadioItem>
                                </IsVclOwner>

                                <PlateId
                                    id="vclPlateId"
                                    inputRef={vclPlatIdRef}
                                    label={ReadTextResource.getTextById("VCL_PLATE_NO")}
                                    textRdc={[plateIdSt, plateIdDis]}
                                    validationsMsg="Invalid Vehicle Plate Number"
                                    validateEmpty={true}
                                    options={{ readOnly: true }}
                                ></PlateId>

                                <VclRegNoCmp
                                    id="vclRegNo"
                                    validateEmpty={true}
                                    textRdc={[vclRegNoCmpSt, vlcRegNoCmpDis]}
                                    inputRef={vclRegNoRef}
                                    label={ReadTextResource.getTextById("VCL_REG_NO")}
                                    options={{ readOnly: true }}
                                ></VclRegNoCmp>

                                <VclMakeAutoComplete
                                    id="vclMake"
                                    label={ReadTextResource.getTextById("VCL_MAKE")}
                                    validateEmpty={true}
                                    autoCompleteRdc={[vclMakeAutoCompleteSt, vclMakeAutoCompleteDis]}
                                    inputOptions={vehicleMakes}
                                    optionLable={(option) => {
                                        return option ? option : "";
                                    }}
                                    onChange={() => {
                                        // setVclModelValueSt("");
                                        // setUserVehicleYear("");
                                    }}
                                ></VclMakeAutoComplete>

                                <VclModelAutoComplete
                                    id="vclModel"
                                    label={ReadTextResource.getTextById("VCL_MODEL")}
                                    validateEmpty={true}
                                    autoCompleteRdc={[vclModelAutoCompleteSt, vclModelAutoCompleteDis]}
                                    optionLable={(option) => {
                                        return option ? option : "";
                                    }}
                                    inputOptions={
                                        vclMakeAutoCompleteSt.value ? vehicleModels[vclMakeAutoCompleteSt.value as keyof typeof vehicleModels] : []
                                    }
                                    onChange={() => {
                                        // vehicleYearRef!.current!.value = "";
                                        console.log("vehicleYearRef.current", vehicleYearRef?.current);
                                        console.log("vehicleYearRef.current", (vehicleYearRef.current as any).node.value);
                                        setUserVehicleYear("");
                                        // if (vehicleYearRef.current) {
                                        //     console.log("In if current");
                                        //     vehicleYearRef.current.value = "7";
                                        //     (vehicleYearRef.current as any).node.value="7";
                                        // }
                                    }}
                                ></VclModelAutoComplete>

                                <SelectUserVehicleYear
                                    id="userVehicleYear"
                                    label={ReadTextResource.getTextById("VCL_YEAR")}
                                    validateEmpty={true}
                                    selectRdc={[userVehicleYearSt, setUserVehicleYearDis]}
                                    inputRef={vehicleYearRef}
                                    selectValues={vehicleYearsArrSt}
                                // defaultValue={"2020"}
                                ></SelectUserVehicleYear>

                                <ColorInput
                                    id="vclColor"
                                    validateEmpty={true}
                                    textRdc={[colorInputSt, colorInputDis]}
                                    inputRef={vclColorRef}
                                    label={ReadTextResource.getTextById("VCL_COLOR")}
                                    options={{ readOnly: true }}
                                ></ColorInput>

                                <SelectVclLoad
                                    id="vclLoad"
                                    label={ReadTextResource.getTextById("VCL_LOAD")}
                                    validateEmpty={true}
                                    selectRdc={[selectVclLoadSt, selectVclLoadDis]}
                                    inputRef={vclLoadRef}
                                    selectValues={vclLoadArrSt}
                                ></SelectVclLoad>

                                {!!selectVclLoadSt?.value && (
                                    <VclSeatSelector
                                        id="vclSeatSelectorId1"
                                        label={ReadTextResource.getTextById("VCL_SEAT_SLCT")}
                                        visible={!!selectVclLoadSt?.value}
                                        seatRdc={[vclSeatSelectorSt, vclSeatSelectorStDis]}
                                        inputRef={vclSeatSelectorStRef}
                                        load={(selectVclLoadSt.value as unknown as number) ? (selectVclLoadSt.value as unknown as number) : 0}
                                    ></VclSeatSelector>
                                )}

                                <UploadVclPhoto
                                    id="vclPhotoId"
                                    inputRef={vclPhotoRef}
                                    label={ReadTextResource.getTextById("VCL_FTO")}
                                // options={{ onchage: onImageUpload }}
                                >
                                    {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                                </UploadVclPhoto>
                            </>}



                        {isVclOwnerSt.value === "NO" && FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR" &&
                            <>
                                <FormCmps.LabelComp.JsxComp id="wekomeCaptinId1">
                                    <strong> {ReadTextResource.getTextById("VCL_ONER_INFO")}</strong>
                                </FormCmps.LabelComp.JsxComp>

                                <VclOwnerNationalIdCmp
                                    id="vclOwnerNationalId"
                                    inputRef={vclOwnerNationalNoRef}
                                    label={ReadTextResource.getTextById("VCL_ONER_NAT_NO")}
                                    options={{ readOnly: true }}
                                    regExpPattern="^\d+$"
                                    validationsMsg="Invalid National Number"
                                    // validateEmpty={true}
                                    textRdc={[vclOwnerNationalIdSt, vclOwnerNationalIdDis]}
                                ></VclOwnerNationalIdCmp>

                                <VclOwnerName1
                                    id="vclOwnerName1"
                                    inputRef={vclOwnerName1Ref}
                                    validateEmpty={true}
                                    textRdc={[vclOwner1NameSt, vlcOwner1NameDis]}
                                    label={ReadTextResource.getTextById("NAME_1")}
                                    options={{ readOnly: true }}
                                ></VclOwnerName1>

                                <VclOwnerName2
                                    id="vclOwnerName2"
                                    inputRef={vclOwnerName2Ref}
                                    validateEmpty={true}
                                    textRdc={[vclOwner2NameSt, vlcOwner2NameDis]}
                                    label={ReadTextResource.getTextById("NAME_2")}
                                    options={{ readOnly: true }}
                                ></VclOwnerName2>

                                <VclOwnerName3
                                    id="vclOwnerName3"
                                    validateEmpty={true}
                                    textRdc={[vclOwner3NameSt, vlcOwner3NameDis]}
                                    inputRef={vclOwnerName3Ref}
                                    label={ReadTextResource.getTextById("NAME_3")}
                                    options={{ readOnly: true }}
                                ></VclOwnerName3>

                                <VclOwnerName4
                                    id="vclOwnerName4"
                                    inputRef={vclOwnerName4Ref}
                                    label={ReadTextResource.getTextById("NAME_4")}
                                    options={{ readOnly: true }}
                                ></VclOwnerName4>

                                <VclOwnerPhoneNo
                                    id="vclOwnerPhoneNo"
                                    // validateEmpty={true}
                                    required={true}
                                    label={ReadTextResource.getTextById("VCL_ONER_PHONE_NO")}
                                    inputRef={vclOwnerPhoneNoRef}
                                    phoneRdc={[vclOwnerPhoneNoSt, vclOwnerPhoneNoDis]}
                                ></VclOwnerPhoneNo>

                                <VclOwnerEmailCmp
                                    id="vclOwnerEmail"
                                    inputRef={vclOwnerEmailRef}
                                    emailRdc={[vclOwnerEmailSt, vclOwnerEmailDis]}
                                    label={ReadTextResource.getTextById("EMAIL")}
                                ></VclOwnerEmailCmp>
                            </>
                        }
                        {FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR" && <UploadVclLicensePhotoId
                            id="vclLicensePhotoId"
                            inputRef={vclLicesnePhotoRef}
                            label={ReadTextResource.getTextById("VLC_LIC_FTO")}
                            uploadRdc={[uploadVclLicensePhotoIdSt, uploadVclLicensePhotoIdDis]}
                            validateEmpty={
                                FormCmps.RadioCmp.getValue(userTypeSt) === "DRVR"
                            }
                        >
                        </UploadVclLicensePhotoId>}

                    </FormCmps.ContainerNoFormCmp>

                    <div style={{ height: "60px" }}></div>
                </div>
            </>
        );
    }

    if (props.crudType === CrudTypes.UPDATE) {
        return (
            <>
                <div className="p-3">
                    <FormCmps.Form
                        id="u_form"
                        submitCancelBtns={
                            <FormCmps.FormSubmitBtn id="submitBtn" label={ReadTextResource.getTextById("SAVE")}></FormCmps.FormSubmitBtn>
                        }
                    // onSubmitHandler={onSubmitHandler}
                    >
                        <UploadPrsnlPic
                            id="u_updloadLogo"
                            inputRef={prsnlPicRef}
                            label={ReadTextResource.getTextById("COMPANY_LOGO")}
                        // defaultValue={getCompanyInfoVar().logo_path}
                        // options={{ onchage: onImageUpload }}
                        >
                            {/* {imageContent && <ImageComp imgBase64={imageContent as string} imgWidth="50%" />} */}
                        </UploadPrsnlPic>

                        <div className="text-center">
                            <div
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundImage: `url(${getAccessInfo()?.user?.userPhotoName})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                }}
                            ></div>
                        </div>

                        <FormCmps.Email.JsxComp
                            id="u_email"
                            inputRef={userEmailRef}
                            key={getAccessInfo()?.user?.email}
                            label={ReadTextResource.getTextById("EMAIL")}
                            defaultValue={getAccessInfo()?.user?.email}
                        ></FormCmps.Email.JsxComp>

                        <UserPassword
                            id="u_newPass"
                            label={ReadTextResource.getTextById("PASSWORD")}
                            // validationsMsg="PASS_REQUIREMENT"
                            // required={false}
                            options={{ showPassHint: false, showPassTooltip: true, autoComplete: "new-password" }}
                        ></UserPassword>

                        <UserConfirmPassword
                            id="u_confirmPass"
                            label={ReadTextResource.getTextById("CONFIRM_PASS")}
                            // validationsMsg="PASS_REQUIREMENT"
                            // required={false}
                            inputRef={userPassRef}
                        ></UserConfirmPassword>

                        <Username1
                            id="u_username"
                            inputRef={psgrDrvrName1Ref}
                            label={ReadTextResource.getTextById("USERNAME")}
                            options={{ readOnly: true }}
                            defaultValue={getAccessInfo()?.username}
                        ></Username1>

                        <UserPhone
                            id="phoneNumber"
                            // required={true}
                            label={ReadTextResource.getTextById("PHONE_NUMBER")}
                            inputRef={psgrDrvrPhoneNoRef}
                            phoneRdc={[userPhoneSt, userPhoneDis]}
                        ></UserPhone>

                        <div className="row justify-content-center">
                            <div className="col-sm-9 col-12 mb-3 d-flex justify-content-center text-primary">
                                {emailSent ? ReadTextResource.getTextById("EMAIL_SENT_SUCC") : ""}
                            </div>
                        </div>
                    </FormCmps.Form>
                </div>
            </>
        );
    }
    return <>You have to select a valid Crud operation</>;
}
