import { FormHelperText } from "@mui/material";
import { PropsWithChildren, RefObject, useReducer, useRef, useState } from "react";
import { ReadTextResource } from "../../../commonSrc/ReadTextResource";
import { SeatSelectionStatus, SeatStatusT, VehicleSeatsT } from "../../../commonSrc/Types";
import { VehicleSeats } from "./VehicleSeats";

/**
 *
 * @param props load is the number of passengers without the driver
 * @returns
 */

export namespace VehicleSeatSelector {
    export function getPsgrSeatsCount(vehicleSeats: VehicleSeatsT[]) {
        let count: number = 0;

        vehicleSeats.map((e, i) => {
            if (e.x1 === "Y") count++;
            if (e.x2 === "Y") count++;
            if (e.x3 === "Y") count++;
        });
        if (count < 0) throw new Error("Wrong number of seats");
        return count;
    }

    export enum SeatActDisTypesT {
        ValueInput = "VALUE_INPUT",
        ErrorMsg = "ERROR_MSG",
    }

    export type SeatStT = { value?: VehicleSeatsT[]; errorMsg?: string };

    type SeatActionT = { type: SeatActDisTypesT } & SeatStT;

    export type SeatDisT = (action: SeatActionT) => void;

    export function getValue(pValueContainer: RefObject<HTMLInputElement> | SeatStT) {
        if ("value" in pValueContainer) return pValueContainer.value;
        else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
    }
    // const vehicleSeats: VehicleSeatsT[] = [];
    export class helperFns {
        static createRef = () => useRef<HTMLInputElement>(null);

        static createReducer = () =>
            useReducer(
                (state: SeatStT, action: SeatActionT) => {
                    if (action.type === SeatActDisTypesT.ValueInput) return { ...state, value: action.value };
                    if (action.type === SeatActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                    return { value: state.value, errorMsg: "" };
                },
                { value: [], errorMsg: "" }
            );
    }

    export function JsxComp(
        props: PropsWithChildren<{
            id: string;
            label: string;
            load: number;
            visible?: boolean;
            seatRdc?: [SeatStT, SeatDisT];
            defaultValue?: string;
            onChangeFn?: () => void;
            validationsMsg?: string;
            inputRef?: RefObject<HTMLInputElement>;
            valueTrakingOnChange?: boolean;
            required?: boolean;
            options?: any;
        }>
    ): JSX.Element {
        console.log("First line - VehicleSeatSelector Component");

        const [value, setValue] = useState(props.defaultValue);
        let seatsSt: SeatStT = {} as SeatStT;
        let seatDis: SeatDisT = () => {};

        if (props.seatRdc) {
            [seatsSt, seatDis] = props.seatRdc;
        }

        const [selectedSeats, setSelectedSeats] = useState<any[]>([]);

        const handleSeatSelection = (seatIndex: number) => {
            // Check if the seat is already selected
            const isSeatSelected: any = selectedSeats.includes(seatIndex);

            if (isSeatSelected) {
                // Remove the seat from selectedSeats
                const updatedSeats = selectedSeats.filter((seat: any) => seat !== seatIndex);
                setSelectedSeats(updatedSeats);
            } else {
                // Add the seat to selectedSeats
                const updatedSeats = [...selectedSeats, seatIndex];
                setSelectedSeats(updatedSeats);
            }
        };

        console.log("Math.ceil((props.load - 1) / 3) * 3 + 1 + 2 + 3", Math.ceil((props.load - 1) / 3) * 3 + 1 + 2 + 3, props.load);

        // Generate the seat blueprint based on the number of seats in the car
        const seatsStArr: SeatStatusT[] = Array.from({ length: Math.ceil((props.load - 1) / 3) * 3 + 1 + 2 + 3 }, (_, index) => {
            let seatStatus;
            if (index === 1) seatStatus = selectedSeats.includes(index) ? SeatSelectionStatus.UNSELECTED : SeatSelectionStatus.UNSELECTED;
            else seatStatus = selectedSeats.includes(index) ? SeatSelectionStatus.UNSELECTED : SeatSelectionStatus.SELECTED;
            return { status: seatStatus };
        });
        return (
            <div ref={props.inputRef} className={`col-12 col-lg-12 col-md-12 col-sm-12 ${props.visible ?? false ? "d-block" : "d-none"}`}>
                {/* <h2>Car Seat Selector</h2> */}
                <div className="py-3">{ReadTextResource.getTextById("REMOVE_NA_SEATS")}</div>
                <VehicleSeats
                    load={props.load}
                    seatRdc={[seatsSt, seatDis]}
                    seatsStArr={seatsStArr}
                    onSelectSeat={handleSeatSelection}
                    key={props.id}
                />
                <FormHelperText error={!!seatsSt.errorMsg}>{seatsSt.errorMsg}</FormHelperText>
            </div>
        );
    }

    export const getComp = (initValue?: SeatStT | null) => {
        return {
            JsxComp: JsxComp,
            compReducer: helperFns.createReducer(),
            compRef: helperFns.createRef(),
        };
    };
}
// export function VehicleSeatSelector1(props: PropsWithChildren<{ load: number }>) {}
