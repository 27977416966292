import { Route, Routes } from "react-router-dom";
import App from "../../App";
import MyAccounts from "../acc/MyAccounts";
import TestGraphComp from "../TestGraphComp";
import RequireAuth from "./RequireAuth";
import { AppSettings } from "./AppSettings";
import { DrvrDashboardComp } from "../driver/DrvrDashboardComp";
import { DrvrTransportReq } from "../driver/DrvrTransportReq";
import DrvrOnly from "./DrvrOnly";
import { DrvrTraspReqDtl } from "../driver/DrvrTraspReqDtl";
import { AcceptPsgrReqCmp } from "../driver/AcceptPsgrReqCmp";
import { PsgrChat } from "../passenger/PsgrChat";
import { AllChat } from "../chat/AllChat";
import { DrvrTrips } from "../driver/DrvrTrips";
import { DrvrFindTrip } from "../driver/DrvrFindTrip";
import { DrvrSuspendedReqs } from "../driver/DrvrSuspendedReqs";
import { DrvrGdsTkDecision } from "../driver/DrvrGdsTkDecision";
import { DrvrSntReqDtls } from "../driver/DrvrSntReqDtls";
import { ActivationCmp } from "./ActivationCmp";
import { NotificationsCmp } from "../notifications/NotificationsCmp";
import NotFound from "./NotFound";
import { RatePsgrCmp } from "../driver/RatePsgrCmp";

export const DrvrRoutes = () => {
    console.log("First line - DrvrRoutes Component");

    return (
        <Routes>
            <Route
                path="drvrTraspReq"
                element={
                    <DrvrOnly>
                        <RequireAuth>
                            <DrvrTransportReq />
                        </RequireAuth>
                    </DrvrOnly>
                }
            />
            <Route
                path="testGraph"
                element={
                    <RequireAuth>
                        <TestGraphComp />
                    </RequireAuth>
                }
            />
            <Route
                path="myAccounts"
                element={
                    <RequireAuth>
                        <MyAccounts />
                    </RequireAuth>
                }
            />
            <Route
                path="appSettings"
                element={
                    <RequireAuth>
                        <AppSettings />
                    </RequireAuth>
                }
            />
            <Route
                path="drvrTraspReqDtl/:pReqId/:pDrvrId"
                element={
                    <RequireAuth>
                        <DrvrTraspReqDtl />
                    </RequireAuth>
                }
            />
            <Route
                path="drvrGdsTkDecision/:pReqId/:pDrvrId"
                element={
                    <RequireAuth>
                        <DrvrGdsTkDecision />
                    </RequireAuth>
                }
            />
            <Route
                path="drvrSntReqDtls/:pReqId"
                element={
                    <RequireAuth>
                        <DrvrSntReqDtls />
                    </RequireAuth>
                }
            />
            <Route
                path="acceptPsgrReq/:pReqId"
                element={
                    <RequireAuth>
                        <AcceptPsgrReqCmp />
                    </RequireAuth>
                }
            />
            <Route
                path="drvrChat"
                element={
                    <RequireAuth>
                        {/* <PortalComp elementId="chatRoot"> */}
                        <PsgrChat />
                        {/* </PortalComp> */}
                    </RequireAuth>
                }
            />
            <Route
                path="allChat"
                element={
                    <RequireAuth>
                        {/* <PortalComp elementId="chatRoot"> */}
                        <AllChat />
                        {/* </PortalComp> */}
                    </RequireAuth>
                }
            />
            <Route
                path="notifications"
                element={
                    <RequireAuth>
                        {/* <PortalComp elementId="chatRoot"> */}
                        <NotificationsCmp />
                        {/* </PortalComp> */}
                    </RequireAuth>
                }
            />
            <Route
                path="drvrTrips"
                element={
                    <RequireAuth>
                        <DrvrTrips />
                    </RequireAuth>
                }
            />

            <Route
                path="drvrFindTrip"
                element={
                    <RequireAuth>
                        <DrvrFindTrip />
                    </RequireAuth>
                }
            />
            <Route
                path="drvrSuspendedReqs"
                element={
                    <RequireAuth>
                        <DrvrSuspendedReqs />
                    </RequireAuth>
                }
            />
            <Route
                path="activation"
                element={
                    <RequireAuth>
                        <ActivationCmp />
                    </RequireAuth>
                }
            />
            <Route path="dashboard" element={<DrvrDashboardComp></DrvrDashboardComp>} />
            <Route
                path="ratePsgrs/:tripId"
                element={
                    <RequireAuth>
                        <RatePsgrCmp />
                    </RequireAuth>
                }
            />
            <Route
                path="*"
                element={
                    <NotFound></NotFound>
                }
            />
        </Routes>
    );
};
