// import { makeVar } from "@apollo/client";
import { Offcanvas } from "bootstrap";
import React, { forwardRef, MouseEvent, MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { PropsWithChildren } from "react";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import { dirAndLangRvar, swVersion } from "../../commonSrc/apolloGQL/ReactiveVars";
import { logout } from "../../commonSrc/commonFns";
import historyObject from "../../commonSrc/HistoryRouterObject";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import NavCompStyles from "./cssOfComps/NavComp.module.css";
import { ModalComp } from "./ModalComp";

export namespace NavComp {
    // const navStatusVar = makeVar<boolean>(false);

    // export function useMobileNavStatus(showState: boolean) {
    //   const [show, setShow] = useState(showState);
    //   // const setNavVisible = (visible:boolean)=> setShow(visible);
    //   return [show, setShow] as const;
    // }
    let parentId = "";
    export function MobileNavPanel(props: PropsWithChildren<{ menus: any; parentProps: any; onHideMenu?: () => void }>) {
        if (!props.menus) return <></>;

        return (
            <>
                <div
                    // style={{ width: "70%" }}
                    // w-auto can be added to below className, it was there, but caused bug when changing language
                    className="offcanvas offcanvas-end "
                    data-bs-scroll="true"
                    tabIndex={-1}
                    id="MobileNavPanel_id_0"
                    aria-labelledby="mobileNavButton_1Label"
                >
                    <div className={`offcanvas-header pb-0 align-items-baseline`}>
                        <h5 className="offcanvas-title" id="mobileNavButton_1Label">
                            {ReadTextResource.getTextById("NAVIGATION_PANEL")}
                            <div className="d-none d-sm-none d-md-block text-center">
                                <a
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    style={{ color: "black", fontSize: "10px" }}
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onHideMenu?.();
                                    }}
                                >
                                    {ReadTextResource.getTextById("DOCK_MENU")}
                                </a>
                            </div>
                        </h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <NavSubPanelContent
                                key={"NavSubPanelContent_0"}
                                menus={props.menus.getAppMenus}
                                parentProps={props.parentProps}
                                mobileParent={true}
                                readMenuFromTxtRsc={true}
                            ></NavSubPanelContent>
                        </div>
                    </div>
                    <div className="p-1" style={{fontSize:"10px"}} onClick={async () => {
                        const swReg = await navigator.serviceWorker.getRegistration();
                        swReg?.active?.postMessage({ type: "SW_VERSION" });
                    }}>
                        {ReadTextResource.getTextById("VERSION")}: {localStorage.getItem("version")} -  {swVersion() ? ReadTextResource.getTextById("SW_VERSION") + ": " + swVersion() : ""}
                    </div>
                </div>
            </>
        );
    }

    export const MobileNavButton = forwardRef<HTMLDivElement>((props: PropsWithChildren<{}>, ref) => {
        // const a =useRef<HTMLDivElement>(null)
        // : React.LegacyRef<HTMLDivElement>

        return (
            <div ref={ref} className="d-block d-md-none">
                {/* <div className=""> */}
                <button
                    className="btn btn-primary p-0 fs-1"
                    type="button"
                    onClick={() => {
                        // const newOffCanvas = new Offcanvas(document.getElementById("MobileNavPanel_id_0") as Element);
                        const offcanvasId = document.getElementById("MobileNavPanel_id_0") as Element;
                        const newOffCanvas = Offcanvas.getOrCreateInstance(offcanvasId);
                        newOffCanvas.toggle();

                        function registerEventLsnr_HideOffcanvasOnBackBtn() {
                            offcanvasId?.addEventListener(
                                "shown.bs.offcanvas",
                                (e) => {
                                    //below works as well, but no need for now, as historyObject does the same
                                    // window.history.pushState(null, "", window.location.href);
                                    const currPath = historyObject.location.pathname;

                                    // window.history.pushState({}, "", "");

                                    if (currPath === "/app/dashboard" || currPath === "/" || currPath === "/login") {
                                        window.history.pushState({}, "", "");
                                    }

                                    const unlisten = historyObject.listen((e) => {
                                        if (e.action === "POP") {
                                            // const g = historyObject.location.pathname;
                                            // if (g === "/app/dashboard") {
                                            //     window.history.pushState({}, "", "");
                                            // }
                                            newOffCanvas.hide();
                                            historyObject.push(currPath);
                                        }
                                        if (e.action === "PUSH") {
                                            console.log("push is detected XXXXXXXX", e.location);
                                        }
                                        unlisten();
                                    });

                                    // window.history.pushState({}, "", currPath);

                                    // window.addEventListener(
                                    //     "popstate",
                                    //     (e) => {
                                    //         // e.preventDefault();
                                    //         // newOffCanvas.hide();
                                    //         const g = historyObject.location.pathname;
                                    //         if (g === "/app/dashboard") {
                                    //             window.history.pushState({}, "", "");
                                    //         } else historyObject.back();
                                    //     }
                                    //     // { once: true }
                                    // );

                                    offcanvasId?.addEventListener(
                                        "hidden.bs.offcanvas",
                                        (e) => {
                                            console.log("hidden listener");
                                            unlisten();
                                            // window.removeEventListener("popstate", offcanvasBackBtnLsnr);
                                        },
                                        { once: true }
                                    );
                                },
                                { once: true }
                            );
                        }

                        registerEventLsnr_HideOffcanvasOnBackBtn();
                    }}
                // data-bs-toggle="offcanvas"
                // data-bs-target="#MobileNavPanel_id_0"
                // aria-controls="MobileNavPanel_id_0"
                >
                    <i className="bi bi-list"></i>
                </button>
            </div>
        );
    });

    export function NavSubPanelLeafs(
        props: PropsWithChildren<{ leaf: any; parentMenu: any; parentProps: any; mobileParent: boolean; readMenuFromTxtRsc: boolean }>
    ) {
        const navigate = useNavigate();
        const abc = ['data-bs-dismiss="offcanvas "', ' aria-label="Close"'];
        const [activeState, setActiveState] = useState(false);
        const [icon, setIcon] = useState("");

        // console.log("props.parentProps", props.parentMenu);

        useEffect(() => {
            if (props.leaf)
                if (props.leaf.menuTxtResourceId === "LOGOUT") setIcon("bi-box-arrow-left");
                else setIcon("bi-record-fill");
        }, []);

        if (!props.leaf) return <></>;
        //##&&
        return (
            <>
                {
                    <div
                        // ${activeState ? NavCompStyles["active"] : ""}
                        className={` ${NavCompStyles["hover_bg"]}`}
                        onClick={() => {
                            setActiveState(!activeState);
                        }}
                    >
                        <NavLink
                            data-leaf="true"
                            onClick={async (e: MouseEvent<HTMLAnchorElement>) => {
                                const tree = document.getElementById(props.parentProps.rootPanelId);
                                const allTreeLeafs = tree?.querySelectorAll('[data-leaf="true"]');
                                if (allTreeLeafs) {
                                    for (let i = 0; i < allTreeLeafs?.length; i++) {
                                        // allTreeLeafs[i].className = allTreeLeafs[i].className.replace("active", "");
                                        allTreeLeafs[i].className = "d-block";
                                        console.log("allTreeLeafs[i]=", allTreeLeafs[i]);
                                    }
                                    e.currentTarget.className = " d-block " + NavCompStyles["active"];
                                }

                                if (props.parentMenu.menuTxtResourceId === "LANG") {
                                    e.preventDefault();
                                    if (props.leaf.menuTxtResourceId === "AR_LANG") {
                                        // (async () => {
                                        await ReadTextResource.importTextResource("ar");
                                        localStorage.setItem("dir", "rtl");
                                        localStorage.setItem("lang", "ar");
                                        // refreshRootComponent();
                                        /**
                                         * below 2 lines moved to Lang Component
                                         * // document.getElementById("mainHtmlElement")?.setAttribute("dir", "rtl");
                                         * // document.getElementById("mainHtmlElement")?.setAttribute("lang", "ar");
                                         */
                                        dirAndLangRvar({ direction: "rtl", lang: "ar" });
                                        // })
                                        // ();
                                    }
                                    if (props.leaf.menuTxtResourceId === "EN_LANG") {
                                        // (async () => {
                                        await ReadTextResource.importTextResource("en");
                                        localStorage.setItem("dir", "ltr");
                                        localStorage.setItem("lang", "en");
                                        // refreshRootComponent();

                                        /**
                                         * below 2 lines moved to Lang Component
                                         * // document.getElementById("mainHtmlElement")?.setAttribute("dir", "ltr");
                                         * // document.getElementById("mainHtmlElement")?.setAttribute("lang", "en");
                                         */

                                        dirAndLangRvar({ direction: "ltr", lang: "en" });
                                        // })();
                                    }
                                }
                                if (props.leaf.menuTxtResourceId === "LOGOUT") {
                                    logout();
                                    navigate("/", { replace: true });
                                }
                            }}
                            to={props.leaf.navigateTo ? props.leaf.navigateTo : ""}
                            className={({ isActive }) => {
                                return "";
                            }}
                        // className={({ isActive }) => {
                        //     console.log("isActive##=", isActive);
                        //     // isActive ? "bg-black": "bg-black")}}
                        //     // if (isActive) return "d-block bg-black ";
                        //     // else return "d-block ";
                        //     console.log("ZZZZZZZZZZZZZZZZZz isActive=", isActive);
                        //     console.log(decodeURI(props.leaf.navigateTo + "" + location.search));
                        //     console.log(props.leaf.navigateTo + "?z=" + props.leaf.menuTxtResourceId);
                        //     // if (!props.leaf.navigateTo) return "d-block bg-black ";
                        //     if (
                        //         decodeURI(props.leaf.navigateTo + location.search) ==
                        //         props.leaf.navigateTo + "?z=" + props.leaf.menuTxtResourceId
                        //     )
                        //         return "d-block bg-black ";
                        //     else return "d-block ";
                        // }}
                        >
                            {/* <pre {...(mobParentState === true ? { abc } : "")}> */}
                            <i
                                data-bs-dismiss={props.mobileParent === true ? "offcanvas" : ""}
                                aria-label="Close"
                                className="d-block fs-6 text-black"
                            >
                                <i className={`bi ${icon} fs-5 pe-4  `}></i>
                                <span>
                                    {props.readMenuFromTxtRsc
                                        ? ReadTextResource.getTextById(props.leaf.menuTxtResourceId)
                                        : props.leaf.menuTxtResourceId}
                                </span>
                            </i>
                        </NavLink>
                    </div>
                }
            </>
        );
    }

    export function NavSubPanelBranch(
        props: PropsWithChildren<{ children: any; parent: any; parentProps: any; mobileParent: boolean; readMenuFromTxtRsc: boolean }>
    ) {
        if (!props.children) return <></>;
        //##&&
        return (
            <>
                <h2 className="accordion-header" id={`#header_id_${props.parent.menuId}`}>
                    <button
                        className="accordion-button p-0 me-5 collapsed text-black"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#menu_id_${props.parent.menuId}`}
                        aria-expanded="false"
                        aria-controls="menu_id_1"
                    >
                        {/* <span className="pe-2">{props.parent.menuName}</span> */}
                        <i className="bi bi-justify pe-4 fs-4 text-black"></i>
                        {props.readMenuFromTxtRsc
                            ? ReadTextResource.getTextById(props.parent.menuTxtResourceId)
                            : props.parent.menuTxtResourceId}
                    </button>
                </h2>

                <div id={`menu_id_${props.parent.menuId}`} className="accordion-collapse collapse " aria-labelledby="inner1H">
                    <div className="accordion-body pt-0 pe-0 ps-3">
                        {props.children.map((item: any) => {
                            return item.children.length > 0 ? (
                                <NavSubPanelBranch
                                    key={"NavSubPanelBranch_" + item.menuId}
                                    children={item.children}
                                    parent={item}
                                    parentProps={props.parentProps}
                                    mobileParent={props.mobileParent}
                                    readMenuFromTxtRsc={props.readMenuFromTxtRsc}
                                ></NavSubPanelBranch>
                            ) : (
                                <NavSubPanelLeafs
                                    key={"NavSubPanelLeafs_" + item.menuId}
                                    leaf={item}
                                    parentMenu={props.parent}
                                    parentProps={props.parentProps}
                                    mobileParent={props.mobileParent}
                                    readMenuFromTxtRsc={props.readMenuFromTxtRsc}
                                ></NavSubPanelLeafs>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }

    export function NavSubPanelContent(
        props: PropsWithChildren<{ menus: any; parentProps: any; mobileParent: boolean; readMenuFromTxtRsc: boolean }>
    ) {
        if (!props.menus) return <></>;
        //##&&
        return (
            <>
                {props.menus?.map((item: any) => {
                    return (
                        <div
                            key={"NavSubPanelContent_" + item.menuId}
                            className={`accordion-item border-bottom-0 border-start-0 border-end-0 pt-3 ${NavCompStyles["nav-sub-with-icons"]}`}
                        >
                            {item.children.length > 0 ? (
                                <NavSubPanelBranch
                                    key={"NavSubPanelBranch_" + item.menuId}
                                    children={item.children}
                                    parent={item}
                                    parentProps={props.parentProps}
                                    mobileParent={props.mobileParent}
                                    readMenuFromTxtRsc={props.readMenuFromTxtRsc}
                                ></NavSubPanelBranch>
                            ) : (
                                <NavSubPanelLeafs
                                    key={"NavSubPanelLeafs_" + item.menuId}
                                    leaf={item}
                                    parentMenu={""}
                                    parentProps={props.parentProps}
                                    mobileParent={props.mobileParent}
                                    readMenuFromTxtRsc={props.readMenuFromTxtRsc}
                                ></NavSubPanelLeafs>
                            )}
                        </div>
                    );
                })}
            </>
        );
    }

    export function NavSubPanel(
        props: PropsWithChildren<{
            compName: "NavSubPanel";
            menus: any;
            parentProps?: any;
            readMenuFromTxtRsc: boolean;
            onHideMenu?: () => void;
        }>
    ) {
        // console.log("parentProps##", props.parentProps.rootPanelId);
        const hideSubPanel = (panleId: string, e: MouseEvent<HTMLAnchorElement>) => {
            const navCompPanel = document.getElementById(panleId);
            navCompPanel?.classList.add("d-none");
            navCompPanel?.classList.remove("d-md-block");
            props.onHideMenu?.();
        };

        if (!props.menus) return <></>;

        return (
            <>
                <div className="text-center">
                    <a
                        style={{ color: "black", fontSize: "10px" }}
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            hideSubPanel("mainNavPanel", e);
                        }}
                    >
                        {ReadTextResource.getTextById("HIDE_MENU")}
                    </a>
                </div>
                <div className="accordion" id={"Acoordion" + props.parentProps.panelId}>
                    <NavSubPanelContent
                        key={"NavSubPanelContent_0"}
                        // menus={props.menus.getAppMenus}
                        menus={props.menus}
                        parentProps={props.parentProps}
                        mobileParent={false}
                        readMenuFromTxtRsc={props.readMenuFromTxtRsc}
                    ></NavSubPanelContent>
                </div>
            </>
        );
    }

    export function NavMainPanel(props: PropsWithChildren<{ compName: "NavMainPanel"; panelID?: any }>) {
        return <>{props.children}</>;
    }

    export function NavPanel(props: PropsWithChildren<{ panelID: string }>) {
        let oneChild = undefined;

        let NavSubPanel: ReactElement; // = undefined;
        let NavMainPanel: ReactElement; //= undefined;

        if (Array.isArray(props.children)) {
            if (props.children.length > 2)
                console.warn(
                    "The NavPanel custom compponent accepts only two childs, the NavSubPanel and NavMainPanel, others are ignored"
                );
            const childrenArray = props.children as any; // Array<ReactElement>;
            NavSubPanel = childrenArray?.find((element: any) => element.props.compName === "NavSubPanel");
            NavMainPanel = childrenArray?.find((element: any) => element.props.compName === "NavMainPanel");
        } else {
            oneChild = props.children as any;
            NavSubPanel = oneChild?.props.compName === "NavSubPanel" ? oneChild : "";
            NavMainPanel = oneChild?.props.compName === "NavMainPanel" ? oneChild : "";
        }

        if (!NavMainPanel || !NavSubPanel)
            console.warn("The NavPanel custom compponent should have two childs, the NavSubPanel and NavMainPanel");

        const compStyles = NavCompStyles["nav-sub-with-icons"];

        try {
            NavSubPanel = React.cloneElement(NavSubPanel, { parentProps: { rootPanelId: props.panelID } }, { ...NavSubPanel.props });
            // { menus: NavSubPanel.props.menus, parentProps: props.panelID, onHideMenu: NavSubPanel.props.onHideMenu });
            NavMainPanel = React.cloneElement(NavMainPanel, { rootPanelId: props.panelID }, NavMainPanel.props.children);
        } catch (err) {
            return <div>err</div>;
            // return err;
        }

        // console.log("NavMainPanel=", NavMainPanel);
        // NavMainPanel.props= {...NavMainPanel.props, ...props};

        return (
            <div className="container-fluid d-flex flex-column flex-grow-1">
                <div className="row  d-flex flex-grow-1 justify-content-start">
                    {/* vh-100 */}
                    {/* panel left side */}
                    <div
                        id={props.panelID}
                        // /* vh-100 */
                        className={`col-auto border-start border-end d-none d-md-block  overflow-auto  ${compStyles} `}
                    >
                        {/* {props.subPanel} */}
                        {NavSubPanel}
                    </div>
                    {/* panel right side, application screens */}
                    <div className="col">
                        <div className="pt-3 h-100 ">{NavMainPanel}</div>
                    </div>
                </div>
            </div>
        );
    }

    export function NavButton(props: PropsWithChildren<{ targetPanelID: string }>) {
        const [toggle, setToggle] = useState(true);

        const navButtonOnClickHandler = () => {
            const navCompPanel = document.getElementById(props.targetPanelID);
            if (toggle) {
                // navCompPanel?.style.setProperty("width", "75px");//no need because of col-auto
                navCompPanel?.classList.remove(NavCompStyles["nav-sub-with-icons"]);
                navCompPanel?.classList.add(NavCompStyles["nav-sub-icons-only"]);
                setToggle(!toggle);
            } else {
                // navCompPanel?.style.removeProperty("width");//col-auto
                navCompPanel?.classList.remove(NavCompStyles["nav-sub-icons-only"]);
                navCompPanel?.classList.add(NavCompStyles["nav-sub-with-icons"]);
                setToggle(!toggle);
            }
        };

        return <i className="p-0 fs-1 bi bi-list d-none d-md-block" onClick={navButtonOnClickHandler}></i>;
    }
}
