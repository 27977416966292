import React from "react";
import { AddGlAccComp } from "./AddGlAccComp";

function MyAccounts() {
    console.log("First line - MyAccounts Component");

    return (
        <>
            <AddGlAccComp></AddGlAccComp>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="align-items-start container-fluid flex-column">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarTogglerDemo01"
                                    aria-controls="navbarTogglerDemo01"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="align-items-start collapse flex-column navbar-collapse" id="navbarTogglerDemo01">
                                    <a className="navbar-brand" href="#">
                                        Hidden brand
                                    </a>
                                    <ul className="flex-column mb-2 mb-lg-0 me-auto navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="#">
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">
                                                Link
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link disabled" href="#" tabIndex={-1} aria-disabled="true">
                                                Disabled
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-lg-10">
                        {" "}
                        <p>
                            <a
                                className="btn btn-primary p-2"
                                data-bs-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                Link with href
                            </a>
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                Button with data-bs-target
                            </button>
                        </p>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                Some placeholder content for the collapse component. This panel is hidden by default but revealed when the
                                user activates the relevant trigger.
                            </div>
                        </div>
                        <p>list of accounts</p>
                        <p>account1</p>
                        <p>account2</p>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default MyAccounts;
