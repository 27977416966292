import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAccessInfo } from "../../commonSrc/commonFns";

function RequireAuth(props: React.PropsWithChildren<{}>) {
    const nav = useNavigate();
    const location = useLocation();
    const redirectTo = location.pathname;
    // console.log("location in RequireAuth=", location, "ReqAuthTest accessTokenVar=",accessTokenVar());
    if (getAccessInfo()?.isAuthenticated) return <>{props.children}</>;
    return <Navigate to={"/login"} replace={true} state={{ redirectTo: redirectTo, relogin: true }} />;
}

export default RequireAuth;
