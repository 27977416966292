import { Location } from "../components/commonComp/GoogleMapCmp";
import { SERVER_NAME } from "./EnvVariables";
import { accessInfoRvar } from "./apolloGQL/ReactiveVars";

export class Driver {
    drvrId: number;
    drvrlicPhotoPath: String;
    drvrLicPhotoName: string;
}

export class User {
    userId: number;
    driver: Driver;
    username: string;
    userType: string;
    email: string;
    password: string;
    userName1Ar: string;
    userName2Ar: string;
    userName3Ar: string;
    userName4Ar: string;
    userName1En: string;
    userName2En: string;
    userName3En: string;
    userName4En: string;
    phoneNo: string;
    country: string;
    countryByIp: CountryT;
    city: number;
    gender: string;
    nationalNo: number;
    natIdBkEndPhotoName: string;
    natIdFrEndPhotoName: string;
    userPhotoName: string;
    userStatus: string;
    userStartDate: Date;
    userEndDate: Date;
    userExpiryDate: Date;
    hasNewMsg: boolean;
    isActivated: string;
}

export class UserNotification {
    // constructor() {
    //     this.toUserId = accessInfoRvar()?.user?.userId ?? undefined;
    // }
    notificationId: number;
    lang: string;
    toUserId: number;
    ntcTitle: string;
    ntcText: string;
    status: string;
    isNewNtf: boolean;
    isNtfRead: boolean;
    toDeviceId?: number;
    fromUserId?: number;
    fromDeviceId?: number;
}

export class Auth {
    username: string | undefined = "";
    email: string = "";
    token: string = "";
    isAuthenticated: boolean = false;
    user: User | undefined;
}

export type SelectValueItem = { selectLableAndId: string; valueKey: string };

export type SelectValueType = SelectValueItem[];

export class Company {
    companyId: number;
    compName: string;
    currencyId: string;
    companyType: string;
    // logoPath: string;
    logoFileName: string;
    isActivated: string;
    phoneNumber: string;
    phoneNumber2: string;
    phoneNumber3: string;
    fax: string;
    pobox: string;
    website: string;
    email: string;
    address: string;

    constructor() { }

    get logoUrl() {
        if (this.logoFileName) return `${SERVER_NAME}/images/${this.logoFileName}`;
        else return undefined;
    }
}

export interface CountryT {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}
export enum SeatSelectionStatus {
    "SELECTED" = "s",
    "UNSELECTED" = "uns",
}
export type SeatStatusT = { status: SeatSelectionStatus };

export type VehicleSeatsT = {
    // seatId: number;
    y: number;
    x1: "Y" | "N" | "D";
    x2: "Y" | "N" | "D";
    x3: "Y" | "N" | "D";
};

export type ReservedSeatsT = {
    reqId: number;
    y: number;
    x: number;
    gender: string;
    maleBeside: string;
    srcLoc?: Location | null;
    destLoc?: Location | null;
    tripDuration?: number;
    distance?: number;
    price?: number;
};

export type GenderT = "F" | "M";

export type Waypoint = { location: Location; stopover: boolean, text: string };

export async function openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("chatDb", 1);

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;

            // Create an object store (if it doesn't exist)
            if (!db.objectStoreNames.contains("chattingUsers")) {
                db.createObjectStore("chattingUsers", { keyPath: "userId" });
            }
        };

        request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            resolve(db);
        };

        request.onerror = (event) => {
            reject((event.target as IDBOpenDBRequest).error);
        };
    });
}
