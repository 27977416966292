import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { findCityById } from "../../commonSrc/commonFns";
import { format, formatDistance, parseISO } from "date-fns";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";

export function PsgrTrips(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrJoinedTrips Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    const [fetchType, setFetchType] = useState("today");

    const {
        JsxComp: MyReqsFilter,
        compReducer: [myReqsFilterSt, myReqsFilterDis],
        compRef: myReqsFilterRef,
    } = FormCmps.RadioCmp.getComp("today");

    const {
        error: getUserTraspReqsErr,
        data: getUserTraspReqsData,
        loading: getUserTraspReqsLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.PSGR_RDY_TRIPS/* USER_REQS *//* USER_TRASP_REQS_N_WATING_DRVR_ACPT */, {
        fetchPolicy: "no-cache",
        variables: {
            fetchType: fetchType,           
        },
    });

    useEffect(() => {
        async function executeAsyncUseEffect() { }
        executeAsyncUseEffect();
    }, [dirAndLangRvar().lang]);

    if (getUserTraspReqsLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="psgrMainCompSpinnerId1"
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );
        
    console.log("$$getUserTraspReqs", getUserTraspReqsData);

    return (
        <div>
            <FormCmps.LabelComp.JsxComp id="psgrRequestsId1">
                <div className="text-center p-2 bg-info rounded mt-3">
                    <strong>{ReadTextResource.getTextById("PSGR_JOINT_REQ")}</strong>
                </div>
            </FormCmps.LabelComp.JsxComp>

            <div>
                <MyReqsFilter
                    radioRdc={[myReqsFilterSt, myReqsFilterDis]}
                    id="myReqsFilterId"
                    label={ReadTextResource.getTextById("MY_REQS_FLTR")}
                    inputRef={myReqsFilterRef}
                    radioDir={"horizontal"}
                    onChange={(e, v) => {
                        if (v === "today") setFetchType("today");
                        else setFetchType("all");
                    }}
                >
                    <FormCmps.RadioItem
                        id="todayRadio"
                        label={ReadTextResource.getTextById("TODAY_REQS")}
                        value="today"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem id="allRadio" label={ReadTextResource.getTextById("ALL_REQS")} value="all"></FormCmps.RadioItem>
                </MyReqsFilter>

                {getUserTraspReqsData?.getPsgrRdyTrips?.dataArr?.map((e: any) => {
                    return (
                        <div className="mb-5 p-3 rounded shadow position-relative" key={"your" + e.reqId}>
                            <div>
                                <strong>{ReadTextResource.getTextById("REQ_NO")}</strong> {` ${e.reqId}`}
                                <strong className="ps-3">{ReadTextResource.getTextById("FROM")}</strong>{" "}
                                {` ${findCityById(e.fromCountry, e.fromCity)} `}
                                <strong>{ReadTextResource.getTextById("TO")}</strong> {` ${findCityById(e.fromCountry, e.toCity)} `}
                            </div>
                            <div>
                                <strong>{ReadTextResource.getTextById("DATE_TIME")}</strong>{" "}
                                {` ${format(parseISO(e.traspDatetime), "dd-MM-yyyy hh:mm a")} `}
                            </div>
                            <div>
                                <strong>{ReadTextResource.getTextById("REM_TIME")} </strong>
                                {` ${formatDistance(parseISO(e.traspDatetime), new Date(), { addSuffix: true })} `}
                            </div>
                            <strong>{ReadTextResource.getTextById("REQ_STATUS")} </strong>{" "}
                            {` ${ReadTextResource.getTextById(e.reqStatus?.toUpperCase())} `}
                            {(e.reqType === "Goods" || e.reqType === "Fs_ALL") && (
                                <>
                                    <strong>{ReadTextResource.getTextById("REQ_TYPE") + " "}</strong>
                                    {ReadTextResource.getTextById((e.reqType as string)?.toUpperCase() as any )}
                                </>
                            )}
                            {e.reqType === "Goods" && (
                                <div className="position-absolute top-0 end-0 fs-1 text-info p-3 text-danger">
                                    <i className="bi bi-luggage"></i>
                                </div>
                            )}
                            <div>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                        navigate(`/app/psgrTripDtls/${e.reqId}/${e.driver?.userId}`, {
                                            state: { drvrUserId: e.driver.userId },
                                        });
                                    }}
                                >
                                    {ReadTextResource.getTextById("REQ_DTLS")}{" "}
                                </button>
                            </div>
                        </div>
                    );
                })}
                {getUserTraspReqsData?.getPsgrRdyTrips?.dataArr?.length === 0 ? (
                    <div> {ReadTextResource.getTextById("NO_REQS_TODAY")}</div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
