import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { FormCmps } from "../commonComp/FormComp";
import format from "date-fns/format";
import { parseISO } from "date-fns";
import { findCityById, findCountryById, getAccessInfo } from "../../commonSrc/commonFns";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";
import { MapLocActDisTypesT } from "../commonComp/GoogleMapCmp";

export function TripDtls(
    props: React.PropsWithChildren<{
        reqId?: number;
        reqInfo?: any;
        // goodsInfo?: any[];
        goodsCmp?: React.ReactElement;
        reqDtlType: "DrvrSessionOnly" | "AcceptingPsgrReq" | "PsgrReq" | "JoiningDrvrReq" | "AcceptingDrvrTrip";
    }>
) {
    console.log("First line - TripDtls Component");

    const [traspDatetimeSt, setTraspDatetimeSt] = useState(props?.reqInfo?.traspDatetime);
    const [fromCountrySt, setFromCountrySt] = useState(props?.reqInfo?.fromCountry);
    const [toCountrySt, setToCountrySt] = useState(props?.reqInfo?.toCountry);
    const [fromCitySt, setFromCitySt] = useState(props?.reqInfo?.fromCity);
    const [toCitySt, setToCitySt] = useState(props?.reqInfo?.toCity);
    const [traspTypeSt, setTraspTypeSt] = useState(props?.reqInfo?.traspType);
    const [drvrSrcLocSt, setDrvrSrcLocSt] = useState(props?.reqInfo?.drvrSrcLoc);
    const [drvrDestLocSt, setSrvrDestLocSt] = useState(props?.reqInfo?.drvrDestLoc);
    const [psgrSrcLocSt, setPsgrSrcLocSt] = useState(props?.reqInfo?.applicantSrcLoc);
    const [psgrDestLocSt, setPsgrDestLocSt] = useState(props?.reqInfo?.applicantDestLoc);
    const [psgrCompanionsSt, setPsgrCompanionsSt] = useState(
        props?.reqInfo?.fPsgrCompanions ?? 0 + props?.reqInfo?.mPsgrCompanions ?? 0
    );
    const [reqTypeSt, setReqTypeSt] = useState(props?.reqInfo?.reqType);
    const [drvrIdSt, setDrvrIdSt] = useState(props?.reqInfo?.drvrId);
    const [drvrUserIdSt, setDrvrUserIdSt] = useState(props?.reqInfo?.driver?.userId);
    const [reqIdSt, setReqIdSt] = useState(props?.reqInfo?.reqId);

    const [getReqDtls, { loading: getReqDtlLoading, data: getReqDtlData, error: getReqDtlErr }] = useLazyQuery(
        getGqlStmt().queries.TRASP_REQ,
        {
            fetchPolicy: "no-cache",
            variables: {
                reqId: +props.reqId!,
            },

        }
    );

    useEffect(() => {
        async function fff() {
            if (props.reqId) {
                await getReqDtls({
                    onCompleted: (data) => {
                        setTraspDatetimeSt(data?.getTraspReq?.data?.traspDatetime);
                        setFromCountrySt(data?.getTraspReq?.data?.fromCountry);
                        setToCountrySt(data?.getTraspReq?.data?.toCountry);
                        setFromCitySt(data?.getTraspReq?.data?.fromCity);
                        setToCitySt(data?.getTraspReq?.data?.toCity);
                        setTraspTypeSt(data?.getTraspReq?.data?.traspType);
                        setDrvrSrcLocSt(data?.getTraspReq?.data?.drvrSrcLoc);
                        setSrvrDestLocSt(data?.getTraspReq?.data?.drvrDestLoc);
                        setPsgrSrcLocSt(data?.getTraspReq?.data?.applicantSrcLoc);
                        setPsgrDestLocSt(data?.getTraspReq?.data?.applicantDestLoc);
                        setPsgrCompanionsSt(
                            data?.getTraspReq?.data?.mPsgrCompanions ?? 0 + data?.getTraspReq?.data?.fPsgrCompanions ?? 0
                        );
                        setReqTypeSt(data?.getTraspReq?.data.reqType);
                        setDrvrIdSt(data?.getTraspReq?.data.drvrId);
                        setDrvrUserIdSt(data?.getTraspReq?.data.driver?.userId);
                        setReqIdSt(data?.getTraspReq?.data.reqId);
                    }
                });
            }
        }
        fff();
    }, []);

    const {
        JsxComp: PsgrSrcLocationComp,
        compReducer: [psgrSrcLocationSt, psgrSrcLocationDis],
        compRef: psgrSrcLocationRef,
    } = FormCmps.LocationCmp.getComp(psgrSrcLocSt);

    const {
        JsxComp: PsgrDestLocation,
        compReducer: [psgrDestLocationSt, psgrDestLocationDis],
        compRef: psgrDestLocationStRef,
    } = FormCmps.LocationCmp.getComp(psgrDestLocSt);

    const {
        JsxComp: DrvrSrcLocationComp,
        compReducer: [drvrSrcLocationSt, drvrSrcLocationDis],
        compRef: drvrSrcLocationRef,
    } = FormCmps.LocationCmp.getComp(drvrSrcLocSt);

    const {
        JsxComp: DrvrDestLocationComp,
        compReducer: [drvrDestLocationSt, drvrDestLocationDis],
        compRef: drvrDestLocationRef,
    } = FormCmps.LocationCmp.getComp(drvrDestLocSt);

    const {
        JsxComp: JoinedPsgrsComp,
        compReducer: [joinedPsgrsSt, joinedPsgrsDis],
        compRef: joinedPsgrsRef,
    } = DrvrJoinedPsgrsCmp.getComp();

    const {
        JsxComp: DrvrJoinedPsgrsComp,
        compReducer: [drvrJoinedPsgrsSt, drvrJoinedPsgrsDis],
        compRef: drvrJoinedPsgrsRef,
    } = DrvrJoinedPsgrsCmp.getComp();

    const {
        JsxComp: GoodsSrcLocComp,
        compReducer: [goodsSrcLocSt, goodsSrcLocDis],
        compRef: goodsSrcLocRef,
    } = FormCmps.LocationCmp.getComp();

    const {
        JsxComp: GoodsDestLocComp,
        compReducer: [goodsDestLocSt, goodsDestLocDis],
        compRef: goodsDestLocRef,
    } = FormCmps.LocationCmp.getComp();

    if (props.reqId && props.reqInfo) {
        // throw new Error("Only one parameter allowed for TripDtls component");
        console.warn("Only one of these parameters are allowed for TripDtls component, reqId or reqInfo");
        return <div>Only one of these parameters are allowed for TripDtls component, reqId or reqData</div>;
    }
    // if (props.reqId) getReqDtls();
    if (props.reqId && getReqDtlLoading) {
        return <div>loading...</div>;
    }

    if (!props.reqId && !props.reqInfo) return <div>Invalid parameters</div>;

    if ((props.reqId && (!getReqDtlData?.getTraspReq?.success || !getReqDtlData?.getTraspReq?.data)) || (!props.reqId && !props.reqInfo)) {
        return <div>{ReadTextResource.getTextById("NO_DATA")!}</div>;
    }

    const AcceptingPsgrReq = (
        <div className="row col-12 col-sm-12 col-lg-12 col-md-12">
            {psgrSrcLocSt ? (
                <div className="col-md-6 col-lg-6">
                    <PsgrSrcLocationComp
                        id="PsgrSrcLocationLocationId01"
                        locationRdc={[psgrSrcLocationSt, psgrSrcLocationDis]}
                        label={<b>{reqTypeSt === "Goods" ? ReadTextResource.getTextById("GOODS_DEST_LOC") : ReadTextResource.getTextById("PSGR_SRC_LOC")}: </b>}
                        inputRef={psgrSrcLocationRef}
                        readOnly={true}
                    ></PsgrSrcLocationComp>
                </div>
            ) : (
                <div>
                    <b>{reqTypeSt === "Goods" ? ReadTextResource.getTextById("GOODS_DEST_LOC") : ReadTextResource.getTextById("PSGR_SRC_LOC")}</b>:{ReadTextResource.getTextById("FROM_DRVR_LOC")}
                </div>
            )}

            {psgrDestLocSt ? (
                <div className="col-md-6 col-lg-6">
                    <PsgrDestLocation
                        id="drvrDropOffLocId1"
                        locationRdc={[psgrDestLocationSt, psgrDestLocationDis]}
                        label={<b> {reqTypeSt === "Goods" ? ReadTextResource.getTextById("GDS_PICKUP_LOC") : ReadTextResource.getTextById("PSGR_DEST_LOC")}:</b>}
                        inputRef={psgrDestLocationStRef}
                        readOnly={true}
                    ></PsgrDestLocation>
                </div>
            ) : (
                <div>
                    <b>{reqTypeSt === "Goods" ? ReadTextResource.getTextById("GDS_PICKUP_LOC") : ReadTextResource.getTextById("PSGR_DEST_LOC")}:</b>
                    {ReadTextResource.getTextById("FROM_DRVR_LOC")}
                </div>
            )}
            <div className="mt-3 " ><b>{ReadTextResource.getTextById("GOODS")}</b></div>
            {props.goodsCmp}
        </div>
    );

    console.log("reqIdSt", reqIdSt, "drvrIdSt", drvrIdSt, "props.reqData", props.reqInfo);

    const PsgrReqComp = (
        <>
            {reqTypeSt !== "Goods" &&
                <div className="col-12 col-sm-12 col-md-8 col-lg-12">
                    <JoinedPsgrsComp
                        id="TripDtlsJoinedPsgrsId01"
                        label={ReadTextResource.getTextById("RES_SEATS")}
                        reqId={reqIdSt}
                        drvrUserId={drvrUserIdSt}
                        seatRdc={[joinedPsgrsSt, joinedPsgrsDis]}
                        inputRef={joinedPsgrsRef}
                    ></JoinedPsgrsComp>
                </div>}
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row row-cols-lg-2 row-cols-1">
                    {/* {psgrSrcLocSt ? (
                        <div>
                            <PsgrSrcLocationComp
                                id="PsgrSrcLocationCmpId01"
                                locationRdc={[psgrSrcLocationSt, psgrSrcLocationDis]}
                                label={<b> {ReadTextResource.getTextById("PSGR_SRC_LOC")}:</b>}
                                inputRef={psgrSrcLocationRef}
                                readOnly={true}
                            ></PsgrSrcLocationComp>
                        </div>
                    ) : (
                        <div>
                            <b>{ReadTextResource.getTextById("PSGR_SRC_LOC")}</b>: {ReadTextResource.getTextById("FROM_DRVR_LOC")}
                        </div>
                    )}

                    {psgrDestLocSt ? (
                        <div>
                            <PsgrDestLocation
                                id="psgrDestLocId02"
                                locationRdc={[psgrDestLocationSt, psgrDestLocationDis]}
                                label={<b> {ReadTextResource.getTextById("PSGR_DEST_LOC")}:</b>}
                                inputRef={psgrDestLocationStRef}
                                readOnly={true}
                            ></PsgrDestLocation>
                        </div>
                    ) : (
                        <div>
                            <b> {ReadTextResource.getTextById("PSGR_DEST_LOC")} :</b>
                            {ReadTextResource.getTextById("TO_DRVR_LOC")}
                        </div>
                    )} */}

                    {/*           {drvrSrcLocSt ? (
                        <div>
                            <DrvrSrcLocationComp
                                id="drvrDropOffLocId1"
                                locationRdc={[drvrSrcLocationSt, drvrSrcLocationDis]}
                                label={<b>{ReadTextResource.getTextById("DRVR_SRC_LOC")} :</b>}
                                inputRef={psgrDestLocationStRef}
                                readOnly={true}
                            ></DrvrSrcLocationComp>
                        </div>
                    ) : (
                        <div>
                            <b>{ReadTextResource.getTextById("DRVR_SRC_LOC")} :</b>
                            {ReadTextResource.getTextById("FROM_PSGR_LOC")}
                        </div>
                    )}

                    {drvrSrcLocSt ? (
                        <div>
                            <DrvrDestLocationComp
                                id="drvrDropOffLocId123"
                                locationRdc={[drvrDestLocationSt, drvrDestLocationDis]}
                                label={<b>موقع وصول السائق:</b>}
                                inputRef={psgrDestLocationStRef}
                                readOnly={true}
                            ></DrvrDestLocationComp>
                        </div>
                    ) : (
                        <div>
                            <b>{ReadTextResource.getTextById("DRVR_DEST_LOC")} :</b>
                            {ReadTextResource.getTextById("TO_PSGR_LOC")}
                        </div>
                    )} */}
                </div>
            </div>
            <div className="mt-3 col-12 col-sm-12 col-md-8 col-lg-12" ><b>{ReadTextResource.getTextById("GOODS")}</b></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-12">
                {props.goodsCmp}
            </div>
        </>
    );

    const DrvrReqComp = <div className="col-12 col-sm-12 col-md-12 col-lg-12">
        {reqTypeSt !== "Goods" &&
            <FormCmps.ContainerNoFormCmp id="drvrTraspReqDtlId1">
                <div className="row g-1 col-12 col-sm-12 col-md-12 col-lg-8">
                    <DrvrJoinedPsgrsComp
                        id="vclSeatSelectorId1"
                        label="المقاعد المحجوزة"
                        reqId={reqIdSt}
                        drvrUserId={getAccessInfo()?.user?.userId!}
                        seatRdc={[drvrJoinedPsgrsSt, drvrJoinedPsgrsDis]}
                        inputRef={drvrJoinedPsgrsRef}
                    ></DrvrJoinedPsgrsComp>
                </div>
            </FormCmps.ContainerNoFormCmp>
        }
        <div className="mt-3 " ><b>{ReadTextResource.getTextById("GOODS")}</b></div>
        <div className="row g-1 col-12 col-sm-12 col-md-12 col-lg-12">
            {props.goodsCmp}
        </div>
    </div>

    const AcceptingDrvrTrip = AcceptingPsgrReq;

    return (
        <FormCmps.ContainerNoFormCmp id="TripDtlsId1">
            {/* <div className="row g-1"> */}
            <div className="pb-1 text-center col-lg-12 col-md-12 col-sm-12 bg-info rounded-2">
                {/* <u> */}
                <b> {ReadTextResource.getTextById("REQ_DTLS")}:</b>
                {/* </u> */}
            </div>
            <div className="row pb-3">
                <div className="col-6 text-center">
                    <b> {ReadTextResource.getTextById("DATE")}: </b>
                    <br />
                    {format(parseISO(traspDatetimeSt), "EEEE dd-MM-yyyy")}
                    {/* {traspDatetimeSt ? format(parseISO(traspDatetimeSt), "EEEE dd-MM-yyyy") : ""} */}
                </div>
                <div className="col-6 text-center">
                    <b> {ReadTextResource.getTextById("TIME")}:</b>
                    <br />
                    {format(parseISO(traspDatetimeSt), "hh:mm a")}
                    {/* {traspDatetimeSt ? format(parseISO(traspDatetimeSt), "hh:mm a") : ""} */}
                </div>
            </div>
            {traspTypeSt === "In" && (
                <div className={`${traspTypeSt === "In" ? "d-none" : "d-blcok"}`}>
                    <b>{ReadTextResource.getTextById("FROM_COUNTRY")} :</b>
                    <br />
                    {findCountryById(fromCountrySt)}
                </div>
            )}
            <div className={`${traspTypeSt === "In" ? "d-none" : "d-blcok"}`}>
                <b> {ReadTextResource.getTextById("TO_COUNTRY")} :</b>
                <br />
                {traspTypeSt === "In" ? findCountryById(fromCountrySt) : findCountryById(toCountrySt)}
            </div>
            <div className="row pb-3">
                <div className="col-6 text-center">
                    <b>{ReadTextResource.getTextById("FROM_CITY")} :</b>
                    <br />
                    {findCityById(fromCountrySt, fromCitySt)}
                </div>
                <div className="col-6 text-center">
                    <b>{ReadTextResource.getTextById("TO_CITY")} :</b>
                    <br />
                    {findCityById(traspTypeSt === "In" ? fromCountrySt : toCountrySt, toCitySt)}
                </div>
            </div>
            <div className="row pb-3">
                {reqTypeSt !== "Goods" && (
                    <div className="col-6 text-center">
                        <b> {ReadTextResource.getTextById("NO_OF_PSGRS")} :</b>
                        <br />
                        {psgrCompanionsSt + 1}
                    </div>
                )}
                {reqTypeSt === "Fs_ALL" &&
                    <div className="col-6 text-center">
                        <b> {ReadTextResource.getTextById("F_ONLY")} </b>
                    </div>}
            </div>

            {/* row class was in below div, it was removed to lay the maps on most left and right */}
            <div className=" col-12 col-sm-12 col-md-12 col-lg-12  pb-3">
                <div className="row ">
                    <div className="col-6 text-center">
                        <div>
                            {drvrSrcLocSt && <DrvrSrcLocationComp
                                id="drvrDropOffLocId1"
                                locationRdc={[drvrSrcLocationSt, drvrSrcLocationDis]}
                                label={<b>{ReadTextResource.getTextById("DRVR_SRC_LOC")} :</b>}
                                inputRef={psgrDestLocationStRef}
                                readOnly={true}
                                defaultValue={drvrSrcLocSt}
                            ></DrvrSrcLocationComp>}
                        </div>
                    </div>
                    <div className="col-6 text-center">
                        {drvrDestLocSt && <DrvrDestLocationComp
                            id="drvrDropOffLocId123"
                            locationRdc={[drvrDestLocationSt, drvrDestLocationDis]}
                            label={<b>{ReadTextResource.getTextById("DRVR_DEST_LOC")} :</b>}
                            inputRef={psgrDestLocationStRef}
                            defaultValue={drvrDestLocSt}
                            readOnly={true}
                        ></DrvrDestLocationComp>}
                    </div>
                </div>
            </div>

            {props.reqDtlType === "PsgrReq" && PsgrReqComp}

            {props.reqDtlType === "DrvrSessionOnly" && DrvrReqComp}

            {props.reqDtlType === "AcceptingPsgrReq" && (
                <>
                    {AcceptingPsgrReq}
                    {/* {DrvrReqComp} */}
                </>
            )}

            {props.reqDtlType === "AcceptingDrvrTrip" && <>{AcceptingDrvrTrip} </>}
            {/* <div>المسافة:{!props.reqId ? props.reqData.getTraspReq.data.distance : getReqDtlData.getTraspReq.data.distance}</div> */}

            {/* </div> */}

        </FormCmps.ContainerNoFormCmp>
    );
}
