import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import React, { FormEvent, useRef, useState } from "react";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { accessInfoRvar, dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ModalComp } from "../commonComp/ModalComp";
import { apolloClient } from "../../commonSrc/apolloGQL/ApolloConfig";
import { getAccessInfo, getGraphQlError, setAccessInfo } from "../../commonSrc/commonFns";
import { Auth, CountryT, User } from "../../commonSrc/Types";
import { countries } from "../../commonSrc/Constants";
import { SERVER_NAME } from "../../commonSrc/EnvVariables";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { socket } from "../../Socket";
import { Button } from "../commonComp/Button";

export default function Login(props: React.PropsWithChildren<{ /* no need for now */ relogin?: boolean }>) {
    console.log("First line - Login Component");
    // const fff = useReactiveVar(dirAndLangRvar); // to be deleted xxxxxxxxxxxxxxxxxxxxxxxxxx
    const location = useLocation();
    const redirectTo = (location.state as any)?.redirectTo || "/app/dashboard";
    // const relogin = (state as any)?.relogin;

    console.log("redirectTo in login", redirectTo, "address is", location);
    console.log("location.search", location.search);

    // console.log("relogin=", (state as any)?.relogin);

    const searchParams = new URLSearchParams(location.search);
    const relogin = searchParams.get("relogin");

    const accessToken = useReactiveVar(accessInfoRvar);
    const nav = useNavigate();
    const [AuthMessage, setAuthMessage] = useState("");

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const actvLinkBtnRef = useRef<HTMLButtonElement>(null);

    const [login, { error, data, loading }] = useMutation(getGqlStmt().mutations.LOGIN_MUTATION, {
        fetchPolicy: "no-cache",
    });

    const [sendActivationLink, { error: actvLinkError, data: actvLinkData, loading: actvLinkLoading }] = useMutation(
        getGqlStmt().mutations.RESEND_ACTV_LINK,
        {
            fetchPolicy: "no-cache",
            variables: {
                email: (emailRef?.current?.value as string)?.trim(),
                password: passwordRef?.current?.value as string,
            },
            onCompleted: () => {
                setAuthMessage(
                    "Email Sent successfully, please go to your email Inbox or Spam and click the link to activate your account"
                );
                actvLinkBtnRef.current?.setAttribute("disabled", "true");
            },
        }
    );

    const [getCompany, { error: getCompanyError, data: getCompanyData, loading: getCompanyLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_COMPANY,
        {
            fetchPolicy: "no-cache",
        }
    );

    const goMyAccounts = () => {
        nav("/myAccounts");
    };
    const [showActivationLink, setShowActivationLink] = useState(false);

    const onSubmitLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("INVLID_USER_PASS") });
        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        try {
            if (!emailRef.current?.value || !passwordRef.current?.value) {
                okModal.setProps({ msg: ReadTextResource.getTextById("INVLID_USER_PASS") });
                okModal.showAsync();
                return;
            }
            await spinner.showAsync();
            const loginResponse = (
                await login({
                    variables: {
                        email: (emailRef.current?.value as string).trim(),
                        password: passwordRef.current?.value as string,
                    },
                    onCompleted: () => {
                        spinner.hide();
                    },
                    onError: (err) => {
                        spinner.hide();
                        if (getGraphQlError(err).errorId === "ERR_USR_NOT_EXIST") {
                            setAuthMessage("No such account!");
                            return;
                        }
                    },
                })
            ).data.login as Auth & { errorId: string, errorDesc: string };

            console.log("##loginResponse=", loginResponse);

            if (loginResponse?.errorId === "ERR_USR_NOT_EXIST") {
                setAuthMessage("No such account!");
                return;
            }

            if (loginResponse?.isAuthenticated && loginResponse.user?.isActivated === "Y") {
                setAuthMessage("");
                if (!socket.connected) {
                    socket.auth = { jwtToken: loginResponse?.token };
                    const s = socket.connect();
                    if (s.connected)
                        console.log("%%now socket is connected");
                    else
                        console.log("%%still socket is NOT connected");


                } else console.log("already connected to socket");
            } else {
                if (!loginResponse.user?.isActivated || loginResponse.user?.isActivated === "N") {
                    await spinner.hideAsync();
                    setShowActivationLink(true);
                    setAuthMessage(ReadTextResource.getTextById("ACC_IS_NOT_ACTV"));
                    return;
                }
                await spinner.hideAsync();
                okModal.show();
                setAuthMessage(ReadTextResource.getTextById("INVLID_USER_PASS"));
                //recently added ##TO-DO## to review all login process, some code should not be called while credentials is not successfull
                return;
            }

            let currentCountryByIp;
            try {
                const ipInfoQuery = await apolloClient.mutate({
                    mutation: getGqlStmt().queries.GET_IP_INFO,
                });
                console.log("##ipInfoQuery=", ipInfoQuery);

                currentCountryByIp = countries.find((e) => e.code === ipInfoQuery.data.getIpInfo.data.country_code) as CountryT;
                console.log("##currentCountryByIp=", currentCountryByIp);
            } catch (err) {
                console.log("##error in getting GET_IP_INFO - abd107", err);
            }

            // setAccessInfo({
            //     token: loginResponse?.token,
            //     email: loginResponse.email,
            //     username: loginResponse?.username,
            //     isAuthenticated: loginResponse?.isAuthenticated,
            //     // user: loginResponse.user,
            //     user: { ...(loginResponse.user as User), countryByIp: currentCountryByIp as CountryT },
            // });

            accessInfoRvar({
                token: loginResponse?.token,
                email: loginResponse.email,
                username: loginResponse?.username,
                isAuthenticated: loginResponse?.isAuthenticated,
                // user: loginResponse.user,
                user: { ...(loginResponse.user as User), countryByIp: currentCountryByIp as CountryT },
            });
            // if (!socket.connected) {
            //     socket.auth = { jwtToken: getAccessInfo()?.token };
            //     socket.connect();
            // }

            if (loginResponse?.isAuthenticated) await serviceWorkerRegistration.subscribeFn();
            // userInfoRvar(loginResponse?.user as User);
            // console.log("userInfoRvar(loginResponse?.user as User)=", userInfoRvar(loginResponse?.user as User));

            // console.log("in login getCompanyInfoVar1##=", getCompanyInfoVar());
            // const companyInfo = await getCompany({
            //     onCompleted(data) {
            //         const company = Object.assign(new Company(), data?.getCompany);
            //         console.log("company##",company)
            //         getCompanyInfoVar(company);
            //         console.log("getCompanyInfoVar().logoUrl##",getCompanyInfoVar().logoUrl)
            //     },
            //     variables: {
            //         username: loginResponse?.username,
            //     },
            // }).catch((err) => {
            //     console.log("err##", err);
            // });
            // console.log("in login getCompanyInfoVar##=", getCompanyInfoVar());

            //get the user and company data

            if (loginResponse?.isAuthenticated) {
                await spinner.hideAsync();
                nav(redirectTo, { replace: true });
            }
        } catch (err) {
            console.log("err2##", err);
            await spinner.hideAsync();
        }
    };

    return (
        <>
            <div>
                <form className="container d-flex flex-column" onSubmit={onSubmitLogin}>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <p className="text-danger">{relogin ? "Please login to continue" : ""}</p>
                            <label className="form-label" htmlFor="email">
                                {ReadTextResource.getTextById("EMAIL")}
                            </label>
                            <input className="form-control" type="text" id="email" ref={emailRef} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <label className="form-label" htmlFor="Password">
                                {ReadTextResource.getTextById("PASSWORD")}
                            </label>
                            <input className="form-control" type="password" id="password" ref={passwordRef} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3">
                            <div className="row">
                                <NavLink to={"/forgotPass"} className={(isActive) => "pe-2 col-auto" + (!isActive ? " unselected" : "")}>
                                    {ReadTextResource.getTextById("RESET_PASSWORD")}
                                </NavLink>
                                <NavLink
                                    to={"/newUser"}
                                    className={(isActive) => "pe-2 col-4 ms-auto text-end " + (!isActive ? " unselected" : "")}
                                >
                                    {ReadTextResource.getTextById("NEW_USER")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3 d-flex justify-content-center">
                            <input className="btn btn-primary flex-fill" type="submit" value={ReadTextResource.getTextById("LOGIN")} />
                            <button
                                type="button"
                                className="d-none"
                                onClick={async () => {
                                    // alert("AAAAAAAAAAAAAAAA");
                                    // caches.delete()
                                    // const reg = await navigator.serviceWorker.getRegistration();
                                    // const sub = await reg?.pushManager?.getSubscription();
                                    // console.log("subscription by btn", sub);
                                    const p = () => {
                                        return new Promise(async (ok) => {
                                            const cacheNames = await caches.keys();
                                            for (let i = 0; i < cacheNames.length; i++) await caches.delete(cacheNames[i]);
                                            serviceWorkerRegistration.unregister();
                                            ok("");
                                        });
                                    };
                                    await p();
                                    // cacheNames.forEach(async (cacheName) => {
                                    // });

                                    // setTimeout(async () => {
                                    window.location.reload();
                                    // }, 5000);

                                    // caches.keys().then((cacheNames) => {
                                    //     cacheNames.forEach((cacheName) => {
                                    //         caches.delete(cacheName);
                                    //     });
                                    // });
                                }}
                            >
                                ClearCaches 1
                            </button>
                            {/* <button>44444</button> */}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-6 col-12 mb-3 d-flex justify-content-center">
                            <a id="googleLgin" href={`https://local.dev.com:9000/auth/google`} >
                                <img src='/googleLogo.png' alt="googleLogo" />
                                Sign in with Google</a>
                        </div>
                    </div>
                    {!getAccessInfo()?.isAuthenticated ? (
                        <div className="row justify-content-center">
                            <div className="col-sm-9 col-12 mb-3 d-flex justify-content-center text-danger">{AuthMessage}</div>
                            <div
                                className={`col-sm-9 col-12 mb-3 d-flex justify-content-center text-danger ${showActivationLink ? "d-block" : "d-none"
                                    }`}
                            >
                                <div>
                                    <button
                                        type="button"
                                        ref={actvLinkBtnRef}
                                        className="btn btn-danger flex-fill"
                                        onClick={async () => {
                                            await sendActivationLink();
                                        }}
                                    >
                                        {ReadTextResource.getTextById("RESEND_ACTV_LINK")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </form>
            </div>
        </>
    );
}
