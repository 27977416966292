import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { chattingUsersRVar, prevUserNtnsRVar, receivedMsgRVar, userNotificationsRVar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { getAccessInfo } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { User, UserNotification } from "../../commonSrc/Types";
import { getIDbUserNotifications, setAllNewNtfsReadIDb, setIDbUserNotifications, setNtfReadIDb } from "../../commonSrc/indexedDb/fns";

export function NotificationsCmp(props: React.PropsWithChildren<{}>) {
    console.log("First line - NotificationsCmp Component");
    const navigate = useNavigate();
    // useReactiveVar(receivedMsgRVar);
    // useReactiveVar(chattingUsersRVar);
    useReactiveVar(userNotificationsRVar);
    useReactiveVar(prevUserNtnsRVar);


    async function setIDbNewMsgRead() {
        const readUserNtfs = userNotificationsRVar().map((e) => {
            e.isNewNtf = false;
            return e;
        });
        userNotificationsRVar(readUserNtfs);
        // const iDbUsers: UserNotification[] = await getIDbUserNotifications();

        // const iDbUsersUpdated = iDbUsers.map((e) => {
        //     e.isNewNtf = false;
        //     return e;
        // });
        // await setIDbUserNotifications(iDbUsersUpdated);
        await setAllNewNtfsReadIDb();
    }

    const [divStyle, setDivStyle] = useState({});
    const [pageSt, setPageSt] = useState(1);
    const [pageSizeSt, setPageSizeSt] = useState(30);


    const [
        getUserNotifications,
        // refetch: refetchPsgrsTraspReqs,
        { refetch: refetchUserNotifications, error: getUserNotificationsErr, data: getUserNotificationsData, loading: getUserNotificationsLoading },
    ] = useLazyQuery(getGqlStmt().queries.USER_NOTIFICATIONS, {
        fetchPolicy: "no-cache",
        // variables: {
        //     page: 1,
        //     pageSize: 30,
        // },
        onCompleted: (data) => { },
    });

    const chatBoxRef = useRef<HTMLDivElement>(null);
    const [firstTimeNtfsFetched, setFirstTimeNtfsFetched] = useState(false);

    useEffect(() => {
        async function fnUseEfct() {
            const ntfDivEl = document.getElementById("ntfDivEl");
            const eachNtfHeight = ntfDivEl?.clientHeight ?? 60;
            const mainHeader = document.getElementById("mainHeaderId0");
            const mainHeaderHeight = mainHeader?.clientHeight;
            const ntfContainerHeight = window.innerHeight - 15 - (mainHeaderHeight ?? 15)

            const toFullNtfCapacity = Math.ceil((ntfContainerHeight ?? 30) / (eachNtfHeight ?? 1)) + 1;



            console.log("toFullNtfCapacity", toFullNtfCapacity, "eachNtfHeight", eachNtfHeight, "ntfContainerHeight", ntfContainerHeight);
            setPageSizeSt(toFullNtfCapacity);

            if (!firstTimeNtfsFetched) {
                const ntfs = await getUserNotifications({
                    variables: {
                        page: 1,
                        pageSize: toFullNtfCapacity,
                    },
                    onCompleted: () => {
                        // setTimeout(() => {
                        //     const g = chatBoxRef?.current;
                        //     if (g) {
                        //         g.scrollTop = g.scrollHeight;
                        //     }
                        // }, 500);
                    },
                });

                const length = ntfs?.data?.getUserNotifications?.dataArr.length;
                const userNftsArr: UserNotification[] = ntfs?.data?.getUserNotifications?.dataArr;
                const arr: any[] = [];
                // for (let i = 0; i < length; i++) {
                //     arr[i] = chatMsgsArr[length - i - 1];
                // }
                // prevUserMsgsRVar([...arr, ...prevUserMsgsRVar()]);


                const userNtfsLclStrg: UserNotification[] = await getIDbUserNotifications();
                let readNtfs = [];
                userNtfsLclStrg.map((els) => {
                    if (els.isNtfRead) {
                        userNftsArr.map((e) => (els.notificationId === e.notificationId) ? (e.isNtfRead = true) : "");
                    }
                });

                for (let i = 0; i < userNftsArr.length; i++) {
                    if (!userNtfsLclStrg.some((lclNtf) => lclNtf.notificationId === userNftsArr[i].notificationId))
                        userNftsArr[i].isNtfRead = true;

                }

                prevUserNtnsRVar([...userNftsArr]);

                setFirstTimeNtfsFetched(true);
                await setIDbNewMsgRead();

            }


            let chatStyle = {
                maxHeight: `calc(100vh - 15px - ${mainHeaderHeight + "px"} )`,
                height: "100vh",
            };

            setDivStyle(chatStyle);

            //this is used to scroll to bottom, it's used in chat app only
            // setTimeout(() => {
            //     const g = chatBoxRef?.current;
            //     if (g) {
            //         g.scrollTop = g.scrollHeight;
            //     }
            // }, 30);
        }
        fnUseEfct();
    }, [userNotificationsRVar()]);

    useEffect(() => {
        return () => {
            userNotificationsRVar([]);
        };
    }, []);

    // if (getUserChatUsersLoading) return <div>Loading...</div>;
    if (prevUserNtnsRVar().length === 0) return <div>No notifications</div>;

    return (
        <div>
            <div id="userNntsId1" className="d-flex flex-column" style={divStyle}>
                <div
                    ref={chatBoxRef}
                    className="flex-grow-1 overflow-auto"
                    onScroll={async (e) => {
                        const { scrollTop, clientHeight, scrollHeight, offsetHeight } = e.currentTarget;

                        // if (scrollTop === 0) {
                        if (scrollTop + clientHeight >= scrollHeight - 10) {
                            setPageSt(pageSt + 1);
                            const refetchData = await refetchUserNotifications({
                                page: pageSt + 1,
                                pageSize: pageSizeSt,
                            });

                            const length = refetchData?.data?.getUserNotifications?.dataArr.length;
                            if (length !== 0) chatBoxRef.current!.scrollTop = 0.2 * scrollHeight;
                            const userNtfsArr = refetchData?.data?.getUserNotifications?.dataArr;
                            const arr: any[] = [];
                            // for (let i = 0; i < length; i++) {
                            //     arr[i] = chatMsgsArr[length - i - 1];
                            // }
                            prevUserNtnsRVar([...prevUserNtnsRVar(), ...userNtfsArr]);
                            // setTimeout(() => {
                            // }, 100);
                            // prevUserMsgsRVar([...prevUserMsgsRVar(), ...refetchData?.data.getUserNotifications?.dataArr]);
                        }
                    }}
                >
                    {/* style={{ height: "350px" }} */}

                    <div>
                        {userNotificationsRVar().length !== 0 &&
                            userNotificationsRVar()
                                .map((e, i) => {
                                    return (
                                        <div
                                            className={`rounded shadow-sm border border-1 p-3 my-3 ${e.isNtfRead ? "" : "bg-info"}`}
                                            key={i + "ntfNewId"}
                                            onClick={() => {
                                                const readNtfs = userNotificationsRVar().map((e1) => {
                                                    if (e1.notificationId === e.notificationId)
                                                        e1.isNtfRead = true;
                                                    return e;
                                                });
                                                userNotificationsRVar(readNtfs);
                                            }}
                                        >
                                            {e.ntcText}
                                        </div>
                                    );
                                })}
                    </div>

                    <div>Old Received notifications:</div>
                    {prevUserNtnsRVar().map((e, i) => {
                        return (
                            <div id="ntfDivEl"
                                className={`rounded shadow-sm border border-1 p-3 my-3 ${e.isNtfRead ? "" : "bg-info"}`}
                                key={i + "ntfId"}
                                onClick={
                                    async () => {
                                        const readNtfs = prevUserNtnsRVar().map((e1) => {
                                            if (e1.notificationId === e.notificationId)
                                                e1.isNtfRead = true;
                                            return e1;
                                        });
                                        prevUserNtnsRVar(readNtfs);
                                        // const iDbUsers: UserNotification[] = await getIDbUserNotifications();

                                        // const iDbUsersUpdated = iDbUsers.map((e1) => {
                                        //     if (e1.notificationId === e.notificationId)
                                        //         e1.isNtfRead = true;
                                        //     return e1;
                                        // });
                                        // await setIDbUserNotifications(iDbUsersUpdated);
                                        await setNtfReadIDb(e.notificationId)
                                    }}>
                                <div>{e.ntcText}</div>
                            </div>
                        );
                    })}

                    <div>----------- end old notifications ---------</div>

                </div>
            </div>
        </div >
    );
}
