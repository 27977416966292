// This optional code is used to register a service worker.
// register() is not called by default.

import { apolloClient } from "./commonSrc/apolloGQL/ApolloConfig";
import { isNewUpdate } from "./commonSrc/apolloGQL/ReactiveVars";
import { getGqlStmt } from "./commonSrc/graphQL/Queries";
import { ModalComp } from "./components/commonComp/ModalComp";
// import manifest from "./manifest.json";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        //window.location.hostname === 'app.localhost' ||
        window.location.hostname === "192.168.8.150" ||
        window.location.hostname === "192.168.8.151" ||
        window.location.hostname === "192.168.1.34" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
    console.log("serviceWorker process.env.NODE_ENV=" || process.env.NODE_ENV);
    //  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    if ("serviceWorker" in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            console.log("serviceWorker problem");
            return;
        }

        window.addEventListener("load", async () => {
            // alert("onLoad is called");
            setInterval(checkVersionChange, 1000 * 60*1);
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
            console.log("serviceWorker isLocalhost=", isLocalhost ? "localhost ok" : "localhost notOk");

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                // navigator.serviceWorker.ready.then(async (registration) => {
                //     await registration?.showNotification("BBBB", { body: "BBBBBBBBBB" }).catch((err) => {
                //         alert("to delete the whole code here  " + err);
                //     });
                // });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }

            // let refreshing = false;

            // detect controller change and refresh the page
            // navigator.serviceWorker.addEventListener("controllerchange", async () => {
            //     alert("controllerchange controller " + (navigator.serviceWorker.controller?"Exist":"null"));
            //     // await subscribeFn();

            //     let refreshing = false;
            //     // if (!refreshing && navigator.serviceWorker.controller == null) {
            //     //     alert("reloading");
            //     //     window.location.reload();
            //     //     refreshing = true;
            //     //     return;
            //     // }
            // });
        });
    }
}

export async function subscribeFn() {
    return new Promise(async (resolve, reject) => {
        try {
            // let refreshing = false;

            // alert(
            //     "controllerchange refreshing= " +
            //         (!refreshing ? "will refresh" : "No refresh") +
            //         " controller " +
            //         (navigator.serviceWorker.controller ? "exist" : "null")
            // );

            const registration = await navigator.serviceWorker.ready;

            console.log("This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA");

            const publicKeyVapid = "BLCAGHYGuMYdTRycgUNBo_lgVrDiejO9-pCo5s1DI6jSj5fCr30C2TGP82ZUji9Tlheix9hqZDrpHC_sFJzxkus";
            let subscription = null;
            subscription = await registration.pushManager.getSubscription();
            if (navigator.serviceWorker.controller) {
                if (!subscription) {
                    subscription = await registration.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: publicKeyVapid,
                        })
                        .catch((err) => {
                            console.log("subscribeInfo error", err);
                            resolve("");
                        });
                    console.log("subscribeInfo", subscription);
                }
                const subscribeRes = await apolloClient.mutate({
                    mutation: getGqlStmt().mutations.SUBSCRIBE_USER_NOTIFIACTION,
                    fetchPolicy: "no-cache",
                    variables: {
                        subscription: JSON.stringify(subscription),
                    },
                    context: {
                        fetchOptions: {
                            keepalive: true,
                        },
                    },
                });
                // alert("mutate and subscribe called"); ##for sw

                if (!subscribeRes || !subscribeRes.data.subscribeUserNotification) {
                    console.log("error in subscribe to notifications", subscribeRes);
                    alert("error in subscribe to notifications");
                }
            }

            if (registration) {
                try {
                    // await registration?.showNotification("push notification test", { body: "push notification msg body" }).catch((err) => {
                    //     console.log("$$n registration.showNotification", err);
                    //     resolve("");
                    // });
                } catch (err) {
                    console.log("$$n registration.showNotification error", err);
                    resolve("");
                }
            } else {
                console.log("no registration");
            }
        } catch (err) {
            console.log(err);
            console.log("$$n error in subscribe notification err = ", err);
            alert("$$n error in subscribe");
            resolve("");
        }

        resolve("");
    });
}

function registerValidSW(swUrl: string, config?: Config) {
    // alert("registerValidSW");
    navigator.serviceWorker
        .register(swUrl)
        .then(async (registration) => {
            // alert("registerValidSW then blcok");
            console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL);

            const res = await fetch("/manifest.json");
            const jsn = await res.json();
            localStorage.setItem("version", jsn.version);
            let refreshing = false;

            if (registration.waiting) {
                invokeServiceWorkerUpdateFlow(registration);
                return;
            }

            navigator.serviceWorker.addEventListener("controllerchange", async () => {
                //##for sw alert(
                //     "EventListener of controllerchange, controller state = " +
                //         (navigator.serviceWorker.controller ? "Exist" : "null") +
                //         "  Rstate= " +
                //         registration.installing?.state
                // );

                // if (navigator.userAgent.indexOf("Firefox") > -1) {
                await subscribeFn();
                // }

                if (!refreshing) {
                    //##for sw alert("reload in controllerchange controller " + (navigator.serviceWorker.controller ? "Exist" : "null"));
                    window.location.reload();
                    refreshing = true;
                }
            });

            registration.onupdatefound = async (e) => {
                //alert("onupdatefound");
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    //alert("installingWorker == null");
                    return;
                }
                installingWorker.onstatechange = async () => {
                    //alert("onstatechange =" + installingWorker.state);

                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            // registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
                            if (registration.waiting) {
                                invokeServiceWorkerUpdateFlow(registration);
                            }

                            console.log(
                                "New content is available and will be used when all " +
                                    "tabs for this page are closed. See https://cra.link/PWA."
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log("Content is cached for offline use.");
                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }

                    // if (installingWorker.state === "activated") {
                    //     await subscribeFn();
                    //     window.location.reload();
                    // }

                    // if (installingWorker.state === "redundant") {
                    //     window.location.reload();
                    // }
                };
            };
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            console.log("sw res", response);

            const contentType = response.headers.get("content-type");
            if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        //##for sw alert("Reload, not valid sw in checkValidServiceWorker");
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log("No internet connection found. App is running in offline mode.");
        });
}

export async function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}

export async function invokeServiceWorkerUpdateFlow(registration: ServiceWorkerRegistration) {
    // TODO implement your own UI notification element
    // setTimeout(() => {
    isNewUpdate(true);
    // const m2 = new ModalComp.SpinnerPortalModal();
    // m2.show();
    /* const m = new ModalComp.TwoBtnsModal({
        msg: "There Is New Update",
        btn1Label: "Update Now",
        btn2Label: "Later",
        clickHandlerBtn1: async () => {
            if (registration.waiting) {
                // let waiting Service Worker know it should became active
                const p = () => {
                    return new Promise(async (ok) => {
                        const cacheNames = await caches.keys();
                        for (let i = 0; i < cacheNames.length; i++) await caches.delete(cacheNames[i]);
                        unregister();
                        ok("");
                    });
                };
                await p();                

                registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
                m.hide();
            }
        },
        clickHandlerBtn2: () => {
            m.hide();
        },
    }); */
    // m.show();

    //##for sw alert("invokeServiceWorkerUpdateFlow called");
    const p = () => {
        return new Promise(async (ok) => {
            const cacheNames = await caches.keys();
            for (let i = 0; i < cacheNames.length; i++) await caches.delete(cacheNames[i]);
            await unregister();
            ok("");
        });
    };
    await p();

    registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
}

async function checkVersionChange() {
    const res = await fetch("/manifest.json");
    const jsn = await res.json();
    // const vE = document.getElementById("version");
    // if (vE) vE.innerHTML = jsn.version;
    console.log("localStorage.getItem('version')", localStorage.getItem("version"));

    if (jsn.version !== localStorage.getItem("version")) {
        const m = new ModalComp.TwoBtnsModal(
            { isPortal: false },
            {
                msg: "There Is New Update",
                btn1Label: "Update Now",
                btn2Label: "Later",
                clickHandlerBtn1: () => {
                    if (!localStorage.getItem("version")) localStorage.setItem("version", jsn.version);
                    //##for sw alert("reload in checkVersionChange");
                    window.location.reload();
                },
                clickHandlerBtn2: () => {
                    m.hide();
                },
            }
        );
        m.show();
    }
}
