// export const SERVER_NAME = "192.168.1.102:9000";
// export const SERVER_NAME = "http://app.localhost:9000";
// export const SERVER_NAME = "app.localhost:9000";
// export const SERVER_NAME = "192.168.8.108:3000";
// export const SERVER_NAME = "abdthegroup:9000";
console.log("process.env.REACT_APP_MY_ENVIRONMENT=", process.env.REACT_APP_MY_ENVIRONMENT);

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "http://app.localhost:9000" : "https://my-node-project-1-360309.oa.r.appspot.com";

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "http://abdthegroup.local:9000" : "https://my-node-project-1-360309.oa.r.appspot.com";

// export const SERVER_NAME =
//     process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://192.168.8.150:9000" : "https://my-node-project-1-360309.oa.r.appspot.com";

// export const SERVER_NAME =
//     process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://192.168.8.151:9000" : "https://my-node-project-1-360309.oa.r.appspot.com";

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "http://192.168.8.105:9000" : "https://my-node-project-1-360309.oa.r.appspot.com";
//AIzaSyDmwt9fU1rTI2d216WS3VF_vmHA3j2Jeyk

// export const FrontEndServer = "https://192.168.8.150:3000";
/////////////////////////////////////////////////////////////////////////////////////////

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://192.168.8.151:9000" : "https://api.GTripApp.com";

export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://192.168.1.34:9000" : "https://api.GTripApp.com";

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://127.0.0.1:9000" : "https://api.GTripApp.com";

// export const SERVER_NAME = process.env.REACT_APP_MY_ENVIRONMENT === "dev" ? "https://local.dev.com:9000" : "https://api.GTripApp.com";



export const RES_SERVER_NAME = "https://storage.googleapis.com/gtrip-bucket/";
