import { gql, useReactiveVar } from "@apollo/client";
import { dirAndLangRvar } from "../apolloGQL/ReactiveVars";

/**
 * this function is used to get the GraphQL queries and mutation by the current user language
 * @returns GqlStms object, which contains all the application queries and mutations by language.
 */
export const getGqlStmt = () => {
    const lang = dirAndLangRvar().lang;

    /**
     * it's possible to access the gql query and change as you want by below code
     * (GET_COMPANY.definitions[0] as any).selectionSet.selections[0].selectionSet.selections[0].name="vvvv";
     * console.log("getGqlByLang GET_COMPANY=",(GET_COMPANY.definitions[0] as any).selectionSet.selections[0].selectionSet.selections[0].name )
     *  */

    const GqlStms = {
        queries: {
            GQL_QUERY: gql`
                query getAccountName($id: Int!) {
                    getAccount(id: $id) {
                        accId
                        accNameAr
                        isActive @client
                    }
                }
            `, //endf

            GET_COMPANY: gql` 
                query getCompany($username:String!){
                    getCompany(username: $username) {
                    companyId
                    compName:${lang === "en" ? "companyNameLng1" : "companyNameLng2"}
                    currencyId
                    companyType
                    isActivated
                    phoneNumber
                    phoneNumber2
                    phoneNumber3
                    fax
                    pobox
                    website
                    email
                    logoFileName
                    address:${lang === "en" ? "addressLng1" : "addressLng2"}                                                
                    }
                    }    
                `, //endf

            GET_USER: gql`
                query getUser {
                    getUser {
                        success
                        errorId
                        errorDesc
                        data {
                            userId
                            username
                            userType
                            email
                            userName1Ar
                            userName2Ar
                            userName3Ar
                            userName4Ar
                            userName2En
                            userName3En
                            userName4En
                            phoneNo
                            country
                            city
                            gender
                            nationalNo
                            natIdBkEndPhotoName
                            natIdFrEndPhotoName
                            userPhotoName
                            userStatus
                            userStartDate
                            userEndDate
                            userExpiryDate
                            driver {
                                drvrId
                                drvrLicPhotoName
                                userId
                            }
                        }
                    }
                }
            `, //endf

            GET_IP_INFO: gql`
                query getIpInfo {
                    getIpInfo {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf

            VALIDATE_TOKEN: gql`
                query validateToken($token: String!, $tokenType: String!) {
                    validateToken(token: $token, tokenType: $tokenType)
                }
            `, //endf

            GET_CATEGOR: gql`
                query getCategory($categoryId: Int, $accMasterid:Int, $isBalanceSheet:String!, $companyType:Int!){
                getCategory(id:$categoryId) {
                accId
                desc:${lang === "en" ? "accNameEn" : "accNameAr"}
                }
                getGlAccTypesMaster(id: $accMasterid, isBalanceSheet:$isBalanceSheet, companyType:  $companyType){
                headerId
                desc:${lang === "en" ? "headerDescLng2" : "headerDescLng1"}                                                
                }
                }
            `, //endf

            GET_ACC_TYPES_DTL: gql`
                query getGlAccTypesDtl($headerId: Int, $companyType:Int!){
                getGlAccTypesDtl(headerId:$headerId, companyType:  $companyType) {
                typeId
                desc:${lang === "en" ? "typeDescLng2" : "typeDescLng1"}
                }                   
                }
            `, //endf


            USER_REQS: gql`
                query getUserReqs($fetchType: String, $status: String, $reqType: String, $isReqCrtdByUsr: String, $isAprSentByUsr: String) {
                    getUserReqs(fetchType: $fetchType, status: $status, reqType: $reqType, isReqCrtdByUsr: $isReqCrtdByUsr, isAprSentByUsr: $isAprSentByUsr) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf
            DRVR_PENDING_REQS: gql`
            query getDrvrPendingReqs($fetchType: String) {
                getDrvrPndngRcvdReqs(fetchType: $fetchType) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        #drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                    }
                }
                getDrvrPndngSntReqs(fetchType: $fetchType) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                    }
                }                
            }
             `, //endf
            PSGR_PENDING_REQS: gql`
                query getPsgrPendingReqs($fetchType: String) {
                    getPsgrPndngRcvReqs(fetchType: $fetchType) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            approvalsCount
                            canReachPsgr
                            dropoffType
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }                  
                    }
                getPsgrPndngSntReqs(fetchType: $fetchType) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        canReachPsgr
                        approvalsCount
                        dropoffType
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        tripApprovals {
                            id
                            reqId                            
                            price
                            sourceUserId
                            status
                            tripGoodsId
                            tripGoods {
                              goodsReqId
                              srcLoc{
                                lat
                                lng
                              }
                              destLoc{
                                lat
                                lng
                              }
                              userId
                              tripGoodsDtl {
                                goodHeight
                                goodLength
                                goodWeight
                                goodNote
                              }
                            }
                          }
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                    }                    
                }                
            }
            `, //endf
            GOODS_REQS_FROM_PSGRS: gql`
                query getGoodsReqsFromPsgrs {
                    getGoodsReqsFromPsgrs {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            approvalsCount
                            dropoffType
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf
            GOODS_DTLS: gql`
                query GetGdsDtls($tripId: Int!) {
                    getGdsDtls(tripId: $tripId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                          reqId
                          sourceUserId
                          status
                          drvrDestLoc {
                            lat
                            lng
                          }
                          drvrSrcLoc {
                            lat
                            lng
                          }
                          psgrSrcLoc {
                            lat
                            lng
                          }
                          psgrDestLoc {
                            lat
                            lng
                          }
                          tripGoods {
                            goodsReqId
                            srcLoc{
                                lat
                                lng
                              }
                            destLoc{
                                lat
                                lng
                              }
                            userId
                            tripGoodsDtl {
                              goodsId
                              parentGoodsReqId
                              #reqId
                              goodWeight
                              goodHeight
                              goodLength
                              goodWidth
                              goodNote
                              distance
                              tripDuration
                            }
                          }
                        }
                    }
                }
            `, //endf

            SEARCH_DRVRS_REQS: gql`
                query searchDrvrsReqs(
                    $pageSize: Int!
                    $page: Int!
                    $reqId: Int
                    $fromCountry: String
                    $toCountry: String
                    $fromCity: Int
                    $toCity: Int
                    $fromDatetime: DateTime
                    $toDatetime: DateTime
                    $reqType: String
                    $applicantSrcLoc: String
                ) {
                    searchDrvrsReqs(
                        pageSize: $pageSize
                        page: $page
                        reqId: $reqId
                        fromCountry: $fromCountry
                        toCountry: $toCountry
                        fromCity: $fromCity
                        toCity: $toCity
                        fromDatetime: $fromDatetime
                        toDatetime: $toDatetime
                        reqType: $reqType
                        applicantSrcLoc: $applicantSrcLoc
                    ) {
                        success
                        errorDesc
                        errorId
                        totalCount
                        dataArr {
                            reqId
                            drvrId
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf

            SEARCH_PSGRS_REQS: gql`
                query searchPsgrsReqs(
                    $pageSize: Int!
                    $page: Int!
                    $reqId: Int
                    $fromCountry: String
                    $toCountry: String
                    $fromCity: Int
                    $toCity: Int
                    $fromDatetime: DateTime
                    $toDatetime: DateTime
                    $reqType: String
                    $applicantSrcLoc: String
                ) {
                    searchPsgrsReqs(
                        pageSize: $pageSize
                        page: $page
                        reqId: $reqId
                        fromCountry: $fromCountry
                        toCountry: $toCountry
                        fromCity: $fromCity
                        toCity: $toCity
                        fromDatetime: $fromDatetime
                        toDatetime: $toDatetime
                        reqType: $reqType
                        applicantSrcLoc: $applicantSrcLoc
                    ) {
                        success
                        errorDesc
                        errorId
                        totalCount
                        dataArr {
                            reqId
                            drvrId
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf
            USER_CHAT_MSGS: gql`
                query GetUserChatMsgs($pageSize: Int!, $page: Int!, $userRoomId: Int!) {
                    getUserChatMsgs(pageSize: $pageSize, page: $page, userRoomId: $userRoomId) {
                        success
                        errorDesc
                        errorId
                        totalCount
                        dataArr {
                            fromUserId
                            toUserId
                            msg
                            msgDate
                        }
                    }
                }
            `, //endf
            USER_NOTIFICATIONS: gql`
            query getUserNotifications($pageSize: Int!, $page: Int!) {
                getUserNotifications(pageSize: $pageSize, page: $page) {
                    errorId
                    errorDesc
                    success
                    dataArr {
                      creationDate
                      lang
                      notificationId
                      ntcText
                      ntcTitle
                      status
                    }
                }
            }
        `, //endf
            USER_CHAT_USERS: gql`
                query GetUserChatUsers {
                    getUserChatUsers {
                        dataArr {
                            userId
                            email
                        }
                    }
                }
            `, //endf
            JOINED_PSGRS: gql`
                query GetTraspReqJoinedPsgrs($reqId: Int!) {
                    getTraspReqJoinedPsgrs(reqId: $reqId) {
                        success
                        errorId
                        errorDesc
                        dataArr {
                            id
                            reqId
                            gender
                            userId
                            user {
                              userId
                              email
                              username
                              userName1Ar
                              userName1En
                              userPhotoName
                            }
                          }
                    }
                }
            `, //endf
            VLC_STRUCTURE: gql`
                query getVclStructure($vclId: Int!) {
                    getVclStructure(vclId: $vclId) {
                        success
                        errorId
                        errorDesc
                        dataArr {
                            vclId
                            y
                            x1
                            x2
                            x3
                        }
                    }
                }
            `, //endf
            VCL_STRUCTURE_N_JOINED_PSGRS: gql`
                query GetVclStrucutreByDrvr($drvrUserId: Int!, $reqId: Int!) {
                    getVcldByDrvr(drvrUserId: $drvrUserId) {
                        success
                        errorId
                        errorDesc
                        data {
                            vclId
                        }
                        query {
                            getVclStructure(
                                valuesFromParent: [{ srcParentName: "getVcldByDrvr", srcFieldName: "data.vclId", destFieldName: "vclId" }]
                            ) {
                                success
                                errorId
                                errorDesc
                                dataArr {
                                    vclId
                                    y
                                    x1
                                    x2
                                    x3
                                }
                            }
                        }
                    }
                    getTraspReqJoinedPsgrs(reqId: $reqId) {
                        success
                        errorId
                        errorDesc
                        dataArr {
                            id
                            reqId
                            y
                            x
                            gender
                            userId
                            maleBeside
                            destLoc {
                                lat
                                lng
                            }
                            srcLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf
            TRASP_REQ_WAYPOINTS: gql`
                query GetTraspReqWaypoints($reqId: Int!) {
                    getTraspReq(reqId: $reqId) {
                        success
                        errorId
                        errorDesc
                        data {
                            drvrId
                            reqType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                    getTraspReqJoinedPsgrs(reqId: $reqId) {
                        success
                        errorId
                        errorDesc
                        dataArr {
                            user {
                                userId
                                username
                                userName1Ar
                                userName1En
                            }
                            srcLoc {
                                lat
                                lng
                            }
                            destLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
            `, //endf
            TRASP_REQ: gql`
                query GetTraspReq($reqId: Int!) {
                    getTraspReq(reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        data {
                            reqId
                            drvrId
                            canReachPsgr
                            traspDatetime
                            distance
                            reqSrcUserId
                            reqStatus
                            fromCity
                            toCity
                            approvalsCount
                            fromCountry
                            toCountry
                            traspType
                            fPsgrCompanions
                            mPsgrCompanions
                            reqType
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            applicantDestLoc {
                                lat
                                lng
                            }
                        }
                    }                                     
                }
            `, //endf
            TRASP_REQ_N_APRV_RQUIR_ACTION_FRM_PSGR: gql`
                query GetTraspReq($reqId: Int!) {
                    getTraspReq(reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        data {
                            reqId
                            drvrId
                            canReachPsgr
                            approvalsCount
                            traspDatetime
                            distance
                            reqSrcUserId
                            reqStatus
                            fromCity
                            toCity
                            fromCountry
                            toCountry
                            traspType
                            fPsgrCompanions
                            mPsgrCompanions
                            reqType
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            applicantDestLoc {
                                lat
                                lng
                            }
                        }
                    }  
                    getAprvRquirActionFrmPsgr: getUserReqs(fetchType:"all", reqId: $reqId, status: "pending", reqType: null, isReqCrtdByUsr: "Y", isAprSentByUsr:"N",  getReqWithEmptyAprv:"N") {
                        success
                        errorId
                        errorDesc
                        dataArr {
                          reqId
                          reqSrcUserId
                          reqSrcUser {
                            userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                            userName1En
                            userName1Ar
                          }
                          tripApprovals {
                            status
                            price
                            sourceUser {
                                userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                userName1En
                                userName1Ar
                              }
                            psgrSrcLoc {
                              lat
                              lng
                            }
                            psgrDestLoc {
                              lat
                              lng
                            }
                            tripGoods {
                                goodsReqId
                                srcLoc{
                                    lat
                                    lng
                                  }
                                destLoc{
                                    lat
                                    lng
                                  }
                                userId
                              tripGoodsDtl {
                                goodsId
                                #reqId
                                userId
                                goodWeight
                                goodLength
                                goodWidth
                                goodHeight
                                goodNote
                              }
                            }
                          }
                        }                       
                    }                                                             
                }
            `, //endf
            PSGR_PNDNG_REQ_DTL: gql`
            query getPsgrPndngReqDtl($reqId: Int!) {
                getPsgrPndngReqDtl(fetchType: "all", reqId: $reqId) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                        reqSrcUser {
                          userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                          userName1En
                          userName1Ar
                        }
                        tripApprovals {
                          status
                          price
                          sourceUser {
                              userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                              userName1En
                              userName1Ar
                            }
                          psgrSrcLoc {
                            lat
                            lng
                          }
                          psgrDestLoc {
                            lat
                            lng
                          }
                          tripGoods {
                            goodsReqId
                            srcLoc{
                                lat
                                lng
                              }
                            destLoc{
                                lat
                                lng
                              }
                            userId
                            tripGoodsDtl {
                              goodsId
                              #reqId
                              userId
                              goodWeight
                              goodLength
                              goodWidth
                              goodHeight
                              goodNote
                            }
                          }
                        }                            
                    }
                }
            }
        `, //endf            


            DRVR_PNDNG_RCV_REQ_DTL: gql`
                query getDrvrPndngRcvdReqsDtl($reqId: Int!) {
                    getDrvrPndngRcvdReqs(fetchType: "all", reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            approvalsCount
                            dropoffType
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            reqSrcUser {
                              userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                              userName1En
                              userName1Ar
                            }
                            tripApprovals {
                                reqId
                                status
                                price
                                sourceUser {
                                    userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                    userName1En
                                    userName1Ar
                                    }
                                psgrSrcLoc {
                                    lat
                                    lng
                                }
                                psgrDestLoc {
                                    lat
                                    lng
                                }
                                tripGoods {
                                    goodsReqId
                                    srcLoc{
                                        lat
                                        lng
                                    }
                                    destLoc{
                                        lat
                                        lng
                                    }
                                    userId
                                    tripGoodsDtl {
                                    goodsId
                                    #reqId
                                    userId
                                    goodWeight
                                    goodLength
                                    goodWidth
                                    goodHeight
                                    goodNote
                                    }
                                }
                                }                            
                            }
                        }
                }
            `, //endf
            DRVR_PNDNG_SNT_REQ_DTL: gql`
            query getDrvrPndngSntReqsDtl($reqId: Int!) {
                getDrvrPndngSntReqs(fetchType: "all", reqId: $reqId) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                        reqSrcUser {
                          userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                          userName1En
                          userName1Ar
                        }
                        tripApprovals {
                          reqId
                          status
                          price
                          sourceUser {
                              userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                              userName1En
                              userName1Ar
                            }
                          psgrSrcLoc {
                            lat
                            lng
                          }
                          psgrDestLoc {
                            lat
                            lng
                          }
                          tripGoods {
                            goodsReqId
                            srcLoc{
                                lat
                                lng
                              }
                            destLoc{
                                lat
                                lng
                              }
                            userId
                            tripGoodsDtl {
                              goodsId
                              #reqId
                              userId
                              goodWeight
                              goodLength
                              goodWidth
                              goodHeight
                              goodNote
                            }
                          }
                        }                            
                    }
                }
            }
        `, //endf
            TRIP_PRICE: gql`
                query getTripPrice($reqId: Int!, $reservedSeatsByUser: [TraspReqJoinedPsgrsInput!]!, $drvrSrcLoc: PointObjectInputType, $drvrDestLoc: PointObjectInputType) {
                    getTripPrice(reqId: $reqId, reservedSeatsByUser: $reservedSeatsByUser, drvrSrcLoc: $drvrSrcLoc, drvrDestLoc: $drvrDestLoc) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            GOODS_DELIVERY_PRICE: gql`
                query getGdsDeliveryPrice($reqId: Int!, $goods: [TripGoodsInput!]!, $drvrSrcLoc: PointObjectInputType, $drvrDestLoc: PointObjectInputType) {
                    getGdsDeliveryPrice(reqId: $reqId, goods: $goods, drvrSrcLoc: $drvrSrcLoc, drvrDestLoc: $drvrDestLoc) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            TRIP_ACCEPTANCE: gql`
                query getTripApprovals($tripId: Int!) {
                    getTripApprovals(tripId: $tripId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            id
                            sourceUserId
                            sourceUser {
                                userId
                                userName1Ar
                                userName1En
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }      
                            status
                            tripGoods {
                                goodsReqId
                                srcLoc{
                                    lat
                                    lng
                                  }
                                destLoc{
                                    lat
                                    lng
                                  }
                                userId
                                tripGoodsDtl {
                                    goodLength
                                    goodWeight
                                    goodNote
                                }
                            }
                        }
                    }
                }
            `, //endf
            DRVR_RDY_TRIPS: gql`
                query getDrvrRdyTrips($fetchType: String) {
                    getDrvrRdyTrips(fetchType: $fetchType) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                        }
                    }
                }
                `, //endf
            DRVR_RDY_REQ_DTL: gql`
                query GetDrvrRdyReqDtl($reqId: Int!) {                    
                    getDrvrRdyTrips(fetchType: "all", reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            reqSrcUser {
                              userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                              userName1En
                              userName1Ar
                            }
                            tripApprovals {
                              status
                              price
                              sourceUser {
                                  userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                  userName1En
                                  userName1Ar
                                  userId
                                }
                              psgrSrcLoc {
                                lat
                                lng
                              }
                              psgrDestLoc {
                                lat
                                lng
                              }
                              tripGoods {
                                goodsReqId
                                srcLoc{
                                    lat
                                    lng
                                  }
                                destLoc{
                                    lat
                                    lng
                                  }
                                userId
                                tripGoodsDtl {
                                  goodsId
                                  #reqId
                                  userId
                                  goodWeight
                                  goodLength
                                  goodWidth
                                  goodHeight
                                  goodNote
                                }
                              }
                            }                            
                        }
                    }
                    getUsrReqAcceptedGoods: getUserReqs(fetchType:"all", reqId: $reqId, status: "accepted", reqType: null, isReqCrtdByUsr: null, isAprSentByUsr:"N") {
                        success
                        errorId
                        errorDesc
                        dataArr {
                          reqId
                          reqSrcUserId
                          reqSrcUser {
                            userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                            userName1En
                            userName1Ar
                          }
                          tripApprovals {
                            status
                            price
                            sourceUser {
                                userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                userName1En
                                userName1Ar
                              }
                            psgrSrcLoc {
                              lat
                              lng
                            }
                            psgrDestLoc {
                              lat
                              lng
                            }
                            tripGoods {
                                goodsReqId
                                srcLoc{
                                    lat
                                    lng
                                  }
                                destLoc{
                                    lat
                                    lng
                                  }
                                userId
                              tripGoodsDtl {
                                goodsId
                                #reqId
                                userId
                                goodWeight
                                goodLength
                                goodWidth
                                goodHeight
                                goodNote
                              }
                            }
                          }
                        }                       
                    }
                }
            `, //endf
            PSGR_RDY_TRIPS: gql`
            query getPsgrRdyTrips($fetchType: String) {
                getPsgrRdyTrips(fetchType: $fetchType) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                    }
                }
            }
            `, //endf
            PSGR_RDY_TRIP_DTL: gql`
            query getPsgrRdyTrips($fetchType: String, $reqId:Int!) {
                getPsgrRdyTrips(fetchType: $fetchType,reqId:$reqId) {
                    success
                    errorDesc
                    errorId
                    dataArr {
                        reqId
                        drvrId
                        driver {
                            userId
                        }
                        canReachPsgr
                        dropoffType
                        approvalsCount
                        fromCountry
                        toCountry
                        fromCity
                        toCity
                        creationDate
                        mPsgrCompanions
                        fPsgrCompanions
                        reqSrcUserId
                        reqStatus
                        reqType
                        traspDatetime
                        traspType
                        applicantDestLoc {
                            lat
                            lng
                        }
                        applicantSrcLoc {
                            lat
                            lng
                        }
                        drvrSrcLoc {
                            lat
                            lng
                        }
                        drvrDestLoc {
                            lat
                            lng
                        }
                        tripApprovals {
                            status
                            price
                            sourceUser {
                                userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                userName1En
                                userName1Ar
                            }
                            psgrSrcLoc {
                                lat
                                lng
                            }
                            psgrDestLoc {
                                lat
                                lng
                            }
                            tripGoods {
                                goodsReqId
                                srcLoc{
                                    lat
                                    lng
                                  }
                                destLoc{
                                    lat
                                    lng
                                  }
                                userId
                                tripGoodsDtl {
                                    goodsId
                                    #reqId
                                    userId
                                    goodWeight
                                    goodLength
                                    goodWidth
                                    goodHeight
                                    goodNote
                                }
                            }
                        }                        
                    }
                }
            }
            `, //endf
            PSGR_RCV_APR_N_REQ_DTL: gql`
                query getPsgrPndngApr_N_ReqDtl($reqId: Int!) {
                    getPsgrPndngReqDtl(fetchType: "all", reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            reqSrcUser {
                                userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                userName1En
                                userName1Ar
                            }
                            tripApprovals {
                                status
                                price
                                sourceUser {
                                    userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                    userName1En
                                    userName1Ar
                                }
                                psgrSrcLoc {
                                    lat
                                    lng
                                }
                                psgrDestLoc {
                                    lat
                                    lng
                                }
                                tripGoods {
                                    goodsReqId
                                    srcLoc{
                                        lat
                                        lng
                                      }
                                    destLoc{
                                        lat
                                        lng
                                      }
                                    userId
                                    tripGoodsDtl {
                                        goodsId
                                        ##reqId
                                        userId
                                        goodWeight
                                        goodLength
                                        goodWidth
                                        goodHeight
                                        goodNote
                                    }
                                }
                            }                            
                        }
                    }
                    getPsgrPndngRcvReqs(fetchType: "all", reqId: $reqId) {
                        success
                        errorDesc
                        errorId
                        dataArr {
                            reqId
                            drvrId
                            driver {
                                userId
                            }
                            canReachPsgr
                            dropoffType
                            approvalsCount
                            fromCountry
                            toCountry
                            fromCity
                            toCity
                            creationDate
                            mPsgrCompanions
                            fPsgrCompanions
                            reqSrcUserId
                            reqStatus
                            reqType
                            traspDatetime
                            traspType
                            applicantDestLoc {
                                lat
                                lng
                            }
                            applicantSrcLoc {
                                lat
                                lng
                            }
                            drvrSrcLoc {
                                lat
                                lng
                            }
                            drvrDestLoc {
                                lat
                                lng
                            }
                            reqSrcUser {
                                userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                userName1En
                                userName1Ar
                            }
                            tripApprovals {
                                status
                                price
                                sourceUserId
                                sourceUser {
                                    userName:${lang === "en" ? "userName1En" : "userName1Ar"}
                                    userName1En
                                    userName1Ar
                                }
                                psgrSrcLoc {
                                    lat
                                    lng
                                }
                                psgrDestLoc {
                                    lat
                                    lng
                                }
                                drvrSrcLoc {
                                    lat
                                    lng
                                }
                                drvrDestLoc {
                                    lat
                                    lng
                                }
                                tripGoods {
                                    goodsReqId
                                    srcLoc{
                                        lat
                                        lng
                                      }
                                    destLoc{
                                        lat
                                        lng
                                      }
                                    userId
                                    tripGoodsDtl {
                                        goodsId
                                        #reqId
                                        userId
                                        goodWeight
                                        goodLength
                                        goodWidth
                                        goodHeight
                                        goodNote
                                    }
                                }
                            }                            
                        }
                    }
                    

                }
        `, //endf
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            GL_ACCS_TREE: gql`
                fragment glAccFields on GlAccount {
                    menuId:accId
                    accName:${lang === "en" ? "accNameEn" : "accNameAr"}
                    menuTxtResourceId:${lang === "en" ? "accNameEn" : "accNameAr"}
                    parentAcc
                }

                fragment RecurGl on GlAccount {
                    children {
                        ...glAccFields
                        children {
                            ...glAccFields
                            children {
                                ...glAccFields
                                children {
                                    ...glAccFields
                                    children {
                                        ...glAccFields
                                        children {
                                            ...glAccFields
                                            children {
                                                ...glAccFields
                                                children {
                                                    ...glAccFields
                                                    children {
                                                        ...glAccFields
                                                        children {
                                                            ...glAccFields
                                                            children {
                                                                ...glAccFields
                                                                children {
                                                                    ...glAccFields
                                                                    children {
                                                                        ...glAccFields
                                                                        children {
                                                                            ...glAccFields
                                                                            children {
                                                                                ...glAccFields
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                query getGlAccountsTree {
                    getGlAccountsTree(companyId: 1) {
                        ...glAccFields
                        ...RecurGl
                    }
                }
            `, //endf            
            APP_MENUS: gql`
                fragment appMenuFields on AppMenu {
                    menuId
                    menuName
                    menuTxtResourceId
                    menuParentId
                    isLeaf
                    levelNo
                    companyId
                    navigateTo
                }

                fragment Recur on AppMenu {
                    children {
                      ...appMenuFields
                      children {
                        ...appMenuFields
                        children {
                          ...appMenuFields
                          children {
                            ...appMenuFields
                            children {
                              ...appMenuFields
                              children {
                                ...appMenuFields
                                # children {
                                #   ...appMenuFields
                                #   children {
                                #     ...appMenuFields
                                #     children {
                                #       ...appMenuFields
                                #       children {
                                #         ...appMenuFields
                                #         children {
                                #           ...appMenuFields
                                #           children {
                                #             ...appMenuFields
                                #             children {
                                #               ...appMenuFields
                                #               children {
                                #                 ...appMenuFields
                                #                 children {
                                #                   ...appMenuFields
                                #                 }
                                #               }
                                #             }
                                #           }
                                #         }
                                #       }
                                #     }
                                #   }
                                # }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  

                query getAppMenus {
                    getAppMenus(companyId: 1) {
                        ...appMenuFields
                        ...Recur
                    }
                }
            `, //endf
        }, //end queries obj
        mutations: {
            LOGIN_MUTATION: gql`
                mutation login($email: String!, $password: String!) {
                    login(email: $email, password: $password) {
                        errorId
                        errorDesc
                        token
                        email
                        username
                        isAuthenticated
                        user {
                            userId
                            username
                            userType
                            email
                            userName1Ar
                            userName2Ar
                            userName3Ar
                            userName4Ar
                            userName2En
                            userName3En
                            userName4En
                            phoneNo
                            country
                            city
                            gender
                            nationalNo
                            natIdBkEndPhotoName
                            natIdFrEndPhotoName
                            userPhotoName
                            userStatus
                            userStartDate
                            userEndDate
                            userExpiryDate
                            isActivated
                            driver {
                                # drvrLicPhotoName
                                drvrId
                                drvrLicPhotoName
                                userId
                            }
                        }
                    }
                }
            `, //endf
            RESEND_ACTV_LINK: gql`
                mutation sendActivationLink($email: String!, $password: String!) {
                    sendActivationLink(email: $email, password: $password) {
                        success
                        errorId
                        errorDesc
                    }
                }
            `, //endf
            LOGOUT_MUTATION: gql`
                mutation logout {
                    logout
                }
            `, //endf
            CREATE_USER: gql`
                mutation createUser($email: String!, $password: String!, $username: String!, $file: Upload!) {
                    createUser(email: $email, password: $password, username: $username, file: $file) {
                        success
                        errorCode
                        errorDesc
                    }
                }
            `, //endf
            CREATE_PASSENGER: gql`
                ##, $file: Upload
                mutation createPsgr($user: UserInput!, $userPhoto: Upload) {
                    createUser(
                        userInput: $user
                        userPhoto: $userPhoto ##userNationalIdFrEndPhoto: $userNationalIdFrEndPhoto ##userNationalIdBkEndPhoto: $userNationalIdBkEndPhoto
                    ) {
                        success
                        errorId
                        errorDesc
                        data
                        mutation {
                            createPsgr(
                                valuesFromParent: [{ srcParentName: "createUser", srcFieldName: "data.userId", destFieldName: "userId" }]
                            ) {
                                success
                                errorId
                                errorDesc
                                data
                            }
                        }
                    }
                }
            `, //endf
            CREATE_DRIVER_AND_VEHICLE: gql`
                mutation oneCoherentTrnx(
                    $user: UserInput!
                    $userPhoto: Upload
                    $userNationalIdFrEndPhoto: Upload!
                    $userNationalIdBkEndPhoto: Upload!
                    $drvrLicPhoto: Upload!
                    $vcl: VclInput!
                    $vclOwner: VclOwnerInput!
                    $vclSeats: [VclSeatRowInput!]
                    $vclLicPhoto: Upload
                    $vclPhoto: Upload
                ) {
                    createUser(
                        userInput: $user
                        userPhoto: $userPhoto
                        userNationalIdFrEndPhoto: $userNationalIdFrEndPhoto
                        userNationalIdBkEndPhoto: $userNationalIdBkEndPhoto
                    ) {
                        success
                        errorId
                        errorDesc
                        data
                        mutation {
                            getOrCreateVclOwner(
                                vclOwner: $vclOwner
                            ) {
                                success
                                errorId
                                errorDesc
                                data
                                mutation{
                                    createVcl(
                                        vclInput: $vcl
                                        vclSeats: $vclSeats
                                        vclLicPhoto: $vclLicPhoto
                                        vclPhoto: $vclPhoto
                                        valuesFromParent: [{ srcParentName: "getOrCreateVclOwner", srcFieldName: "data.vclOwnerId", destFieldName: "vclOwnerId" }]
                                    ) {
                                        success
                                        errorId
                                        errorDesc
                                        data
                                        mutation {
                                            createDrvr(
                                                drvrLicPhoto: $drvrLicPhoto
                                                valuesFromParent: [
                                                    { srcParentName: "createUser", srcFieldName: "data.userId", destFieldName: "userId" }
                                                    { srcParentName: "createVcl", srcFieldName: "data.vclId", destFieldName: "vclId" }
                                                ]
                                            ) {
                                                success
                                                errorId
                                                errorDesc
                                                data
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `, //endf

            CREATE_TRANSPORT_REQUEST: gql`
                mutation CreateTraspReq($transportReqInput: TransportRequestInput!) {
                    createTraspReq(transportReqInput: $transportReqInput) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            CREATE_GDS_TRANSPORT_REQUEST: gql`
                mutation oneCoherentTrnx($transportReqInput: TransportRequestInput!, $goods: [TripGoodsInput!], $drvrId: Int, $reqId: Int) {
                    createTraspReq(transportReqInput: $transportReqInput) {
                        success
                        errorDesc
                        errorId
                        data
                        mutation {
                            psgrAddGoodsReq(
                                reqId: $reqId
                                goods: $goods
                                drvrId: $drvrId
                                valuesFromParent: [{ srcParentName: "createTraspReq", srcFieldName: "data.reqId", destFieldName: "reqId" }]
                            ) {
                                success
                                errorDesc
                                errorId
                                data
                            }
                        }
                    }
                }
            `, //endf
            SUBSCRIBE_USER_NOTIFIACTION: gql`
                mutation subscribeUserNotification($subscription: String!) {
                    subscribeUserNotification(subscription: $subscription) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            PSGR_JOIN_TRASP: gql`
                mutation joinTrasp($reqId: Int!, $reservedSeatsByUser: [TraspReqJoinedPsgrsInput!]!, $drvrUserId: Int, $drvrSrcLoc:PointObjectInputType, $drvrDestLoc:PointObjectInputType ) {
                    joinTrasp(reqId: $reqId, reservedSeatsByUser: $reservedSeatsByUser, drvrUserId: $drvrUserId, drvrSrcLoc:$drvrSrcLoc, drvrDestLoc:$drvrDestLoc) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf

            PSGR_ACCEPT_RCVD_DRVR_GOODS_REQ: gql`
                mutation psgrAcceptRcvdDrvrGoodsReq($reqId: Int!, $drvrUserId: Int!) {
                    psgrAcceptRcvdDrvrGoodsReq(reqId: $reqId, drvrUserId: $drvrUserId) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf


            PSGR_ADD_GOODS: gql`
                mutation psgrAddGoodsReq($reqId: Int!, $goods: [TripGoodsInput!]!, $drvrId: Int) {
                    psgrAddGoodsReq(reqId: $reqId, goods: $goods, drvrId: $drvrId) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            ACCEPT_PSGR_REQ: gql`
                mutation AcceptPsgrReq($reqId: Int!, $drvrSrcLoc: PointObjectInputType!, $drvrDestLoc: PointObjectInputType!) {
                    acceptPsgrReq(reqId: $reqId, drvrSrcLoc: $drvrSrcLoc, drvrDestLoc: $drvrDestLoc) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            UPDATE_COMPANY: gql`
                mutation updateCompany($username: String!, $company: CompanyInput!, $file: Upload) {
                    updateCompany(username: $username, company: $company, file: $file)
                }
            `, //endf
            UPLOAD_FILE: gql`
                mutation createUser($email: String!, $password: String!, $username: String!) {
                    createUser(email: $email, password: $password, username: $username) {
                        success
                        errorCode
                        errorDesc
                    }
                }
            `, //endf
            RFRESH_ACCESS_TOKEN: gql`
                mutation refreshAccessToken {
                    refreshAccessToken {
                        success
                        errorId
                        errorDesc
                        data
                    }
                }
            `, //enf
            FORGOT_PASS: gql`
                mutation forgotPass($email: String!) {
                    forgotPass(email: $email)
                }
            `, //endf
            DRVR_ACCEPT_GDS_TO_HIS_REQ: gql`
                mutation drvrAcceptNewGdsOnHisReq($goodsId: Int!, $reqId: Int!) {
                    drvrAcceptNewGdsOnHisReq(goodsId: $goodsId, reqId: $reqId) {
                        success
                        errorId
                        errorDesc
                    }
                }
            `, //enf
            DRVR_REJECT_GDS_TO_HIS_REQ: gql`
            mutation drvrRejectNewGdsOnHisReq($goodsId: Int!, $reqId: Int!) {
                drvrRejectNewGdsOnHisReq(goodsId: $goodsId, reqId: $reqId) {
                    success
                    errorId
                    errorDesc
                }
            }
        `, //enf
            VALIDATE_FORGOT_PASS: gql`
                mutation validateForgotPass($token: String!, $tempPass: String!, $newPass: String!) {
                    validateForgotPassReq(token: $token, tempPass: $tempPass, newPass: $newPass)
                }
            `, //endf

            VALIDATE_ACCOUNT_ACTIVATION: gql`
                mutation validateActivateAccount($token: String!) {
                    validateActivateAccount(token: $token)
                }
            `, //endf

            BT_GENERATE_TKN: gql`
                mutation generateBtPayToken {
                    generateBtPayToken {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
        `, //endf

            BT_PAY_TRNX: gql`
            mutation btPayTrnx($nonce: String!, $deviceData:String, $tripId: Int!) {
                btPayTrnx(nonce: $nonce, deviceData: $deviceData, tripId: $tripId) {
                    success
                    errorDesc
                    errorId
                    data
                }
            }
        `, //endf
            RATE_DRVR: gql`
                mutation rateDrvr(
                    $review: String!
                    $rateValue: Int!
                    $ratee: Int!
                    $tripId: Int!
                ) {
                    rateDrvr(
                        review: $review
                        rateValue: $rateValue
                        ratee: $ratee
                        tripId: $tripId
                    ) {
                        success
                        errorId
                        errorDesc
                    }
                }
        `, //endf
            RATE_PSGR: gql`
                mutation ratePsgrs($ratingsValues: [Object!]!, $tripId: Int!) {
                    ratePsgrs(ratingsValues: $ratingsValues, tripId: $tripId) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
             `, //endf
            RATE_PSGRs_ALL_IN_ONE: gql`
             mutation RatePsgrsAllInOne($review: String!, $rateValue: Int!, $tripId: Int!) {
                ratePsgrsAllInOne(review: $review, rateValue: $rateValue, tripId: $tripId) {
                     success
                     errorDesc
                     errorId
                     data
                 }
             }
          `, //endf
            PSGR_TRIP_COMPLETED: gql`
                mutation psgrTripCompleted($tripId: Int!, $psgrUserId: Int!) {
                    psgrTripCompleted(tripId: $tripId, psgrUserId: $psgrUserId) {
                        success
                        errorDesc
                        errorId
                        data
                    }
                }
            `, //endf
            CREATE_GL_ACC: gql`
                mutation createGlAccount($glAccParams: GlAccountInput!) {
                    createGlAccount(GlAccountInput: $glAccParams)
                }
            `,
        }, //end mutations object
    };
    return GqlStms;
};
