import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { findCityById, getAccessInfo, getDirection } from "../../commonSrc/commonFns";
import { format, parseISO, formatDistance, addMinutes } from "date-fns";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ReqListItem } from "../trip/ReqListItem";

export function DrvrTrips(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrMainComp Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    const [fetchType, setFetchType] = useState("today");

    const {
        JsxComp: MyReqsFilter,
        compReducer: [myReqsFilterSt, myReqsFilterDis],
        compRef: myReqsFilterRef,
    } = FormCmps.RadioCmp.getComp("today");

    const [
        getPsgrTraspReq,
        // refetch: refetchPsgrsTraspReqs,
        { refetch: refetchPsgrsTraspReqs, error: searchPsgrsReqsErr, data: searchPsgrsReqsData, loading: searchPsgrsReqsLoading },
    ] = useLazyQuery(getGqlStmt().queries.SEARCH_PSGRS_REQS, {
        fetchPolicy: "no-cache",
    });

    const {
        error: getUserTraspReqsErr,
        data: getUserTraspReqsData,
        loading: getUserTraspReqsLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.DRVR_RDY_TRIPS/* USER_REQS *//* USER_TRASP_REQS */, {
        fetchPolicy: "no-cache",
        variables: {
            fetchType: fetchType,
        },
    });

    useEffect(() => {
        async function executeAsyncUseEffect() { }
        executeAsyncUseEffect();
    }, [dirAndLangRvar().lang]);

    if (getUserTraspReqsLoading || searchPsgrsReqsLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="drvrMainCompSpinner"
                modalId="bbb"
                autoShow={true}
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );

    return (
        <div>
            <MyReqsFilter
                radioRdc={[myReqsFilterSt, myReqsFilterDis]}
                id="myReqsFilterId"
                label={ReadTextResource.getTextById("MY_REQS_FLTR")}
                inputRef={myReqsFilterRef}
                radioDir={"horizontal"}
                onChange={(e, v) => {
                    if (v === "today") setFetchType("today");
                    else setFetchType("all");
                }}
            >
                <FormCmps.RadioItem id="todayRadio" label={ReadTextResource.getTextById("TODAY_REQS")} value="today"></FormCmps.RadioItem>
                <FormCmps.RadioItem id="allRadio" label={ReadTextResource.getTextById("ALL_REQS")} value="all"></FormCmps.RadioItem>
            </MyReqsFilter>

            {/* <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                    setFetchType("today");
                }}
            >
                طلبات اليوم
            </button>
            <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                    setFetchType("all");
                }}
            >
                كل الطلبات
            </button> */}
            {getUserTraspReqsData.getDrvrRdyTrips.dataArr.map((e: any) => {
                return <ReqListItem
                    key={e.reqId}
                    enableAprvRedBoxCount={false}
                    reqDtlInfo={e}
                    embedCmp={<button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            navigate(`/app/drvrTraspReqDtl/${e.reqId}/${e.driver?.userId}`);
                        }}
                    >
                        {ReadTextResource.getTextById("REQ_DTLS")}
                    </button>}
                />
            })}
            {getUserTraspReqsData.getDrvrRdyTrips.dataArr.length === 0 ? <div>{ReadTextResource.getTextById("NO_REQS_TODAY")}</div> : ""}
        </div>
    );
}
