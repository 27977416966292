import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { chattingUsersRVar, connectedUsersRVar, prevUserMsgsRVar, receivedMsgRVar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { getIDbChattingUsers, setIDbChattingUsers } from "../../commonSrc/indexedDb/fns";
import { getAccessInfo } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { User } from "../../commonSrc/Types";
import { socket } from "../../Socket";
import { FormCmps } from "../commonComp/FormComp";
import { StickyBar } from "../commonComp/StickyBar";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
// import ChatStyles from "../commonComp/cssOfComps/ChatCss.module.css";

export function PsgrChat(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrChat Component");
    //
    const location = useLocation();
    let toUserId = (location.state as any)?.toUserId;
    console.log("toUserId", toUserId);

    // if ("virtualKeyboard" in navigator) {
    //     const g = navigator.virtualKeyboard;
    //     g.overlaysContent = true;
    // if (navigator?.virtualKeyboard)
    //     navigator.virtualKeyboard.
    // }
    // else
    //     alert("virtualKeyboard is not exist");

    useReactiveVar(connectedUsersRVar);
    useReactiveVar(receivedMsgRVar);
    // const [connectedUsers, setConnectedUsers] = useState([]);


    // const stickyRef = useRef<HTMLDivElement>(null);
    // const [refresh, setRefresh] = useState(true)


    async function setIDbNewMsgRead() {
        const userMsgRead = chattingUsersRVar().find((e) => e.userId == toUserId ?? +toUserSt.value!);
        if (userMsgRead) {
            userMsgRead.hasNewMsg = false;
            // const localStorageUsers: User[] = JSON.parse(localStorage.getItem("chattingUsers") ?? "");
            const iDbUsers: User[] = await getIDbChattingUsers();

            const iDbUsersUpdated = iDbUsers.filter((e) => e.userId !== userMsgRead.userId);
            await setIDbChattingUsers(iDbUsersUpdated);
            //localStorage.setItem("chattingUsers", JSON.stringify(localStorageUsersUpdated));
        }
    }

    const {
        JsxComp: ToUser,
        compReducer: [toUserSt, toUserDis],
        compRef: toUserRef,
    } = FormCmps.TextCmp.getComp();

    // const {
    //     JsxComp: Msg,
    //     compReducer: [msgSt, msgDis],
    //     compRef: msgRef,
    // } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: Msg,
        compReducer: [msgSt, msgDis],
        compRef: msgRef,
    } = FormCmps.TextAreaCmp.getComp();

    const [divStyle, setDivStyle] = useState({});
    const [pageSt, setPageSt] = useState(1);

    //##to-do## remove all +toUserSt.value!
    const [
        getUserChatMsgs,
        // refetch: refetchPsgrsTraspReqs,
        { refetch: refetchUserChatMsgs, error: getUserChatMsgsErr, data: getUserChatMsgsData, loading: getUserChatMsgsLoading },
    ] = useLazyQuery(getGqlStmt().queries.USER_CHAT_MSGS, {
        fetchPolicy: "no-cache",
        variables: {
            userRoomId: toUserId ?? +toUserSt.value!,
            page: 1,
            pageSize: 25,
        },
        onCompleted: (data) => { },
    });

    const chatBoxRef = useRef<HTMLDivElement>(null);
    const [firstTimeMsgsFetched, setFirstTimeMsgsFetched] = useState(false);
    // let divStyle;
    useEffect(() => {
        async function fn() {
            // isNewMsg(false);

            await setIDbNewMsgRead();

            if (!firstTimeMsgsFetched) {
                const chatMsgs = await getUserChatMsgs({
                    onCompleted: () => {
                        // setTimeout(() => {
                        //     const g = chatBoxRef?.current;
                        //     if (g) {
                        //         g.scrollTop = g.scrollHeight;
                        //     }
                        // }, 500);
                    },
                });
                console.log("##1getUserChatMsgsData?.getUserChatMsgs?.dataArr", chatMsgs.data.getUserChatMsgs.dataArr);
                console.log("##1prevUserMsgsRVar", prevUserMsgsRVar());

                const length = chatMsgs?.data?.getUserChatMsgs?.dataArr.length;
                const chatMsgsArr = chatMsgs?.data?.getUserChatMsgs?.dataArr;
                const arr: any[] = [];
                for (let i = 0; i < length; i++) {
                    arr[i] = chatMsgsArr[length - i - 1];
                }
                // prevUserMsgsRVar([...arr, ...prevUserMsgsRVar()]);
                prevUserMsgsRVar([...arr]);
                setFirstTimeMsgsFetched(true);
            }

            const mainHeader = document.getElementById("mainHeaderId0");
            const mainHeaderHeight = mainHeader?.clientHeight;
            let chatStyle = {
                maxHeight: `calc(100vh - 15px - ${mainHeaderHeight + "px"} )`,
                height: "100vh",
            };

            setDivStyle(chatStyle);

            setTimeout(() => {
                const g = chatBoxRef?.current;
                if (g) {
                    g.scrollTop = g.scrollHeight;
                }
            }, 30);
        }
        fn();
    }, [receivedMsgRVar()]);

    useEffect(() => {
        return () => {
            receivedMsgRVar([]);
        };
    }, []);

    if (getUserChatMsgsLoading) return <div>loading...</div>;

    //commented temporarily until we finish testing
    // if (!toUserId || isNaN(parseInt(toUserId))) return <div>Invalid user to chat with</div>;
    return (
        <div>
            <div id="chatFormId1" className="d-flex flex-column" style={divStyle}>
                <div
                    ref={chatBoxRef}
                    className="flex-grow-1 overflow-auto"
                    onScroll={async (e) => {
                        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

                        if (scrollTop === 0) {
                            setPageSt(pageSt + 1);
                            console.log("##1prevUserMsgsRVar", prevUserMsgsRVar());

                            const refetchData = await refetchUserChatMsgs({
                                page: pageSt + 1,
                                pageSize: 21,
                                userRoomId: toUserId ?? +toUserSt.value!,
                            });

                            const length = refetchData?.data?.getUserChatMsgs?.dataArr.length;
                            if (length !== 0) chatBoxRef.current!.scrollTop = 0.2 * scrollHeight;
                            const chatMsgsArr = refetchData?.data?.getUserChatMsgs?.dataArr;
                            const arr: any[] = [];
                            for (let i = 0; i < length; i++) {
                                arr[i] = chatMsgsArr[length - i - 1];
                            }
                            prevUserMsgsRVar([...arr, ...prevUserMsgsRVar()]);
                            // setTimeout(() => {
                            // }, 100);
                            // prevUserMsgsRVar([...prevUserMsgsRVar(), ...refetchData?.data.getUserChatMsgs?.dataArr]);
                        }
                    }}
                >
                    {/* style={{ height: "350px" }} */}

                    <div>{`User${toUserId} - Received messages:`}</div>
                    {prevUserMsgsRVar().map((e, i) => {
                        // {getUserChatMsgsData?.getUserChatMsgs.dataArr.map((e: any) => {
                        return (
                            <div className="py-1 position-relative" key={i + "prevMsgId"}>
                                <div className="mx-2 px-1" >
                                    {e.fromUserId == getAccessInfo()?.user?.userId ?
                                        <div className="d-flex justify-content-start pe-5"   >
                                            <div
                                                className="border border-1 rounded-3 shadow p-2"
                                                style={{ backgroundColor: "whitesmoke" }}>
                                                {
                                                    e.msg.split(/\r?\n/).map((line: any, ii: any) => <p key={"lineMsg" + ii}>{line}</p>)

                                                }
                                                {/* <div className="position-absolute top-0 left-0" >You</div> */}
                                            </div>
                                        </div> :
                                        <div className="d-flex justify-content-end ps-5">
                                            <div
                                                className="border border-1 rounded-3 shadow p-2"
                                                style={{ backgroundColor: "ghostwhite" }}>
                                                {e.msg.split(/\r?\n/).map((line: any, ii: any) => <p key={"lineMsg" + ii}>{line}</p>)
                                                }
                                            </div>
                                        </div>
                                    }</div>
                                {/* <div>{e.to !==  getAccessInfo()?.user?.userId  ? "you:" + e.msg : "FromUser:" + e.msg}</div> */}

                                {/* <div>{e.msg}</div> */}
                            </div>
                        );
                    })}

                    <div>----------- end old messages ---------</div>
                    {/* <div>{receivedMsgRVar().from ===  getAccessInfo()?.user?.userId  ? "" : "you"}</div> */}
                    {/* <div>{receivedMsgRVar().length}</div> */}
                    <div>
                        {receivedMsgRVar().length !== 0 &&
                            receivedMsgRVar()
                                .filter((e) => +e.from == (toUserId ?? toUserSt?.value!) || +e.from == getAccessInfo()?.user?.userId)
                                .map((e, i) => {
                                    return (
                                        <div className="py-1 position-relative" key={i + "newMsgId"}>
                                            <div className="mx-2 px-1" >
                                                {+e.from === getAccessInfo()?.user?.userId ?
                                                    <div className="d-flex justify-content-start pe-5"   >
                                                        <div
                                                            className="border border-1 rounded-3 shadow p-2"
                                                            style={{ backgroundColor: "whitesmoke" }}>
                                                            {
                                                                e.content.split(/\r?\n/).map((line: any, ii) => <p key={"lineMsg" + ii}>{line}</p>)

                                                                // e.content.replace(/\n/, "</br>")
                                                            }
                                                            {/* <div className="position-absolute top-0 left-0" >You</div> */}
                                                        </div>
                                                    </div> :
                                                    <div className="d-flex justify-content-end ps-5">
                                                        <div
                                                            className="border border-1 rounded-3 shadow p-2"
                                                            style={{ backgroundColor: "ghostwhite" }}>
                                                            {e.content.split(/\r?\n/).map((line: any, ii) => <p key={"lineMsg" + ii}>{line}</p>)
                                                            }
                                                        </div>
                                                    </div>
                                                }</div>
                                            {/* <div>{e.to !==  getAccessInfo()?.user?.userId  ? "you:" + e.msg : "FromUser:" + e.msg}</div> */}

                                            {/* <div>{e.msg}</div> */}
                                        </div>
                                        // <div className="d-flex justify-content-start pe-5" key={i + "msgNewId"}>
                                        //     <div
                                        //         className="border border-1 rounded-3 shadow p-2"
                                        //         style={{ backgroundColor: "whitesmoke" }}>
                                        //         {+e.from == getAccessInfo()?.user?.userId ? "you:" + e.content : "FromUser:" + e.content}
                                        //         {/* <div className="position-absolute top-0 left-0" >You</div> */}
                                        //     </div>
                                        // </div>
                                    );
                                })}
                    </div>
                </div>

                <StickyBar id="chatSendMsgId" type="sticky-bottom" borderBottom={false}>
                    <div className="flex-grow-1 ">
                        <Msg
                            id="msgId1"
                            inputRef={msgRef}
                            label="الرسالة"
                            placeHolder={ReadTextResource.getTextById("MSG")}
                            // options={{ readOnly: true }}
                            textRdc={[msgSt, msgDis]}
                            valueTrakingOnChange={true}
                        /* defaultValue="ادخل نص الرسالة" */
                        ></Msg>

                    </div>


                    <div className="">
                        <button
                            type="button"
                            className="btn btn-primary ms-2"
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (msgRef.current?.value.trim().length === 0)
                                    return;
                                console.log("toUserId ?? +toUserSt.value!", toUserId ?? +toUserSt.value!);

                                socket.emit("privateMessage", { to: toUserId ?? +toUserSt.value!, content: msgSt.value ?? "nothing!!" });
                                if (msgRef.current) msgRef.current.value = "";
                                msgDis({ type: FormCmps.TextCmp.textActDisTypesT.ValueInput, value: "" });
                            }}
                        >
                            Send
                        </button>
                    </div>
                </StickyBar>
            </div>
        </div>
    );
}
