import { useMutation } from "@apollo/client";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { country_cities } from "../../commonSrc/textResources/country_cities";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { StickyBar } from "../commonComp/StickyBar";
import { ModalComp } from "../commonComp/ModalComp";
import { getAccessInfo } from "../../commonSrc/commonFns";
import { GoodsCmp } from "./GoodsCmp";
// import { Location } from "../commonComp/GoogleMapCmp";

export function PsgrTransportReq(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrTransportReq Component");

    const goodsCmpRef = useRef<any>(null);

    //--START-- JSX Components Declarations**************************************
    const {
        JsxComp: ReqType,
        compReducer: [reqTypeSt, reqTypeDis],
        compRef: reqTypeRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: InOutTrans,
        compReducer: [inOutTransSt, inOutTransStDis],
        compRef: inOutTransStRef,
    } = FormCmps.RadioCmp.getComp("In");

    const {
        JsxComp: MalesCompanion,
        compReducer: [malesCompanionSt, malesCompanionDis],
        compRef: malesCompanionRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: FemalesCompanion,
        compReducer: [femalesCompanionSt, femalesCompanionDis],
        compRef: femalesCompanionRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: FemalesReqType,
        compReducer: [femalesReqTypeSt, femalesReqTypeDis],
        compRef: femalesReqTypeRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: FromCityAutoComplete,
        compReducer: [fromCityAutoCompleteSt, fromCityAutoCompleteDis],
        compRef: fromCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: ToCityAutoComplete,
        compReducer: [toCityAutoCompleteSt, toCityAutoCompleteDis],
        compRef: toCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    console.log("$$getAccessInfo()?.user?.countryByIp", getAccessInfo()?.user?.countryByIp);

    const {
        JsxComp: FromCountry,
        compReducer: [fromCountrySt, fromCountryDis],
        compRef: fromCountryRef,
        // { code: "JO", label: "Jordan", phone: "+962", suggested: true }
    } = FormCmps.CountryCmp.getComp(getAccessInfo()?.user?.countryByIp);

    const {
        JsxComp: ToCountry,
        compReducer: [toCountrySt, toCountryDis],
        compRef: toCountryRef,
    } = FormCmps.CountryCmp.getComp();

    const {
        JsxComp: TransportDate,
        compReducer: [traspDateSt, traspDateStDis],
        compRef: traspDateStRef,
    } = FormCmps.DateComp.getComp(/* new Date() */);

    const {
        JsxComp: DeliveryTime,
        compReducer: [deliveryTimeSt, deliveryTimeDis],
        compRef: deliveryTimeStRef,
    } = FormCmps.TimeComp.getComp(new Date());

    const {
        JsxComp: ReachPassenger,
        compReducer: [reachPassengerSt, reachPassengerDis],
        compRef: reachPassengerStRef,
    } = FormCmps.CheckboxCmp.getComp();

    const {
        JsxComp: DroppOffType,
        compReducer: [droppOffTypeSt, droppOffTypeDis],
        compRef: droppOffTypeRef,
    } = FormCmps.RadioCmp.getComp();

    const {
        JsxComp: ApplicantSrcLoc,
        compReducer: [applicantSrcLocSt, applicantSrcLocDis],
        compRef: applicantSrcLocRef,
    } = FormCmps.LocationCmp.getComp(/* { lat: 31.165375, lng: 35.745947 } */);

    const {
        JsxComp: ApplicantDestLoc,
        compReducer: [applicantDestSt, applicantDestDis],
        compRef: applicantDestRef,
    } = FormCmps.LocationCmp.getComp();

    const {
        JsxComp: ApplicantDestChoice,
        compReducer: [dropOffLocChoiceSt, applicantDestChoiceDis],
        compRef: applicantDestChoiceRef,
    } = FormCmps.RadioCmp.getComp("");

    //--END-- JSX Components Declarations****************************************************************

    /* const [getIpInfo, { error: getIpInfoError, data: getIpInfoData, loading: getIpInfoLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_IP_INFO,
        {
            onCompleted(data) {
                console.log("ipInfo=", data);
            },
            fetchPolicy: "no-cache",
        }
    ); */

    useEffect(() => {
        async function a() {
            /* getting current country by ip address when page is loaded or refreshed, it also can be gotten on login and stored on Reactive Var and 
            re-get it on refresh in App.tsx where we refresh the accesss token.
            const ipInfo = await getIpInfo();
            const currentCountryByIp = countries.find((e) => e.code === ipInfo.data.getIpInfo.country_code) as CountryT;
            console.log("gggggg=", ipInfo.data.getIpInfo.country_code, "cccc=", currentCountryByIp);
            fromCountryDis({ type: FormCmps.CountryCmp.countryActDisTypesT.ValueInput, value: currentCountryByIp }); 
            */
        }
        a();
    }, [dirAndLangRvar().lang]);

    // const [location, setLocation] = useState("");

    async function isFormValid(): Promise<boolean> {
        let isFormValid: boolean = true;
        const okModalInstance = new ModalComp.OkModal(
            { isPortal: false },
            {
                msg: ReadTextResource.getTextById("FILL_ALL_FLDS"),
                navTo: undefined, // or just remove it
            }
        );

        isFormValid = await FormCmps.RadioCmp.validate(
            inOutTransSt,
            inOutTransStDis,
            isFormValid,
            okModalInstance,
            inOutTransStRef,
            "Select a value for Transport type"
        );


        isFormValid = await FormCmps.SelectCmp.validate(
            reqTypeSt,
            reqTypeDis,
            isFormValid,
            okModalInstance,
            reqTypeRef,
            "Select a value for Request type"
        );

        isFormValid = await FormCmps.CountryCmp.validate(fromCountryRef, fromCountryDis, isFormValid, okModalInstance);

        if (inOutTransSt.value === "Out")
            isFormValid = await FormCmps.CountryCmp.validate(toCountryRef, toCountryDis, isFormValid, okModalInstance);

        isFormValid = await FormCmps.AutoCompleteCmp.validate(
            fromCityAutoCompleteRef,
            fromCityAutoCompleteSt,
            fromCityAutoCompleteDis,
            isFormValid,
            okModalInstance
        );

        isFormValid = await FormCmps.AutoCompleteCmp.validate(
            toCityAutoCompleteRef,
            toCityAutoCompleteSt,
            toCityAutoCompleteDis,
            isFormValid,
            okModalInstance
        );

        isFormValid = await FormCmps.DateComp.validate(
            traspDateSt,
            traspDateStDis,
            isFormValid,
            okModalInstance,
            traspDateStRef,
            "please set the transport date and time"
        );

        const isGoodsFormValid = await goodsCmpRef.current?.isGoodsFormValid();

        if (reqTypeSt.value === "Goods" && isFormValid && !isGoodsFormValid) await okModalInstance.showAsync();

        return isFormValid;
    }

    const [createTraspReq, { error: createTraspReqError, data: createTraspReqData, loading: createTraspReqLoading }] = useMutation(
        getGqlStmt().mutations.CREATE_TRANSPORT_REQUEST,
        {
            fetchPolicy: "no-cache",
        }
    );

    const [createGdsTraspReq, { error: createGdsTraspReqError, data: createGdsTraspReqData, loading: createGdsTraspReqLoading }] =
        useMutation(getGqlStmt().mutations.CREATE_GDS_TRANSPORT_REQUEST, {
            fetchPolicy: "no-cache",
        });

    async function onSave(e: MouseEvent<HTMLButtonElement>) {
        console.log("deliveryDateSt.value=", traspDateSt.value);
        console.log("reachPassengerSt.value=", reachPassengerSt.value);

        e?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        await spinner.showAsync();

        const okModalInstance = new ModalComp.OkModal(
            { isPortal: false },
            { msg: ReadTextResource.getTextById("FILL_ALL_FLDS"), navTo: "" }
        );

        try {
            if (reqTypeSt.value === "Goods")
                await createGdsTraspReq({
                    variables: {
                        transportReqInput: {
                            applicantSrcLoc: {
                                lat: applicantSrcLocSt.location?.lat,
                                lng: applicantSrcLocSt.location?.lng,
                            },
                            applicantDestLoc: applicantDestSt?.location
                                ? {
                                    lat: applicantDestSt?.location?.lat,
                                    lng: applicantDestSt?.location?.lng,
                                }
                                : null,
                            // // can_reach_psgr: reachPassengerSt.value,
                            // dropoffType: droppOffTypeSt.value, //"AllInOneDest",
                            fromCountry: fromCountrySt.value?.code,
                            toCountry: toCountrySt.value?.code,
                            fromCity: fromCityAutoCompleteSt?.value?.id,
                            toCity: toCityAutoCompleteSt.value.id,
                            fPsgrCompanions: +(FormCmps.TextCmp.getValue(femalesCompanionRef) ?? 0),
                            mPsgrCompanions: +(FormCmps.TextCmp.getValue(malesCompanionSt) ?? 0),
                            // reqSrcUserId: 15,
                            reqType: reqTypeSt.value,
                            // reqStatus: "New",
                            traspType: inOutTransSt.value,
                            traspDatetime: traspDateSt.value,
                            tripDuration: 0,
                            distance: 0,
                        },
                        goods: goodsCmpRef.current?.getGoodsArr(),
                    },
                    onCompleted: async (data) => {
                        await spinner.hideAsync();
                        if (data?.createTraspReq?.success) {
                            okModalInstance.setProps({
                                msg: (
                                    <p>
                                        {ReadTextResource.getTextById("SAVED_SUCCES")} <br />
                                        {/* {ReadTextResource.getTextById("ACTV_CODE_SENT")} */}
                                    </p>
                                ),
                                //commented temporarily, should navigate to some other page when it's done
                                navTo: "/",
                            });
                        } else {
                            console.log("createTransportReq mutation is not successfull -abd-");
                            okModalInstance.setProps({
                                msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + "  " + data?.createTraspReq?.errorDesc,
                            });
                        }
                        await okModalInstance.showAsync();
                    },
                    onError: async (err) => {
                        console.log("createTransportReq mutation is not successfull -abd- err=", err);
                        await spinner.hideAsync();
                        okModalInstance.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
                        await okModalInstance.showAsync();
                    },
                });
            else
                await createTraspReq({
                    variables: {
                        transportReqInput: {
                            applicantSrcLoc: {
                                lat: applicantSrcLocSt.location?.lat,
                                lng: applicantSrcLocSt.location?.lng,
                            },
                            applicantDestLoc: applicantDestSt?.location
                                ? {
                                    lat: applicantDestSt?.location?.lat,
                                    lng: applicantDestSt?.location?.lng,
                                }
                                : null,
                            // // can_reach_psgr: reachPassengerSt.value,
                            // dropoffType: droppOffTypeSt.value, //"AllInOneDest",
                            fromCountry: fromCountrySt.value?.code,
                            toCountry: toCountrySt.value?.code,
                            fromCity: fromCityAutoCompleteSt?.value?.id,
                            toCity: toCityAutoCompleteSt.value.id,
                            fPsgrCompanions: +(FormCmps.TextCmp.getValue(femalesCompanionRef) ?? 0),
                            mPsgrCompanions: +(FormCmps.TextCmp.getValue(malesCompanionSt) ?? 0),
                            // reqSrcUserId: 15,
                            reqType: reqTypeSt.value === "Females" ? femalesReqTypeSt.value : reqTypeSt.value,
                            // reqStatus: "New",
                            traspType: inOutTransSt.value,
                            traspDatetime: traspDateSt.value,
                            tripDuration: 0,
                            distance: 0,
                        },
                        goods: goodsCmpRef.current?.getGoodsArr(),
                    },
                    onCompleted: async (data) => {
                        await spinner.hideAsync();
                        if (data?.createTraspReq?.success) {
                            okModalInstance.setProps({
                                msg: (
                                    <p>
                                        {ReadTextResource.getTextById("SAVED_SUCCES")} <br />
                                        {/* {ReadTextResource.getTextById("ACTV_CODE_SENT")} */}
                                    </p>
                                ),
                                //commented temporarily, should navigate to some other page when it's done
                                navTo: "/",
                            });
                        } else {
                            console.log("createTransportReq mutation is not successfull -abd-");
                            okModalInstance.setProps({
                                msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + "  " + data?.createTraspReq?.errorDesc,
                            });
                        }
                        await okModalInstance.showAsync();
                    },
                    onError: async (err) => {
                        console.log("createTransportReq mutation is not successfull -abd- err=", err);
                        await spinner.hideAsync();
                        okModalInstance.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
                        await okModalInstance.showAsync();
                    },
                });

        } catch (err) {
            //OK
            // const okModal = ModalComp.OkModal;
            await spinner.hideAsync();
            console.log("errrrrrrrrrrrrrrrrrrr2=", err);
            okModalInstance.setProps({ msg: "Sorry1, an error occured, please call the administrator, TRANS-10" + err });
            await okModalInstance.showAsync();
            return;
        }
    }

    return (
        <>
            <StickyBar id="mainStickBar1">
                <FormCmps.FormSubmitBtn
                    id="submitBtn"
                    label={ReadTextResource.getTextById("SAVE")}
                    onOkClickHandler={onSave}
                ></FormCmps.FormSubmitBtn>
            </StickyBar>

            <FormCmps.ContainerNoFormCmp id="drvrTraspReqId1">
                <FormCmps.LabelComp.JsxComp id="welcomeCaptinId1">
                    <div className="">{ReadTextResource.getTextById("CRT_NEW_PSGR_REQ_HINT")}</div>
                </FormCmps.LabelComp.JsxComp>

                <ReqType
                    id="ReqTypeId1"
                    selectRdc={[reqTypeSt, reqTypeDis]}
                    label={ReadTextResource.getTextById("TRANS_TYPE")}
                    selectValues={[
                        { selectLableAndId: ReadTextResource.getTextById("NORMAL"), valueKey: "Normal" },
                        { selectLableAndId: ReadTextResource.getTextById("FEMALES"), valueKey: "Females" },
                        { selectLableAndId: ReadTextResource.getTextById("RESERVE"), valueKey: "Reserve" },
                        { selectLableAndId: ReadTextResource.getTextById("GOODS"), valueKey: "Goods" },
                    ]}
                    inputRef={reqTypeRef}
                ></ReqType>

                <FemalesReqType
                    className={`${reqTypeSt.value === "Females" ? "d-block" : "d-none"}`}
                    id="femalesReqTypeId1"
                    selectRdc={[femalesReqTypeSt, femalesReqTypeDis]}
                    label={ReadTextResource.getTextById("F_REQ_OPTIONS")}
                    selectValues={[
                        { selectLableAndId: ReadTextResource.getTextById("F_ONLY"), valueKey: "Fs_ALL" },
                        { selectLableAndId: ReadTextResource.getTextById("FS_ONLY_BS"), valueKey: "Fs_ONLY_BS" },
                        { selectLableAndId: ReadTextResource.getTextById("FS_BS_M1_FS"), valueKey: "Fs_BS_M1_FS" },
                        { selectLableAndId: ReadTextResource.getTextById("F1_FS_MS_BS"), valueKey: "F1_FS_Ms_BS" },
                    ]}
                    inputRef={femalesReqTypeRef}
                ></FemalesReqType>

                {reqTypeSt.value !== "Goods" && (
                    <>
                        <FormCmps.LabelComp.JsxComp id="passengerCompanionId1">
                            {ReadTextResource.getTextById("COMPANIONS")}:
                        </FormCmps.LabelComp.JsxComp>

                        <div>
                            {(femalesReqTypeSt.value !== "Fs_ALL" || reqTypeSt.value !== "Females") && (
                                <MalesCompanion
                                    id="malesCompanionId1"
                                    label={ReadTextResource.getTextById("NO_OF_MALES")}
                                    inputRef={malesCompanionRef}
                                    textRdc={[malesCompanionSt, malesCompanionDis]}
                                    inputMode="number"
                                    regExpPattern="[0-9]*"
                                    validateEmpty
                                    validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                                ></MalesCompanion>
                            )}

                            <FemalesCompanion
                                id="femalesCompanionId1"
                                label={ReadTextResource.getTextById("NO_OF_FEMALES")}
                                inputRef={femalesCompanionRef}
                                textRdc={[femalesCompanionSt, femalesCompanionDis]}
                                inputMode="number"
                                regExpPattern="[0-9]*"
                                validateEmpty
                                validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                            ></FemalesCompanion>
                        </div>
                    </>
                )}
                {reqTypeSt.value === "Goods" && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <GoodsCmp key={"goodsCmpId0"} ref={goodsCmpRef}></GoodsCmp>
                    </div>
                )}

                <InOutTrans
                    radioRdc={[inOutTransSt, inOutTransStDis]}
                    id="inOutTransId1"
                    label={ReadTextResource.getTextById("TRANS_TYPE")}
                    radioDir={"horizontal"}
                >
                    <FormCmps.RadioItem
                        id="inTransRadio"
                        label={ReadTextResource.getTextById("IN_COUNTRY")}
                        value="In"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem
                        id="outTransRadio"
                        label={ReadTextResource.getTextById("OUT_COUNTRY")}
                        value="Out"
                    ></FormCmps.RadioItem>
                </InOutTrans>

                <FromCountry
                    id="fromCoutry1"
                    label={ReadTextResource.getTextById("COUNTRY")}
                    // defaultValue={!getIpInfoLoading ? countries.find((e) => e.code === getIpInfoData?.getIpInfo?.country_code) : undefined}
                    // defaultValue={userInfoRvar().countryByIp}
                    inputRef={fromCountryRef}
                    validateEmpty={true}
                    countryRdc={[fromCountrySt, fromCountryDis]}
                    onChange={() => {
                        fromCityAutoCompleteDis({
                            type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                            value: "",
                        });
                    }}
                ></FromCountry>

                {inOutTransSt.value === "Out" && (
                    <ToCountry
                        id="userCoutry"
                        label={ReadTextResource.getTextById("OUT_COUNTRY")}
                        inputRef={toCountryRef}
                        validateEmpty={true}
                        countryRdc={[toCountrySt, toCountryDis]}
                        visible={inOutTransSt.value === "Out"}
                        onChange={() => {
                            toCityAutoCompleteDis({
                                type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                                value: "",
                            });
                        }}
                    ></ToCountry>
                )}

                <FromCityAutoComplete
                    id="fromCity"
                    label={ReadTextResource.getTextById("FROM_CITY")}
                    inputRef={fromCityAutoCompleteRef}
                    validateEmpty={true}
                    autoCompleteRdc={[fromCityAutoCompleteSt, fromCityAutoCompleteDis]}
                    optionLable={(option) => {
                        return option?.name_ar ? option?.name_ar : option?.name || "";
                    }}
                    inputOptions={
                        // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                        (() => {
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            if (fromCountrySt.value?.code) {
                                const e = country_cities?.find((e) => {
                                    return e.iso2 === fromCountrySt.value?.code; /* userCountrySt.value?.code; */
                                    //|| e.phone_code == ("+" || countryValSt.phone)
                                });
                                if (e) return e.states;
                                else return [];
                            }
                            return [];
                        })()
                    }
                ></FromCityAutoComplete>

                <ToCityAutoComplete
                    id="toCity"
                    label={ReadTextResource.getTextById("TO_CITY")}
                    inputRef={toCityAutoCompleteRef}
                    validateEmpty={true}
                    autoCompleteRdc={[toCityAutoCompleteSt, toCityAutoCompleteDis]}
                    optionLable={(option) => {
                        return option?.name_ar ? option?.name_ar : option?.name || "";
                    }}
                    inputOptions={
                        // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                        (() => {
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            if (inOutTransSt.value === "Out") {
                                if (toCountrySt.value?.code) {
                                    const e = country_cities?.find((e) => {
                                        return e.iso2 === toCountrySt.value?.code; //userCountrySt.value?.code;
                                        //|| e.phone_code == ("+" || countryValSt.phone)
                                    });
                                    if (e) return e.states;
                                    else return [];
                                }
                            } else {
                                if (fromCountrySt.value?.code) {
                                    const e = country_cities?.find((e) => {
                                        return e.iso2 === fromCountrySt.value?.code; //userCountrySt.value?.code;
                                        //|| e.phone_code == ("+" || countryValSt.phone)
                                    });
                                    if (e) return e.states;
                                    else return [];
                                }
                            }
                            return [];
                        })()
                    }
                ></ToCityAutoComplete>

                <TransportDate
                    id="deliveryDateId"
                    label={ReadTextResource.getTextById("TRIP_DATETIME")}
                    dateRdc={[traspDateSt, traspDateStDis]}
                ></TransportDate>

                <ApplicantSrcLoc
                    id="psgrCollectionLocationId1"
                    locationRdc={[applicantSrcLocSt, applicantSrcLocDis]}
                    label={ReadTextResource.getTextById("SELECT_UR_LOC")}
                    inputRef={applicantSrcLocRef}
                ></ApplicantSrcLoc>

                <ApplicantDestChoice
                    radioRdc={[dropOffLocChoiceSt, applicantDestChoiceDis]}
                    id="dropOffLocChoiceId1"
                    label={ReadTextResource.getTextById("DROP_OFF_TYPE")}
                    radioDir={"horizontal"}
                    onChange={() => {
                        setTimeout(() => {
                            applicantDestRef.current?.scrollIntoView({ block: "center" });
                        }, 200);
                    }}
                >
                    <FormCmps.RadioItem
                        id="inTransRadio"
                        label={ReadTextResource.getTextById("DROPOFF_LAST_STOP")}
                        value="drvr"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem
                        id="outTransRadio"
                        label={ReadTextResource.getTextById("I_SET_DROPOFF")}
                        value="psgr"
                    ></FormCmps.RadioItem>
                </ApplicantDestChoice>

                {dropOffLocChoiceSt.value === "psgr" && (
                    <ApplicantDestLoc
                        id="psgrDropOffLocId1"
                        locationRdc={[applicantDestSt, applicantDestDis]}
                        label={ReadTextResource.getTextById("DEST_LOC") + ":"}
                        inputRef={applicantDestRef}
                    ></ApplicantDestLoc>
                )}
            </FormCmps.ContainerNoFormCmp>
        </>
    );
}
