import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useId, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ReqListItem } from "../trip/ReqListItem";

export function PsgrSuspendedReqs(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrMainComp Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);
    const id = useId();

    const [fetchType, setFetchType] = useState("today");

    const {
        error: getUserTraspReqsErr,
        data: getUserTraspReqsData,
        loading: getUserTraspReqsLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.PSGR_PENDING_REQS/* USER_TRASP_REQS_N_WATING_DRVR_ACPT */, {
        fetchPolicy: "no-cache",
        variables: {
            fetchType: "all",
        },
    });

    useEffect(() => {
        async function executeAsyncUseEffect() { }
        executeAsyncUseEffect();
    }, [dirAndLangRvar().lang]);

    if (getUserTraspReqsLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="psgrMainCompSpinnerId1"
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );
    console.log("$$getUserTraspReqs", getUserTraspReqsData);

    return (
        <div>
            <FormCmps.LabelComp.JsxComp id="psgrRequestsId1">
                <div className="text-center p-2 bg-info rounded">
                    <strong>{ReadTextResource.getTextById("PSGR_CRTED_REQ_NEED_ACCEPT")}</strong>
                </div>
            </FormCmps.LabelComp.JsxComp>
            {getUserTraspReqsData?.getPsgrPndngRcvReqs?.dataArr?.map((e: any) => {
                return <ReqListItem
                    key={e.reqId}
                    enableAprvRedBoxCount={true}
                    reqDtlInfo={e}
                    embedCmp={<button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            // if (e.reqType === "Goods") navigate(`/app/psgrGdsDtl/${e.reqId}`);
                            // else 
                            navigate(`/app/acceptingDrvrTrip/${e.reqId}/${e.driver?.userId}`);
                        }}
                    >
                        {ReadTextResource.getTextById("REQ_DTLS")}
                    </button>}
                />
            })}


            {getUserTraspReqsData?.getPsgrPndngRcvReqs?.dataArr?.length === 0 ? (
                <div className="pb-4">{ReadTextResource.getTextById("PSGR_NO_REQS_NEED_ACCEPT")}</div>
            ) : (
                ""
            )}

            <FormCmps.LabelComp.JsxComp id={id}>
                <div className="text-center p-2 bg-info rounded">
                    <strong>{ReadTextResource.getTextById("PSGR_SENT_TO_DRVRS")}</strong>
                </div>
            </FormCmps.LabelComp.JsxComp>

            {getUserTraspReqsData?.getPsgrPndngSntReqs?.dataArr?.map((e: any) => {
                return <ReqListItem
                    key={e.reqId}
                    enableAprvRedBoxCount={false}
                    reqDtlInfo={e}
                    embedCmp={<button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            navigate(`/app/psgrGdsDtl/${e.reqId}`);
                            // , { state: { goodsInfo: e?.tripApprovals?.tripGoods.flatMap(dtl => dtl.tripGoods?.tripGoodsDtl || []);
                            // } });
                            // else navigate(`/app/acceptingDrvrTrip/${e.reqId}/${e.drvrId}`);
                        }}
                    >
                        {ReadTextResource.getTextById("REQ_DTLS")}
                    </button>}
                />
            })}
            {getUserTraspReqsData?.getPsgrPndngSntReqs?.dataArr?.length === 0 ? (
                <div className="pb-4">{ReadTextResource.getTextById("PSGR_NO_REQS_NEED_ACCEPT")}</div>
            ) : (
                ""
            )}
        </div>
    );
}
