import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { getGqlStmt } from "../../../commonSrc/graphQL/Queries";


export const useValidateToken = (token: string, tokenType: string) => {
  // validateToken:LazyQueryExecFunction<any, OperationVariables>) => {

  const [validateToken, { data: validateTokenData, loading: validateTokenLoading, error: validateTokenError }] =
    useLazyQuery(getGqlStmt().queries.VALIDATE_TOKEN);

  const [expiredToken, setExpiredToken] = useState(false);

  useEffect(() => {
    try {
      console.log("Token expiry check");
      const decodedToken: any = jwtDecode(token);

      //check if expired
      if (Date.now() / 1000 > decodedToken.exp) {
        console.log("Token is expired...");
        setExpiredToken(true);
        return;
      }
    } catch (err) {
      //token is not valid, catch it just to prevent runtime error
    }

    (async () => {
      console.log("Token validate is being called");

      await validateToken({
        fetchPolicy: "no-cache",
        variables: {
          token: token,
          tokenType: tokenType,
        },
      });
    })();
  }, []);

  return [expiredToken, validateTokenData, validateTokenLoading, validateTokenError];
};
