import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { dividerClasses } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addOffsetOnLocation, getDeviceCurrentLocation, getGraphQlError } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { Waypoint } from "../../commonSrc/Types";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";

export function DrvrSntReqDtls(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrGdsTkDecision Component");
    const { pReqId } = useParams();

    async function isFormValid(): Promise<boolean> {
        return true;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
    }
    let loc: any;

    const {
        refetch: getReqDtlReftch,
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.DRVR_PNDNG_SNT_REQ_DTL/* DRVR_PNDNG_SNT_REQ_DTL *//* TRASP_REQ_N_PENDING_GOODS */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
        onCompleted: async (data) => {
            // goodsSrcLocDis({
            //     type: FormCmps.LocationCmp.LocationActDisTypesT.Location,
            //     location: data?.getDrvrPendingGoods?.dataArr[0]?.tripGoods[0]?.srcLoc,
            // });
        },
    });

    if (getReqDtlLoading) return <div>loading ...</div>;

    if (!pReqId || isNaN(parseInt(pReqId)) || getReqDtlErr)
        return <div>Invalid request parameters or getReqDtlErr</div>;

    return (
        <>
            <TripDtls
                reqDtlType="DrvrSessionOnly" reqInfo={getReqDtlData?.getDrvrPndngSntReqs?.dataArr[0]}
                goodsCmp={<GoodsDtlsCmp
                    showApproval={false}
                    pGoodsData={getReqDtlData?.getDrvrPndngSntReqs?.dataArr[0]?.tripApprovals}
                ></GoodsDtlsCmp >} >
            </TripDtls>
        </>
    );
}
