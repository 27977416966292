//],export const car_model = {"toyota":["a","b","b"], "bmw":["c","y"]}
export const vehicleModels = {
    Acura: [
        "CL",
        "ILX",
        "Integra",
        "Legend",
        "MDX",
        "MDX Sport Hybrid",
        "NSX",
        "RDX",
        "RL",
        "RLX",
        "RLX Sport Hybrid",
        "RSX",
        "SLX",
        "TL",
        "TLX",
        "TSX",
        "Vigor",
        "ZDX",
    ],
    "Alfa Romeo": ["164", "4C", "4C Spider", "Giulia", "Spider", "Stelvio"],
    "Aston Martin": ["DB11", "DB9", "DB9 GT", "DBS", "Rapide", "Rapide S", "Vanquish", "Vanquish S", "Vantage", "Virage"],
    Audi: [
        "100",
        "80",
        "90",
        "A3",
        "A3 Sportback e-tron",
        "A4",
        "A4 (2005.5)",
        "A4 allroad",
        "A5",
        "A5 Sport",
        "A6",
        "A6 allroad",
        "A7",
        "A8",
        "allroad",
        "Cabriolet",
        "e-tron",
        "Q3",
        "Q5",
        "Q7",
        "Q8",
        "Quattro",
        "R8",
        "RS 3",
        "RS 4",
        "RS 5",
        "RS 6",
        "RS 7",
        "S3",
        "S4",
        "S4 (2005.5)",
        "S5",
        "S6",
        "S7",
        "S8",
        "SQ5",
        "TT",
    ],
    Bentley: ["Arnage", "Azure", "Azure T", "Bentayga", "Brooklands", "Continental", "Flying Spur", "Mulsanne"],
    BMW: [
        "1 Series",
        "2 Series",
        "3 Series",
        "4 Series",
        "5 Series",
        "6 Series",
        "7 Series",
        "8 Series",
        "Alpina B7",
        "i3",
        "i8",
        "M",
        "M2",
        "M3",
        "M4",
        "M5",
        "M6",
        "X1",
        "X2",
        "X3",
        "X4",
        "X5",
        "X5 M",
        "X6",
        "X6 M",
        "X7",
        "Z3",
        "Z4",
        "Z4 M",
        "Z8",
    ],
    Buick: [
        "Cascada",
        "Century",
        "Enclave",
        "Encore",
        "Encore GX",
        "Envision",
        "LaCrosse",
        "LeSabre",
        "Lucerne",
        "Park Avenue",
        "Rainier",
        "Regal",
        "Regal Sportback",
        "Regal TourX",
        "Rendezvous",
        "Riviera",
        "Roadmaster",
        "Skylark",
        "Terraza",
        "Verano",
    ],
    Cadillac: [
        "Allante",
        "ATS",
        "ATS-V",
        "Brougham",
        "Catera",
        "CT4",
        "CT5",
        "CT6",
        "CT6-V",
        "CTS",
        "CTS-V",
        "DeVille",
        "DTS",
        "Eldorado",
        "ELR",
        "Escalade",
        "Escalade ESV",
        "Escalade EXT",
        "Fleetwood",
        "Seville",
        "Sixty Special",
        "SRX",
        "STS",
        "XLR",
        "XT4",
        "XT5",
        "XT6",
        "XTS",
    ],
    Chevrolet: [
        "1500 Extended Cab",
        "1500 Regular Cab",
        "2500 Crew Cab",
        "2500 Extended Cab",
        "2500 HD Extended Cab",
        "2500 HD Regular Cab",
        "2500 Regular Cab",
        "3500 Crew Cab",
        "3500 Extended Cab",
        "3500 HD Extended Cab",
        "3500 HD Regular Cab",
        "3500 Regular Cab",
        "APV Cargo",
        "Astro Cargo",
        "Astro Passenger",
        "Avalanche",
        "Avalanche 1500",
        "Avalanche 2500",
        "Aveo",
        "Beretta",
        "Blazer",
        "Bolt EV",
        "Camaro",
        "Caprice",
        "Caprice Classic",
        "Captiva Sport",
        "Cavalier",
        "City Express",
        "Classic",
        "Cobalt",
        "Colorado Crew Cab",
        "Colorado Extended Cab",
        "Colorado Regular Cab",
        "Corsica",
        "Corvette",
        "Cruze",
        "Cruze Limited",
        "Equinox",
        "Express 1500 Cargo",
        "Express 1500 Passenger",
        "Express 2500 Cargo",
        "Express 2500 Passenger",
        "Express 3500 Cargo",
        "Express 3500 Passenger",
        "G-Series 1500",
        "G-Series 2500",
        "G-Series 3500",
        "G-Series G10",
        "G-Series G20",
        "G-Series G30",
        "HHR",
        "Impala",
        "Impala Limited",
        "Lumina",
        "Lumina APV",
        "Lumina Cargo",
        "Lumina Passenger",
        "Malibu",
        "Malibu (Classic)",
        "Malibu Limited",
        "Metro",
        "Monte Carlo",
        "Prizm",
        "S10 Blazer",
        "S10 Crew Cab",
        "S10 Extended Cab",
        "S10 Regular Cab",
        "Silverado (Classic) 1500 Crew Cab",
        "Silverado (Classic) 1500 Extended Cab",
        "Silverado (Classic) 1500 HD Crew Cab",
        "Silverado (Classic) 1500 Regular Cab",
        "Silverado (Classic) 2500 HD Crew Cab",
        "Silverado (Classic) 2500 HD Extended Cab",
        "Silverado (Classic) 2500 HD Regular Cab",
        "Silverado (Classic) 3500 Crew Cab",
        "Silverado (Classic) 3500 Extended Cab",
        "Silverado (Classic) 3500 Regular Cab",
        "Silverado 1500 Crew Cab",
        "Silverado 1500 Double Cab",
        "Silverado 1500 Extended Cab",
        "Silverado 1500 HD Crew Cab",
        "Silverado 1500 LD Double Cab",
        "Silverado 1500 Regular Cab",
        "Silverado 2500 Crew Cab",
        "Silverado 2500 Extended Cab",
        "Silverado 2500 HD Crew Cab",
        "Silverado 2500 HD Double Cab",
        "Silverado 2500 HD Extended Cab",
        "Silverado 2500 HD Regular Cab",
        "Silverado 2500 Regular Cab",
        "Silverado 3500 Crew Cab",
        "Silverado 3500 Extended Cab",
        "Silverado 3500 HD Crew Cab",
        "Silverado 3500 HD Double Cab",
        "Silverado 3500 HD Extended Cab",
        "Silverado 3500 HD Regular Cab",
        "Silverado 3500 Regular Cab",
        "Sonic",
        "Spark",
        "Spark EV",
        "Sportvan G10",
        "Sportvan G20",
        "Sportvan G30",
        "SS",
        "SSR",
        "Suburban",
        "Suburban 1500",
        "Suburban 2500",
        "Suburban 3500HD",
        "Tahoe",
        "Tahoe (New)",
        "Tracker",
        "Trailblazer",
        "Traverse",
        "Trax",
        "Uplander Cargo",
        "Uplander Passenger",
        "Venture Cargo",
        "Venture Passenger",
        "Volt",
    ],
    Chrysler: [
        "200",
        "300",
        "300M",
        "Aspen",
        "Cirrus",
        "Concorde",
        "Crossfire",
        "Fifth Ave",
        "Grand Voyager",
        "Imperial",
        "LeBaron",
        "LHS",
        "New Yorker",
        "Pacifica",
        "Pacifica Hybrid",
        "Prowler",
        "PT Cruiser",
        "Sebring",
        "Town & Country",
        "Voyager",
    ],
    Daewoo: ["Lanos", "Leganza", "Nubira"],
    Daihatsu: ["Charade", "Rocky"],
    Dodge: [
        "Avenger",
        "Caliber",
        "Caravan Cargo",
        "Caravan Passenger",
        "Challenger",
        "Charger",
        "Colt",
        "D150 Club Cab",
        "D150 Regular Cab",
        "D250 Club Cab",
        "D250 Regular Cab",
        "D350 Club Cab",
        "D350 Regular Cab",
        "Dakota Club Cab",
        "Dakota Crew Cab",
        "Dakota Extended Cab",
        "Dakota Quad Cab",
        "Dakota Regular Cab",
        "Dart",
        "Daytona",
        "Durango",
        "Dynasty",
        "Grand Caravan Cargo",
        "Grand Caravan Passenger",
        "Intrepid",
        "Journey",
        "Magnum",
        "Monaco",
        "Neon",
        "Nitro",
        "Ram 1500 Club Cab",
        "Ram 1500 Crew Cab",
        "Ram 1500 Mega Cab",
        "Ram 1500 Quad Cab",
        "Ram 1500 Regular Cab",
        "Ram 2500 Club Cab",
        "Ram 2500 Crew Cab",
        "Ram 2500 Mega Cab",
        "Ram 2500 Quad Cab",
        "Ram 2500 Regular Cab",
        "Ram 3500 Club Cab",
        "Ram 3500 Crew Cab",
        "Ram 3500 Mega Cab",
        "Ram 3500 Quad Cab",
        "Ram 3500 Regular Cab",
        "Ram 50 Regular Cab",
        "Ram Van 1500",
        "Ram Van 2500",
        "Ram Van 3500",
        "Ram Van B150",
        "Ram Van B250",
        "Ram Van B350",
        "Ram Wagon 1500",
        "Ram Wagon 2500",
        "Ram Wagon 3500",
        "Ram Wagon B150",
        "Ram Wagon B250",
        "Ram Wagon B350",
        "Ramcharger",
        "Shadow",
        "Spirit",
        "Sprinter 2500 Cargo",
        "Sprinter 2500 Passenger",
        "Sprinter 3500 Cargo",
        "Stealth",
        "Stratus",
        "Viper",
    ],
    Eagle: ["Premier", "Summit", "Talon", "Vision"],
    Ferrari: [
        "430 Scuderia",
        "458 Italia",
        "458 Speciale",
        "458 Spider",
        "488 GTB",
        "488 Spider",
        "599 GTB Fiorano",
        "599 GTO",
        "612 Scaglietti",
        "812 Superfast",
        "California",
        "F12berlinetta",
        "F430",
        "FF",
        "GTC4Lusso",
        "Portofino",
    ],
    FIAT: ["124 Spider", "500", "500 Abarth", "500c", "500c Abarth", "500e", "500L", "500X"],
    Fisker: ["Karma"],
    Ford: [
        "Aerostar Cargo",
        "Aerostar Passenger",
        "Aspire",
        "Bronco",
        "Bronco Sport",
        "C-MAX Energi",
        "C-MAX Hybrid",
        "Club Wagon",
        "Contour",
        "Crown Victoria",
        "E150 Cargo",
        "E150 Passenger",
        "E150 Super Duty Cargo",
        "E150 Super Duty Passenger",
        "E250 Cargo",
        "E250 Super Duty Cargo",
        "E350 Super Duty Cargo",
        "E350 Super Duty Passenger",
        "Econoline E150 Cargo",
        "Econoline E150 Passenger",
        "Econoline E250 Cargo",
        "Econoline E350 Cargo",
        "Econoline E350 Super Duty Cargo",
        "Econoline E350 Super Duty Passenger",
        "EcoSport",
        "Edge",
        "Escape",
        "Escort",
        "Excursion",
        "Expedition",
        "Expedition EL",
        "Expedition MAX",
        "Explorer",
        "Explorer Sport",
        "Explorer Sport Trac",
        "F150 (Heritage) Regular Cab",
        "F150 (Heritage) Super Cab",
        "F150 Regular Cab",
        "F150 Super Cab",
        "F150 SuperCrew Cab",
        "F250 Crew Cab",
        "F250 Regular Cab",
        "F250 Super Cab",
        "F250 Super Duty Crew Cab",
        "F250 Super Duty Regular Cab",
        "F250 Super Duty Super Cab",
        "F350 Crew Cab",
        "F350 Regular Cab",
        "F350 Super Cab",
        "F350 Super Duty Crew Cab",
        "F350 Super Duty Regular Cab",
        "F350 Super Duty Super Cab",
        "F450 Super Duty Crew Cab",
        "Festiva",
        "Fiesta",
        "Five Hundred",
        "Flex",
        "Focus",
        "Focus ST",
        "Freestar Cargo",
        "Freestar Passenger",
        "Freestyle",
        "Fusion",
        "Fusion Energi",
        "GT",
        "Mustang",
        "Probe",
        "Ranger Regular Cab",
        "Ranger Super Cab",
        "Ranger SuperCab",
        "Ranger SuperCrew",
        "Taurus",
        "Taurus X",
        "Tempo",
        "Thunderbird",
        "Transit 150 Van",
        "Transit 150 Wagon",
        "Transit 250 Van",
        "Transit 350 HD Van",
        "Transit 350 Van",
        "Transit 350 Wagon",
        "Transit Connect Cargo",
        "Transit Connect Cargo Van",
        "Transit Connect Passenger",
        "Windstar Cargo",
        "Windstar Passenger",
        "ZX2",
    ],
    Freightliner: [
        "Sprinter 2500 Cargo",
        "Sprinter 2500 Crew",
        "Sprinter 2500 Passenger",
        "Sprinter 3500 Cargo",
        "Sprinter 3500XD Cargo",
        "Sprinter WORKER Cargo",
        "Sprinter WORKER Passenger",
    ],
    Genesis: ["G70", "G80", "G90", "GV80"],
    Geo: ["Metro", "Prizm", "Storm", "Tracker"],
    GMC: [
        "1500 Club Coupe",
        "1500 Regular Cab",
        "2500 Club Coupe",
        "2500 Crew Cab",
        "2500 HD Club Coupe",
        "2500 HD Extended Cab",
        "2500 HD Regular Cab",
        "2500 Regular Cab",
        "3500 Club Coupe",
        "3500 Crew Cab",
        "3500 Extended Cab",
        "3500 Regular Cab",
        "Acadia",
        "Acadia Limited",
        "Canyon Crew Cab",
        "Canyon Extended Cab",
        "Canyon Regular Cab",
        "Envoy",
        "Envoy XL",
        "Envoy XUV",
        "Hummer EV",
        "Jimmy",
        "Rally Wagon 1500",
        "Rally Wagon 2500",
        "Rally Wagon 3500",
        "Rally Wagon G2500",
        "Rally Wagon G3500",
        "Safari Cargo",
        "Safari Passenger",
        "Savana 1500 Cargo",
        "Savana 1500 Passenger",
        "Savana 2500 Cargo",
        "Savana 2500 Passenger",
        "Savana 3500 Cargo",
        "Savana 3500 Passenger",
        "Sierra (Classic) 1500 Crew Cab",
        "Sierra (Classic) 1500 Extended Cab",
        "Sierra (Classic) 1500 HD Crew Cab",
        "Sierra (Classic) 1500 Regular Cab",
        "Sierra (Classic) 2500 Crew Cab",
        "Sierra (Classic) 2500 HD Crew Cab",
        "Sierra (Classic) 2500 HD Extended Cab",
        "Sierra (Classic) 2500 HD Regular Cab",
        "Sierra (Classic) 3500 Crew Cab",
        "Sierra (Classic) 3500 Extended Cab",
        "Sierra (Classic) 3500 Regular Cab",
        "Sierra 1500 Crew Cab",
        "Sierra 1500 Double Cab",
        "Sierra 1500 Extended Cab",
        "Sierra 1500 HD Crew Cab",
        "Sierra 1500 Limited Double Cab",
        "Sierra 1500 Regular Cab",
        "Sierra 2500 Crew Cab",
        "Sierra 2500 Extended Cab",
        "Sierra 2500 HD Crew Cab",
        "Sierra 2500 HD Double Cab",
        "Sierra 2500 HD Extended Cab",
        "Sierra 2500 HD Regular Cab",
        "Sierra 2500 Regular Cab",
        "Sierra 3500 Crew Cab",
        "Sierra 3500 Extended Cab",
        "Sierra 3500 HD Crew Cab",
        "Sierra 3500 HD Double Cab",
        "Sierra 3500 HD Extended Cab",
        "Sierra 3500 HD Regular Cab",
        "Sierra 3500 Regular Cab",
        "Sonoma Club Cab",
        "Sonoma Club Coupe Cab",
        "Sonoma Crew Cab",
        "Sonoma Extended Cab",
        "Sonoma Regular Cab",
        "Suburban 1500",
        "Suburban 2500",
        "Terrain",
        "Vandura 1500",
        "Vandura 2500",
        "Vandura 3500",
        "Vandura G1500",
        "Vandura G2500",
        "Vandura G3500",
        "Yukon",
        "Yukon Denali",
        "Yukon XL",
        "Yukon XL 1500",
        "Yukon XL 2500",
    ],
    Honda: [
        "Accord",
        "Accord Crosstour",
        "Accord Hybrid",
        "Civic",
        "Civic Type R",
        "Clarity Electric",
        "Clarity Fuel Cell",
        "Clarity Plug-in Hybrid",
        "CR-V",
        "CR-V Hybrid",
        "CR-Z",
        "Crosstour",
        "del Sol",
        "Element",
        "Fit",
        "HR-V",
        "Insight",
        "Odyssey",
        "Passport",
        "Pilot",
        "Prelude",
        "Ridgeline",
        "S2000",
    ],
    HUMMER: ["H1", "H2", "H3", "H3T"],
    Hyundai: [
        "Accent",
        "Azera",
        "Elantra",
        "Elantra GT",
        "Entourage",
        "Equus",
        "Excel",
        "Genesis",
        "Genesis Coupe",
        "Ioniq Electric",
        "Ioniq Hybrid",
        "Ioniq Plug-in Hybrid",
        "Kona",
        "Kona Electric",
        "NEXO",
        "Palisade",
        "Santa Fe",
        "Santa Fe Sport",
        "Santa Fe XL",
        "Scoupe",
        "Sonata",
        "Sonata Hybrid",
        "Sonata Plug-in Hybrid",
        "Tiburon",
        "Tucson",
        "Tucson Fuel Cell",
        "Veloster",
        "Venue",
        "Veracruz",
        "XG300",
        "XG350",
    ],
    INFINITI: ["EX", "FX", "G", "I", "J", "JX", "M", "Q", "Q40", "Q50", "Q60", "Q70", "QX", "QX30", "QX50", "QX60", "QX70", "QX80"],
    Isuzu: [
        "Amigo",
        "Ascender",
        "Axiom",
        "Hombre Regular Cab",
        "Hombre Spacecab",
        "i-280 Extended Cab",
        "i-290 Extended Cab",
        "i-350 Crew Cab",
        "i-370 Crew Cab",
        "i-370 Extended Cab",
        "Impulse",
        "Oasis",
        "Regular Cab",
        "Rodeo",
        "Rodeo Sport",
        "Spacecab",
        "Stylus",
        "Trooper",
        "VehiCROSS",
    ],
    Jaguar: ["E-PACE", "F-PACE", "F-TYPE", "I-PACE", "S-Type", "X-Type", "XE", "XF", "XJ", "XK"],
    Jeep: [
        "Cherokee",
        "Comanche Regular Cab",
        "Commander",
        "Compass",
        "Gladiator",
        "Grand Cherokee",
        "Grand Cherokee L",
        "Grand Wagoneer",
        "Liberty",
        "Patriot",
        "Renegade",
        "Wrangler",
        "Wrangler Unlimited",
    ],
    Kia: [
        "Amanti",
        "Borrego",
        "Cadenza",
        "Forte",
        "Forte Koup",
        "Forte5",
        "K5",
        "K900",
        "Niro",
        "Niro EV",
        "Niro Plug-in Hybrid",
        "Optima",
        "Optima (2006.5)",
        "Optima Hybrid",
        "Optima Plug-in Hybrid",
        "Rio",
        "Rondo",
        "Sedona",
        "Seltos",
        "Sephia",
        "Sorento",
        "Soul",
        "Soul EV",
        "Spectra",
        "Sportage",
        "Stinger",
        "Telluride",
    ],
    Lamborghini: ["Aventador", "Gallardo", "Huracan", "Murcielago", "Murcielago LP640"],
    "Land Rover": [
        "Defender 110",
        "Defender 90",
        "Discovery",
        "Discovery Series II",
        "Discovery Sport",
        "Freelander",
        "LR2",
        "LR3",
        "LR4",
        "Range Rover",
        "Range Rover Evoque",
        "Range Rover Sport",
        "Range Rover Velar",
    ],
    Lexus: ["CT", "ES", "GS", "GX", "HS", "IS", "IS F", "LC", "LFA", "LS", "LX", "NX", "RC", "RX", "SC", "UX"],
    Lincoln: [
        "Aviator",
        "Blackwood",
        "Continental",
        "Corsair",
        "LS",
        "Mark LT",
        "Mark VII",
        "Mark VIII",
        "MKC",
        "MKS",
        "MKT",
        "MKX",
        "MKZ",
        "Nautilus",
        "Navigator",
        "Navigator L",
        "Town Car",
        "Zephyr",
    ],
    Lotus: ["Elise", "Evora", "Evora 400", "Exige", "Exige S"],
    Maserati: ["Coupe", "Ghibli", "GranSport", "GranTurismo", "Levante", "Quattroporte"],
    Maybach: ["57", "62"],
    MAZDA: [
        "323",
        "626",
        "929",
        "B-Series Cab Plus",
        "B-Series Extended Cab",
        "B-Series Regular Cab",
        "CX-3",
        "CX-30",
        "CX-5",
        "CX-7",
        "CX-9",
        "MAZDA2",
        "MAZDA3",
        "MAZDA5",
        "MAZDA6",
        "Millenia",
        "MPV",
        "MX-3",
        "MX-5 Miata",
        "MX-5 Miata RF",
        "MX-6",
        "Navajo",
        "Protege",
        "Protege5",
        "RX-7",
        "RX-8",
        "Tribute",
    ],
    McLaren: ["570GT", "570S", "650S", "675LT", "720S", "MP4-12C"],
    "Mercedes-Benz": [
        "190 E",
        "300 CE",
        "300 D",
        "300 E",
        "300 SD",
        "300 SE",
        "300 SL",
        "300 TE",
        "400 E",
        "400 SE",
        "400 SEL",
        "500 E",
        "500 SEC",
        "500 SEL",
        "500 SL",
        "600 SEC",
        "600 SEL",
        "600 SL",
        "A-Class",
        "B-Class",
        "C-Class",
        "CL-Class",
        "CLA",
        "CLA-Class",
        "CLK-Class",
        "CLS",
        "CLS-Class",
        "E-Class",
        "G-Class",
        "GL-Class",
        "GLA",
        "GLA-Class",
        "GLB",
        "GLC",
        "GLC Coupe",
        "GLE",
        "GLE Coupe",
        "GLK-Class",
        "GLS",
        "M-Class",
        "Mercedes-AMG C-Class",
        "Mercedes-AMG CLA",
        "Mercedes-AMG CLS",
        "Mercedes-AMG E-Class",
        "Mercedes-AMG G-Class",
        "Mercedes-AMG GLA",
        "Mercedes-AMG GLC",
        "Mercedes-AMG GLC Coupe",
        "Mercedes-AMG GLE",
        "Mercedes-AMG GLE Coupe",
        "Mercedes-AMG GLS",
        "Mercedes-AMG GT",
        "Mercedes-AMG S-Class",
        "Mercedes-AMG SL",
        "Mercedes-AMG SLC",
        "Mercedes-AMG SLK",
        "Mercedes-Maybach S 600",
        "Mercedes-Maybach S-Class",
        "Metris Cargo",
        "Metris Passenger",
        "Metris WORKER Cargo",
        "Metris WORKER Passenger",
        "R-Class",
        "S-Class",
        "SL",
        "SL-Class",
        "SLC",
        "SLK",
        "SLK-Class",
        "SLR McLaren",
        "SLS-Class",
        "Sprinter 2500 Cargo",
        "Sprinter 2500 Crew",
        "Sprinter 2500 Passenger",
        "Sprinter 3500 Cargo",
        "Sprinter 3500 XD Cargo",
        "Sprinter WORKER Cargo",
        "Sprinter WORKER Passenger",
    ],
    Mercury: [
        "Capri",
        "Cougar",
        "Grand Marquis",
        "Marauder",
        "Mariner",
        "Milan",
        "Montego",
        "Monterey",
        "Mountaineer",
        "Mystique",
        "Sable",
        "Topaz",
        "Tracer",
        "Villager",
    ],
    MINI: ["Clubman", "Convertible", "Cooper", "Countryman", "Coupe", "Hardtop", "Hardtop 2 Door", "Hardtop 4 Door", "Paceman", "Roadster"],
    Mitsubishi: [
        "3000GT",
        "Diamante",
        "Eclipse",
        "Eclipse Cross",
        "Endeavor",
        "Expo",
        "Galant",
        "i-MiEV",
        "Lancer",
        "Lancer Evolution",
        "Mighty Max Macro Cab",
        "Mighty Max Regular Cab",
        "Mirage",
        "Mirage G4",
        "Montero",
        "Montero Sport",
        "Outlander",
        "Outlander PHEV",
        "Outlander Sport",
        "Precis",
        "Raider Double Cab",
        "Raider Extended Cab",
    ],
    Nissan: [
        "200SX",
        "240SX",
        "300ZX",
        "350Z",
        "370Z",
        "Altima",
        "Armada",
        "cube",
        "Frontier Crew Cab",
        "Frontier King Cab",
        "Frontier Regular Cab",
        "GT-R",
        "JUKE",
        "Kicks",
        "King Cab",
        "LEAF",
        "Maxima",
        "Murano",
        "NV1500 Cargo",
        "NV200",
        "NV200 Taxi",
        "NV2500 HD Cargo",
        "NV3500 HD Cargo",
        "NV3500 HD Passenger",
        "NX",
        "Pathfinder",
        "Pathfinder Armada",
        "Quest",
        "Regular Cab",
        "Rogue",
        "Rogue Select",
        "Rogue Sport",
        "Sentra",
        "Stanza",
        "Titan Crew Cab",
        "Titan King Cab",
        "TITAN Single Cab",
        "TITAN XD Crew Cab",
        "TITAN XD King Cab",
        "TITAN XD Single Cab",
        "Versa",
        "Versa Note",
        "Xterra",
    ],
    Oldsmobile: [
        "88",
        "98",
        "Achieva",
        "Alero",
        "Aurora",
        "Bravada",
        "Ciera",
        "Custom Cruiser",
        "Cutlass",
        "Cutlass Ciera",
        "Cutlass Cruiser",
        "Cutlass Supreme",
        "Intrigue",
        "LSS",
        "Regency",
        "Silhouette",
        "Toronado",
    ],
    Panoz: ["Esperante"],
    Plymouth: ["Acclaim", "Breeze", "Colt", "Colt Vista", "Grand Voyager", "Laser", "Neon", "Prowler", "Sundance", "Voyager"],
    Polestar: ["2"],
    Pontiac: [
        "Aztek",
        "Bonneville",
        "Firebird",
        "G3",
        "G5",
        "G6",
        "G6 (2009.5)",
        "G8",
        "Grand Am",
        "Grand Prix",
        "GTO",
        "LeMans",
        "Montana",
        "Montana SV6",
        "Solstice",
        "Sunbird",
        "Sunfire",
        "Torrent",
        "Trans Sport",
        "Vibe",
    ],
    Porsche: [
        "718 Boxster",
        "718 Cayman",
        "718 Spyder",
        "911",
        "928",
        "968",
        "Boxster",
        "Carrera GT",
        "Cayenne",
        "Cayenne Coupe",
        "Cayman",
        "Macan",
        "Panamera",
        "Taycan",
    ],
    Ram: [
        "1500 Classic Crew Cab",
        "1500 Classic Quad Cab",
        "1500 Classic Regular Cab",
        "1500 Crew Cab",
        "1500 Quad Cab",
        "1500 Regular Cab",
        "2500 Crew Cab",
        "2500 Mega Cab",
        "2500 Regular Cab",
        "3500 Crew Cab",
        "3500 Mega Cab",
        "3500 Regular Cab",
        "C/V",
        "C/V Tradesman",
        "Dakota Crew Cab",
        "Dakota Extended Cab",
        "ProMaster 1500 Cargo",
        "ProMaster 2500 Cargo",
        "ProMaster 3500 Cargo",
        "ProMaster Cargo Van",
        "ProMaster City",
        "ProMaster Window Van",
    ],
    Rivian: ["R1S", "R1T"],
    "Rolls-Royce": ["Dawn", "Ghost", "Phantom", "Wraith"],
    Saab: ["3-Sep", "5-Sep", "9-2X", "9-4X", "9-7X", "900", "9000"],
    Saturn: ["Astra", "Aura", "Ion", "L-Series", "Outlook", "Relay", "S-Series", "SKY", "VUE"],
    Scion: ["FR-S", "iA", "iM", "iQ", "tC", "xA", "xB", "xD"],
    smart: ["fortwo", "fortwo cabrio", "fortwo electric drive", "fortwo electric drive cabrio"],
    SRT: ["Viper"],
    Subaru: [
        "Ascent",
        "B9 Tribeca",
        "Baja",
        "BRZ",
        "Crosstrek",
        "Forester",
        "Impreza",
        "Justy",
        "Legacy",
        "Loyale",
        "Outback",
        "SVX",
        "Tribeca",
        "WRX",
        "XV Crosstrek",
    ],
    Suzuki: [
        "Aerio",
        "Equator Crew Cab",
        "Equator Extended Cab",
        "Esteem",
        "Forenza",
        "Grand Vitara",
        "Kizashi",
        "Reno",
        "Samurai",
        "Sidekick",
        "Swift",
        "SX4",
        "Verona",
        "Vitara",
        "X-90",
        "XL-7",
        "XL7",
    ],
    Tesla: ["Model 3", "Model S", "Model X"],
    Toyota: [
        "4Runner",
        "86",
        "Avalon",
        "Avalon Hybrid",
        "C-HR",
        "Camry",
        "Camry Hybrid",
        "Celica",
        "Corolla",
        "Corolla Hatchback",
        "Corolla Hybrid",
        "Corolla iM",
        "Cressida",
        "Echo",
        "FJ Cruiser",
        "GR Supra",
        "Highlander",
        "Highlander Hybrid",
        "Land Cruiser",
        "Matrix",
        "Mirai",
        "MR2",
        "Paseo",
        "Previa",
        "Prius",
        "Prius c",
        "Prius Plug-in Hybrid",
        "Prius Prime",
        "Prius v",
        "RAV4",
        "RAV4 Hybrid",
        "RAV4 Prime",
        "Regular Cab",
        "Sequoia",
        "Sienna",
        "Solara",
        "Supra",
        "T100 Regular Cab",
        "T100 Xtracab",
        "Tacoma Access Cab",
        "Tacoma Double Cab",
        "Tacoma Regular Cab",
        "Tacoma Xtracab",
        "Tercel",
        "Tundra Access Cab",
        "Tundra CrewMax",
        "Tundra Double Cab",
        "Tundra Regular Cab",
        "Venza",
        "Xtra Cab",
        "Yaris",
        "Yaris Hatchback",
        "Yaris iA",
    ],
    Volkswagen: [
        "Arteon",
        "Atlas",
        "Atlas Cross Sport",
        "Beetle",
        "Cabrio",
        "Cabrio (New)",
        "Cabriolet",
        "CC",
        "Corrado",
        "e-Golf",
        "Eos",
        "Eurovan",
        "Fox",
        "GLI",
        "Golf",
        "Golf (New)",
        "Golf Alltrack",
        "Golf GTI",
        "Golf III",
        "Golf R",
        "Golf SportWagen",
        "GTI",
        "GTI (New)",
        "Jetta",
        "Jetta (New)",
        "Jetta GLI",
        "Jetta III",
        "Jetta SportWagen",
        "New Beetle",
        "Passat",
        "Passat (New)",
        "Phaeton",
        "R32",
        "Rabbit",
        "Routan",
        "Tiguan",
        "Tiguan Limited",
        "Touareg",
        "Touareg 2",
    ],
    Volvo: [
        "240",
        "740",
        "850",
        "940",
        "960",
        "C30",
        "C70",
        "S40",
        "S40 (New)",
        "S60",
        "S70",
        "S80",
        "S90",
        "V40",
        "V50",
        "V60",
        "V70",
        "V90",
        "XC40",
        "XC60",
        "XC70",
        "XC90",
    ],
};
