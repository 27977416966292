import React, { PropsWithChildren, useState } from "react";
import { GoogleMap /* useJsApiLoader */ } from "@react-google-maps/api";
// import { userInfoRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { SpinnerComp } from "../commonComp/SpinnerComp";
import { getAccessInfo, getDeviceCurrentLocation } from "../../commonSrc/commonFns";
import { pinDarkBlue1 } from "../../commonSrc/Constants";

/* to be implemented in server-side, or no need to move it to server-side as google can force certian IP addresses
to use the api key */
// const GOOGLE_API_KEY = "AIzaSyDmwt9fU1rTI2d216WS3VF_vmHA3j2Jeyk";
// export const locPin1 = new google.maps.marker.PinElement({
//     background: "#490db7",
//     borderColor: "#f10b0b",
//     scale: 0.5,
//     glyph: "You",
//     glyphColor: "#f35474"
// })

export type Location = google.maps.LatLngLiteral | undefined;

export function clearMarkers(markers: google.maps.marker.AdvancedMarkerElement[]): void {
    markers.forEach((marker) => {
        marker.map = null;// setMap(null);
    });

    while (markers.length > 0) {
        markers.pop();
    }
}

const libs: any[] = ["places"];

export enum MapLocActDisTypesT {
    Location = "LOCATION",
    Map = "MAP",
    Marker = "MARKER",
    ErrorMsg = "ERROR_MSG",
}
export type MapStT = {
    map?: google.maps.Map | null;
    markers?: google.maps.marker.AdvancedMarkerElement[] | null;
    location?: Location;
    getLocationByMarker?: () => Location;
    errorMsg?: string;
};

export type MapActionT = { type: MapLocActDisTypesT } & MapStT;

export type MapDisT = (action: MapActionT) => void;

function GoogleMapCmp(
    props: PropsWithChildren<{
        //id is important to set, to get the map in fullscreen successfully
        id: string;
        mapLocRdc?: [MapStT, MapDisT | null];
        mapRef: any;
        readOnly?: boolean;
        defaulValue?: Location;
    }>
) {
    console.log("###First line - GoogleMapCmp");
    //-START- Fns declarations ************************************************************************************************************************************************
    const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null);
    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

    function registerOnClickCurrLocBtnOnMap(
        getCurrentLocationBtn: HTMLButtonElement,
        markers: google.maps.marker.AdvancedMarkerElement[],
        map: google.maps.Map
    ): void {
        getCurrentLocationBtn.addEventListener("click", async (e) => {
            e.preventDefault();
            console.log("get current location button is clicked");

            try {
                const loc = await getDeviceCurrentLocation();
                if (loc) {
                    mapLocDis?.({
                        type: MapLocActDisTypesT.Location,
                        location: loc,
                    });

                    clearMarkers(markers);

                    markers.push(
                        new google.maps.marker.AdvancedMarkerElement({
                            map,
                            gmpDraggable: props.readOnly ? false : true,
                            // draggable: props.readOnly ? false : true,
                            // icon: svgMarker,
                            title: "Your Location",
                            position: loc,
                            content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                        })
                    );

                    map.setCenter(loc);
                    map.setZoom(12);
                }
            } catch (err) {
                console.log("err in getCurrentPosition=,", err);
            }
        });
    }

    function registerOnPlacesChangeLsnr(
        searchBox: google.maps.places.SearchBox,
        markers: google.maps.marker.AdvancedMarkerElement[],
        map: google.maps.Map
    ): void {
        searchBox.addListener("places_changed", (e: any) => {
            // e.preventDefault();//doesn't work to prevent page refresh on searching a place in the map
            // e.stopPropagation();//doesn't work to prevent page refresh on searching a place in the map
            const places = searchBox.getPlaces();
            console.log("getPlaces=", places);

            if (places?.length == 0) {
                return;
            }

            if (places?.length === 1) {
                mapLocDis?.({
                    type: MapLocActDisTypesT.Location,
                    location: { lng: places[0]?.geometry?.location?.lng() as number, lat: places[0]?.geometry?.location?.lat() as number },
                });
            }

            clearMarkers(markers);

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();

            places?.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                const svgMarker = {
                    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                    fillColor: "blue",
                    fillOpacity: 0.6,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: 2,
                    anchor: new google.maps.Point(0, 20),
                };

                const icon = {
                    url: place.icon as string,
                    size: new google.maps.Size(80, 80),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 40),
                    scaledSize: new google.maps.Size(35, 50),
                };
                // console.log("icon=", icon);
                // Create a marker for each place.
                const placeMrker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    // icon: svgMarker,
                    // content:
                    title: place.name,
                    position: place.geometry.location,
                    collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
                    content: new google.maps.marker.PinElement(pinDarkBlue1).element
                });
                markers.push(placeMrker);

                placeMrker.addListener("click", () => {
                    if (place?.geometry?.location) {
                        // setCurrentUserLocation({ lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() });
                        // setCurrentLocationValue({ lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() });
                        mapLocDis?.({
                            type: MapLocActDisTypesT.Location,
                            location: { lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() },
                        });

                        clearMarkers(markers);

                        markers.push(
                            new google.maps.marker.AdvancedMarkerElement({
                                map,
                                gmpDraggable: props.readOnly ? false : true,
                                // draggable: props.readOnly ? false : true,
                                // icon: svgMarker,
                                title: "Your Location",
                                position: { lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() },
                                content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                            })
                        );
                    }
                });

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
            // map.setZoom(1);
        });
    }

    function registerOnClickMapLsnr(map: google.maps.Map, markers: google.maps.marker.AdvancedMarkerElement[]): void {
        map.addListener("click", (e: any) => {
            console.log("Google Map on click fired");

            // setCurrentLocationValue({ lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number });
            mapLocDis?.({
                type: MapLocActDisTypesT.Location,
                location: { lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number },
            });
            map?.panTo({ lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number });
            // Clear out the old markers.
            clearMarkers(markers);

            markers.push(
                new google.maps.marker.AdvancedMarkerElement({
                    map,
                    gmpDraggable: props.readOnly ? false : true,
                    // draggable: props.readOnly ? false : true,
                    // icon: svgMarker,
                    title: "Your Location",
                    position: { lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number },
                    content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                })
            );
            // map?.setZoom(12);
            // map?.setZoom(1);
        });
    }

    function createCurrentLocBtnOnMap() {
        const getCurrentLocationBtn = document.createElement("button");
        getCurrentLocationBtn.type = "button";
        getCurrentLocationBtn.style.backgroundColor = "yellow";
        getCurrentLocationBtn.style.color = "black";
        getCurrentLocationBtn.style.border = "none";
        getCurrentLocationBtn.style.outline = "none";
        getCurrentLocationBtn.style.width = "40px";
        getCurrentLocationBtn.style.height = "40px";
        getCurrentLocationBtn.style.borderRadius = "2px";
        getCurrentLocationBtn.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
        getCurrentLocationBtn.style.cursor = "pointer";
        getCurrentLocationBtn.style.marginRight = "10px";
        getCurrentLocationBtn.style.padding = "0px";
        getCurrentLocationBtn.title = "Your Location";
        getCurrentLocationBtn.className = "display-6 bi bi-geo-alt-fill";
        return getCurrentLocationBtn;
    }

    function createExitFullScreenBtnOnMap(mapId: string) {
        const ExistFullScreencBtn = document.createElement("button");
        ExistFullScreencBtn.id = mapId + "FullScrnOnMapBtn";
        ExistFullScreencBtn.type = "button";
        ExistFullScreencBtn.style.backgroundColor = "white";
        ExistFullScreencBtn.style.color = "black";
        ExistFullScreencBtn.style.border = "none";
        ExistFullScreencBtn.style.outline = "none";
        ExistFullScreencBtn.style.width = "40px";
        ExistFullScreencBtn.style.height = "40px";
        ExistFullScreencBtn.style.borderRadius = "2px";
        ExistFullScreencBtn.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
        ExistFullScreencBtn.style.cursor = "pointer";
        ExistFullScreencBtn.style.marginRight = "10px";
        ExistFullScreencBtn.style.padding = "0px";
        ExistFullScreencBtn.title = "Close";
        ExistFullScreencBtn.className = "display-6 bi bi-arrows-fullscreen";

        ExistFullScreencBtn.addEventListener("click", (e) => {
            console.log("ExitFullScreen btn is clicked");
            let isFullscreenExcuted = false;
            document
                ?.exitFullscreen()
                .then(() => {
                    // if (!isFullscreenExcuted) {
                    ExistFullScreencBtn.className = "display-6 bi bi-arrows-fullscreen";
                    // ExistFullScreencBtn.scrollIntoView();
                    // setTimeout(() => {
                    document.getElementById(mapId)?.scrollIntoView({ block: "center" });
                    // }, 300);
                    // document.getElementById(mapId)?.scrollIntoView();
                    // }
                })
                .catch((err) => {
                    //screen changed to fullscreen
                    isFullscreenExcuted = true;
                    ExistFullScreencBtn.className = "display-6 bi bi-fullscreen-exit";

                    const mapEl = document.getElementById(mapId) as any;
                    if (mapEl?.requestFullscreen) {
                        mapEl?.requestFullscreen();
                    } else if (mapEl?.webkitRequestFullscreen) {
                        /* Safari */
                        mapEl?.webkitRequestFullscreen();
                    } else if (mapEl?.msRequestFullscreen) {
                        /* IE11 */
                        mapEl?.msRequestFullscreen();
                    }
                });
            //exist full screen is executed
        });
        return ExistFullScreencBtn;
    }

    async function getBoundBasedOnCountry(bounds: google.maps.LatLngBounds, map: google.maps.Map, countryId: string) {
        const geocoder = new google.maps.Geocoder();
        try {
            console.log("###countryId in geocode function=", countryId);
            await geocoder.geocode({ address: countryId, componentRestrictions: { country: countryId } }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results?.length && results?.length > 0 && results[0].geometry) {
                    // setCountryBounds(results[0].geometry.bounds);
                    bounds = results[0].geometry.bounds!!;
                    map.fitBounds(results[0].geometry.bounds!!);
                    map.setZoom(7);
                } else console.log("results[0].geometry.bounds=nothing");
            });
        } catch (error) {
            console.error("Error fetching user country:", error);
        }
        return bounds;
    }
    function createInputElement() {
        const input = document.createElement("input") as HTMLInputElement;
        input.addEventListener("keydown", (e) => {
            if (e.key.toLowerCase() === "enter")
                e.preventDefault();
            // return false;
        })
        input.id = "inputOfGoogleMapSearchBoxId";
        input.style.boxSizing = "border-box";
        input.style.border = "1px solid red";
        input.placeholder = "Search for a place";
        input.style.width = "240px";
        input.style.height = "32px";
        input.style.padding = "12px";
        input.style.borderRadius = "3px";
        input.style.boxShadow = "0 2px 6px rgba(0; 0; 0; 0.3)";
        input.style.fontSize = "14px";
        // input.style.outline = "";
        input.style.textOverflow = "ellipses";
        // input.style.position = "absolute";
        // input.style.left = "50%";
        input.style.marginLeft = "10px";
        input.style.zIndex = "9999999999";
        return input;
    }
    //-END- Fns declarations ***************************************************************************************************************************************************

    let mapLocSt: MapStT = {};
    let mapLocDis: MapDisT | null;

    if (props.mapLocRdc) {
        [mapLocSt, mapLocDis] = props.mapLocRdc;
    }

    // const { isLoaded } = useJsApiLoader({
    //     id: "google-map-script",
    //     googleMapsApiKey: GOOGLE_API_KEY,
    //     libraries: libs,
    //     // version:"3.50"
    // });

    const [map, setMap] = React.useState<google.maps.Map>();

    const onLoad = React.useCallback(async function callback(map: google.maps.Map) {
        console.log("###onLoad called, props.id",props.id);
        map.setOptions({ mapId: "209a84381de4fd18" });

        /* // Optional: subscribe to map capability changes.
        map.addListener('mapcapabilities_changed', () => {
            const mapCapabilities = map.getMapCapabilities();

            if (!mapCapabilities.isAdvancedMarkersAvailable) {
                // Advanced markers are *not* available, add a fallback.
                console.warn("Advanced markers are *not* available, add a fallback.");
                
            }
        }); */


        const input = createInputElement();
        const searchBox = new window.google.maps.places.SearchBox(input);
        setSearchBox(searchBox);
        const getCurrentLocationBtn = createCurrentLocBtnOnMap();
        const ExistFullScreen = createExitFullScreenBtnOnMap(props.id);

        searchBox.setBounds(currentBounds);
        let markers: google.maps.marker.AdvancedMarkerElement[] = [];

        //to be updated ########
        let bounds = new window.google.maps.LatLngBounds();
        //     {
        //     lat: 32.02270516093821,
        //     lng: 35.84407458566004,
        // }




        if (!mapLocSt.location && !props.defaulValue) {
            if (getAccessInfo()?.user?.country) bounds = await getBoundBasedOnCountry(bounds, map, getAccessInfo()?.user?.country!!);
            else throw new Error("Error, country is no there abd109");
        } else {
            markers.push(
                new google.maps.marker.AdvancedMarkerElement({
                    map,
                    // icon: { size: { height: 10, width: 10, } },
                    gmpDraggable: props.readOnly ? false : true,
                    title: "Your Location",
                    position: mapLocSt.location ?? props.defaulValue,
                    content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                })
            );
            map.setCenter(mapLocSt.location! ?? props.defaulValue);
            map.setZoom(12);
        }

        // map.fitBounds(bounds);

        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(getCurrentLocationBtn);
        if (!props.readOnly) map.controls[google.maps.ControlPosition.LEFT_TOP].push(input);
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ExistFullScreen);

        //-START- event listeners
        if (!props.readOnly) {
            registerOnClickMapLsnr(map, markers);

            registerOnPlacesChangeLsnr(searchBox, markers, map);

            registerOnClickCurrLocBtnOnMap(getCurrentLocationBtn, markers, map);
        }

        // map.setOptions({ mapId: props.id })

        setMap(map);
        mapLocDis?.({ type: MapLocActDisTypesT.Map, map: map });
        mapLocDis?.({ type: MapLocActDisTypesT.Marker, markers: markers });

        //-END- event listeners
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(undefined);
    }, []);

    //-START- JSX Component ************************************************************************************************************************************************
    return /* isLoaded && */ getAccessInfo()?.user?.country ? (
        <div ref={props.mapRef} className="py-3">
            <GoogleMap
                options={{ gestureHandling: "greedy", streetViewControl: false, fullscreenControl: false }}
                id={props.id}
                clickableIcons={true}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                // center={currentLocationValue}
                // zoom={14}
                onLoad={onLoad}
                onBoundsChanged={() => {
                    console.log("onBoundsChanged=", map?.getBounds());
                    // map?.fitBounds(map?.getBounds()!!);
                    setCurrentBounds(map?.getBounds() || null);
                    searchBox?.setBounds(map?.getBounds() || null);
                }}
                onUnmount={onUnmount}
            ></GoogleMap>
        </div>
    ) : (
        <>
            Loading...
            <SpinnerComp size="sm"></SpinnerComp>
            {/* <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> */}
        </>
    );
    //-END- JSX Component ************************************************************************************************************************************************
}

export default React.memo(GoogleMapCmp);
