import React, { CSSProperties, FormEvent, PropsWithChildren, Ref } from "react";
import { useNavigate } from "react-router-dom";

export function ActivationCmp(
    props: PropsWithChildren<{

    }>
) {
    const navigate = useNavigate();
    return (
        <>
            <div>Your account is not active</div>
            <button className="btn btn-primary" type="button" onClick={() => { navigate(-1) }}>OK</button>
        </>
    );
}
