import { PropsWithChildren, useEffect, useState } from "react";
import { GenderT, ReservedSeatsT, SeatSelectionStatus } from "../../../commonSrc/Types";
import { FormCmps } from "../FormComp";
import { ModalComp } from "../ModalComp";
import { PsgrVclSeatSelector } from "./PsgrVclSeatSelector";

export function PsgrSeat(
    props: PropsWithChildren<{
        seatRdc: [PsgrVclSeatSelector.SeatStT, PsgrVclSeatSelector.SeatDisT];
        seatId: number;
        seatNoInRow: number;
        seatRow: number;
        psgrInfo: any | undefined;
        alreadyReservedSeats: ReservedSeatsT[];
        onChange?: () => void;
        isEmpty?: boolean;
    }>
) {
    console.log("First line - PsgrSeat Component");

    const [seatSt, seatDis] = props.seatRdc;

    // const [seatVisible, setSeatVisible] = useState(true);
    const [seatStyle, setSeatStyle] = useState({});

    function updatePsgrSeat(pUpdType: "UPDATE" | "REMOVE"): boolean {
        let userCurrReservedSeats = seatSt.value;

        if (pUpdType === "REMOVE") {
            userCurrReservedSeats?.map((seat, i) => {
                if (seat.x === props.seatNoInRow && seat.y === props.seatRow) {
                    userCurrReservedSeats?.splice(i, 1);
                }
            });
        } /**else is for adding or updating current seat */ else {
            /** adjacent passenger checks:
             * if 3 -> check 2
             * if 2 -> check 1 and 3
             * if 1 -> check 2
             */

            if (canReserveSeat(genderSt.value as GenderT, props.seatNoInRow) === "N") {
                const cannotReserveOkModal = new ModalComp.OkModal(
                    { isPortal: false },
                    { msg: "بناءا على طلب الراكب بجانبك، لا يمكنك حجز هذا المقعد الا لانثى" }
                );
                genderDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
                cannotReserveOkModal.show();
                return false;
            }

            const seatFound = userCurrReservedSeats?.find((seat) => {
                return seat.x === props.seatNoInRow && seat.y === props.seatRow;
            });

            if (seatFound && genderSt.value) {
                seatFound.gender = genderSt.value;
                seatFound.maleBeside = determineNeighborPsgrSt.value ?? genderSt.value === "M" ? "Y" : "N";
                // seatFound.srcLoc = {}
                // seatFound.destLoc={}
            } else if (genderSt.value) {
                userCurrReservedSeats?.push({
                    reqId: props.psgrInfo.reqId,
                    gender: genderSt.value!!,
                    x: props.seatNoInRow,
                    y: props.seatRow,
                    maleBeside: determineNeighborPsgrSt.value ?? genderSt.value === "M" ? "Y" : "N",
                });
            }
        }
        console.log("New seats state is:", userCurrReservedSeats);
        console.log("seatSt.value:", seatSt.value);
        // setCurrentSeatSt({ empty: false, gender: genderSt.value ?? "" });
        seatDis({
            type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
            value: userCurrReservedSeats,
        });
        props.onChange?.();
        return true;
    }

    function canReserveSeat(reserverGender: GenderT, requestedSeatNo: number): "Y" | "N" {
        console.log("props.reservedSeats=", props.alreadyReservedSeats);

        if (reserverGender === "F") return "Y";

        const getAdjacentAlreadyReservedSeats = props.alreadyReservedSeats?.filter((seat) => {
            return seat.y === props.seatRow;
        });

        if (!getAdjacentAlreadyReservedSeats || getAdjacentAlreadyReservedSeats.length === 0) return "Y";

        if (requestedSeatNo === 1 || requestedSeatNo === 3) {
            const foundPsgrInSeat2 = getAdjacentAlreadyReservedSeats.find((seat) => seat.x === 2);

            if (foundPsgrInSeat2 && foundPsgrInSeat2.gender === "F" && foundPsgrInSeat2.maleBeside === "N") return "N";
            return "Y";
        }

        if (requestedSeatNo === 2) {
            const notAllowed = getAdjacentAlreadyReservedSeats.find(
                (seat) => (seat.x === 1 || seat.x === 3) && seat.gender === "F" && seat.maleBeside === "N"
            );

            if (notAllowed) return "N";
            return "Y";
        }

        return "N";
    }
    const {
        JsxComp: Gender,
        compReducer: [genderSt, genderDis],
        compRef: genderRef,
    } = FormCmps.RadioCmp.getComp("");

    const {
        JsxComp: DetermineNeighborPsgr,
        compReducer: [determineNeighborPsgrSt, determineNeighborPsgrStDis],
        compRef: determineNeighborPsgrStRef,
    } = FormCmps.RadioCmp.getComp("");

    useEffect(() => {
        // if (seatVisible) {
        if (props.seatNoInRow === 1 && props.seatRow === 1) {
            setSeatStyle({ backgroundImage: `url("/DrvrSteering.png")`, width: "95px", height: "95px" });
            // setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "95px", height: "95px" });
        } else {
            if (props.psgrInfo?.gender == "M") {
                setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "75px", height: "75px" });
            } else if (props.psgrInfo?.gender == "F") setSeatStyle({ backgroundImage: `url("/fPsgr.jpg")`, width: "75px", height: "75px" });
            else {
                setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
            }
        }
        // } else {
        //     setSeatStyle({});
        // }
    }, []);

    const customModalIdSuffix = props.seatRow + "_" + props.seatNoInRow;

    const genderModal = new ModalComp.OkModal(
        { isPortal: true, modalIdSuffix: customModalIdSuffix },
        {
            autoHideOnOk: false,
            okClickHandler: async () => {
                // const genderErrorModal = new ModalComp.OkModal(
                //     { isPortal: false },
                //     {
                //         msg: "ادخل كل الحقول",
                //         okClickHandler: async () => {
                //             console.log("showing genderModal");
                //             // genderModal.show();
                //         },
                //     }
                // );
                let isFormValid: boolean = true;

                isFormValid = await FormCmps.RadioCmp.validate(
                    genderSt,
                    genderDis,
                    isFormValid,
                    null,
                    // genderErrorModal,
                    genderRef,
                    "Fill all fields"
                );

                if (genderSt.value === "F" && !determineNeighborPsgrSt.value) {
                    isFormValid = await FormCmps.RadioCmp.validate(
                        determineNeighborPsgrSt,
                        determineNeighborPsgrStDis,
                        isFormValid,
                        // genderErrorModal,
                        null,
                        determineNeighborPsgrStRef,
                        "Fill all fields"
                    );
                }

                // return;
                if (!isFormValid) {
                    // genderModal.hide();
                    return;
                }
                // }
                if (updatePsgrSeat("UPDATE"))
                    if (genderSt.value == "M") {
                        setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "75px", height: "75px" });
                    } else if (genderSt.value == "F") {
                        setSeatStyle({ backgroundImage: `url("/fPsgr.jpg")`, width: "75px", height: "75px" });
                    }
                if (isFormValid) {
                    genderModal.hide();
                }
                // genderErrorModal.hide();
                // genderModal.hide();
            },
            msg: (
                <div>
                    "اختر جنس الراكب"
                    <Gender
                        radioRdc={[genderSt, genderDis]}
                        id="genderId1"
                        label={""}
                        radioDir={"horizontal"}
                        // onChange={(c, v) => {
                        //     props.callBack1(v);
                        // }}
                    >
                        <FormCmps.RadioItem id="genderMId1" label={"ذكر"} value="M"></FormCmps.RadioItem>
                        <FormCmps.RadioItem id="genderFId1" label={"انثى"} value="F"></FormCmps.RadioItem>
                    </Gender>
                    {genderSt.value === "F" && (
                        <DetermineNeighborPsgr
                            radioRdc={[determineNeighborPsgrSt, determineNeighborPsgrStDis]}
                            id="determineNeighborPsgrId1"
                            label={"هل تمانعي بوجود راكب ذكر بجانبك؟"}
                            radioDir={"horizontal"}
                        >
                            <FormCmps.RadioItem
                                id="determineNeighborPsgrFId1"
                                label={"اريد انثى فقط بجانبي"}
                                value="N" //maleBeside will be N
                            ></FormCmps.RadioItem>
                            <FormCmps.RadioItem id="determineNeighborPsgrMId1" label={"لا امانع"} value="Y"></FormCmps.RadioItem>
                        </DetermineNeighborPsgr>
                    )}
                </div>
            ),
        }
    );

    return (
        <div className="col-4 justify-content-center row text-center pt-4">
        {/* it was this for correct alignemnt of seats in screen sizes <div className="col-4 col-md-auto justify-content-center row text-center pt-4"> */}
            <>
                {/* 
            this is another approach to use protal modal
            <ModalComp.OkModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="5"
                msg={
                    <div>
                        "اختر جنس الراكب"
                        <Gender
                            radioRdc={[genderSt, genderDis]}
                            id="genderId1"
                            label={""}
                            radioDir={"horizontal"}
                            // onChange={(c, v) => {
                            //     props.callBack1(v);
                            // }}
                        >
                            <FormCmps.RadioItem id="genderMId1" label={"ذكر"} value="M"></FormCmps.RadioItem>
                            <FormCmps.RadioItem id="genderFId1" label={"انثى"} value="F"></FormCmps.RadioItem>
                        </Gender>
                        {genderSt.value === "F" && (
                            <DetermineNeighborPsgr
                                radioRdc={[determineNeighborPsgrSt, determineNeighborPsgrStDis]}
                                id="determineNeighborPsgrId1"
                                label={"هل تمانعي بوجود راكب ذكر بجانبك؟"}
                                radioDir={"horizontal"}
                            >
                                <FormCmps.RadioItem
                                    id="determineNeighborPsgrFId1"
                                    label={"اريد انثى فقط بجانبي"}
                                    value="M"
                                ></FormCmps.RadioItem>
                                <FormCmps.RadioItem id="determineNeighborPsgrMId1" label={"لا امانع"} value="F"></FormCmps.RadioItem>
                            </DetermineNeighborPsgr>
                        )}
                    </div>
                }
            ></ModalComp.OkModal.JsxPortalComp> 
            */}
            </>
            {/* below another approach to use portal modal that save state of containing component */}
            {genderModal.getJsxPortalElement()}
            {!props.isEmpty ? (
                <>
                    <div
                        onClick={() => {
                            if (props.seatNoInRow === 1 && props.seatRow === 1) return;
                            if (props.psgrInfo.gender) {
                                const alreadyReservedModal = new ModalComp.OkModal({ isPortal: false }, { msg: "محجوز مسبقا!" });
                                alreadyReservedModal.show();
                            } else genderModal.show();
                            // }
                        }}
                        className={`position-relative d-block`}
                        style={{
                            ...seatStyle,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div
                            className={`${props.seatNoInRow === 1 && props.seatRow === 1 ? "d-block text-center" : "d-none"}`}
                            style={{
                                position: "absolute",
                                bottom: "0",
                                // bottom: 0,
                                left: 0,
                                right: 0,
                                // height: "100%",
                                // width: "100%",
                                // opacity: "0.5",
                                // background: "gray",
                            }}
                        >
                            الكابتن
                        </div>
                        <div
                            className=""
                            style={{
                                position: "absolute",
                                top: "0",
                                // bottom: 0,
                                // left: 0,
                                right: 0,
                                // height: "100%",
                                // width: "100%",
                                // opacity: "0.5",
                                // background: "gray",
                            }}
                        >
                            <div
                                className={`${
                                    (props.seatRow === 1 && props.seatNoInRow === 1) || props.psgrInfo.gender || genderSt.value === ""
                                        ? "d-none"
                                        : "d-blcok"
                                }`}
                                style={{
                                    opacity: "1",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    border: "1px 1px",
                                    cursor: "pointer",
                                    color: "red",
                                }}
                                onClick={(e) => {
                                    // props.status = SeatSelectionStatus.UNSELECTED;
                                    e.stopPropagation();
                                    updatePsgrSeat("REMOVE");
                                    genderDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                                    determineNeighborPsgrStDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                                    setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
                                }}
                            >
                                X
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div style={{ width: "75px", height: "75px" }}></div>
            )}
        </div>
    );
}
