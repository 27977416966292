import { io } from "socket.io-client";
import { chattingUsersRVar, connectedUsersRVar, prevUserMsgsRVar, receivedMsgRVar } from "./commonSrc/apolloGQL/ReactiveVars";
import { getIDbChattingUsers, setIDbChattingUsers } from "./commonSrc/indexedDb/fns";
import { getAccessInfo } from "./commonSrc/commonFns";
import { SERVER_NAME } from "./commonSrc/EnvVariables";
import { User } from "./commonSrc/Types";
import { ModalComp } from "./components/commonComp/ModalComp";
const URL = SERVER_NAME;
const socket = io(URL, {
    autoConnect: false,
    //added 25Jun24
    transports:["websocket"]
    //  reconnectionAttempts: 5, reconnectionDelay: 10000 
});

// export let connectedUsers: any[];

// function

socket.onAny((event, ...args) => {
    console.log("ClientSide Socket onAny, event=", event, "args=", args);
});

socket.on("privateMessage", async (data) => {
    console.log("ClientSide Socket private message data=", data);
    // receivedMsgRVar().push(data);
    // const g = receivedMsgRVar();
    // receivedMsgRVar(g);
    // isNewMsg(+data?.from === getAccessInfo()?.user?.userId ? false : true);
    receivedMsgRVar([...receivedMsgRVar(), data]);
    // receivedMsgRVar([data]);
    console.log("chattingUsersRVar()", chattingUsersRVar());

    let filteredChattingUsers: any[] = [];
    const newUserChat = new User();
    newUserChat.userId = data.from;
    newUserChat.hasNewMsg = true;
    if (data.from != getAccessInfo()?.user?.userId) {
        if (
            chattingUsersRVar().some((e) => {
                console.log("e", e.userId);

                return e.userId === +data?.from;
            })
        ) {
            filteredChattingUsers = chattingUsersRVar().filter((item) => item.userId !== +data?.from);

            chattingUsersRVar([newUserChat, ...filteredChattingUsers]);
        } else {
            chattingUsersRVar([newUserChat, ...chattingUsersRVar()]);
        }

        //add new msg to localStorage
        // localStorage.setItem("chattingUsers", JSON.stringify(chattingUsersRVar()));
        await setIDbChattingUsers(chattingUsersRVar());
    }
});

socket.on("users", (users: any[]) => {
    // connectedUsers = users;
    const connUsers: any[] = [{ selectLableAndId: "nothing", valueKey: "nothing" }];
    users.forEach((e) => {
        connUsers.push({ selectLableAndId: e.userId, valueKey: e.userId });
        // if (e.userId === getAccessInfo()?.user?.userId) prevUserMsgsRVar(e.userMsgs);
    });
    connectedUsersRVar(connUsers);
});

socket.on("disconnect", () => {
    connectedUsersRVar([]);
    receivedMsgRVar([]);
    //##ToAddNotification##
});

socket.on("connect_error", (err) => {
    console.log("socket connect error:", err);

    if (err.message === "Socket Authentication Error - abd-") return;
    const socketModalErr = new ModalComp.OkModal(
        { isPortal: false },
        { msg: "Socket Connection Error - to be deleted on production" + err }
    );
    socketModalErr.show();
});

socket.on("connect", () => {
    if (socket.connected) console.log("just connected to socket");
});
export { socket };
