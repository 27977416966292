import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { useNavigate, useParams } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { Button } from "../commonComp/Button";
import NotFound from "../commonComp/NotFound";

export function RatePsgrCmp(props: React.PropsWithChildren<{}>) {
    console.log("First line - RateDrvrCmp Component");
    const { tripId } = useParams();
    const navigate = useNavigate();

    const [uniqueUsers, setUniqueUsers] = useState([]);
    const [tripJoinedPsgrsQ, {
        data: tripJoinedPsgrsData,
        error: tripJoinedPsgrsErr,
        loading: tripJoinedPsgrsLoading,
    }] = useLazyQuery(getGqlStmt().queries.JOINED_PSGRS, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +tripId!,
        },
    });

    const [
        ratePsgrs,
        { error: ratePsgrsErr, data: ratePsgrsData, loading: ratePsgrsLding },
    ] = useMutation(getGqlStmt().mutations.RATE_PSGR, {
        fetchPolicy: "no-cache",
    });

    const [
        ratePsgrsAllInOne,
        { error: ratePsgrsAllInOneErr, data: ratePsgrsAllInOneData, loading: ratePsgrsAllInOneLding },
    ] = useMutation(getGqlStmt().mutations.RATE_PSGRs_ALL_IN_ONE, {
        fetchPolicy: "no-cache",
    });

    const { JsxComp: RatePsgrCmp,
        // compReducer: [ratePsgrsSt, ratePsgrsDis],
        compReducerArr: [ratePsgrsArrSt, ratePsgrsArrDis],
        compRef: ratePsgrsRef
    } = FormCmps.RatingCmp.getComp();

    const { JsxComp: OneRateForAllCmp,
        compReducer: [oneRateForAllSt, oneRateForAllDis],
        compRef: oneRateForAllArrRef
    } = FormCmps.RatingCmp.getComp();

    const { JsxComp: ReviewCmp,
        // compReducer: [reviewSt, reviewDis],
        compReducerArr: [reviewArrSt, reviewArrDis],
        compRef: reviewRef
    } = FormCmps.TextAreaCmp.getComp();

    const { JsxComp: OneReviewForAllCmp,
        compReducer: [oneReviewForAllSt, oneReviewForAllDis],
        compRef: oneReviewForAllRef
    } = FormCmps.TextAreaCmp.getComp();

    const {
        JsxComp: PsgrRatingTypeCmp,
        compReducer: [psgrRatingTypeSt, psgrRatingTypeDis],
        compRef: psgrRatingTypeRef,
    } = FormCmps.RadioCmp.getComp("OneByOne");

    useEffect(() => {
        console.log("##useEffect", tripJoinedPsgrsData);
        (async () => {
            const joinedPsgrRes = await tripJoinedPsgrsQ()

            const uniqueUsers = joinedPsgrRes?.data.getTraspReqJoinedPsgrs?.dataArr.filter((user: any, index: number, self: any) =>
                index === self.findIndex((u: any) => u.userId === user.userId)
            );

            // const psgrUsers = joinedPsgrRes?.data.getTraspReqJoinedPsgrs?.dataArr.map((psgr: any) => psgr.userId);
            // const uniqueUsers = Array.from(new Set(psgrUsers));

            uniqueUsers.map((user: any, i: number) => {
                console.log("##item.userId", user, "idx", i);

                ratePsgrsArrDis({
                    type: FormCmps.RatingCmp.ratingActDisTypesT.ValueInput,
                    rdcIdx: i,
                    rdcKey: user.userId,//(ratePsgrsArrSt[ratePsgrsArrSt.length - 1].rdcKey ?? 0) + 1,
                    value: 0
                });
                reviewArrDis({
                    type: FormCmps.TextAreaCmp.textActDisTypesT.ValueInput,
                    rdcKey: user.userId,//(reviewArrSt[reviewArrSt.length - 1].rdcKey ?? 0) + 1,
                    rdcIdx: i,
                    value: ""
                });
            });

            setUniqueUsers(uniqueUsers);

        })()

    }, [])


    if (!tripId) {
        return <NotFound></NotFound>
    }

    if (tripJoinedPsgrsLoading)
        return <div>Loading ...</div>

    if (tripJoinedPsgrsErr)
        return <div>Sorry an error occurred</div>
    return (
        <div className="container d-flex flex-column align-items-center">
            <div className="my-4 fs-3 ">{ReadTextResource.getTextById("HOW_WAS_TRP")}</div>
            <PsgrRatingTypeCmp
                radioRdc={[psgrRatingTypeSt, psgrRatingTypeDis]}
                id="userType"
                label={"Rating Type"}
                inputRef={psgrRatingTypeRef}
                radioDir={"horizontal"}
            >
                <FormCmps.RadioItem
                    id="OneByOne"
                    label={"Rate Every Passenger"}
                    value="OneByOne"
                ></FormCmps.RadioItem>
                <FormCmps.RadioItem
                    id="AllInOne"
                    label={"General Rating for All Passengers"}
                    value="AllInOne"
                ></FormCmps.RadioItem>
            </PsgrRatingTypeCmp>
            {psgrRatingTypeSt.value === "OneByOne" &&
                uniqueUsers?.map((item: any, i: number) => {
                    return <div className="container d-flex flex-column align-items-center shadow my-2 p-3" key={"key" + i}>
                        <div>{item?.user?.userName1Ar ?? item?.user?.userName1En}</div>
                        <div className=" d-flex flex-row align-items-center">
                            <RatePsgrCmp id="ratePsgrs" label="rate" ratingRdc={[ratePsgrsArrSt[i], ratePsgrsArrDis]}></RatePsgrCmp>
                        </div>
                        <div >{ratePsgrsArrSt[i]?.value ?? 0}/5</div>
                        <div className="w-100 my-1">
                            <ReviewCmp
                                id="review"
                                placeHolder={ReadTextResource.getTextById("YOUR_REVIEW")}
                                label="review"
                                textRdc={[reviewArrSt[i], reviewArrDis]}
                                valueTrakingOnChange={true} >
                            </ReviewCmp>
                        </div>

                    </div>
                })
            }

            {psgrRatingTypeSt.value === "AllInOne" && <div className="container d-flex flex-column align-items-center shadow my-2 p-3" key={"keyOneForAll"}>
                <OneRateForAllCmp id="oneRateForAll" label="rate" ratingRdc={[oneRateForAllSt, oneRateForAllDis]} />
                <div >{oneRateForAllSt?.value ?? 0}/5</div>
                <div className="w-100 my-1">
                    <OneReviewForAllCmp
                        id="oneReviewForAll"
                        placeHolder={ReadTextResource.getTextById("YOUR_REVIEW")}
                        label="review"
                        textRdc={[oneReviewForAllSt, oneReviewForAllDis]}
                        valueTrakingOnChange={true} >
                    </OneReviewForAllCmp>
                </div>
            </div>
            }

            <div className="container d-flex flex-row justify-content-center">
                <Button
                    id="d"
                    label="Submit"
                    classNames="mt-3"
                    onOkClickHandler={async () => {
                        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("THNK_U"), navTo: "/app/notifications" })

                        if (psgrRatingTypeSt.value === "OneByOne") {
                            const rates: { psgrUserId: number, review: string | undefined, rateValue: number | undefined }[] = [];
                            for (let psgrRated of ratePsgrsArrSt) {
                                console.log("psgrRated", psgrRated);

                                if (!psgrRated.value) {
                                    okModal.setProps({ msg: ReadTextResource.getTextById("ADD_RATE_ST"), navTo: null })
                                    await okModal.showAsync();
                                    return;
                                }
                                rates.push({ psgrUserId: psgrRated.rdcKey, rateValue: psgrRated.value, review: reviewArrSt.find((e) => e.rdcKey === psgrRated.rdcKey)?.value })

                            };

                            const res = await ratePsgrs({
                                variables: {
                                    ratingsValues: rates,
                                    tripId: +tripId
                                }
                            });

                            if (res.data?.ratePsgrs?.success) {
                                await okModal.showAsync();
                                // navigate("/app/notifications", { replace: true })
                            }
                            else if (res.data?.ratePsgrs?.errorId === "ERR_RATING_DUPLICATE") {
                                okModal.setProps({ msg: ReadTextResource.getTextById("ALRDY_RATED") });
                                await okModal.showAsync();
                            }
                            else {
                                okModal.setProps({ msg: ReadTextResource.getTextById("SAVING_ERROR") });
                                await okModal.showAsync();
                                // navigate("/app/notifications", { replace: true })

                            }
                        } else {
                            //AllInOne
                            const res = await ratePsgrsAllInOne({
                                variables: {
                                    tripId: +tripId,
                                    rateValue: oneRateForAllSt.value,
                                    review: oneReviewForAllSt.value
                                }
                            });
                            if (res.data?.ratePsgrsAllInOne?.success) {
                                await okModal.showAsync();
                                // navigate("/app/notifications", { replace: true })
                            }
                            else if (res.data?.ratePsgrsAllInOne?.errorId === "ERR_RATING_DUPLICATE") {
                                okModal.setProps({ msg: ReadTextResource.getTextById("ALRDY_RATED") });
                                await okModal.showAsync();
                            }
                            else {
                                okModal.setProps({ msg: ReadTextResource.getTextById("SAVING_ERROR") });
                                await okModal.showAsync();
                            }
                        }
                    }} />
            </div>
        </div>
    );
}