import bsLTR from "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import bsRTL from "../../../node_modules/bootstrap/dist/css/bootstrap.rtl.css";
//below import causes the dropdown component to not work
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useReactiveVar } from "@apollo/client";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { dirAndLangRvar, refreshRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ModalComp } from "./ModalComp";

let ltrStyleUsed = false;
let rtlStyleUsed = false;

export function Lang(props: PropsWithChildren<{}>) {
    console.log("First line - Lang Component");
    //don't remove useReactiveVar, it is used to cause this component to refresh upon change the language
    useReactiveVar(dirAndLangRvar);
    useReactiveVar(refreshRvar);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const setLang = async () => {
            let lang = localStorage.getItem("lang");
            let direction = localStorage.getItem("dir");

            // if (!dirAndLang.lang)
            // dirAndLangRvar({ direction: direction as string, lang: lang as string });
            if (!direction && !lang) {
                await ReadTextResource.importTextResource("en");
                localStorage.setItem("dir", "ltr");
                localStorage.setItem("lang", "en");
                lang = "en";
                direction = "ltr";
                console.log("setLang1 LTR DFT");
                dirAndLangRvar({ direction: "ltr", lang: "en" });
            }

            if (direction === "ltr") {
                //import("../node_modules/bootstrap/dist/css/bootstrap.css");
                //used when refresh the whole page by browser refresh button or F5
                await ReadTextResource.importTextResource("en");

                if (!ltrStyleUsed) {
                    bsLTR.use();
                    bsRTL.unuse();
                    ltrStyleUsed = true;
                    rtlStyleUsed = false;
                }
                document.getElementById("mainHtmlElement")?.setAttribute("dir", "ltr");
                document.getElementById("mainHtmlElement")?.setAttribute("lang", "en");
                console.log("setLangLTR");
                //const a= await import("./components/common/compCss/awa.css");
            } else if (direction === "rtl") {
                await ReadTextResource.importTextResource("ar");
                // import("../node_modules/bootstrap/dist/css/bootstrap.rtl.css");
                if (!rtlStyleUsed) {
                    bsRTL.use();
                    bsLTR.unuse();
                    ltrStyleUsed = false;
                    rtlStyleUsed = true;
                }

                document.getElementById("mainHtmlElement")?.setAttribute("dir", "rtl");
                document.getElementById("mainHtmlElement")?.setAttribute("lang", "ar");
                console.log("setLangRTL");
            }

            setLoading(false);
            // console.log('ReadTextResource.getTextById(txtId, "ar")=',ReadTextResource.getTextById("WELCOME", "ar"));
        };

        setLang();
    }, [dirAndLangRvar().lang]);

    // return <>{props.children}</>;

    if (loading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="LnagSpinnerId1"
                modalId="Lang"
                autoShow={true}
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );

    const childrenWithProps = React.Children.map(props.children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child as any, { lang: dirAndLangRvar().lang });
        }
        return child;
    });
    return <>{childrenWithProps}</>;
}
