import { useLazyQuery, useMutation } from "@apollo/client";
import { MouseEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { getDistanceInfo, getGraphQlError } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { PsgrVclSeatSelector } from "../commonComp/vehicle/PsgrVclSeatSelector";
import { TripDtls } from "../trip/TripDtls";
import { ErrorBoundary } from "../commonComp/ErrorBoundary";

export function PsgrAddGoods(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrAddGoods Component");
    const { pReqId, pDrvrId, pDrvrDestLoc } = useParams();
    console.log("parms", pReqId, pDrvrId);

    type goodsArrT = {
        goodWeight: number;
        goodHeight: number;
        goodWidth: number;
        goodLength: number;
        goodNote: string;
        reqId: number;
        destLoc: Location;
        srcLoc: Location;
        tripDuration: number;
        distance: number;
    }[];

    const [goodsArrSt, setGoodsArrSt] = useState<goodsArrT>([]);

    const {
        JsxComp: PsgrVclSeatSelectorComp,
        compReducer: [psgrReservedSeatsSt, psgrReservedSeatsDis],
        compRef: psgrReservedSeatsRef,
    } = PsgrVclSeatSelector.getComp();

    const {
        JsxComp: GoodWeight,
        compReducer: [goodWeightSt, goodWeightDis],
        compReducerArr: [goodWeightArrSt, goodWeightArrDis],
        compRef: goodWeightRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: GoodLength,
        compReducer: [goodLengthSt, goodLengthDis],
        compReducerArr: [goodLengthArrSt, goodLengthArrDis],
        compRef: goodLengthRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: GoodHeight,
        compReducer: [goodHeightSt, goodHeightDis],
        compReducerArr: [goodHeightArrSt, goodHeightArrDis],
        compRef: goodHeightRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: GoodWidth,
        compReducer: [goodWidthSt, goodWidthDis],
        compReducerArr: [goodWidthArrSt, goodWidthArrDis],
        compRef: goodWidthRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: GoodNotes,
        compReducer: [goodNoteSt, goodNoteDis],
        compReducerArr: [goodNoteArrSt, goodNoteArrDis],
        compRef: goodNoteRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: CollectionLocation,
        compReducer: [psgrSrcColctLocSt, psgrSrcColctLocDis],
        compRef: psgrSrcColctLocRef,
    } = FormCmps.LocationCmp.getComp(/* { lat: 31.165375, lng: 35.745947 } */);

    const {
        JsxComp: DropOffLocChoice,
        compReducer: [dropOffLocChoiceSt, dropOffLocChoiceDis],
        compRef: dropOffLocChoiceRef,
    } = FormCmps.RadioCmp.getComp("");

    const {
        JsxComp: DropOffLocation,
        compReducer: [dropOffLocationSt, dropOffLocationStDis],
        compRef: dropOffLocationRef,
    } = FormCmps.LocationCmp.getComp();

    const {
        JsxComp: CollectionLocType,
        compReducer: [collectionLocTypeSt, collectionLocTypeDis],
        compRef: collectionLocTypeRef,
    } = FormCmps.RadioCmp.getComp("");

    const [psgrJoinTrasp, { error: psgrJoinTraspErr, data: psgrJoinTraspData, loading: psgrJoinTraspLoading }] = useMutation(
        getGqlStmt().mutations.PSGR_JOIN_TRASP,

        {
            variables: {
                reqId: +pReqId!,
                reservedSeatsByUser: psgrReservedSeatsSt.value,
            },
            fetchPolicy: "no-cache",
        }
    );

    const [psgrAddGoods, { error: psgrAddGoodsErr, data: psgrAddGoodsData, loading: psgrAddGoodsLoading }] = useMutation(
        getGqlStmt().mutations.PSGR_ADD_GOODS,
        {
            fetchPolicy: "no-cache",
        }
    );

    const [getGdsDeliveryPrice, { error: getGdsDeliveryPriceErr, data: getGdsDeliveryPriceData, loading: getGdsDeliveryPriceLoading }] =
        useLazyQuery(getGqlStmt().queries.GOODS_DELIVERY_PRICE, {
            variables: {
                reqId: +pReqId!,
                goods: goodsArrSt,
            },
            fetchPolicy: "no-cache",
        });

    const addGoodInfoCmp = () => {
        goodWeightArrDis({
            type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
            rdcKey: (goodWeightArrSt[goodWeightArrSt.length - 1].rdcKey ?? 0) + 1,
            value: "",
        });
        goodLengthArrDis({
            type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
            rdcKey: (goodLengthArrSt[goodLengthArrSt.length - 1].rdcKey ?? 0) + 1,
            value: "",
        });

        goodWidthArrDis({
            type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
            rdcKey: (goodWidthArrSt[goodWidthArrSt.length - 1].rdcKey ?? 0) + 1,
            value: "",
        });
        goodHeightArrDis({
            type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
            rdcKey: (goodHeightArrSt[goodHeightArrSt.length - 1].rdcKey ?? 0) + 1,
            value: "",
        });
        goodNoteArrDis({
            type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
            rdcKey: (goodNoteArrSt[goodNoteArrSt.length - 1].rdcKey ?? 0) + 1,
            value: "",
        });
    };

    const removeGoodInfoCmp = (rdcKey: number) => {
        goodWeightArrDis({ type: FormCmps.TextCmp.textActDisTypesT.Remove, rdcKey: rdcKey, value: "" });
        goodLengthArrDis({ type: FormCmps.TextCmp.textActDisTypesT.Remove, rdcKey: rdcKey, value: "" });
        goodWidthArrDis({ type: FormCmps.TextCmp.textActDisTypesT.Remove, rdcKey: rdcKey, value: "" });
        goodHeightArrDis({ type: FormCmps.TextCmp.textActDisTypesT.Remove, rdcKey: rdcKey, value: "" });
        goodNoteArrDis({ type: FormCmps.TextCmp.textActDisTypesT.Remove, rdcKey: rdcKey, value: "" });
    };
    if (pReqId === undefined || pDrvrId === undefined) return <div>Invalid request parameters</div>;

    async function isFormValid(): Promise<boolean> {
        let isPrevFormValid: boolean = true;
        const validationModal = new ModalComp.OkModal(
            { isPortal: false },
            { msg: ReadTextResource.getTextById("FILL_ALL_FLDS"), navTo: "" }
        );

        await Promise.all(
            goodWeightArrSt.map(async (v, i) => {
                if (!v.value || (v.value && +v.value <= 0)) {
                    goodWeightArrDis({
                        rdcKey: v.rdcKey,
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                    });

                    if (isPrevFormValid) {
                        // validationModal.setProps({
                        //     okClickHandler: () => {
                        //         psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        //     },
                        // });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        isPrevFormValid = false;
                        await validationModal.showAsync();
                    }
                    // return isPrevFormValid;
                }
            })
        );

        await Promise.all(
            goodLengthArrSt.map(async (v, i) => {
                if (!v.value || (v.value && +v.value <= 0)) {
                    goodLengthArrDis({
                        rdcKey: v.rdcKey,
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                    });

                    if (isPrevFormValid) {
                        // validationModal.setProps({
                        //     okClickHandler: () => {
                        //         psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        //     },
                        // });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        isPrevFormValid = false;
                        await validationModal.showAsync();
                    }
                    // return isPrevFormValid;
                }
            })
        );

        await Promise.all(
            goodWidthArrSt.map(async (v, i) => {
                if (!v.value || (v.value && +v.value <= 0)) {
                    goodWidthArrDis({
                        rdcKey: v.rdcKey,
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                    });

                    if (isPrevFormValid) {
                        // validationModal.setProps({
                        //     okClickHandler: () => {
                        //         psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        //     },
                        // });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        isPrevFormValid = false;
                        await validationModal.showAsync();
                    }
                    // return isPrevFormValid;
                }
            })
        );

        await Promise.all(
            goodHeightArrSt.map(async (v, i) => {
                if (!v.value || (v.value && +v.value <= 0)) {
                    goodHeightArrDis({
                        rdcKey: v.rdcKey,
                        type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                        errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                    });

                    if (isPrevFormValid) {
                        // validationModal.setProps({
                        //     okClickHandler: () => {
                        //         psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        //     },
                        // });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        isPrevFormValid = false;
                        await validationModal.showAsync();
                    }
                    // return isPrevFormValid;
                }
            })
        );


        if (collectionLocTypeSt.value === "psgrLoc")
            isPrevFormValid = await FormCmps.LocationCmp.validate(
                psgrSrcColctLocSt,
                psgrSrcColctLocDis,
                isPrevFormValid,
                validationModal,
                psgrSrcColctLocRef
            );

        if (dropOffLocChoiceSt.value === "psgrLoc")
            isPrevFormValid = await FormCmps.LocationCmp.validate(
                dropOffLocationSt,
                dropOffLocationStDis,
                isPrevFormValid,
                validationModal,
                dropOffLocationRef
            );

        /*  if (psgrReservedSeatsSt.value?.length === 0) {
            psgrReservedSeatsDis({
                type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                errorMsg: ReadTextResource.getTextById("SLCT_SEAT"),
            });

            if (isPrevFormValid) {
                validationModal.setProps({
                    okClickHandler: () => {
                        psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await validationModal.showAsync();
                isPrevFormValid = false;
            }
        } */

        /* if (goodWeightSt?.value && +goodWeightSt?.value !== 0) {
            if (+goodWeightSt?.value !== PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value)) {
                psgrReservedSeatsDis({
                    type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                    errorMsg: ReadTextResource.getTextById("SEAT_NOT_MATCH"),
                });
                goodWeightDis({
                    type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                    errorMsg: ReadTextResource.getTextById("SEAT_NOT_MATCH"),
                });

                if (isPrevFormValid) {
                    validationModal.setProps({
                        okClickHandler: () => {
                            psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await validationModal.showAsync();
                    isPrevFormValid = false;
                }
            }
        } else if (
            !goodWeightSt?.value &&
            goodWeightSt?.value === "" &&
            PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value) > 1
        ) {
            psgrReservedSeatsDis({
                type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                errorMsg: ReadTextResource.getTextById("SEAT_EXCEED"),
            });

            if (isPrevFormValid) {
                validationModal.setProps({
                    okClickHandler: () => {
                        psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await validationModal.showAsync();
                isPrevFormValid = false;
            }
        } */

        isPrevFormValid = await FormCmps.RadioCmp.validate(
            dropOffLocChoiceSt,
            dropOffLocChoiceDis,
            isPrevFormValid,
            validationModal,
            dropOffLocChoiceRef
        );

        isPrevFormValid = await FormCmps.RadioCmp.validate(
            collectionLocTypeSt,
            collectionLocTypeDis,
            isPrevFormValid,
            validationModal,
            collectionLocTypeRef
        );

        return isPrevFormValid;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }

        const goodsWithLoc: any[] = [];
        goodWeightArrSt.map((v, i) => {
            goodsWithLoc.push({
                goodWeight: +v.value!,
                goodHeight: +goodHeightArrSt[i].value!,
                goodLength: +goodLengthArrSt[i].value!,
                goodWidth: +goodWidthArrSt[i].value!,
                goodNote: goodNoteArrSt[i].value!,
                reqId: +pReqId!,
                destLoc: dropOffLocChoiceSt.value === "psgrLoc" ? dropOffLocationSt?.getLocationByMarker?.() : null,
                srcLoc: collectionLocTypeSt.value === "psgrLoc" ? psgrSrcColctLocSt.location : null,
                tripDuration: 0/* psgrTripEndTime */,
                distance: 0/* psgrSrcToDestDistance.distance */,
                // price: tripPriceData?.getGdsDeliveryPrice?.data?.totalPrice
            });
        });

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        await spinner.showAsync();

        const joinReqOkModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
        await getGdsDeliveryPrice({
            variables: {
                reqId: +pReqId!,
                goods: goodsWithLoc,
            },
            onCompleted: async (tripPriceData) => {
                await spinner.hideAsync();
                if (!tripPriceData?.getGdsDeliveryPrice?.success) {
                    if (tripPriceData.getGdsDeliveryPrice.errorId === "ERR_TRIP_OVERLAP") {
                        joinReqOkModal.setProps({ msg: tripPriceData.getGdsDeliveryPrice.errorDesc });
                        joinReqOkModal.showAsync();
                        return;
                    }
                    joinReqOkModal.setProps({ msg: tripPriceData.getGdsDeliveryPrice.errorDesc });
                    joinReqOkModal.showAsync();
                    return;
                }


                const goodsWithPriceAndLoc: any[] = [];
                goodWeightArrSt.map((v, i) => {
                    goodsWithPriceAndLoc.push({
                        goodWeight: +v.value!,
                        goodHeight: +goodHeightArrSt[i].value!,
                        goodLength: +goodLengthArrSt[i].value!,
                        goodWidth: +goodWidthArrSt[i].value!,
                        goodNote: goodNoteArrSt[i].value!,
                        reqId: +pReqId!,
                        destLoc: dropOffLocChoiceSt.value === "psgrLoc" ? dropOffLocationSt?.getLocationByMarker?.() : null,
                        srcLoc: collectionLocTypeSt.value === "psgrLoc" ? psgrSrcColctLocSt.location : null,
                        tripDuration: 0/* psgrTripEndTime */,
                        distance: 0/* psgrSrcToDestDistance.distance */,
                        price: tripPriceData?.getGdsDeliveryPrice?.data?.totalPrice
                    });
                });

                const priceModalOk = new ModalComp.TwoBtnsModal(
                    { isPortal: false },
                    {
                        btn1Label: ReadTextResource.getTextById("OK"),
                        btn2Label: ReadTextResource.getTextById("CANCEL"),
                        msg:
                            /* ReadTextResource.getTextById("EST_PRICE") +
                            " " +
                            tripPriceData.getGdsDeliveryPrice.data.tripPrice + */
                            ReadTextResource.getTextById("TOT_PRICE") +
                            " " +
                            tripPriceData.getGdsDeliveryPrice.data.totalPrice,
                        clickHandlerBtn1: async () => {
                            await priceModalOk.hideAsync();
                            await spinner.showAsync();

                            // psgrReservedSeatsDis({
                            //     type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
                            //     value: psgrReservedSeatsWithLoc,
                            // });

                            await psgrAddGoods({
                                variables: {
                                    reqId: +pReqId!,
                                    goods: goodsWithPriceAndLoc,
                                },
                                onCompleted: async (data) => {
                                    await spinner.hideAsync();
                                    if (data.psgrAddGoodsReq.success) {
                                        joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("REQ_SENT_SUCCES") });
                                    } else {
                                        if (data.psgrAddGoodsReq.errorId === "DUPLICATES_ERR")
                                            joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ALRDY_EXIST") });
                                        else if (data.psgrAddGoodsReq.errorId === "ERR_MAX_JOIN_REQUEST")
                                            joinReqOkModal.setProps({ msg: data.psgrAddGoodsReq.errorDesc });
                                        else if (data.psgrAddGoodsReq.errorId === "ERR_TRIP_OVERLAP")
                                            joinReqOkModal.setProps({ msg: data.psgrAddGoodsReq.errorDesc });
                                        else joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
                                    }
                                    joinReqOkModal.showAsync();
                                },
                                onError: async (err) => {
                                    await spinner.hideAsync();
                                    console.log("psgrAddGoodsReq mutation is not successfull -abd- err=", err);
                                    joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });
                                    joinReqOkModal.showAsync();
                                },
                            });
                        },
                        clickHandlerBtn2: async () => {
                            await spinner.hideAsync();
                            await priceModalOk.hideAsync();
                        },
                    }
                );
                await priceModalOk.showAsync();
            },
            onError: async (err) => {
                await spinner.hideAsync();
                console.log("getTripPrice query is not successfull -abd- err=", err);
                if (getGraphQlError(err).errorId === "ERR_LIMIT_RATE_REACHED")
                    joinReqOkModal.setProps({ msg: "لقد قمت بارسال الطلب مرات عديدة، الرجاء المحاولة لاحقا" });
                else
                    joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });

                joinReqOkModal.showAsync();
            },
        });
    }
    return (
        <>

            <StickyBar id="addGoodsMainStickBar1">
                <FormCmps.FormSubmitBtn
                    id="submitBtn"
                    label={ReadTextResource.getTextById("SAVE")}
                    onOkClickHandler={onSaveHandler}
                ></FormCmps.FormSubmitBtn>
            </StickyBar>

            <TripDtls
                reqDtlType="JoiningDrvrReq"
                // reqInfo={getReqDtlData?.searchDrvrsReqs?.dataArr[0]
                reqId={+pReqId}
            />

            <FormCmps.ContainerNoFormCmp id="psgrAddGoodsId1">
                <FormCmps.LabelComp.JsxComp id="goodSizeId1">
                    <strong> {ReadTextResource.getTextById("GOOD_SIZE")}</strong>
                </FormCmps.LabelComp.JsxComp>

                {goodWeightArrSt.map((v, i) => {
                    return (
                        <div key={"goodsItm" + i} className="row row-cols-2 gy-2 m-0 p-0 mb-4 py-3 rounded shadow">
                            <GoodWeight
                                id={"goodWeightId" + v.rdcKey}
                                key={"goodWeightKey" + v.rdcKey}
                                label={ReadTextResource.getTextById("GOOD_WGHT_KGM")}
                                inputMode="number"
                                inputRef={goodWeightRef}
                                // textRdc={[goodWeightArrSt.find((item) => item.rdcKey === v.rdcKey)!, goodWeightArrDis]}
                                textRdc={[goodWeightArrSt[i], goodWeightArrDis]}
                                regExpPattern="[0-9]*"
                                validateEmpty
                                numberMinVal="0"
                                valueTrakingOnChange={true}
                                validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                            ></GoodWeight>

                            <GoodLength
                                id={"goodLengthId1" + v.rdcKey}
                                label={ReadTextResource.getTextById("LENGTH")}
                                inputMode="number"
                                inputRef={goodLengthRef}
                                // textRdc={[goodLengthArrSt.find((item) => item.rdcKey === v.rdcKey)!, goodLengthArrDis]}
                                textRdc={[goodLengthArrSt[i], goodLengthArrDis]}
                                regExpPattern="[0-9]*"
                                // validateEmpty
                                numberMinVal="0"
                                valueTrakingOnChange={true}
                                validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                            ></GoodLength>

                            <GoodWidth
                                id={"goodWidthId" + v.rdcKey}
                                label={ReadTextResource.getTextById("WIDTH")}
                                inputMode="number"
                                inputRef={goodWidthRef}
                                textRdc={[goodWidthArrSt[i], goodWidthArrDis]}
                                regExpPattern="[0-9]*"
                                // validateEmpty
                                numberMinVal="0"
                                valueTrakingOnChange={true}
                                validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                            ></GoodWidth>

                            <GoodHeight
                                id={"goodHeightId" + v.rdcKey}
                                label={ReadTextResource.getTextById("HEIGHT")}
                                inputMode="number"
                                inputRef={goodHeightRef}
                                textRdc={[goodHeightArrSt[i], goodHeightArrDis]}
                                regExpPattern="[0-9]*"
                                // validateEmpty
                                numberMinVal="0"
                                valueTrakingOnChange={true}
                                validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                            ></GoodHeight>

                            <div className="col-12 col-lg-8 col-md-12 col-sm-12">
                                <GoodNotes
                                    id={"goodNotes" + v.rdcKey}
                                    label={ReadTextResource.getTextById("NOTE")}
                                    inputRef={goodNoteRef}
                                    textRdc={[goodNoteArrSt[i], goodNoteArrDis]}
                                    placeHolder={ReadTextResource.getTextById("GOODS_NOTE")}
                                    valueTrakingOnChange={true}
                                ></GoodNotes>
                            </div>

                            {i > 0 && (
                                <div>
                                    <button
                                        type="button"
                                        id={v.rdcKey + ""}
                                        key={"btnRemove" + v.rdcKey}
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                            console.log("button i is:", v.rdcKey);
                                            removeGoodInfoCmp(v.rdcKey!);
                                        }}
                                    >
                                        {ReadTextResource.getTextById("REMOVE")}
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })}

                <span
                    className="text-center d-flex flex-column justify-content-center"
                    onClick={() => {
                        addGoodInfoCmp();
                    }}
                >
                    {ReadTextResource.getTextById("ADD")}
                    <i className="bi bi-plus-circle-dotted fs-2 " style={{ cursor: "pointer" }}></i>
                </span>

                <div className="col-12 col-lg-12 col-md-12 col-sm-12">

                    <CollectionLocType
                        radioRdc={[collectionLocTypeSt, collectionLocTypeDis]}
                        id="collectionLocTypeStd1"
                        label={ReadTextResource.getTextById("SRC_LOC")}
                        radioDir={"horizontal"}
                        onChange={() => {
                            setTimeout(() => {
                                collectionLocTypeRef.current?.scrollIntoView({ block: "center" });
                            }, 200);
                        }}
                    >
                        <FormCmps.RadioItem
                            id="collectionLocTypeStRadio"
                            label={"من موقع انطلاق الكابتن"}
                            value="drvrLoc"
                        ></FormCmps.RadioItem>
                        <FormCmps.RadioItem
                            id="outTransRadio"
                            label={"من موقعي"}
                            value="psgrLoc"
                        ></FormCmps.RadioItem>
                    </CollectionLocType>

                    {collectionLocTypeSt.value === "psgrLoc" &&
                        <CollectionLocation
                            id="psgrCollectionLocationId1"
                            locationRdc={[psgrSrcColctLocSt, psgrSrcColctLocDis]}
                            label={ReadTextResource.getTextById("SELECT_UR_LOC") + ":"}
                            inputRef={psgrSrcColctLocRef}
                        ></CollectionLocation>
                    }

                    <DropOffLocChoice
                        radioRdc={[dropOffLocChoiceSt, dropOffLocChoiceDis]}
                        id="dropOffLocChoiceId1"
                        label={ReadTextResource.getTextById("GOODS_DEST_LOC")}
                        radioDir={"horizontal"}
                        onChange={() => {
                            setTimeout(() => {
                                dropOffLocationRef.current?.scrollIntoView({ block: "center" });
                            }, 200);
                        }}
                    >
                        <FormCmps.RadioItem
                            id="dtRadio1"
                            label={ReadTextResource.getTextById("G_DROPOFF_LAST_STOP")}
                            value="drvrLoc"
                        ></FormCmps.RadioItem>
                        <FormCmps.RadioItem
                            id="dtRadio2"
                            label={ReadTextResource.getTextById("G_I_SET_DROPOFF")}
                            value="psgrLoc"
                        ></FormCmps.RadioItem>
                    </DropOffLocChoice>

                    {dropOffLocChoiceSt.value === "psgrLoc" && (
                        <DropOffLocation
                            id="psgrDropOffLocId1"
                            locationRdc={[dropOffLocationSt, dropOffLocationStDis]}
                            label={ReadTextResource.getTextById("GOODS_DEST_LOC")}
                            inputRef={dropOffLocationRef}
                        ></DropOffLocation>
                    )}
                </div>
            </FormCmps.ContainerNoFormCmp>

        </>
    );
}
