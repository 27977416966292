import { makeVar } from "@apollo/client";
import { Auth, Company, User, UserNotification } from "../Types";

// type accessTokenT = { accessToken: string | null, userId?: string };

// export const accessTokenVar = makeVar<accessTokenT>({ accessToken: null, userId:"Not Connected" });

export const accessInfoRvar = makeVar<Auth | null>(null);

export const csrfTokenRvar = makeVar<{ csrfToken?: string }>({});

export const dirAndLangRvar = makeVar<{ direction: string; lang: string }>({
    direction: localStorage.getItem("dir") ?? "ltr",
    lang: localStorage.getItem("lang") ?? "en",
});

// export const userInfoRvar = makeVar<User>(new User());

export const refreshRvar = makeVar<Boolean>(true);

export const isNewUpdate = makeVar<Boolean>(false);

export const connectedUsersRVar = makeVar<any[]>([]);

export const receivedMsgRVar = makeVar<{ content: string; from: string }[]>([]);

export const chattingUsersRVar = makeVar<User[]>([]);

export const prevUserMsgsRVar = makeVar<any[]>([]);

export const blockOtherModalsRVar = makeVar<boolean>(false);

export const userNotificationsRVar = makeVar<UserNotification[]>([]);

export const prevUserNtnsRVar = makeVar<UserNotification[]>([]);

export const swVersion = makeVar<string>("");

// export const isNewMsg = makeVar<boolean>(false);

// refreshRoutesOnUserTypeChange
