import { FormEvent, MouseEvent, PropsWithChildren, ReactNode, Ref, RefObject, createElement, useReducer, useRef, useState } from "react";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { CountryT, SelectValueItem, SelectValueType, Waypoint } from "../../commonSrc/Types";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
// import 'react-phone-input-2/lib/material.css'
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { MuiTelInput } from "mui-tel-input";
import { Autocomplete, Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Rating } from "@mui/material";
import { ModalComp } from "../commonComp/ModalComp";
import { MobileDateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { Button } from "./Button";
import GoogleMapCmp, { clearMarkers, Location, MapActionT, MapDisT, MapLocActDisTypesT, MapStT } from "./GoogleMapCmp";
import { ButtonGroup } from "./ButtonGroup";
import { getDeviceCurrentLocation } from "../../commonSrc/commonFns";
import { parseISO, startOfDay } from "date-fns";
import { countries, pinDarkBlue1 } from "../../commonSrc/Constants";
import GoogleMapDirCmp from "./GoogleMapDirCmp";
import { useNavigate } from "react-router-dom";

/**
 * JsxComp function is the compoenent that's used as a regualr react functional component in tsx files (web pages)
 * emailActDisTypesT enum is used to determine the dispatcher type
 * helperFns class contains helper functions to create Ref, create reducer and get the component current value
 * getComp function is used as a warpper to get all necessary code for a component all in one call, the JsxComp, ref and reducer
 */
export namespace FormCmps {
    // type InputType={
    //     input:"input"| "password" | "email"

    // }
    export function Form(
        props: PropsWithChildren<{
            id: string;
            submitCancelBtns?: ReactNode;
            visible?: boolean;
            onSubmitHandler?: (event: FormEvent<HTMLFormElement>) => void;
            onInvalid?: (e: FormEvent<HTMLFormElement>) => void;
        }>
    ) {
        // if (props.visible == undefined) props.visible = true;
        return (
            <form
                id={props.id}
                className={`container-fluid pt-1 ${props.visible == undefined ? "" : props.visible ? "" : "d-none"}`}
                onInvalid={props.onInvalid}
                onSubmit={props.onSubmitHandler}
            >
                <div className={`pb-3 row sticky-top ${props.submitCancelBtns ? "" : "d-none"}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    style={{
                                        // backgroundColor: "aliceblue",
                                        backgroundColor: "white",
                                    }}
                                    className="border-bottom d-flex justify-content-center justify-content-md-start justify-content-sm-center ps-1 py-2 rounded  mb-3"
                                >
                                    {props.submitCancelBtns}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" gy-4 row row-cols-12 row-cols-lg-3 row-cols-sm-2">{props.children}</div>
            </form>
        );
    }

    export function ContainerNoFormCmp(
        props: PropsWithChildren<{
            id: string;
            submitCancelBtns?: ReactNode;
            visible?: boolean;
            onSubmitHandler?: (event: FormEvent<HTMLFormElement>) => void;
            onInvalid?: (e: FormEvent<HTMLFormElement>) => void;
        }>
    ) {
        // if (props.visible == undefined) props.visible = true;
        return (
            <div
                key={"ContainerNoForm" + props.id}
                id={props.id}
                className={`container-fluid pt-1 ${props.visible == undefined ? "" : props.visible ? "" : "d-none"}`}
            // onInvalid={props.onInvalid}
            // onSubmit={props.onSubmitHandler}
            >
                <div className={`pb-3 row sticky-top ${props.submitCancelBtns ? "" : "d-none"}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    style={{
                                        // backgroundColor: "aliceblue",
                                        backgroundColor: "white",
                                    }}
                                    className="border-bottom d-flex justify-content-center justify-content-md-start justify-content-sm-center ps-1 py-2 rounded  mb-3"
                                >
                                    {props.submitCancelBtns}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" gy-4 row row-cols-12 row-cols-lg-3 row-cols-sm-2">{props.children}</div>
            </div>
        );
    }

    export namespace Phone {
        export enum phoneActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }

        type phoneStT = { value?: string; errorMsg?: string };

        type phoneActionT = { type: phoneActDisTypesT } & phoneStT;

        type phoneDisT = (action: phoneActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | phoneStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () => {
                return useReducer(
                    (state: { value?: string; errorMsg?: string }, action: phoneActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
            };
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                phoneRdc?: [phoneStT, phoneDisT];
                defaultValue?: string;
                onChangeFn?: () => void;
                validationsMsg?: string;
                inputRef?: RefObject<HTMLInputElement>;
                valueTrakingOnChange?: boolean;
                required?: boolean;
                options?: any;
            }>
        ): JSX.Element {
            const [value, setValue] = useState(props.defaultValue);
            let phoneSt: phoneStT = {};
            let phoneDis: phoneDisT;

            if (props.phoneRdc) {
                [phoneSt, phoneDis] = props.phoneRdc;
            }
            return (
                <div className="">
                    <div className="">
                        {/* <label className="form-label" htmlFor={props.id}>
                            {props.label}
                        </label> */}
                        <div dir="ltr">
                            <MuiTelInput
                                defaultCountry="JO"
                                label={props.label}
                                value={phoneSt.value}
                                error={!!phoneSt?.errorMsg}
                                helperText={phoneSt?.errorMsg}
                                inputRef={props.inputRef}
                                required={props.required}
                                onChange={(val, e) => {
                                    console.log("AAAAAA##", val, e);
                                    // setValue(val);
                                    // if (props.valueTrakingOnChange)
                                    phoneDis({ type: phoneActDisTypesT.ValueInput, value: val });
                                    // if (!e.currentTarget.validity.patternMismatch && phoneSt.errorMsg) {
                                    //     phoneDis({ type: phoneActDisTypesT.ErrorMsg, errorMsg: "" });
                                    // }
                                    // (props.inputRef as any).current.value=val;
                                }}
                                // inputProps={{ defaultValue: value }}
                                key={props.label}
                                className="w-100"
                                onBlur={(e) => {
                                    if (typeof phoneDis === "undefined") {
                                        console.warn("the phone component is required, you need to add a reducer");
                                    }
                                    console.log("e.currentTarget.value", e.currentTarget.value);
                                    if (props.required && e.currentTarget.value.length < 5) {
                                        console.log("zzzzzzzzzzz");
                                        phoneDis({ type: phoneActDisTypesT.ErrorMsg, errorMsg: "Please enter phone number" });
                                    }
                                    // if (e.currentTarget.validity.patternMismatch || e.currentTarget.value.length == 0) {
                                    //     phoneDis({ type: phoneActDisTypesT.ErrorMsg, errorMsg: "Phone is not correct" });
                                    // }
                                    else {
                                        console.log("LLLLLLLL");
                                        phoneDis({ type: phoneActDisTypesT.ErrorMsg, errorMsg: "" });
                                    }
                                }}
                            />

                            {/* <PhoneInput
                                inputProps={{ ref: props.inputRef }}
                                onChange={props.onChangeFn}
                                key={props.label}
                                inputClass="w-100 py-3"
                                // inputStyle={{color:"blue"}}
                                specialLabel={props.label}
                                placeholder={props.label}
                                country="jo"
                                value={props.defaultValue}
                            /> */}
                        </div>
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    /**
     * This compoenet can get the current value by its ref.
     * This component can keep track of value change by state if the prop.valueTrakingOnChange=true, it' recommended
     * to keep this prop as false when you have fast heavy input typing
     */
    export namespace Email {
        export enum emailActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }

        type emailStT = { value?: string; errorMsg?: string };
        type emailActionT = { type: emailActDisTypesT } & emailStT;
        type emailDisT = (action: emailActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | emailStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of Email");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);
            static createReducer = () => {
                return useReducer(
                    (state: { value?: string; errorMsg?: string }, action: emailActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
            };
        }

        /**
         *
         * @param props
         * props.valueTrakingOnChange when true, it will update the state on every new input, if false
         * then the component will be updated only onBlur, you can use the ref to get the current value.
         * @returns JSX component that can be used as a regular react functional componennt in tsx file
         */
        export const JsxComp = (
            props: PropsWithChildren<{
                id: string;
                label: string;
                defaultValue?: string;
                required?: boolean;
                validationsMsg?: string;
                inputRef?: RefObject<HTMLInputElement>;
                emailRdc?: [emailStT, (action: emailActionT) => void];
                valueTrakingOnChange?: boolean;
                options?: any;
            }>
        ) => {
            let emailSt: emailStT = {};
            let emailDis: emailDisT;

            if (props.emailRdc) {
                [emailSt, emailDis] = props.emailRdc;
            }

            return (
                <div className="">
                    <div className="">
                        <TextField
                            variant="outlined"
                            error={!!emailSt?.errorMsg}
                            helperText={emailSt?.errorMsg}
                            defaultValue={props.defaultValue}
                            id={props.id}
                            key={props.label}
                            className="w-100"
                            label={props.label}
                            type="email"
                            required={props.required ? true : false}
                            inputProps={{ pattern: /^.+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/.source }}
                            inputRef={props.inputRef}
                            onChange={(e) => {
                                if (props.valueTrakingOnChange) {
                                    emailDis({ type: emailActDisTypesT.ValueInput, value: e.currentTarget.value });
                                    if (e.currentTarget.validity.patternMismatch || e.currentTarget.value.length == 0) {
                                        console.log("Mismatch");
                                        emailDis({ type: emailActDisTypesT.ErrorMsg, errorMsg: "Email is not correct" });
                                    }
                                }
                                if (!e.currentTarget.validity.patternMismatch && emailSt.errorMsg) {
                                    emailDis({ type: emailActDisTypesT.ErrorMsg, errorMsg: "" });
                                }
                            }}
                            onBlur={(e) => {
                                if (e.currentTarget.validity.patternMismatch || e.currentTarget.value.length == 0) {
                                    console.log("Mismatch");
                                    emailDis({ type: emailActDisTypesT.ErrorMsg, errorMsg: "Email is not correct" });
                                } else {
                                    console.log("Matched");
                                    emailDis({ type: emailActDisTypesT.ErrorMsg, errorMsg: "" });
                                }
                            }}
                        />
                    </div>
                </div>
            );
        };

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    /**
     * This component cannot get the current value by its ref, use getValue function to know which params you need to get the current value,
     * usually they are either Ref or State
     * This component can get current value by reducer object
     */
    export namespace RadioCmp {
        export enum radioActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type radioStT = { value?: string; errorMsg?: string };

        type radioActionT = { type: radioActDisTypesT } & radioStT;

        type radioDisT = (action: radioActionT) => void;

        export const getValue = (pRadioSt: radioStT) => pRadioSt.value;

        export class helperFns {
            static createRef = () => useRef<HTMLDivElement>(null);

            static createReducer = (initVal?: string) =>
                useReducer(
                    (state: radioStT, action: radioActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: initVal, errorMsg: "" }
                );
        }

        export async function validate(
            pRadioSt: radioStT,
            pRadioDis: radioDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">> | null | undefined,
            pRadioRef: React.RefObject<HTMLDivElement>,
            pErrorMsg?: string
        ) {
            if (!FormCmps.RadioCmp.getValue(pRadioSt)) {
                pRadioDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ErrorMsg, errorMsg: pErrorMsg ? pErrorMsg : "select a value" });
                if (pIsPrevFormValid) {
                    const g = pOkModalInstance?.getProps().okClickHandler;
                    pOkModalInstance?.setProps({
                        okClickHandler: () => {
                            console.log("showing pOkModalInstance.getProps().okClickHandler", g);
                            g?.();

                            pRadioRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(pOkModalInstance);//updated and not tested
                    await pOkModalInstance?.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                radioRdc: [radioStT, radioDisT];
                radioDir?: string;
                onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
                validationsMsg?: string;
                required?: boolean;
                inputRef?: Ref<any>;
                options?: any;
            }>
        ) {
            // const [value, setValue] = props.valueState;
            let radioSt: radioStT = {};
            let radioDis: radioDisT;

            if (props.radioRdc) {
                [radioSt, radioDis] = props.radioRdc;
            }
            /**
             * this is used to refresh the component in case you use it in Modal component
             * outside the component where you defined reducer, where modal doens't save the state becuase it's not a portal.
             * this issue was solved by using portal modal to save state
             */
            // const [value, setValue] = useState(radioSt.value ?? "");
            return (
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <div ref={props.inputRef} className="">
                        <FormControl error={!!radioSt?.errorMsg}>
                            <FormLabel
                                /* style={{ color: "blue" }} use this when you want to change the color*/ id="demo-controlled-radio-buttons-group"
                            >
                                {props.label}
                            </FormLabel>
                            <RadioGroup
                                row={props.radioDir == "horizontal"}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={radioSt.value ?? ""}
                                onChange={(c: any, v: any) => {
                                    console.log("onChange is executed for RadioButton value:", v);
                                    radioDis({ type: radioActDisTypesT.ValueInput, value: v });
                                    // setValue(v);
                                    if (v) radioDis({ type: radioActDisTypesT.ErrorMsg, errorMsg: "" });

                                    props.onChange?.(c, v);
                                }}
                            >
                                {props.children}
                            </RadioGroup>
                            <FormHelperText>{radioSt.errorMsg}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
            );
        }
        export const getComp = (initVal?: string) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initVal),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace Passrd {
        export enum passrdActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type PassrdStT = { value?: string; errorMsg?: string };

        type passrdActionT = { type: passrdActDisTypesT } & PassrdStT;

        type PassrdDisT = (action: passrdActionT) => void;

        // export function getValue (pValueContainer: RefObject<HTMLInputElement>):string;
        // export function getValue (pValueContainer: PassrdStT):;
        export function getValue(pValueContainer: RefObject<HTMLInputElement> | PassrdStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of Passrd");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: PassrdStT, action: passrdActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                passrdRdc?: [PassrdStT, PassrdDisT];
                required?: boolean;
                validationsMsg?: string;
                inputRef?: RefObject<HTMLInputElement>;
                valueTrakingOnChange?: boolean;
                passToConfirm?: string;
                options?: any;
            }>
        ) {
            let passrdSt: PassrdStT = {};
            let passrdDis: PassrdDisT;

            if (props.passrdRdc) {
                [passrdSt, passrdDis] = props.passrdRdc;
            }
            return (
                <div id={`${props.id}Container`} className="">
                    {/* <Tooltip title={props.options?.showPassTooltip ? props.validationsMsg : ""}> */}
                    <TextField
                        className="w-100"
                        error={!!passrdSt?.errorMsg}
                        helperText={passrdSt?.errorMsg}
                        variant="outlined"
                        key={props.label}
                        id={props.id}
                        label={props.label}
                        type="password"
                        autoComplete={props.options?.autoComplete}
                        required={props.required ? true : false}
                        inputProps={{ pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+\-]).{8,30}$/.source }}
                        inputRef={props.inputRef}
                        onChange={(e) => {
                            if (props.valueTrakingOnChange)
                                passrdDis({ type: passrdActDisTypesT.ValueInput, value: e.currentTarget.value });
                            if (!e.currentTarget.validity.patternMismatch && passrdSt.errorMsg) {
                                passrdDis({ type: passrdActDisTypesT.ErrorMsg, errorMsg: "" });
                            }
                        }}
                        onBlur={(e) => {
                            console.log("props.passToConfirm", props.passToConfirm);
                            if (e.currentTarget.validity.patternMismatch || e.currentTarget.value.length == 0) {
                                console.log("Mismatch");
                                passrdDis({
                                    type: passrdActDisTypesT.ErrorMsg,
                                    errorMsg:
                                        "Password should be at least 8 characters with at least 1 number, 1 capital letter, 1 small letter and 1 special character",
                                });
                            } else if (props.passToConfirm) {
                                if (props.passToConfirm !== e.currentTarget.value)
                                    passrdDis({ type: passrdActDisTypesT.ErrorMsg, errorMsg: "Password Mismatch" });
                            } else {
                                console.log("Matched");
                                passrdDis({ type: passrdActDisTypesT.ErrorMsg, errorMsg: "" });
                            }
                        }}
                    />
                    {/* </Tooltip> */}
                </div>
            );
            {
                /* <div className="">
                        <div id={`${props.id}Container`} className="">
                            <label className="form-label" htmlFor={props.id}>
                                {props.label}
                            </label>
                            <input
                                key={props.label}
                                ref={props.inputRef}
                                className="form-control"
                                type="password"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$"
                                size={30}
                                required={props.required ? true : false}
                                id={props.id}
                                autoComplete={props.options?.autoComplete}
                                placeholder={props.label}
                                title={ReadTextResource.getTextById(props.validationsMsgRscId ?? "")}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-container={`#${props.id}Container`}
                                data-bs-title={ReadTextResource.getTextById(props.validationsMsgRscId ?? "")}
                                onFocus={(e) => {
                                    // console.log("showing the tooltip=",Tooltip.getOrCreateInstance(document.getElementById(props.id) as Element ));
                                    if (props.options?.showPassTooltip)
                                        Tooltip.getOrCreateInstance(document.getElementById(props.id) as Element)?.show();
                                }}
                                onChange={(e) => {
                                    if (e.currentTarget.validity.patternMismatch)
                                        e.currentTarget.setCustomValidity(ReadTextResource.getTextById(props.validationsMsgRscId ?? ""));
                                    else e.currentTarget.setCustomValidity("");
                                }}
                            />
                            {props.options?.showPassHint === true ? (
                                <p style={{ fontSize: ".8em" }} className="text-danger">
                                    {ReadTextResource.getTextById(props.validationsMsgRscId ?? "")}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div> */
            }
        }
        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    export function RadioItem(
        props: PropsWithChildren<{
            id: string;
            label: string;
            checked?: boolean;
            radioDir?: string;
            validationsMsg?: string;
            required?: boolean;
            value?: string;
            inputRef?: Ref<any>;
            options?: any;
        }>
    ) {
        return <FormControlLabel value={props.value} control={<Radio required={props.required} />} label={props.label} />;
    }

    /**
     * This compoenet can get the current value by its ref.
     * This component can keep track of value change by state if the prop.valueTrakingOnChange=true, it recommended
     * to keep this prop as false when you have fast heavy input typing
     */
    export namespace TextCmp {
        export enum textActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
            Remove = "REMOVE",
        }

        type textStT = { rdcKey?: number; value?: string; errorMsg?: string };

        type textdActionT = { type: textActDisTypesT } & textStT;

        type textDisT = (action: textdActionT) => void;

        //

        // type textStArrItemT = { rdcKey?: number; value?: string; errorMsg?: string };

        type textStArrT = [textStT];

        // type textdActionArrT = { type: textActDisTypesT } & textStT;

        type textDisArrT = (action: textStArrT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | textStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TextCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: textStT, action: textdActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );

            static createReducers = () =>
                useReducer(
                    (state: textStArrT, action: textdActionT) => {
                        let found: boolean = false;
                        if (action.type === textActDisTypesT.Remove) {
                            const newStateAfterRemove = state.filter((v) => v.rdcKey !== action.rdcKey);
                            return [...newStateAfterRemove] as textStArrT;
                        }

                        //if rdcKey exist, then update the value, else a new rdcKey will be added below
                        state.map((v) => {
                            if (v.rdcKey === action.rdcKey) {
                                found = true;
                                if (action.type === textActDisTypesT.ValueInput) v.value = action.value;
                                else if (action.type === textActDisTypesT.ErrorMsg) v.errorMsg = action.errorMsg;
                            }
                        });

                        //if rdcKey not exist, then add new one
                        if (!found) state.push({ rdcKey: action.rdcKey, value: action.value, errorMsg: action.errorMsg });

                        const ggg = [...state];
                        console.log("ggg", ggg);

                        return [...state] as textStArrT;
                    },
                    [{ rdcKey: 0, value: "", errorMsg: "" }]
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                readonly?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                textRdc?: [textStT, textDisT];
                valueTrakingOnChange?: boolean;
                validateOnBlur?: () => void;
                onBlur?: (e: any) => void;
                regExpPattern?: string;
                validateEmpty?: boolean;
                inputMode?: "number" | "input";
                numberMinVal?: string;
                placeHolder?: string;
                options?: any;
            }>
        ) {
            let textSt: textStT = {};
            let textDis: textDisT;

            if (props.textRdc) {
                [textSt, textDis] = props.textRdc;
            }
            return (
                <div key={props.id + "txt1"} className="">
                    <div key={props.id + "txt2"} className="">
                        <TextField
                            key={props.id + "txt3"}
                            variant="outlined"
                            itemType=""
                            // disabled
                            className="w-100"
                            {...(props.valueTrakingOnChange && { value: textSt?.value })}
                            // {props.valueTrakingOnChange && value={textSt.value}}
                            error={!!textSt?.errorMsg}
                            helperText={textSt?.errorMsg}
                            // key={props.label}
                            id={props.id}
                            label={props.label}
                            placeholder={props.placeHolder}
                            type={props.inputMode ?? "input"}
                            // defaultValue={props.defaultValue}
                            required={props.required ? true : false}
                            inputRef={props.inputRef}
                            inputProps={{
                                pattern: props.regExpPattern,
                                min: props.numberMinVal ?? "-99999999999",
                                readOnly: props.readonly ?? false,
                            }}
                            onChange={(e) => {
                                if (props.valueTrakingOnChange)
                                    textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ValueInput, value: e.currentTarget.value });
                                if (props.regExpPattern && !e.currentTarget.validity.patternMismatch && textSt.errorMsg) {
                                    textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: "" });
                                }
                                if (props.inputMode === "number" && e.currentTarget.validity.rangeUnderflow)
                                    textDis({
                                        rdcKey: textSt.rdcKey,
                                        type: textActDisTypesT.ErrorMsg,
                                        errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                                    });
                            }}
                            onBlur={(e) => {
                                try {
                                    props.onBlur?.(e);
                                    // stickyRef.current?.focus();
                                    //for test virtual keyboard on browser to prevent auto scrolling
                                    // e.preventDefault();
                                    if (props.inputMode === "number" && e.currentTarget.validity.rangeUnderflow) {
                                        textDis({
                                            rdcKey: textSt.rdcKey,
                                            type: textActDisTypesT.ErrorMsg,
                                            errorMsg: ReadTextResource.getTextById("ENTER_CORR_NUM"),
                                        });
                                        return;
                                    }

                                    if (props.validateEmpty && e.currentTarget.value.length == 0) {
                                        textDis({
                                            rdcKey: textSt.rdcKey,
                                            type: textActDisTypesT.ErrorMsg,
                                            errorMsg: "Please fill above value",
                                        });
                                        return;
                                    }
                                    if (props.regExpPattern && e.currentTarget.validity.patternMismatch) {
                                        textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: props.validationsMsg });
                                        return;
                                    } else if (props.regExpPattern || props.validateEmpty) {
                                        textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: "" });
                                        return;
                                    }
                                } catch (err) {
                                    console.warn(
                                        "when the props validateEmpty is true, then you need to assign an Rdc as props to TextCmp"
                                    );
                                    throw err;
                                }
                            }}
                        />
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compReducerArr: helperFns.createReducers(),
                compRef: helperFns.createRef(),
            };
        };
    }


    export namespace TextAreaCmp {
        export enum textActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
            Remove = "REMOVE",
        }

        type textStT = { rdcIdx?: number; rdcKey?: number; value?: string; errorMsg?: string };

        type textdActionT = { type: textActDisTypesT } & textStT;

        type textDisT = (action: textdActionT) => void;

        //

        // type textStArrItemT = { rdcKey?: number; value?: string; errorMsg?: string };

        type textStArrT = [textStT];

        // type textdActionArrT = { type: textActDisTypesT } & textStT;

        type textDisArrT = (action: textStArrT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | textStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TextCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: textStT, action: textdActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );

            /**
             * to set the initial value of the reducer at index 0, you need to use rdcIdx in your dispatch
             * @returns array of reducers
             */
            static createReducers = () =>
                useReducer(
                    (state: textStArrT, action: textdActionT) => {
                        let found: boolean = false;
                        if (action.type === textActDisTypesT.Remove) {
                            const newStateAfterRemove = state.filter((v) => v.rdcKey !== action.rdcKey);
                            return [...newStateAfterRemove] as textStArrT;
                        }

                        //if rdcKey exist, then update the value, else a new rdcKey will be added below
                        state.map((v) => {
                            if (v.rdcKey === action.rdcKey || v.rdcIdx === action.rdcIdx) {
                                found = true;
                                if (action.type === textActDisTypesT.ValueInput) {
                                    v.value = action.value;
                                    v.rdcKey = action.rdcKey
                                }
                                else if (action.type === textActDisTypesT.ErrorMsg) v.errorMsg = action.errorMsg;
                            }
                        });

                        //if rdcKey not exist, then add new one
                        if (!found) state.push({ rdcIdx: action.rdcIdx, rdcKey: action.rdcKey, value: action.value, errorMsg: action.errorMsg });

                        // const ggg = [...state];
                        // console.log("ggg", ggg);

                        return [...state] as textStArrT;
                    },
                    [{ rdcIdx: 0, rdcKey: 0, value: "", errorMsg: "" }]
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                readonly?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                textRdc?: [textStT, textDisT];
                valueTrakingOnChange?: boolean;
                validateOnBlur?: () => void;
                onBlur?: (e: any) => void;
                regExpPattern?: string;
                validateEmpty?: boolean;
                placeHolder?: string;
            }>
        ) {
            let textSt: textStT = {};
            let textDis: textDisT;

            if (props.textRdc) {
                [textSt, textDis] = props.textRdc;
            }
            return (
                <div key={props.id + "txt1"} className="">
                    <div key={props.id + "txt2"} className="">
                        <TextareaAutosize
                            key={props.id + "txt3"}
                            className="w-100"
                            maxRows={3}
                            minRows={2}
                            {...(props.valueTrakingOnChange && { value: textSt?.value })}
                            // {props.valueTrakingOnChange && value={textSt.value}}
                            id={props.id}
                            placeholder={props.placeHolder}
                            required={props.required ? true : false}
                            onChange={(e) => {
                                if (props.valueTrakingOnChange)
                                    textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ValueInput, value: e.currentTarget.value });
                                if (props.regExpPattern && !e.currentTarget.validity.patternMismatch && textSt.errorMsg) {
                                    textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: "" });
                                }
                            }}
                            onBlur={(e) => {
                                try {
                                    props.onBlur?.(e);
                                    if (props.validateEmpty && e.currentTarget.value.length == 0) {
                                        textDis({
                                            rdcKey: textSt.rdcKey,
                                            type: textActDisTypesT.ErrorMsg,
                                            errorMsg: "Please fill above value",
                                        });
                                        return;
                                    }
                                    if (props.regExpPattern && e.currentTarget.validity.patternMismatch) {
                                        textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: props.validationsMsg });
                                        return;
                                    } else if (props.regExpPattern || props.validateEmpty) {
                                        textDis({ rdcKey: textSt.rdcKey, type: textActDisTypesT.ErrorMsg, errorMsg: "" });
                                        return;
                                    }
                                } catch (err) {
                                    console.warn(
                                        "when the props validateEmpty is true, then you need to assign an Rdc as props to TextCmp"
                                    );
                                    throw err;
                                }
                            }}
                        />
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compReducerArr: helperFns.createReducers(),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace UploadCmp {
        export enum uploadActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type uploadStT = { value?: string; errorMsg?: string };

        type uploadActionT = { type: uploadActDisTypesT } & uploadStT;

        type uploadDisT = (action: uploadActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | uploadStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: uploadStT, action: uploadActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" } as uploadStT
                );
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                uploadRdc?: [uploadStT, uploadDisT];
                defaultValue?: string;
                required?: boolean;
                validateEmpty?: boolean;
                validationsMsg?: string;
                inputRef?: Ref<HTMLInputElement>;
                options?: any;
            }>
        ) {
            let uploadSt: uploadStT = { errorMsg: "", value: "" };
            let uploadDis: uploadDisT;

            if (props.uploadRdc) {
                [uploadSt, uploadDis] = props.uploadRdc;
            }

            return (
                <div className="">
                    <div className="">
                        <div className="">
                            <div className="">
                                <TextField
                                    id={props.id}
                                    error={!!uploadSt.errorMsg}
                                    helperText={uploadSt.errorMsg}
                                    variant="outlined"
                                    key={props.label}
                                    defaultValue={props.defaultValue}
                                    className="w-100"
                                    label={props.label}
                                    type="file"
                                    required={props.required ? true : false}
                                    inputProps={{
                                        accept: "image/png, image/jpeg",
                                    }}
                                    inputRef={props.inputRef}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    /* onClick={async (e) => {
                                        (e.currentTarget as HTMLInputElement).value = "";
                                        // e.currentTarget.value= "VV";
                                        console.log("onclick register focus", e);
                                        // e.currentTarget.value="XXX";
                                        e.currentTarget.addEventListener("focus", focusEventLsnr, { once: true });
                                    }} */
                                    onChange={async (e) => {
                                        try {
                                            if (props.validateEmpty)
                                                uploadDis({ type: uploadActDisTypesT.ValueInput, value: e.currentTarget.value });
                                        } catch (err) {
                                            console.warn(
                                                "when the props validateEmpty is true, then you need to assign an Rdc as props to UploadCmp"
                                            );
                                            throw err;
                                        }
                                        if (props.validateEmpty && e.currentTarget.value.length != 0) {
                                            uploadDis({
                                                type: uploadActDisTypesT.ErrorMsg,
                                                errorMsg: "",
                                            });
                                        }
                                    }}
                                    onBlur={(e) => {
                                        try {
                                            if (props.validateEmpty && e.currentTarget.value.length == 0) {
                                                uploadDis({
                                                    type: uploadActDisTypesT.ErrorMsg,
                                                    errorMsg: "This field is required",
                                                });
                                            } else if (props.validateEmpty) {
                                                uploadDis({
                                                    type: uploadActDisTypesT.ErrorMsg,
                                                    errorMsg: "",
                                                });
                                            }
                                        } catch (err) {
                                            console.warn(
                                                "when the props validateEmpty is true, then you need to assign an Rdc as props to UploadCmp"
                                            );
                                            throw err;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    /**
     * This component can get its value by its ref
     * This component always updates the state value (reducer)
     */
    export namespace AutoCompleteCmp {
        export enum autoCompleteActDisTypesEnum {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        // type countryStT = { value?: { code: string; label: string; phone: string; suggested?: boolean }; errorMsg?: string };
        type autoCompleteStT = { value?: any; errorMsg?: string };

        type autoCompleteActionT = { type: autoCompleteActDisTypesEnum } & autoCompleteStT;

        type autoCompleteDisT = (action: autoCompleteActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | autoCompleteStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: autoCompleteStT, action: autoCompleteActionT) => {
                        if (action.type === autoCompleteActDisTypesEnum.ValueInput) return { ...state, value: action.value };
                        if (action.type === autoCompleteActDisTypesEnum.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" } //inital value
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                autoCompleteRdc: [autoCompleteStT, autoCompleteDisT];
                inputOptions?: any;
                optionLable?: (option: any) => string;
                validationsMsg?: string;
                required?: boolean;
                validateEmpty?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                options?: any;
                onChange?: (currentVal: any) => void;
            }>
        ) {
            let autoCompleteSt: autoCompleteStT = { errorMsg: "", value: "" };
            let autoCompleteDis: autoCompleteDisT;

            if (props.autoCompleteRdc) {
                [autoCompleteSt, autoCompleteDis] = props.autoCompleteRdc;
            }
            // const [value, setValue] = props.valueState;

            return (
                <div className="">
                    <div className="">
                        <Autocomplete
                            disablePortal={false}
                            id={props.id}
                            options={props.inputOptions}
                            // defaultValue={props.defaultValue ?? null}
                            value={autoCompleteSt?.value}
                            isOptionEqualToValue={(option, val) => {
                                return true;
                            }}
                            getOptionLabel={
                                props.optionLable ||
                                ((option: any) => {
                                    return option?.desc ?? "No Label";
                                    // return "No Options";
                                })
                            }
                            onChange={(event: any, val: any) => {
                                console.log("val##=", val);
                                // props.inputRef?["valueId"]=val;
                                // setValue2(val);
                                autoCompleteDis({ type: autoCompleteActDisTypesEnum.ValueInput, value: val });
                                if (props.validateEmpty && val?.length != 0) {
                                    autoCompleteDis({
                                        type: autoCompleteActDisTypesEnum.ErrorMsg,
                                        errorMsg: "",
                                    });
                                }
                                props.onChange?.(val);
                            }}
                            // ref={props.inputRef}
                            // sx={{ width: 300 }}
                            className="w-100"
                            renderInput={(params) => (
                                //  <TextField style={{margin:0 }} {...params} label={ReadTextResource.getTextById("ACCOUNT_CATERGOY")} />
                                <TextField
                                    {...params}
                                    error={!!autoCompleteSt.errorMsg}
                                    helperText={autoCompleteSt.errorMsg}
                                    variant="outlined"
                                    className="w-100"
                                    key={props.label}
                                    id={`${props.id}InTxt`}
                                    label={props.label}
                                    type="input"
                                    required={props.required ? true : false}
                                    inputRef={props.inputRef}
                                    onChange={(e) => {
                                        if (props.validateEmpty && e.currentTarget.value.length != 0) {
                                            autoCompleteDis({
                                                type: autoCompleteActDisTypesEnum.ErrorMsg,
                                                errorMsg: "",
                                            });
                                        }
                                    }}
                                    onBlur={(e) => {
                                        try {
                                            if (props.validateEmpty && e.currentTarget.value.length == 0) {
                                                autoCompleteDis({
                                                    type: autoCompleteActDisTypesEnum.ErrorMsg,
                                                    errorMsg: "Please fill above value",
                                                });
                                            } else {
                                                autoCompleteDis({
                                                    type: autoCompleteActDisTypesEnum.ErrorMsg,
                                                    errorMsg: "",
                                                });
                                            }
                                        } catch (err) {
                                            console.warn(
                                                "when the props validateEmpty is true, then you need to assign an Rdc as props to TextCmp"
                                            );
                                            throw err;
                                        }
                                    }}
                                />
                            )}
                        ></Autocomplete>
                    </div>
                </div>
            );
        }

        export async function validate(
            pFromCityAutoCompleteRef: React.RefObject<HTMLInputElement>,
            pFromCityAutoCompleteSt: autoCompleteStT,
            pFromCityAutoCompleteDis: autoCompleteDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">>,
            pErrorMsg?: string
        ) {
            if (!FormCmps.AutoCompleteCmp.getValue(pFromCityAutoCompleteSt)) {
                pFromCityAutoCompleteDis({
                    type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ErrorMsg,
                    errorMsg: pErrorMsg ? pErrorMsg : "Please select value",
                });
                if (pIsPrevFormValid) {
                    pOkModalInstance.setProps({
                        okClickHandler: () => {
                            pFromCityAutoCompleteRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(pOkModalInstance);
                    await pOkModalInstance.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace SelectCmp {
        export enum selectActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type selectStT = { value?: string; errorMsg?: string };

        type selectDisActionT = { type: selectActDisTypesT } & selectStT;

        type selectDisT = (action: selectDisActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | selectStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: selectStT, action: selectDisActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                selectRdc: [selectStT, selectDisT];
                label: string;
                selectValues: SelectValueType;
                className?: string;
                defaultValue?: string;
                required?: boolean;
                validateEmpty?: boolean;
                validationsMsg?: string;
                inputRef?: Ref<any>;
                onChange?: (val: SelectChangeEvent<any>) => void;
                options?: any;
            }>
        ) {
            let selectSt: selectStT = { errorMsg: "", value: "" };
            let selectDis: selectDisT;

            if (props.selectRdc) {
                [selectSt, selectDis] = props.selectRdc;
            }

            let selecValues: SelectValueType = props.selectValues;

            if (props.selectValues) selecValues = props.selectValues as SelectValueType;
            else console.warn("No values provided to FromComp.FormInput component, abd user-warn ID 0001");

            const handleChange = (event: SelectChangeEvent) => {
                if (props.selectRdc) {
                    selectDis({ type: selectActDisTypesT.ValueInput, value: event.target.value as string });
                }
            };

            return (
                <div className={`${props.className}`}>
                    <div className="">
                        <FormControl className="w-100" error={!!selectSt.errorMsg}>
                            <InputLabel id={props.id}>{props.label}</InputLabel>
                            <Select
                                variant="outlined"
                                error={!!selectSt.errorMsg}
                                defaultValue={props.defaultValue?.toUpperCase() ?? ""}
                                //  value={selectedVal}
                                {...(props.selectRdc && { value: selectSt.value })}
                                defaultChecked={true}
                                labelId={props.label}
                                id={props.id}
                                inputRef={props.inputRef}
                                required={props.required}
                                label={props.label}
                                onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.value) selectDis({ type: selectActDisTypesT.ErrorMsg, errorMsg: "" });
                                }}
                                onBlur={(e) => {
                                    console.log("selectSt.errorMsg", selectSt.errorMsg);
                                    if (typeof selectDis === "undefined") {
                                        console.warn("the SelectCmp component is required, you need to add a reducer");
                                    }
                                    if ((props.required || props.validateEmpty) && selectSt.value?.length === 0) {
                                        selectDis({ type: selectActDisTypesT.ErrorMsg, errorMsg: "Please select an option" });
                                    } else {
                                        selectDis({ type: selectActDisTypesT.ErrorMsg, errorMsg: "" });
                                    }
                                }}
                            // placeholder={"Please select a value"}
                            // value={props.defaultValue?.toUpperCase() ?? ""}
                            >
                                {/* <MenuItem
                                    hidden
                                    key={ReadTextResource.getTextById("SELECT_AN_OPTION")}
                                    // value={ReadTextResource.getTextById("SELECT_AN_OPTION")}
                                >
                                    {ReadTextResource.getTextById("SELECT_AN_OPTION")}
                                </MenuItem> */}
                                {selecValues.map((item: SelectValueItem) => {
                                    return (
                                        <MenuItem className="text-wrap" key={item?.valueKey} value={item.valueKey}>
                                            {ReadTextResource.getTextById(item?.selectLableAndId as any) || item.selectLableAndId}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>{selectSt.errorMsg}</FormHelperText>
                        </FormControl>
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };

        export async function validate(
            pSelectSt: selectStT,
            pSelectDis: selectDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">> | undefined,
            pSelectRef: React.RefObject<HTMLDivElement>,
            pErrorMsg?: string
        ) {
            if (!FormCmps.RadioCmp.getValue(pSelectSt)) {
                pSelectDis({ type: FormCmps.SelectCmp.selectActDisTypesT.ErrorMsg, errorMsg: pErrorMsg ? pErrorMsg : "select a value" });
                if (pIsPrevFormValid) {
                    const g = pOkModalInstance?.getProps().okClickHandler;
                    pOkModalInstance?.setProps({
                        okClickHandler: () => {
                            console.log("showing pOkModalInstance.getProps().okClickHandler", g);
                            g?.();

                            pSelectRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(pOkModalInstance);//updated and not tested
                    await pOkModalInstance?.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }
    }

    export function FormSubmitBtn(
        props: PropsWithChildren<{
            id: string;
            // inputType: InputTypes;
            label: string;
            validationsMsg?: string;
            inputRef?: Ref<any>;
            options?: any;
            onOkClickHandler?: (e: MouseEvent<HTMLButtonElement>) => void;
        }>
    ) {
        const navigate = useNavigate();
        return (
            <>
                {/* <div className="form-label">
                    <label htmlFor="submitBtn">&#160;</label>
                </div> */}
                <div>
                    <button
                        className="btn btn-primary px-5 px-md-5 px-sm-5 py-1 py-md-2 py-sm-1"
                        type="button"
                        // value={props.label}
                        ref={props.inputRef}
                        onClick={(e) => {
                            props.onOkClickHandler?.(e);
                        }}
                    >
                        {props.label}
                    </button>
                </div>
                <div className="px-2">
                    <button
                        type="button"
                        className="btn btn-danger px-5 px-md-5 px-sm-5 py-1 py-md-2 py-sm-1"
                        onClick={async () => {
                            console.log("Cancel button clicked");
                            navigate("/");
                        }}
                    >
                        {ReadTextResource.getTextById("CANCEL")}
                    </button>
                </div>
            </>
        );
    }

    /**
     * Ref will get the current value lable(country name) in the input component, not the coutry code.
     * State can be used to get some value of the type CoutryType such as the country code.
     */
    export namespace CountryCmp {
        export enum countryActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        // type countryStT = { value?: { code: string; label: string; phone: string; suggested?: boolean }; errorMsg?: string };
        export type CountryStT = { value?: CountryT | null; errorMsg?: string };

        type CountryActionT = { type: countryActDisTypesT } & CountryStT;

        type CountryDisT = (action: CountryActionT) => void;

        /**
         * @param pValueContainer ref or countryStT input
         * @returns country name (lable) for ref. or CountryType for state input
         */
        export function getValue(pValueContainer: RefObject<HTMLInputElement>): string;
        export function getValue(pValueContainer: CountryStT): CountryT;
        export function getValue(pValueContainer: RefObject<HTMLInputElement> | CountryStT): string | CountryT {
            if ("value" in pValueContainer) return pValueContainer.value as CountryT;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of CountryCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = (initValue?: CountryT | null) =>
                useReducer(
                    (state: CountryStT, action: CountryActionT) => {
                        if (action.type === countryActDisTypesT.ValueInput) return { ...state, value: action.value };
                        if (action.type === countryActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: initValue, errorMsg: "" } //inital value
                );
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                countryRdc: [CountryStT, CountryDisT];
                visible?: boolean;
                defaultValue?: CountryT | undefined;
                onChange?: () => void;
                validateEmpty?: boolean;
                inputRef?: RefObject<HTMLInputElement>;
            }>
        ) {
            let countrySt: CountryStT | null = null;
            let countryDis: CountryDisT;

            if (props.countryRdc) {
                [countrySt, countryDis] = props.countryRdc;
            }
            // const [value, setValue] = props.valueState;
            return (
                <div className={`${props.visible ?? true ? "d-block" : "d-none"}`}>
                    <div className="">
                        <Autocomplete
                            id={props.id}
                            className="w-100"
                            sx={{ width: 300 }}
                            // defaultValue={props.defaultValue}
                            value={countrySt?.value ?? null}
                            options={countries || []}
                            autoHighlight
                            onChange={(c, v) => {
                                if (props.validateEmpty && v != null) {
                                    countryDis({
                                        type: countryActDisTypesT.ErrorMsg,
                                        errorMsg: "",
                                    });
                                }
                                countryDis({ type: countryActDisTypesT.ValueInput, value: v as CountryT });
                                props.onChange?.();
                            }}
                            getOptionLabel={(option) => (option?.label ? option?.label : "")}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option?.label} ({option?.code}) +{option?.phone}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!countrySt?.errorMsg}
                                    helperText={countrySt?.errorMsg}
                                    inputRef={props.inputRef}
                                    label={props.label}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                    onBlur={(e) => {
                                        try {
                                            if (props.validateEmpty && e.currentTarget.value.length == 0) {
                                                countryDis({
                                                    type: countryActDisTypesT.ErrorMsg,
                                                    errorMsg: "Please fill above value",
                                                });
                                            } else {
                                                countryDis({
                                                    type: countryActDisTypesT.ErrorMsg,
                                                    errorMsg: "",
                                                });
                                            }
                                        } catch (err) {
                                            console.warn(
                                                "when the props validateEmpty is true, then you need to assign an Rdc as props to CountryCmp"
                                            );
                                            throw err;
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (props.validateEmpty && e.currentTarget.value.length != 0) {
                                            countryDis({
                                                type: countryActDisTypesT.ErrorMsg,
                                                errorMsg: "",
                                            });
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            );
        }

        export async function validate(
            pFromCountryRef: React.RefObject<HTMLInputElement>,
            pFromCountryDis: CountryDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">>,
            pErrorMsg?: string
        ) {
            if (!FormCmps.CountryCmp.getValue(pFromCountryRef)) {
                pFromCountryDis({
                    type: FormCmps.CountryCmp.countryActDisTypesT.ErrorMsg,
                    errorMsg: pErrorMsg ? pErrorMsg : "Please select country",
                });
                if (pIsPrevFormValid) {
                    pOkModalInstance.setProps({
                        okClickHandler: () => {
                            pFromCountryRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    await pOkModalInstance.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export const getComp = (initValue?: CountryT) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initValue),
                compRef: helperFns.createRef(),
            };
        };
    }

    /**
     * This is simple compoenets that takes children (label) and render them in the Form component
     * keeping the same desing of the form component
     */
    export namespace LabelComp {
        export enum labelActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type labelStT = { value?: string; errorMsg?: string };

        type labelActionT = { type: labelActDisTypesT } & labelStT;

        type labelDisT = (action: labelActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | labelStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TextCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: labelStT, action: labelActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                inputRef?: Ref<any>;
                labelRdc?: [labelStT, labelDisT];
            }>
        ) {
            let labelSt: labelStT = {};
            let labelDis: labelDisT;

            if (props.labelRdc) {
                [labelSt, labelDis] = props.labelRdc;
            }
            return (
                <div id={props.id} className="col-12 col-lg-12 col-md-12 col-sm-12">
                    {props.children}
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace DateComp {
        export enum DateActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }

        type DateStT = { value?: Date; errorMsg?: string };

        type DatedActionT = { type: DateActDisTypesT } & DateStT;

        type DateDisT = (action: DatedActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | DateStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of DateCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = (initValue?: Date) =>
                useReducer(
                    (state: DateStT, action: DatedActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: initValue, errorMsg: "" }
                );
        }

        export async function validate(
            pTraspDateSt: DateStT,
            pTraspDateStDis: DateDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">>,
            pTraspDateStRef: React.RefObject<HTMLInputElement>,
            pErrorMsg: string
        ) {
            if (!pTraspDateSt.value) {
                pTraspDateStDis({
                    type: FormCmps.DateComp.DateActDisTypesT.ErrorMsg,
                    errorMsg: pErrorMsg ? pErrorMsg : "please set the date and time",
                });
                if (pIsPrevFormValid) {
                    pOkModalInstance.setProps({
                        okClickHandler: () => {
                            pTraspDateStRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    await pOkModalInstance.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                dateRdc?: [DateStT, DateDisT];
                valueTrakingOnChange?: boolean;
                regExpPattern?: string;
                validateEmpty?: boolean;
                options?: any;
            }>
        ) {
            let dateSt: DateStT = {};
            let dateDis: DateDisT;

            if (props.dateRdc) {
                [dateSt, dateDis] = props.dateRdc;
            }
            return (
                <div className="">
                    <div className="">
                        <MobileDateTimePicker
                            className="w-100"
                            ampm={true}
                            value={dateSt?.value ? parseISO(dateSt.value.toISOString()) : null}
                            key={props.label}
                            label={props.label}
                            format="EEEE dd/MM/yyyy hh:mm a"
                            openTo="day"
                            views={["year", "month", "day", "hours", "minutes"]}
                            // views={["day","hours", "minutes"]}

                            minDate={startOfDay(new Date())}
                            onError={
                                (newError) => dateSt?.errorMsg + " " + newError
                                // dateDis({ type: DateActDisTypesT.ErrorMsg, errorMsg: "some error - abd" });
                            }
                            slotProps={{
                                actionBar: {
                                    actions: ["cancel", "clear", "today", "accept"],
                                },
                                //     textField: {
                                //         helperText: dateSt.errorMsg ? dateSt.errorMsg : "",
                                //     },
                            }}
                            // required={props.required ? true : false}
                            inputRef={props.inputRef}
                            // inputProps={{ pattern: props.regExpPattern }}
                            onChange={(val, ctx) => {
                                console.log("datePickerVal = ", val, "typeof val", typeof val, val instanceof Date ? "true" : "flase");

                                dateDis({ type: DateActDisTypesT.ValueInput, value: (val as Date) ?? "" });
                                if (val) {
                                    dateDis({ type: DateActDisTypesT.ErrorMsg, errorMsg: "" });
                                }
                            }}
                        />
                        <FormHelperText error={!!dateSt.errorMsg}>{dateSt.errorMsg ? dateSt.errorMsg : ""}</FormHelperText>
                    </div>
                </div>
            );
        }

        export const getComp = (initValue?: Date) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initValue),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace TimeComp {
        export enum TimeActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }

        type TimeStT = { value?: Date; errorMsg?: string };

        type TimeActionT = { type: TimeActDisTypesT } & TimeStT;

        type TimeDisT = (action: TimeActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | TimeStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TimeCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = (initValue?: Date) =>
                useReducer(
                    (state: TimeStT, action: TimeActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: initValue, errorMsg: "" }
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                timeRdc?: [TimeStT, TimeDisT];
                valueTrakingOnChange?: boolean;
                regExpPattern?: string;
                validateEmpty?: boolean;
                options?: any;
            }>
        ) {
            let timeSt: TimeStT = {};
            let timeDis: TimeDisT;

            if (props.timeRdc) {
                [timeSt, timeDis] = props.timeRdc;
            }
            return (
                <div className="">
                    <div className="">
                        <TimePicker
                            className="w-100"
                            value={timeSt.value ?? null}
                            // format={"hh:mm"}
                            views={["hours", "minutes"]}
                            key={props.label}
                            label={props.label}
                            inputRef={props.inputRef}
                            onError={(newError) => timeSt.errorMsg}
                            slotProps={{
                                textField: {
                                    helperText: timeSt.errorMsg ? timeSt.errorMsg : "",
                                },
                            }}
                            onChange={(val, ctx) => {
                                console.log("props.valueTrakingOnChange", props.valueTrakingOnChange);
                                timeDis({ type: TimeActDisTypesT.ValueInput, value: (val as Date) ?? "" });
                            }}
                        />
                    </div>
                </div>
            );
        }

        export const getComp = (initValue?: Date) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initValue),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace CheckboxCmp {
        export enum checkboxActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
        }
        type checkboxStT = { value?: string; errorMsg?: string };

        type checkboxdActionT = { type: checkboxActDisTypesT } & checkboxStT;

        type checkboxDisT = (action: checkboxdActionT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | checkboxStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TextCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = () =>
                useReducer(
                    (state: checkboxStT, action: checkboxdActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: "", errorMsg: "" }
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                checkboxRdc?: [checkboxStT, checkboxDisT];
                valueTrakingOnChange?: boolean;
                regExpPattern?: string;
                validateEmpty?: boolean;
                options?: any;
            }>
        ) {
            let checkboxSt: checkboxStT = {};
            let checkboxDis: checkboxDisT;

            if (props.checkboxRdc) {
                [checkboxSt, checkboxDis] = props.checkboxRdc;
            }
            return (
                <div className="">
                    <div className="">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={checkboxSt.value}
                                    onChange={(e, v) => {
                                        console.log("event val=", e.currentTarget.value, "value boolean=", v);
                                        checkboxDis({ type: checkboxActDisTypesT.ValueInput, value: v ? "Y" : "N" });
                                    }}
                                />
                            }
                            label={props.label}
                        />
                    </div>
                </div>
            );
        }

        export const getComp = () => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace LocationCmp {
        export const LocationActDisTypesT = MapLocActDisTypesT;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | MapStT) {
            if ("location" in pValueContainer) return pValueContainer.location;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of LocationCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLDivElement>(null);

            static createReducer = (initValue?: google.maps.LatLngLiteral) =>
                useReducer(
                    (state: MapStT, action: MapActionT) => {
                        if (action.type === MapLocActDisTypesT.Location) {
                            clearMarkers(state.markers ?? []);

                            state.markers?.push(
                                new google.maps.marker.AdvancedMarkerElement({
                                    map: state.map,
                                    gmpDraggable: true,
                                    // icon: svgMarker,
                                    content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                                    title: "Your Location",
                                    position: action.location,
                                })
                            );
                            /**code below has been deprecated, timeout moved out
                             * timeout should be removed when tracking
                             * the user location continuosly
                             * timeout was set when the map is invisible,
                             * it will help in setting the zoom level correctly when
                             * showing the map based on the current location dispath,
                             * it can be modified by setting the time out on the dispatch
                             * itself, and show the map on different flag instead of dispatch
                             * state
                             */
                            // setTimeout(() => {
                            state?.map?.setZoom(15);
                            state?.map?.setCenter(action.location!!);
                            // }, 100);
                            return { ...state, location: action.location, errorMsg: "" };
                        }
                        if (action.type === MapLocActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                        if (action.type === MapLocActDisTypesT.Map) return { ...state, map: action.map };
                        if (action.type === MapLocActDisTypesT.Marker) {
                            // let currentLoc = undefined;
                            // if ((action.markers?.length ?? 0) === 1) {
                            //     console.log("Initializer of getCurrentLocation2");
                            //     currentLoc = {
                            //         lat: action.markers!![0].getPosition()?.lat() as number,
                            //         lng: action.markers!![0].getPosition()?.lng() as number,
                            //     };
                            // }
                            return {
                                ...state,
                                markers: action.markers,
                                // location: currentLoc,
                                getLocationByMarker: () => {
                                    console.log("Initializer of getCurrentLocation", "markers=", action.markers);
                                    if ((action.markers?.length ?? 0) === 1) {
                                        console.log("Initializer of getCurrentLocation2");
                                        // const vMarkers = state.markers as google.maps.marker.AdvancedMarkerElement[];
                                        // if (vMarkers.length > 1)
                                        //     throw new Error("there is more than one marker on the map, only one marker is allowed");

                                        return {
                                            lat: action.markers!![0].position?.lat as number,
                                            lng: action.markers!![0].position?.lng as number,
                                        };
                                        // return { lat: 32.02270516093821, lng: 35.84407458566004 };
                                    } else throw new Error("More than one marker on the map");
                                    return undefined;
                                },
                                errorMsg: "",
                            };
                        }

                        console.log("default state");

                        return state;
                    },
                    { location: initValue, errorMsg: "" }
                );
        }

        export async function validate(
            pLocSt: MapStT,
            pLocDis: MapDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">> | null,
            pLocRef: React.RefObject<HTMLDivElement>,
            pErrorMsg?: string
        ) {
            if ((pLocSt?.markers?.length ?? 0) > 1 || !pLocSt.location) {
                pLocDis({ type: FormCmps.LocationCmp.LocationActDisTypesT.ErrorMsg, errorMsg: pErrorMsg ?? "Please select a location" });
                if (pIsPrevFormValid) {
                    pOkModalInstance?.setProps({
                        okClickHandler: () => {
                            pLocRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    await pOkModalInstance?.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export function JsxComp(
            props: PropsWithChildren<{
                //id is important to set, to get the map in fullscreen successfully
                id: string;
                label: string | JSX.Element;
                readOnly?: boolean;
                validationsMsg?: string;
                required?: boolean;
                defaultValue?: Location;
                inputRef?: RefObject<any>;
                locationRdc: [MapStT, MapDisT];
                valueTrakingOnChange?: boolean;
                regExpPattern?: string;
                validateEmpty?: boolean;
                options?: any;
            }>
        ) {
            const mapDivContianer = useRef<HTMLDivElement>(null);

            const [mapVisible, setMapVisible] = useState(false);
            let locationSt: MapStT = {};
            let locationDis: MapDisT | null = null;

            if (props.locationRdc) {
                [locationSt, locationDis] = props.locationRdc;
            }
            // if (props.defaultValue) locationSt.location = props.defaultValue;
            // else throw new Error("no reducer assigned to Location Map component user-defiend error #9");

            return (
                <div id={props.id + "LocContainerCmp"} className="">
                    <div ref={props.inputRef} className="row g-1">
                        <div className="px-1" id="srcLocLabelId1">
                            {props.label}
                            <div>
                                <ButtonGroup id={props.id + "LocBtnGrp"}>
                                    {!props.readOnly && (
                                        <Button
                                            id={props.id + "LocCurBtn"}
                                            styles={{ fontSize: "0.7rem" }}
                                            classNames="btn-light btn-sm btn-outline-primary"
                                            label={ReadTextResource.getTextById("CURRENT_LOC")}
                                            icon={<i className="bi bi-geo-alt-fill"></i>}
                                            onOkClickHandler={async (e) => {
                                                try {
                                                    const loc = await getDeviceCurrentLocation();
                                                    if (loc) {
                                                        setMapVisible(true);
                                                        setTimeout(async () => {
                                                            locationDis?.({ type: MapLocActDisTypesT.Location, location: loc });
                                                            mapDivContianer.current?.scrollIntoView({ block: "center" });
                                                        }, 200);
                                                    }
                                                } catch (err) {
                                                    setMapVisible(false);
                                                    const okModal = new ModalComp.OkModal(
                                                        { isPortal: false },
                                                        {
                                                            msg: "Please allow to use GPS on this device to get your current location",
                                                        }
                                                    );
                                                    okModal.show();
                                                    // ModalComp.show(okModal);
                                                }
                                            }}
                                        />
                                    )}

                                    <Button
                                        id={props.id + "ViewMapBtn"}
                                        label={`${mapVisible ? ReadTextResource.getTextById("HIDE_MAP") : ReadTextResource.getTextById("SHOW_MAP")
                                            }`}
                                        icon={<i className="bi bi-geo-alt-fill"></i>}
                                        styles={{ fontSize: "0.7rem" }}
                                        classNames="btn-light btn-sm btn-outline-primary"
                                        onOkClickHandler={(e) => {
                                            if (mapVisible) {
                                                setMapVisible(false);
                                                return;
                                            } else {
                                                setMapVisible(true);
                                                setTimeout(() => {
                                                    props.inputRef?.current?.scrollIntoView({ block: "center" });
                                                }, 200);
                                            }

                                            if (props.readOnly) {
                                                return;
                                            }
                                            console.log("FullScreen btn is clicked");
                                            const mapEl = document.getElementById(props.id + "LocGoogleMap") as any;
                                            if (mapEl?.requestFullscreen) {
                                                mapEl?.requestFullscreen();
                                                const ExistFullScreencOnMapBtn = document.getElementById(
                                                    props.id + "LocGoogleMap" + "FullScrnOnMapBtn"
                                                ) as HTMLButtonElement;
                                                if (ExistFullScreencOnMapBtn)
                                                    ExistFullScreencOnMapBtn.className = "display-6 bi bi-fullscreen-exit";
                                            } else if (mapEl?.webkitRequestFullscreen) {
                                                /* Safari */
                                                mapEl?.webkitRequestFullscreen();
                                            } else if (mapEl?.msRequestFullscreen) {
                                                /* IE11 */
                                                mapEl?.msRequestFullscreen();
                                            }
                                        }}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                        {/* <div className="col-auto btn-group btn-group-sm" role="group" aria-label="Basic example"> */}

                        <FormHelperText error={!!locationSt?.errorMsg}>{locationSt?.errorMsg ?? ""}</FormHelperText>
                    </div>
                    <div id={props.id + "LocMapContainer"} className={`row ${mapVisible ? "d-block" : "d-none"}`}>
                        <div className="col-12">
                            <GoogleMapCmp
                                id={props.id + "LocGoogleMap"}
                                // initLocation={locationSt.currentLocation}
                                mapRef={mapDivContianer}
                                mapLocRdc={[locationSt, locationDis]}
                                defaulValue={props.defaultValue}
                                readOnly={props.readOnly}
                            // source={{ lat: 32.02270516093821, lng: 35.84407458566004 }}
                            // destination={{ lat: 31.98478387610699, lng: 35.88407168691589 }}
                            ></GoogleMapCmp>
                        </div>
                    </div>
                </div>
            );
        }

        export const getComp = (initValue?: google.maps.LatLngLiteral) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initValue),
                compRef: helperFns.createRef(),
            };
        };
    }

    export namespace RatingCmp {

        export enum ratingActDisTypesT {
            ValueInput = "VALUE_INPUT",
            ErrorMsg = "ERROR_MSG",
            Remove = "REMOVE",
        }

        type ratingStT = { rdcIdx?: number | undefined; rdcKey?: any; value?: number; errorMsg?: string };

        type ratingdActionT = { type: ratingActDisTypesT } & ratingStT;

        type ratingDisT = (action: ratingdActionT) => void;

        //

        // type textStArrItemT = { rdcKey?: number; value?: string; errorMsg?: string };

        type ratingStArrT = ratingStT[];

        // type textdActionArrT = { type: textActDisTypesT } & textStT;

        type ratingDisArrT = (action: ratingStArrT) => void;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | ratingStT) {
            if ("value" in pValueContainer) return pValueContainer.value;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of TextCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLInputElement>(null);

            static createReducer = (initVal?: number) =>
                useReducer(
                    (state: ratingStT, action: ratingdActionT) => {
                        if (action.type === "VALUE_INPUT") return { ...state, value: action.value };
                        if (action.type === "ERROR_MSG") return { ...state, errorMsg: action.errorMsg };
                        return state;
                    },
                    { value: initVal ?? 0, errorMsg: "" }
                );

            static createReducers = (initVal?: number) =>
                useReducer(
                    (state: ratingStArrT, action: ratingdActionT) => {
                        let found: boolean = false;
                        if (action.type === ratingActDisTypesT.Remove) {
                            const newStateAfterRemove = state.filter((v) => v.rdcKey !== action.rdcKey);
                            return [...newStateAfterRemove] as ratingStArrT;
                        }

                        //if rdcKey exist, then update the value, else a new rdcKey will be added below
                        state.map((v) => {
                            if (v.rdcIdx === action.rdcIdx || v.rdcKey === action.rdcKey) {
                                found = true;
                                if (action.type === ratingActDisTypesT.ValueInput) {
                                    v.value = action.value;
                                    v.rdcKey = action.rdcKey
                                }
                                else if (action.type === ratingActDisTypesT.ErrorMsg) v.errorMsg = action.errorMsg;
                            }
                        });

                        //if rdcKey not exist, then add new one
                        if (!found) state.push({ rdcIdx: action.rdcIdx, rdcKey: action.rdcKey, value: action.value, errorMsg: action.errorMsg });

                        // const ggg = [...state];
                        // console.log("ggg", ggg);

                        return [...state] as ratingStArrT;
                    },
                    [{ rdcIdx: 0, rdcKey: null, value: initVal ?? 0, errorMsg: "" }]
                );
        }
        export function JsxComp(
            props: PropsWithChildren<{
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                readonly?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                ratingRdc?: [ratingStT, ratingDisT];
                valueTrakingOnChange?: boolean;
                validateOnBlur?: () => void;
                onBlur?: (e: any) => void;
                regExpPattern?: string;
                validateEmpty?: boolean;
                inputMode?: "number" | "input";
                numberMinVal?: string;
                placeHolder?: string;
                options?: any;
            }>
        ) {
            let ratingSt: ratingStT = {};
            let ratingDis: ratingDisT;

            if (props.ratingRdc) {
                [ratingSt, ratingDis] = props.ratingRdc;
            }
            return (
                <div>
                    <Rating
                        name="simple-controlled"
                        // size="large"
                        sx={{ fontSize: "8vmin", }}
                        value={+(ratingSt?.value ?? 0)}
                        onChange={(event, newValue) => {
                            ratingDis({ rdcIdx: ratingSt.rdcIdx, rdcKey: ratingSt.rdcKey, type: ratingActDisTypesT.ValueInput, value: (newValue ?? 0) });
                        }}
                    />
                </div>

            );
        }

        export const getComp = (initVal?: number) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initVal),
                compReducerArr: helperFns.createReducers(initVal),
                compRef: helperFns.createRef(),
            };
        };

    }

    //////////////////////////////////////////////////// MapDirectionsComp ///////////////////////////////////////////////////////////////////////////////////////
    export namespace MapDirectionsComp {
        export const LocationActDisTypesT = MapLocActDisTypesT;

        export function getValue(pValueContainer: RefObject<HTMLInputElement> | MapStT) {
            if ("location" in pValueContainer) return pValueContainer.location;
            else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
            throw new Error("Invalid input for getValue of LocationCmp");
        }

        export class helperFns {
            static createRef = () => useRef<HTMLDivElement>(null);

            static createReducer = (initValue?: google.maps.LatLngLiteral) =>
                useReducer(
                    (state: MapStT, action: MapActionT) => {
                        if (action.type === MapLocActDisTypesT.Location) {
                            clearMarkers(state.markers ?? []);
                            state.markers?.push(
                                new google.maps.marker.AdvancedMarkerElement({
                                    map: state.map,
                                    gmpDraggable: true,
                                    // icon: svgMarker,
                                    title: "Your Location",
                                    position: action.location,
                                    content: new google.maps.marker.PinElement(pinDarkBlue1).element,
                                })
                            );
                            /**code below has been deprecated, timeout moved out
                             * timeout should be removed when tracking
                             * the user location continuosly
                             * timeout was set when the map is invisible,
                             * it will help in setting the zoom level correctly when
                             * showing the map based on the current location dispath,
                             * it can be modified by setting the time out on the dispatch
                             * itself, and show the map on different flag instead of dispatch
                             * state
                             */
                            // setTimeout(() => {
                            /* below has been commented out to enable navigate the map while tracking the driver movements so that the map will not recenter automatically,
                            it can be re-centered and resume tracking by clicking the CurBtn on the map to automatically re-center the map on every movement.
                            state?.map?.setZoom(15);
                            state?.map?.setCenter(action.location!!); */
                            // }, 100);
                            return { ...state, location: action.location, errorMsg: "" };
                        }
                        if (action.type === MapLocActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                        if (action.type === MapLocActDisTypesT.Map) return { ...state, map: action.map };
                        if (action.type === MapLocActDisTypesT.Marker)
                            return {
                                ...state,
                                markers: action.markers,
                                getLocationByMarker: () => {
                                    console.log("Initializer of getCurrentLocation", "markers=", action.markers);
                                    if ((action.markers?.length ?? 0) === 1) {
                                        console.log("Initializer of getCurrentLocation2");
                                        // const vMarkers = state.markers as google.maps.marker.AdvancedMarkerElement[];
                                        // if (vMarkers.length > 1)
                                        //     throw new Error("there is more than one marker on the map, only one marker is allowed");

                                        return {
                                            lat: action.markers!![0].position?.lat as number,
                                            lng: action.markers!![0].position?.lng as number,
                                        };
                                        // return { lat: 32.02270516093821, lng: 35.84407458566004 };
                                    } else throw new Error("More than one marker on the map");
                                    return undefined;
                                },
                                errorMsg: "",
                            };

                        console.log("default state");

                        return state;
                    },
                    { location: initValue, errorMsg: "" }
                );
        }

        export async function validate(
            pLocSt: MapStT,
            pLocDis: MapDisT,
            pIsPrevFormValid: boolean,
            pOkModalInstance: ModalComp.OkModal<ModalComp.OkModalProps<"MANDATORY">>,
            pLocRef: React.RefObject<HTMLDivElement>,
            pErrorMsg?: string
        ) {
            if ((pLocSt?.markers?.length ?? 0) > 1 || !pLocSt.location) {
                pLocDis({ type: FormCmps.LocationCmp.LocationActDisTypesT.ErrorMsg, errorMsg: pErrorMsg ?? "Please select a location" });
                if (pIsPrevFormValid) {
                    pOkModalInstance.setProps({
                        okClickHandler: () => {
                            pLocRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    await pOkModalInstance.showAsync();
                }
                pIsPrevFormValid = false;
            }
            return pIsPrevFormValid;
        }

        export function JsxComp(
            props: PropsWithChildren<{
                //id is important to set, to get the map in fullscreen successfully
                id: string;
                label: string;
                validationsMsg?: string;
                required?: boolean;
                defaultValue?: string;
                inputRef?: Ref<any>;
                locationRdc: [MapStT, MapDisT];
                waypoints?: Waypoint[] | undefined;
                valueTrakingOnChange?: boolean;
                regExpPattern?: string;
                validateEmpty?: boolean;
                options?: any;
            }>
        ) {
            const mapDivContianer = useRef<HTMLDivElement>(null);

            let locationSt: MapStT = {};
            let locationDis: MapDisT | null = null;

            if (props.locationRdc) {
                [locationSt, locationDis] = props.locationRdc;
            }

            return (
                <div id={props.id + "dirContainerCmp"} ref={props.inputRef} className="">
                    <div id={props.id + "dirMapContainer"} className={`row }`}>
                        <div className="col-12">
                            <GoogleMapDirCmp
                                id={props.id + "DirGoogleMap"}
                                // initLocation={locationSt.currentLocation}
                                mapRef={mapDivContianer}
                                mapLocRdc={[locationSt, locationDis]}
                                waypoints={props.waypoints}
                            // source={{ lat: 32.02270516093821, lng: 35.84407458566004 }}
                            // destination={{ lat: 31.98478387610699, lng: 35.88407168691589 }}
                            ></GoogleMapDirCmp>
                        </div>
                    </div>
                </div>
            );
        }

        export const getComp = (initValue?: google.maps.LatLngLiteral) => {
            return {
                JsxComp: JsxComp,
                compReducer: helperFns.createReducer(initValue),
                compRef: helperFns.createRef(),
            };
        };
    }
    //////////////////////////////////////////////////// END MapDirectionsComp ///////////////////////////////////////////////////////////////////////////////////////
}
