import { useQuery } from "@apollo/client";
import { FormHelperText } from "@mui/material";
import { PropsWithChildren, RefObject, useReducer, useRef, useState } from "react";
import { getGqlStmt } from "../../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../../commonSrc/ReadTextResource";
import { ReservedSeatsT, SeatSelectionStatus, SeatStatusT, VehicleSeatsT } from "../../../commonSrc/Types";
import { ModalComp } from "../ModalComp";
import { PsgrVclSeats } from "./PsgrVclSeats";
import { VehicleSeats } from "./VehicleSeats";

/**
 *
 * @param props load is the number of passengers without the driver
 * @returns
 */

export namespace PsgrVclSeatSelector {
    export function getPsgrReservedSeatsCount(curReservedSeatsByUser: ReservedSeatsT[] | undefined) {
        let count: number = 0;
        if (!curReservedSeatsByUser) return 0;
        return curReservedSeatsByUser.length;
        // if (count < 0) throw new Error("Wrong number of seats");
        // return count - 1;
    }

    export function get_M_F_psgrReservedSeatsCount(curReservedSeatsByUser: ReservedSeatsT[] | undefined): [number, number] {
        let fCount: number = 0;
        let mCount: number = 0;
        if (!curReservedSeatsByUser) return [0, 0];
        curReservedSeatsByUser.forEach((e, i) => {
            if (e.gender === "F") fCount++;
            else mCount++;
        });
        return [mCount, fCount];
    }

    export enum SeatActDisTypesT {
        ValueInput = "VALUE_INPUT",
        ErrorMsg = "ERROR_MSG",
    }

    export type SeatStT = { value?: ReservedSeatsT[]; errorMsg?: string };

    type SeatActionT = { type: SeatActDisTypesT } & SeatStT;

    export type SeatDisT = (action: SeatActionT) => void;

    export function getValue(pValueContainer: RefObject<HTMLInputElement> | SeatStT) {
        if ("value" in pValueContainer) return pValueContainer.value;
        else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
    }
    // const vehicleSeats: VehicleSeatsT[] = [];
    export class helperFns {
        static createRef = () => useRef<HTMLInputElement>(null);

        static createReducer = () =>
            useReducer(
                (state: SeatStT, action: SeatActionT) => {
                    if (action.type === SeatActDisTypesT.ValueInput) return { ...state, value: action.value };
                    if (action.type === SeatActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                    return { value: state.value, errorMsg: "" };
                },
                { value: [], errorMsg: "" }
            );
    }

    export function JsxComp(
        props: PropsWithChildren<{
            id: string;
            label: string;
            reqId: number;
            drvrUserId: number;
            visible?: boolean;
            seatRdc: [SeatStT, SeatDisT];
            defaultValue?: string;
            onChange?: () => void;
            validationsMsg?: string;
            inputRef?: RefObject<HTMLInputElement>;
            valueTrakingOnChange?: boolean;
            required?: boolean;
            options?: any;
        }>
    ): JSX.Element {
        console.log("First line - VehicleSeatSelector Component");

        const [value, setValue] = useState(props.defaultValue);
        let seatsSt: SeatStT = {} as SeatStT;
        let seatDis: SeatDisT = () => {};

        [seatsSt, seatDis] = props.seatRdc;

        const {
            data: getCurrentPsgrsInVclData,
            error: getCurrentPsgrsInVclErr,
            loading: getCurrentPsgrsInVclLoading,
            // refetch: reftechCagegories,
        } = useQuery(getGqlStmt().queries.VCL_STRUCTURE_N_JOINED_PSGRS, {
            fetchPolicy: "no-cache",
            variables: {
                reqId: props.reqId,
                drvrUserId: props.drvrUserId,
            },
        });

        if (getCurrentPsgrsInVclLoading)
            return <ModalComp.SpinnerModal.JsxPortalComp isPortal={true} modalIdSuffix="psgrVclSeatSelectorSpinId1" autoShow={true} />;
        return (
            <div ref={props.inputRef} className={`col-12 col-lg-12 col-md-12 col-sm-12 ${props.visible ?? true ? "d-block" : "d-none"}`}>
                {/* <h2>Car Seat Selector</h2> */}
                <div className="py-3">{ReadTextResource.getTextById("SLCT_SEAT")}:</div>
                <PsgrVclSeats
                    seatRdc={[seatsSt, seatDis]}
                    vclStructure={getCurrentPsgrsInVclData?.getVcldByDrvr?.query?.getVclStructure.dataArr}
                    alreadyReservedSeats={getCurrentPsgrsInVclData?.getTraspReqJoinedPsgrs?.dataArr}
                    reqId={props.reqId}
                    drvrUserId={props.drvrUserId}
                    key={props.id}
                    onChange={props.onChange}
                />
                <FormHelperText error={!!seatsSt.errorMsg}>{seatsSt.errorMsg}</FormHelperText>
            </div>
        );
    }

    export const getComp = (initValue?: SeatStT | null) => {
        return {
            JsxComp: JsxComp,
            compReducer: helperFns.createReducer(),
            compRef: helperFns.createRef(),
        };
    };
}
