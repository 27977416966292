import { useQuery } from "@apollo/client";
import { FormHelperText } from "@mui/material";
import { PropsWithChildren, RefObject, useEffect, useReducer, useRef, useState } from "react";
import { getGqlStmt } from "../../../commonSrc/graphQL/Queries";
import { GenderT, ReservedSeatsT } from "../../../commonSrc/Types";
import { FormCmps } from "../FormComp";
import { ModalComp } from "../ModalComp";
import { SpinnerComp } from "../SpinnerComp";
import { accessInfoRvar } from "../../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../../commonSrc/ReadTextResource";
import { MapLocActDisTypesT } from "../GoogleMapCmp";
import { useNavigate } from "react-router-dom";

export namespace DrvrJoinedPsgrsCmp {
    export function JoinedPsgrSeat(
        props: PropsWithChildren<{
            seatRdc: [DrvrJoinedPsgrsCmp.SeatStT, DrvrJoinedPsgrsCmp.SeatDisT];
            seatId: number;
            seatNoInRow: number;
            seatRow: number;
            psgrInfo: any | undefined;
            alreadyReservedSeats: ReservedSeatsT[];
            onChange?: () => void;
            onClickSeat?: (seatInfo: any) => void;
            isEmpty?: boolean;
        }>
    ) {
        console.log("First line - JoinedPsgrSeat Component");

        const [seatSt, seatDis] = props.seatRdc;

        const navigate = useNavigate();
        // const [seatVisible, setSeatVisible] = useState(true);
        const [seatStyle, setSeatStyle] = useState({});

        function updatePsgrSeat(pUpdType: "UPDATE" | "REMOVE"): boolean {
            let userCurrReservedSeats = seatSt.value;

            if (pUpdType === "REMOVE") {
                userCurrReservedSeats?.map((seat, i) => {
                    if (seat.x === props.seatNoInRow && seat.y === props.seatRow) {
                        userCurrReservedSeats?.splice(i, 1);
                    }
                });
            } /**else is for adding or updating current seat */
            else {
                /** adjacent passenger checks:
                 * if 3 -> check 2
                 * if 2 -> check 1 and 3
                 * if 1 -> check 2
                 */

                if (canReserveSeat(genderSt.value as GenderT, props.seatNoInRow) === "N") {
                    const cannotReserveOkModal = new ModalComp.OkModal(
                        { isPortal: false },
                        { msg: "بناءا على طلب الراكب بجانبك، لا يمكنك حجز هذا المقعد الا لانثى" }
                    );
                    genderDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                    setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
                    cannotReserveOkModal.show();
                    return false;
                }

                const seatFound = userCurrReservedSeats?.find((seat) => {
                    return seat.x === props.seatNoInRow && seat.y === props.seatRow;
                });

                if (seatFound && genderSt.value) {
                    seatFound.gender = genderSt.value;
                    seatFound.maleBeside = determineNeighborPsgrSt.value ?? genderSt.value === "M" ? "Y" : "N";
                    // seatFound.srcLoc = {}
                    // seatFound.destLoc={}
                } else if (genderSt.value) {
                    userCurrReservedSeats?.push({
                        reqId: props.psgrInfo.reqId,
                        gender: genderSt.value!!,
                        x: props.seatNoInRow,
                        y: props.seatRow,
                        maleBeside: determineNeighborPsgrSt.value ?? genderSt.value === "M" ? "Y" : "N",
                    });
                }
            }
            console.log("New seats state is:", userCurrReservedSeats);
            console.log("seatSt.value:", seatSt.value);
            // setCurrentSeatSt({ empty: false, gender: genderSt.value ?? "" });
            seatDis({
                type: DrvrJoinedPsgrsCmp.SeatActDisTypesT.ValueInput,
                value: userCurrReservedSeats,
            });
            props.onChange?.();
            return true;
        }

        function canReserveSeat(reserverGender: GenderT, requestedSeatNo: number): "Y" | "N" {
            console.log("props.reservedSeats=", props.alreadyReservedSeats);

            if (reserverGender === "F") return "Y";

            const getAdjacentAlreadyReservedSeats = props.alreadyReservedSeats?.filter((seat) => {
                return seat.y === props.seatRow;
            });

            if (!getAdjacentAlreadyReservedSeats || getAdjacentAlreadyReservedSeats.length === 0) return "Y";

            if (requestedSeatNo === 1 || requestedSeatNo === 3) {
                const foundPsgrInSeat2 = getAdjacentAlreadyReservedSeats.find((seat) => seat.x === 2);

                if (foundPsgrInSeat2 && foundPsgrInSeat2.gender === "F" && foundPsgrInSeat2.maleBeside === "N") return "N";
                return "Y";
            }

            if (requestedSeatNo === 2) {
                const notAllowed = getAdjacentAlreadyReservedSeats.find(
                    (seat) => (seat.x === 1 || seat.x === 3) && seat.gender === "F" && seat.maleBeside === "N"
                );

                if (notAllowed) return "N";
                return "Y";
            }

            return "N";
        }
        const {
            JsxComp: Gender,
            compReducer: [genderSt, genderDis],
            compRef: genderRef,
        } = FormCmps.RadioCmp.getComp("");

        const {
            JsxComp: DetermineNeighborPsgr,
            compReducer: [determineNeighborPsgrSt, determineNeighborPsgrStDis],
            compRef: determineNeighborPsgrStRef,
        } = FormCmps.RadioCmp.getComp("");

        useEffect(() => {
            // if (seatVisible) {
            if (props.seatNoInRow === 1 && props.seatRow === 1) {
                setSeatStyle({ backgroundImage: `url("/DrvrSteering.png")`, width: "95px", height: "95px" });
                // setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "95px", height: "95px" });
            } else {
                if (props.psgrInfo?.gender == "M") {
                    setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "75px", height: "75px" });
                } else if (props.psgrInfo?.gender == "F")
                    setSeatStyle({ backgroundImage: `url("/fPsgr.jpg")`, width: "75px", height: "75px" });
                else {
                    setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
                }
            }
            // } else {
            //     setSeatStyle({});
            // }
        }, []);

        const customModalIdSuffix = props.seatRow + "_" + props.seatNoInRow;

        const genderModal = new ModalComp.OkModal(
            { isPortal: true, modalIdSuffix: customModalIdSuffix },
            {
                autoHideOnOk: false,
                okClickHandler: async () => {
                    // const genderErrorModal = new ModalComp.OkModal(
                    //     { isPortal: false },
                    //     {
                    //         msg: "ادخل كل الحقول",
                    //         okClickHandler: async () => {
                    //             console.log("showing genderModal");
                    //             // genderModal.show();
                    //         },
                    //     }
                    // );
                    let isFormValid: boolean = true;

                    isFormValid = await FormCmps.RadioCmp.validate(
                        genderSt,
                        genderDis,
                        isFormValid,
                        undefined,
                        // genderErrorModal,
                        genderRef,
                        "Fill all fields"
                    );

                    if (genderSt.value === "F" && !determineNeighborPsgrSt.value) {
                        isFormValid = await FormCmps.RadioCmp.validate(
                            determineNeighborPsgrSt,
                            determineNeighborPsgrStDis,
                            isFormValid,
                            // genderErrorModal,
                            undefined,
                            determineNeighborPsgrStRef,
                            "Fill all fields"
                        );
                    }

                    // return;
                    if (!isFormValid) {
                        // genderModal.hide();
                        return;
                    }
                    // }
                    if (updatePsgrSeat("UPDATE"))
                        if (genderSt.value == "M") {
                            setSeatStyle({ backgroundImage: `url("/mPsgr.jpg")`, width: "75px", height: "75px" });
                        } else if (genderSt.value == "F") {
                            setSeatStyle({ backgroundImage: `url("/fPsgr.jpg")`, width: "75px", height: "75px" });
                        }
                    if (isFormValid) {
                        genderModal.hide();
                    }
                    // genderErrorModal.hide();
                    // genderModal.hide();
                },
                msg: (
                    <div>
                        "اختر جنس الراكب"
                        <Gender
                            radioRdc={[genderSt, genderDis]}
                            id="genderId1"
                            label={""}
                            radioDir={"horizontal"}
                        // onChange={(c, v) => {
                        //     props.callBack1(v);
                        // }}
                        >
                            <FormCmps.RadioItem id="genderMId1" label={"ذكر"} value="M"></FormCmps.RadioItem>
                            <FormCmps.RadioItem id="genderFId1" label={"انثى"} value="F"></FormCmps.RadioItem>
                        </Gender>
                        {genderSt.value === "F" && (
                            <DetermineNeighborPsgr
                                radioRdc={[determineNeighborPsgrSt, determineNeighborPsgrStDis]}
                                id="determineNeighborPsgrId1"
                                label={"هل تمانعي بوجود راكب ذكر بجانبك؟"}
                                radioDir={"horizontal"}
                            >
                                <FormCmps.RadioItem
                                    id="determineNeighborPsgrFId1"
                                    label={"اريد انثى فقط بجانبي"}
                                    value="N" //maleBeside will be N
                                ></FormCmps.RadioItem>
                                <FormCmps.RadioItem id="determineNeighborPsgrMId1" label={"لا امانع"} value="Y"></FormCmps.RadioItem>
                            </DetermineNeighborPsgr>
                        )}
                    </div>
                ),
            }
        );

        return (

            <div className="col-4 justify-content-center row text-center pt-4">
                {/* it was this for correct alignemnt of seats in screen sizes <div className="col-4 col-md-auto justify-content-center row text-center pt-4"> */}
                <>
                    {/* 
                **********************************************
                *this is another approach to use protal modal*
                ***********************************************
                <ModalComp.OkModal.JsxPortalComp
                    isPortal={true}
                    modalIdSuffix="5"
                    msg={
                        <div>
                            "اختر جنس الراكب"
                            <Gender
                                radioRdc={[genderSt, genderDis]}
                                id="genderId1"
                                label={""}
                                radioDir={"horizontal"}
                                // onChange={(c, v) => {
                                //     props.callBack1(v);
                                // }}
                            >
                                <FormCmps.RadioItem id="genderMId1" label={"ذكر"} value="M"></FormCmps.RadioItem>
                                <FormCmps.RadioItem id="genderFId1" label={"انثى"} value="F"></FormCmps.RadioItem>
                            </Gender>
                            {genderSt.value === "F" && (
                                <DetermineNeighborPsgr
                                    radioRdc={[determineNeighborPsgrSt, determineNeighborPsgrStDis]}
                                    id="determineNeighborPsgrId1"
                                    label={"هل تمانعي بوجود راكب ذكر بجانبك؟"}
                                    radioDir={"horizontal"}
                                >
                                    <FormCmps.RadioItem
                                        id="determineNeighborPsgrFId1"
                                        label={"اريد انثى فقط بجانبي"}
                                        value="M"
                                    ></FormCmps.RadioItem>
                                    <FormCmps.RadioItem id="determineNeighborPsgrMId1" label={"لا امانع"} value="F"></FormCmps.RadioItem>
                                </DetermineNeighborPsgr>
                            )}
                        </div>
                    }
                ></ModalComp.OkModal.JsxPortalComp> 
            */}
                </>
                {/* below another approach to use portal modal that save state of containing component */}
                {genderModal.getJsxPortalElement()}
                {!props.isEmpty ? (
                    <>
                        <div
                            onClick={() => {
                                if (props.seatNoInRow === 1 && props.seatRow === 1) return;
                                // if (props.psgrInfo.gender) {
                                // const alreadyReservedModal = new ModalComp.OkModal({ isPortal: false }, { msg: "اظهر تفاصيل الراكب" });
                                // alreadyReservedModal.show();
                                props.onClickSeat?.({
                                    seatId: props.seatId,
                                    seatNoInRow: props.seatNoInRow,
                                    seatRow: props.seatRow,
                                    psgrInfo: props.psgrInfo
                                })
                                // }
                                // else genderModal.show();
                                // }
                            }}
                            className={`position-relative d-block`}
                            style={{
                                ...seatStyle,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <div
                                className={`${props.seatNoInRow === 1 && props.seatRow === 1 ? "d-block text-center" : "d-none"}`}
                                style={{
                                    position: "absolute",
                                    bottom: "0",
                                    // bottom: 0,
                                    left: 0,
                                    right: 0,
                                    // height: "100%",
                                    // width: "100%",
                                    // opacity: "0.5",
                                    // background: "gray",
                                }}
                            >
                                الكابتن
                            </div>
                            <div
                                className=""
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    // bottom: 0,
                                    // left: 0,
                                    right: 0,
                                    // height: "100%",
                                    // width: "100%",
                                    // opacity: "0.5",
                                    // background: "gray",
                                }}
                            >
                                <div
                                    className={`${(props.seatRow === 1 && props.seatNoInRow === 1) || props.psgrInfo.gender || genderSt.value === ""
                                        ? "d-none"
                                        : "d-blcok"
                                        }`}
                                    style={{
                                        opacity: "1",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        border: "1px 1px",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                    onClick={(e) => {
                                        // props.status = SeatSelectionStatus.UNSELECTED;
                                        e.stopPropagation();
                                        updatePsgrSeat("REMOVE");
                                        genderDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                                        determineNeighborPsgrStDis({ type: FormCmps.RadioCmp.radioActDisTypesT.ValueInput, value: "" });
                                        setSeatStyle({ backgroundImage: `url("/seat.jpg")`, width: "75px", height: "75px" });
                                    }}
                                >
                                    X
                                </div>

                            </div>
                            <div className=""
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    // bottom: 0,
                                    // left: 0,
                                    left: 0,
                                    marginLeft: -22
                                    // height: "100%",
                                    // width: "100%",
                                    // opacity: "0.5",
                                    // background: "gray",
                                }}>
                                <div
                                    className={`${(props.seatRow === 1 && props.seatNoInRow === 1) || !props.psgrInfo?.userId
                                        ? "d-none"
                                        : "d-blcok"
                                        }`}
                                    style={{
                                        opacity: "1",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        border: "1px 1px",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                    onClick={(e) => {

                                    }}
                                >
                                    <div className="text-center">
                                        <i
                                            className={`bi bi-chat-dots-fill fs-4 ${accessInfoRvar()?.user?.userType === "Psgr" ? "d-none" : ""}`}
                                            onClick={async () => {
                                                navigate("/app/drvrChat", { state: { toUserId: props.psgrInfo.userId } });
                                            }}></i>
                                    </div>
                                    <div style={{ fontSize: "10px", lineHeight: 0.5 }}>chat</div>

                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ width: "75px", height: "75px" }}></div>
                )}
            </div>
        );
    }

    export function JoinedPsgrsSeats(
        props: PropsWithChildren<{
            seatRdc: [DrvrJoinedPsgrsCmp.SeatStT, DrvrJoinedPsgrsCmp.SeatDisT];
            vclStructure: any[];
            alreadyReservedSeats: ReservedSeatsT[];
            reqId: number;
            drvrUserId: number;
            onChange?: () => void;
            onClickSeat?: (seatInfo: any) => void;

        }>
    ) {
        console.log("First line - JoinedPsgrsSeats Component");

        let [eachDiv, setEachDiv] = useState<JSX.Element[]>([]);

        let seatsSt: DrvrJoinedPsgrsCmp.SeatStT = {} as DrvrJoinedPsgrsCmp.SeatStT;
        let seatDis: DrvrJoinedPsgrsCmp.SeatDisT = () => { };

        [seatsSt, seatDis] = props.seatRdc;

        const [loading, setLoading] = useState(true);

        useEffect(() => {
            eachDiv = [];
            let counter = 0;
            props.vclStructure.map((eachThree, vclStrucIndx) => {

                eachDiv.push(
                    <div key={eachThree.vclId + eachThree.y} className="row justify-content-center">
                        {[1, 2, 3].map((seatNo) => {
                            const psgrInfo = props.alreadyReservedSeats?.find((psgr, idx) => {
                                return psgr.y === eachThree.y && psgr.x === seatNo;
                            });
                            counter++;
                            return (
                                <JoinedPsgrSeat
                                    seatId={counter}
                                    seatRow={eachThree.y}
                                    seatNoInRow={seatNo}
                                    key={"seatNo" + seatNo + "Y" + vclStrucIndx}
                                    psgrInfo={psgrInfo ?? { reqId: props.reqId }}
                                    seatRdc={[seatsSt, seatDis]}
                                    alreadyReservedSeats={props.alreadyReservedSeats}
                                    isEmpty={
                                        (eachThree.y === 1 && seatNo === 2) || eachThree[("x" + seatNo) as keyof typeof eachThree] === "N"
                                    }
                                    onChange={props.onChange}
                                    onClickSeat={props.onClickSeat}
                                />
                            );
                        })}
                    </div>
                );
            });

            setEachDiv(eachDiv);
            setLoading(false);
        }, []);

        //below works fine as well

        if (loading) return <div>loading</div>;
        return (
            <div dir="ltr" className="border-start border-end border-danger border-5">
                {eachDiv?.map((v, i) => v)}
            </div>
        );
    }

    export function getPsgrReservedSeatsCount(curReservedSeatsByUser: ReservedSeatsT[] | undefined) {
        let count: number = 0;
        if (!curReservedSeatsByUser) return 0;
        return curReservedSeatsByUser.length;
        // if (count < 0) throw new Error("Wrong number of seats");
        // return count - 1;
    }

    export enum SeatActDisTypesT {
        ValueInput = "VALUE_INPUT",
        ErrorMsg = "ERROR_MSG",
    }

    export type SeatStT = { value?: ReservedSeatsT[]; errorMsg?: string };

    type SeatActionT = { type: SeatActDisTypesT } & SeatStT;

    export type SeatDisT = (action: SeatActionT) => void;

    export function getValue(pValueContainer: RefObject<HTMLInputElement> | SeatStT) {
        if ("value" in pValueContainer) return pValueContainer.value;
        else if ("current" in pValueContainer) return pValueContainer.current?.value as string;
    }
    // const vehicleSeats: VehicleSeatsT[] = [];
    export class helperFns {
        static createRef = () => useRef<HTMLInputElement>(null);

        static createReducer = () =>
            useReducer(
                (state: SeatStT, action: SeatActionT) => {
                    if (action.type === SeatActDisTypesT.ValueInput) return { ...state, value: action.value };
                    if (action.type === SeatActDisTypesT.ErrorMsg) return { ...state, errorMsg: action.errorMsg };
                    return { value: state.value, errorMsg: "" };
                },
                { value: [], errorMsg: "" }
            );
    }

    export function JsxComp(
        props: PropsWithChildren<{
            id: string;
            label: string;
            reqId: number;
            drvrUserId: number;
            visible?: boolean;
            seatRdc: [SeatStT, SeatDisT];
            defaultValue?: string;
            onChange?: () => void;
            onClickSeat?: (seatInfo: any) => void;
            validationsMsg?: string;
            inputRef?: RefObject<HTMLInputElement>;
            valueTrakingOnChange?: boolean;
            required?: boolean;
            options?: any;
        }>
    ): JSX.Element {
        console.log("First line - JoinedPsgrsCmp Component");

        // const [value, setValue] = useState(props.defaultValue);
        let seatsSt: SeatStT = {} as SeatStT;
        let seatDis: SeatDisT = () => { };

        [seatsSt, seatDis] = props.seatRdc;
        const [psgrReserveDtls, setPsgrReserveDtls] = useState<any>();

        const {
            JsxComp: PsgrSrcLocationComp,
            compReducer: [psgrSrcLocationSt, psgrSrcLocationDis],
            compRef: psgrSrcLocationRef,
        } = FormCmps.LocationCmp.getComp(psgrReserveDtls?.srcLoc);

        const {
            JsxComp: PsgrDestLocation,
            compReducer: [psgrDestLocationSt, psgrDestLocationDis],
            compRef: psgrDestLocationStRef,
        } = FormCmps.LocationCmp.getComp(psgrReserveDtls?.destLoc);

        const {
            data: getCurrentPsgrsInVclData,
            error: getCurrentPsgrsInVclErr,
            loading: getCurrentPsgrsInVclLoading,
            // refetch: reftechCagegories,
        } = useQuery(getGqlStmt().queries.VCL_STRUCTURE_N_JOINED_PSGRS, {
            fetchPolicy: "no-cache",
            variables: {
                reqId: +props.reqId,
                drvrUserId: +props.drvrUserId,
            },
        });

        useEffect(() => {
            if (accessInfoRvar()?.user?.userType === "Psgr") {
                const currentPsgrReserveInfo: any = (getCurrentPsgrsInVclData?.getTraspReqJoinedPsgrs?.dataArr as [])
                    ?.find(((e: any) => e.userId === accessInfoRvar()?.user?.userId));

                setPsgrReserveDtls(currentPsgrReserveInfo);
                psgrSrcLocationDis({ type: MapLocActDisTypesT.Location, location: currentPsgrReserveInfo?.srcLoc });
                psgrDestLocationDis({ type: MapLocActDisTypesT.Location, location: currentPsgrReserveInfo?.destLoc });
            }


        }, [getCurrentPsgrsInVclData]);

        if (getCurrentPsgrsInVclLoading)
            return (
                <ModalComp.SpinnerModal.JsxPortalComp
                    isPortal={true}
                    modalIdSuffix="drvrJoinedPsgrSpinnerId1"
                    modalId="DrvrJoPsgr"
                    autoShow={true}
                ></ModalComp.SpinnerModal.JsxPortalComp>
            );

        return (
            <>
                <div ref={props.inputRef} className={`col-12 col-lg-6 col-md-12 col-sm-12 ${props.visible ?? true ? "d-block" : "d-none"}`}>
                    {/* <h2>Car Seat Selector</h2> */}
                    <div className="py-3"><b> {ReadTextResource.getTextById("RES_SEATS")}</b></div>
                    <JoinedPsgrsSeats
                        seatRdc={[seatsSt, seatDis]}
                        vclStructure={getCurrentPsgrsInVclData.getVcldByDrvr.query.getVclStructure.dataArr}
                        alreadyReservedSeats={getCurrentPsgrsInVclData.getTraspReqJoinedPsgrs.dataArr}
                        reqId={props.reqId}
                        drvrUserId={props.drvrUserId}
                        key={"key" + props.id}
                        onChange={props.onChange}
                        onClickSeat={(seatInfo) => {
                            console.log("seatInfo", seatInfo);
                            if (accessInfoRvar()?.user?.userType === "Drvr") {
                                const currentPsgrReserveInfo: any = (getCurrentPsgrsInVclData?.getTraspReqJoinedPsgrs?.dataArr as [])
                                    ?.find(((e: any) => e.x === seatInfo.seatNoInRow
                                        && e.y === seatInfo.seatRow));

                                setPsgrReserveDtls(currentPsgrReserveInfo);
                                psgrSrcLocationDis({ type: MapLocActDisTypesT.Location, location: currentPsgrReserveInfo?.srcLoc });
                                psgrDestLocationDis({ type: MapLocActDisTypesT.Location, location: currentPsgrReserveInfo?.destLoc });
                            }

                        }}

                    />
                    <FormHelperText error={!!seatsSt.errorMsg}>{seatsSt.errorMsg}</FormHelperText>
                </div>

                {accessInfoRvar()?.user?.userType === "Psgr" && psgrReserveDtls &&
                    <div className="row col-lg-6 pt-5">
                        {psgrReserveDtls?.srcLoc ? (
                            <div className="col text-center">
                                <PsgrSrcLocationComp
                                    id="PsgrSrcLocationCmpId01"
                                    locationRdc={[psgrSrcLocationSt, psgrSrcLocationDis]}
                                    label={<b> {ReadTextResource.getTextById("PSGR_SRC_LOC")}:</b>}
                                    inputRef={psgrSrcLocationRef}
                                    readOnly={true}
                                ></PsgrSrcLocationComp>
                            </div>
                        ) : (
                            <div className="col text-center">
                                <b>{ReadTextResource.getTextById("PSGR_SRC_LOC")}</b>: {ReadTextResource.getTextById("FROM_DRVR_LOC")}
                            </div>
                        )}

                        {psgrReserveDtls?.destLoc ? (
                            <div className="col text-center">
                                <PsgrDestLocation
                                    id="psgrDestLocId02"
                                    locationRdc={[psgrDestLocationSt, psgrDestLocationDis]}
                                    label={<b> {ReadTextResource.getTextById("PSGR_DEST_LOC")}:</b>}
                                    inputRef={psgrDestLocationStRef}
                                    readOnly={true}
                                ></PsgrDestLocation>
                            </div>
                        ) : (
                            <div className="col text-center">
                                <b> {ReadTextResource.getTextById("PSGR_DEST_LOC")} :</b>
                                {ReadTextResource.getTextById("TO_DRVR_LOC")}
                            </div>
                        )}


                    </div>}

                {accessInfoRvar()?.user?.userType === "Drvr" && psgrReserveDtls &&
                    <div className="row col-lg-6 pt-5">
                        {psgrReserveDtls?.srcLoc ? (
                            <div className="col text-center">
                                <PsgrSrcLocationComp
                                    id="PsgrSrcLocationCmpId01"
                                    locationRdc={[psgrSrcLocationSt, psgrSrcLocationDis]}
                                    label={<b> {ReadTextResource.getTextById("PSGR_SRC_LOC")}:</b>}
                                    inputRef={psgrSrcLocationRef}
                                    readOnly={true}
                                ></PsgrSrcLocationComp>
                            </div>
                        ) : (
                            <div className="col text-center">
                                <b>{ReadTextResource.getTextById("PSGR_SRC_LOC")}</b>: {ReadTextResource.getTextById("FROM_DRVR_LOC")}
                            </div>
                        )}

                        {psgrReserveDtls?.destLoc ? (
                            <div className="col text-center">
                                <PsgrDestLocation
                                    id="psgrDestLocId02"
                                    locationRdc={[psgrDestLocationSt, psgrDestLocationDis]}
                                    label={<b> {ReadTextResource.getTextById("PSGR_DEST_LOC")}:</b>}
                                    inputRef={psgrDestLocationStRef}
                                    readOnly={true}
                                ></PsgrDestLocation>
                            </div>
                        ) : (
                            <div className="col text-center">
                                <b> {ReadTextResource.getTextById("PSGR_DEST_LOC")} :</b>
                                {ReadTextResource.getTextById("TO_DRVR_LOC")}
                            </div>
                        )}


                    </div>}
            </>
        );
    }

    export const getComp = (initValue?: SeatStT | null) => {
        return {
            JsxComp: JsxComp,
            compReducer: helperFns.createReducer(),
            compRef: helperFns.createRef(),
        };
    };
}
