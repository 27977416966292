import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { ReqListItem } from "../trip/ReqListItem";

export function DrvrSuspendedReqs(props: React.PropsWithChildren<{}>) {
    console.log("First line - GoodsReqNeedApr Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    const [fetchType, setFetchType] = useState("today");

    /* const {
        JsxComp: MyReqsFilter,
        compReducer: [myReqsFilterSt, myReqsFilterDis],
        compRef: myReqsFilterRef,
    } = FormCmps.RadioCmp.getComp("today"); */

    const {
        error: getGoodsReqsFromPsgrsErr,
        data: getGoodsReqsFromPsgrsData,
        loading: getGoodsReqsFromPsgrsLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.DRVR_PENDING_REQS/* USER_REQS *//* GOODS_REQS_FROM_PSGRS */, {
        fetchPolicy: "no-cache",
        variables: {
            fetchType: "all",
        },
    });

    useEffect(() => {
        async function executeAsyncUseEffect() { }
        executeAsyncUseEffect();
    }, [dirAndLangRvar().lang]);

    if (getGoodsReqsFromPsgrsLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="psgrMainCompSpinnerId1"
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );
    console.log("$$getUserTraspReqs", getGoodsReqsFromPsgrsData);

    return (
        <div>
            <FormCmps.LabelComp.JsxComp id="psgrRequestsId1">
                <div className="text-center p-2 bg-info rounded mt-3">
                    <strong>{ReadTextResource.getTextById("GDS_REQS")}</strong>
                </div>
            </FormCmps.LabelComp.JsxComp>

            <div>
                {/* <MyReqsFilter
                    radioRdc={[myReqsFilterSt, myReqsFilterDis]}
                    id="myReqsFilterId"
                    label={ReadTextResource.getTextById("MY_REQS_FLTR")}
                    inputRef={myReqsFilterRef}
                    radioDir={"horizontal"}
                    onChange={(e, v) => {
                        if (v === "today") setFetchType("today");
                        else setFetchType("all");
                    }}
                >
                    <FormCmps.RadioItem
                        id="todayRadio"
                        label={ReadTextResource.getTextById("TODAY_REQS")}
                        value="today"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem id="allRadio" label={ReadTextResource.getTextById("ALL_REQS")} value="all"></FormCmps.RadioItem>
                </MyReqsFilter> */}

                {getGoodsReqsFromPsgrsData?.getDrvrPndngRcvdReqs?.dataArr?.map((e: any) => {
                    return <ReqListItem
                        key={e.reqId}
                        reqDtlInfo={e}
                        enableAprvRedBoxCount={true}
                        embedCmp={<button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                navigate(`/app/drvrGdsTkDecision/${e.reqId}/${e.driver?.userId}`, {
                                    state: { drvrUserId: e.driver?.userId },
                                });
                            }}
                        >
                            {ReadTextResource.getTextById("REQ_DTLS")}
                        </button>}
                    />
                })}
                {getGoodsReqsFromPsgrsData?.getDrvrPndngRcvdReqs?.dataArr?.length === 0 ? (
                    <div> {ReadTextResource.getTextById("NO_REQS_TODAY")}</div>
                ) : (
                    ""
                )}

                <FormCmps.LabelComp.JsxComp id="psgrRequestsId1">
                    <div className="text-center p-2 bg-info rounded mt-3">
                        <strong>{ReadTextResource.getTextById("DRVR_SENT_REQS")}</strong>
                    </div>
                </FormCmps.LabelComp.JsxComp>

                {getGoodsReqsFromPsgrsData?.getDrvrPndngSntReqs?.dataArr?.map((e: any) => {
                    return <ReqListItem
                        key={e.reqId}
                        reqDtlInfo={e}
                        enableAprvRedBoxCount={false}
                        embedCmp={<button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                navigate(`/app/drvrSntReqDtls/${e.reqId}`, {
                                    state: { drvrUserId: e.driver?.userId },
                                });
                            }}
                        >
                            {ReadTextResource.getTextById("REQ_DTLS")}{" "}
                        </button>}
                    />
                })}
                {getGoodsReqsFromPsgrsData?.getDrvrPndngSntReqs?.dataArr?.length === 0 ? (
                    <div> {ReadTextResource.getTextById("NO_REQS_TODAY")}</div>
                ) : (
                    ""
                )}

            </div>
        </div>
    );
}
