import { PropsWithChildren } from "react";
import { CrudTypes } from "../../commonSrc/Constants";
import { UserCrud } from "../Auth/UserCrud";
import { FormCmps } from "./FormComp";

export function AppSettings(props:PropsWithChildren<{}>){
    return <>
    {/* <UserCrud key={"a"} crudType={CrudTypes.CREATE }></UserCrud> */}
    <UserCrud key={"b"} crudType={CrudTypes.UPDATE}></UserCrud>
    
    </> 
    
}