import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { NavLink, useNavigate } from "react-router-dom";
import { apolloClient } from "../../commonSrc/apolloGQL/ApolloConfig";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { findCityById, getAccessInfo, getDirection, getDistanceInfo } from "../../commonSrc/commonFns";
import { Location } from "../commonComp/GoogleMapCmp";
import { addMinutes, format, formatDistance, parseISO } from "date-fns";
import { country_cities } from "../../commonSrc/textResources/country_cities";
import { Pagination } from "@mui/material";
import { StickyBar } from "../commonComp/StickyBar";
import { ReqListItem } from "../trip/ReqListItem";

export function PsgrFindTrip(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrFindTrip Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    const [fetchType, setFetchType] = useState("today");

    const {
        JsxComp: InOutTrans,
        compReducer: [inOutTransSt, inOutTransStDis],
        compRef: inOutTransStRef,
    } = FormCmps.RadioCmp.getComp("In");

    const {
        JsxComp: FromCityAutoComplete,
        compReducer: [fromCityAutoCompleteSt, fromCityAutoCompleteDis],
        compRef: fromCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: ToCityAutoComplete,
        compReducer: [toCityAutoCompleteSt, toCityAutoCompleteDis],
        compRef: toCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: FromCountry,
        compReducer: [fromCountrySt, fromCountryDis],
        compRef: fromCountryRef,
        // { code: "JO", label: "Jordan", phone: "+962", suggested: true }
    } = FormCmps.CountryCmp.getComp(getAccessInfo()?.user?.countryByIp);

    const {
        JsxComp: ToCountry,
        compReducer: [toCountrySt, toCountryDis],
        compRef: toCountryRef,
    } = FormCmps.CountryCmp.getComp();

    const {
        JsxComp: FromDatetime,
        compReducer: [fromDatetimeSt, fromDatetimeStDis],
        compRef: fromDatetimeStRef,
    } = FormCmps.DateComp.getComp(parseISO(addMinutes(new Date(), 15).toISOString()));

    const {
        JsxComp: ToDatetime,
        compReducer: [toDatetimeSt, toDatetimeStDis],
        compRef: toDatetimeStRef,
    } = FormCmps.DateComp.getComp(/* new Date() */);

    // const {
    //     refetch: refetchDrvrsTraspReqs,
    //     error: getDrvrsTraspReqsErr,
    //     data: getDrvrsTraspReqsData,
    //     loading: getDrvrsTraspReqsLoading,
    //     // refetch: reftechCagegories,
    // } = useQuery(getGqlStmt().queries.DRVRS_TRASP_REQS, {
    //     fetchPolicy: "no-cache",
    // });

    const [
        getDrvrTraspReq,
        { refetch: refetchDrvrsTraspReqs, error: searchDrvrsReqsErr, data: searchDrvrsReqsData, loading: searchDrvrsReqsLoading },
    ] = useLazyQuery(getGqlStmt().queries.SEARCH_DRVRS_REQS, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        async function executeAsyncUseEffect() {
            await getDrvrTraspReq({
                variables: {
                    page: 1,
                    pageSize: 2,
                    reqId: null,
                    fromCountry: fromCountrySt.value?.code ?? null,
                    toCountry: toCountrySt.value?.code ?? null,
                    fromCity: (fromCityAutoCompleteSt.value?.id ?? null) === "" ? null : fromCityAutoCompleteSt.value?.id,
                    toCity: (toCityAutoCompleteSt?.value?.id ?? null) === "" ? null : toCityAutoCompleteSt?.value?.id,
                    fromDatetime: fromDatetimeSt.value ?? null,
                    toDatetime: toDatetimeSt.value ?? null,
                    reqType: null,
                    applicantSrcLoc: null,
                },
            });
        }
        executeAsyncUseEffect();
    }, [dirAndLangRvar().lang]);

    if (searchDrvrsReqsLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="psgrMainCompSpinnerId1"
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );

    return (
        <div>
            <div>
                <FormCmps.LabelComp.JsxComp id="availableReqFromPsgrId1">
                    <div className="pt-4">
                        <div className="text-center p-2 bg-info rounded">
                            <strong> {ReadTextResource.getTextById("REQS_FROM_DRVR")} </strong>
                        </div>
                    </div>
                </FormCmps.LabelComp.JsxComp>
                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-12 gy-3">
                    <InOutTrans
                        radioRdc={[inOutTransSt, inOutTransStDis]}
                        id="inOutTransId1"
                        label={ReadTextResource.getTextById("TRANS_TYPE")}
                        radioDir={"horizontal"}
                    >
                        <FormCmps.RadioItem
                            id="inTransRadio"
                            label={ReadTextResource.getTextById("IN_COUNTRY")}
                            value="In"
                        ></FormCmps.RadioItem>
                        <FormCmps.RadioItem
                            id="outTransRadio"
                            label={ReadTextResource.getTextById("OUT_COUNTRY")}
                            value="Out"
                        ></FormCmps.RadioItem>
                    </InOutTrans>

                    {/* <div className="row gy-3"> */}
                    <FromCountry
                        id="fromCoutry1"
                        label={ReadTextResource.getTextById("FROM_COUNTRY")}
                        // defaultValue={!getIpInfoLoading ? countries.find((e) => e.code === getIpInfoData?.getIpInfo?.country_code) : undefined}
                        // defaultValue={userInfoRvar().countryByIp}
                        inputRef={fromCountryRef}
                        validateEmpty={false}
                        countryRdc={[fromCountrySt, fromCountryDis]}
                        onChange={() => {
                            fromCityAutoCompleteDis({
                                type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                                value: "",
                            });
                        }}
                    ></FromCountry>

                    {inOutTransSt.value === "Out" && (
                        <ToCountry
                            id="toCoutryId1"
                            label={ReadTextResource.getTextById("TO_COUNTRY")}
                            inputRef={toCountryRef}
                            validateEmpty={false}
                            visible={inOutTransSt.value === "Out"}
                            countryRdc={[toCountrySt, toCountryDis]}
                            onChange={() => {
                                toCityAutoCompleteDis({
                                    type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                                    value: "",
                                });
                            }}
                        ></ToCountry>
                    )}

                    <FromCityAutoComplete
                        id="fromCity"
                        label={ReadTextResource.getTextById("FROM_CITY")}
                        inputRef={fromCityAutoCompleteRef}
                        validateEmpty={false}
                        autoCompleteRdc={[fromCityAutoCompleteSt, fromCityAutoCompleteDis]}
                        optionLable={(option) => {
                            return option?.name_ar ? option?.name_ar : option?.name || "";
                        }}
                        inputOptions={
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            (() => {
                                // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                                if (fromCountrySt.value?.code) {
                                    const e = country_cities?.find((e) => {
                                        return e.iso2 === fromCountrySt.value?.code; /* userCountrySt.value?.code; */
                                        //|| e.phone_code == ("+" || countryValSt.phone)
                                    });
                                    if (e) return e.states;
                                    else return [];
                                }
                                return [];
                            })()
                        }
                    ></FromCityAutoComplete>

                    <ToCityAutoComplete
                        id="toCity"
                        label={ReadTextResource.getTextById("TO_CITY")}
                        inputRef={toCityAutoCompleteRef}
                        validateEmpty={false}
                        autoCompleteRdc={[toCityAutoCompleteSt, toCityAutoCompleteDis]}
                        optionLable={(option) => {
                            return option?.name_ar ? option?.name_ar : option?.name || "";
                        }}
                        inputOptions={
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            (() => {
                                // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                                if (inOutTransSt.value === "Out") {
                                    if (toCountrySt.value?.code) {
                                        const e = country_cities?.find((e) => {
                                            return e.iso2 === toCountrySt.value?.code; //userCountrySt.value?.code;
                                            //|| e.phone_code == ("+" || countryValSt.phone)
                                        });
                                        if (e) return e.states;
                                        else return [];
                                    }
                                } else {
                                    if (fromCountrySt.value?.code) {
                                        const e = country_cities?.find((e) => {
                                            return e.iso2 === fromCountrySt.value?.code; //userCountrySt.value?.code;
                                            //|| e.phone_code == ("+" || countryValSt.phone)
                                        });
                                        if (e) return e.states;
                                        else return [];
                                    }
                                }
                                return [];
                            })()
                        }
                    ></ToCityAutoComplete>

                    <FromDatetime
                        id="deliveryDateId"
                        label={ReadTextResource.getTextById("FROM_DATETIME")}
                        dateRdc={[fromDatetimeSt, fromDatetimeStDis]}
                    ></FromDatetime>

                    <ToDatetime
                        id="ToDatetimeId1"
                        label={ReadTextResource.getTextById("TO_DATETIME")}
                        dateRdc={[toDatetimeSt, toDatetimeStDis]}
                    ></ToDatetime>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-info my-0 px-5 mb-1"
                        onClick={async () => {
                            await refetchDrvrsTraspReqs({
                                page: 1,
                                pageSize: 2,
                                reqId: null,
                                fromCountry: fromCountrySt.value?.code ?? null,
                                toCountry: toCountrySt.value?.code ?? null,
                                fromCity: (fromCityAutoCompleteSt.value?.id ?? null) === "" ? null : fromCityAutoCompleteSt?.value?.id,
                                toCity: (toCityAutoCompleteSt.value?.id ?? null) === "" ? null : toCityAutoCompleteSt?.value?.id,
                                fromDatetime: fromDatetimeSt.value ?? null,
                                toDatetime: toDatetimeSt.value ?? null,
                                reqType: null,
                                applicantSrcLoc: null,
                            });
                        }}
                    >
                        {ReadTextResource.getTextById("SEARCH")}
                    </button>
                </div>
            </div>
            <div>
                {searchDrvrsReqsData?.searchDrvrsReqs?.dataArr.map((e: any) => {
                    return <ReqListItem
                        key={e.reqId}
                        enableAprvRedBoxCount={false}
                        reqDtlInfo={e}
                        embedCmp={
                            <>
                                {e.reqType !== "Goods" && (
                                    <button
                                        key={"b" + e.reqId}
                                        type="button"
                                        className="btn btn-primary px-3"
                                        onClick={async () => {
                                            navigate("/app/psgrJoinTraspReq/" + e.reqId + "/" + e.reqSrcUserId + "/" + e.drvrDestLoc);
                                        }}
                                    >
                                        {ReadTextResource.getTextById("JOIN")}
                                    </button>
                                )}
                                <button
                                    key={"gds" + e.reqId}
                                    type="button"
                                    className="btn btn-primary px-3"
                                    onClick={async () => {
                                        navigate("/app/psgrAddGoods/" + e.reqId + "/" + e.reqSrcUserId + "/" + e.drvrDestLoc);
                                    }}
                                >
                                    {ReadTextResource.getTextById("ADD_GOODS")}
                                </button>
                            </>
                        }
                    />
                })}
                {searchDrvrsReqsData?.searchDrvrsReqs?.dataArr.length === 0 ? (
                    <div>{ReadTextResource.getTextById("SEARCH_RESULT")} </div>
                ) : (
                    ""
                )}
                <Pagination
                    key={"dd"}
                    className="mt-3"
                    count={Math.ceil(((searchDrvrsReqsData?.searchDrvrsReqs?.totalCount ?? 1) / 2))}
                    onChange={async (e, page) => {
                        await refetchDrvrsTraspReqs({
                            page: page,
                            pageSize: 2,
                            reqId: null,
                            fromCountry: fromCountrySt.value?.code ?? null,
                            toCountry: toCountrySt.value?.code ?? null,
                            fromCity: (fromCityAutoCompleteSt.value?.id ?? null) === "" ? null : fromCityAutoCompleteSt?.value?.id,
                            toCity: (toCityAutoCompleteSt.value?.id ?? null) === "" ? null : toCityAutoCompleteSt?.value?.id,
                            fromDatetime: fromDatetimeSt.value ?? null,
                            toDatetime: toDatetimeSt.value ?? null,
                            reqType: null,
                            applicantSrcLoc: null,
                        });
                    }}
                    color="primary"
                />
            </div>
        </div>
    );
}
