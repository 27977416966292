// import file from "./textResource.json";
import textResouce_ar from "./textResources/textResource_en.json"
import textResouce_en from "./textResources/textResource_en.json"

export class ReadTextResource {
  private static textResource: any;
  private static textResourceName: string;
  // public static getTextById(msgID: string, lang: string = "en") {
  //   async function getText() {
  //     try {
  //       const textResource = await import("./textResources/textResource_" + lang + ".json");
  //       console.log("ggggggggg=", textResource[msgID]);
  //       return textResource[msgID];
  //     } catch (err) {
  //       console.log(err);
  //       return null;
  //     }
  //   }

  //   return getText();
  // }
  public static async importTextResource(lang: string = "en") {
    try {
      if (ReadTextResource.textResourceName !== "./textResources/textResource_" + lang + ".json") {
        ReadTextResource.textResource = await import("./textResources/textResource_" + lang + ".json");
        ReadTextResource.textResourceName = "./textResources/textResource_" + lang + ".json";
        console.log(`getTextResource called...Current lang = ${lang}, ./textResources/textResource_${lang}.json`);
      }
    } catch (err) {
      console.log("err##, importTextResource", err);
      return null;
    }
  }

  public static getTextById(msgID: (keyof typeof textResouce_ar & keyof typeof textResouce_en)) {
    try {
      return ReadTextResource.textResource[msgID];
    } catch (err) {
      console.log("err##, getTextById", err);
    }
  }
}
