import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MouseEvent } from "react";
import { useParams } from "react-router-dom";
import { getDistanceInfo } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { PsgrVclSeatSelector } from "../commonComp/vehicle/PsgrVclSeatSelector";
import { TripDtls } from "../trip/TripDtls";

export function JoinTransportReqComp(props: React.PropsWithChildren<{}>) {
    console.log("First line - JoinTransportReqComp Component");
    const { pReqId, pDrvrUserId, pDrvrDestLoc } = useParams();
    console.log("parms", pReqId, pDrvrUserId);

    const {
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.SEARCH_DRVRS_REQS/* TRASP_REQ_DTL_N_ACCEPTED_GOODS *//* TRASP_REQ */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
            page: 1,
            pageSize: 1,
        },
    });

    const {
        JsxComp: PsgrVclSeatSelectorComp,
        compReducer: [psgrReservedSeatsSt, psgrReservedSeatsDis],
        compRef: psgrReservedSeatsRef,
    } = PsgrVclSeatSelector.getComp();

    const {
        JsxComp: CompanionCount,
        compReducer: [companionCountSt, companionCountDis],
        compRef: companionCountRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: IsCompanions,
        compReducer: [isCompanionsSt, isCompanionsDis],
        compRef: isCompanionsRef,
    } = FormCmps.RadioCmp.getComp("N");

    const {
        JsxComp: CollectionLocation,
        compReducer: [psgrSrcColctLocSt, psgrSrcColctLocDis],
        compRef: psgrSrcColctLocRef,
    } = FormCmps.LocationCmp.getComp(/* { lat: 31.165375, lng: 35.745947 } */);

    const {
        JsxComp: DropOffLocChoice,
        compReducer: [dropOffLocChoiceSt, dropOffLocChoiceDis],
        compRef: dropOffLocChoiceRef,
    } = FormCmps.RadioCmp.getComp("");

    const {
        JsxComp: CollectionLocType,
        compReducer: [collectionLocTypeSt, collectionLocTypeDis],
        compRef: collectionLocTypeRef,
    } = FormCmps.RadioCmp.getComp("");

    const {
        JsxComp: DropOffLocation,
        compReducer: [dropOffLocationSt, dropOffLocationStDis],
        compRef: dropOffLocationRef,
    } = FormCmps.LocationCmp.getComp();

    const [psgrJoinTrasp, { error: psgrJoinTraspErr, data: psgrJoinTraspData, loading: psgrJoinTraspLoading }] = useMutation(
        getGqlStmt().mutations.PSGR_JOIN_TRASP,
        {
            fetchPolicy: "no-cache",
        }
    );

    const [getTripPrice, { error: getTripPriceErr, data: getTripPriceData, loading: getTripPriceLoading }] = useLazyQuery(
        getGqlStmt().queries.TRIP_PRICE,
        {
            variables: {
                reqId: +pReqId!,
                reservedSeatsByUser: psgrReservedSeatsSt.value,
            },
            fetchPolicy: "no-cache",
        }
    );

    if (pReqId === undefined || pDrvrUserId === undefined || getReqDtlData?.searchDrvrsReqs?.dataArr?.length === 0) return <div>Invalid request parameters</div>;

    if (getReqDtlLoading) return <div>Loading...</div>

    async function isFormValid(): Promise<boolean> {
        let isPrevFormValid: boolean = true;
        const validationModal = new ModalComp.OkModal(
            { isPortal: false },
            { msg: ReadTextResource.getTextById("FILL_ALL_FLDS"), navTo: "" }
        );

        if (isCompanionsSt.value === "Y") {
            if (!companionCountSt.value) {
                companionCountDis({
                    type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                    errorMsg: ReadTextResource.getTextById("FILL_ALL_FLDS"),
                });
                if (isPrevFormValid) {
                    validationModal.setProps({
                        okClickHandler: () => {
                            companionCountRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await validationModal.showAsync();
                    isPrevFormValid = false;
                }
            }
        }

        if (collectionLocTypeSt.value === "psgrLoc")
            isPrevFormValid = await FormCmps.LocationCmp.validate(
                psgrSrcColctLocSt,
                psgrSrcColctLocDis,
                isPrevFormValid,
                validationModal,
                psgrSrcColctLocRef
            );

        if (dropOffLocChoiceSt.value === "psgrLoc")
            isPrevFormValid = await FormCmps.LocationCmp.validate(
                dropOffLocationSt,
                dropOffLocationStDis,
                isPrevFormValid,
                validationModal,
                dropOffLocationRef
            );

        if (psgrReservedSeatsSt.value?.length === 0) {
            psgrReservedSeatsDis({
                type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                errorMsg: ReadTextResource.getTextById("SLCT_SEAT"),
            });

            if (isPrevFormValid) {
                validationModal.setProps({
                    okClickHandler: () => {
                        psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await validationModal.showAsync();
                isPrevFormValid = false;
            }
        }
        if (companionCountSt?.value && +companionCountSt?.value !== 0) {
            if (+companionCountSt?.value !== PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value)) {
                psgrReservedSeatsDis({
                    type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                    errorMsg: ReadTextResource.getTextById("SEAT_NOT_MATCH"),
                });
                companionCountDis({
                    type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                    errorMsg: ReadTextResource.getTextById("SEAT_NOT_MATCH"),
                });

                if (isPrevFormValid) {
                    validationModal.setProps({
                        okClickHandler: () => {
                            psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await validationModal.showAsync();
                    isPrevFormValid = false;
                }
            }
        } else if (
            !companionCountSt?.value &&
            companionCountSt?.value === "" &&
            PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value) > 1
        ) {
            psgrReservedSeatsDis({
                type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                errorMsg: ReadTextResource.getTextById("SEAT_EXCEED"),
            });

            if (isPrevFormValid) {
                validationModal.setProps({
                    okClickHandler: () => {
                        psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                    },
                });
                // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                await validationModal.showAsync();
                isPrevFormValid = false;
            }
        }

        isPrevFormValid = await FormCmps.RadioCmp.validate(
            dropOffLocChoiceSt,
            dropOffLocChoiceDis,
            isPrevFormValid,
            validationModal,
            dropOffLocChoiceRef
        );

        return isPrevFormValid;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }

        // const psgrSrcToDestDistance = await getDistanceInfo({
        //     origins: [psgrSrcColctLocSt.location!],
        //     destinations: [dropOffLocationSt.location ?? (pDrvrDestLoc as Location)!],
        //     travelMode: google.maps.TravelMode.DRIVING,
        //     unitSystem: google.maps.UnitSystem.METRIC,
        //     avoidHighways: false,
        //     avoidTolls: false,
        // });

        // const psgrTripPrice = Math.round(psgrSrcToDestDistance.distance * 0.045 * 100) / 100;
        // const psgrTripEndTime = psgrSrcToDestDistance.duration;
        const psgrReservedSeatsWithLoc = psgrReservedSeatsSt.value?.map((e, i) => {
            e.destLoc = dropOffLocChoiceSt.value === "psgrLoc" ? dropOffLocationSt?.getLocationByMarker?.() : null;
            e.srcLoc = collectionLocTypeSt.value === "psgrLoc" ? psgrSrcColctLocSt?.getLocationByMarker?.() : null;
            e.tripDuration = 0;//psgrTripEndTime;
            e.distance = 0;//psgrSrcToDestDistance.distance;
            e.price = 0;
            return e;
        });

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        await spinner.showAsync();

        const joinReqOkModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
        await getTripPrice({
            onCompleted: async (tripPriceData) => {
                await spinner.hideAsync();
                if (tripPriceData.getTripPrice.errorId === "ERR_TRIP_OVERLAP") {
                    joinReqOkModal.setProps({ msg: tripPriceData.getTripPrice.errorDesc });
                    await joinReqOkModal.showAsync();
                    return;
                }

                const psgrReservedSeatsWithLoc = psgrReservedSeatsSt.value?.map((e, i) => {
                    e.destLoc = dropOffLocChoiceSt.value === "psgrLoc" ? dropOffLocationSt?.getLocationByMarker?.() : null;
                    e.srcLoc = collectionLocTypeSt.value === "psgrLoc" ? psgrSrcColctLocSt?.getLocationByMarker?.() : null;
                    e.tripDuration = 0;//psgrTripEndTime;
                    e.distance = 0;//psgrSrcToDestDistance.distance;
                    e.price = tripPriceData.getTripPrice.data.tripPrice;
                    return e;
                });

                const priceModalOk = new ModalComp.TwoBtnsModal(
                    { isPortal: false },
                    {
                        btn1Label: ReadTextResource.getTextById("OK"),
                        btn2Label: ReadTextResource.getTextById("CANCEL"),
                        msg:
                            ReadTextResource.getTextById("EST_PRICE") +
                            " " +
                            tripPriceData.getTripPrice.data.tripPrice +
                            " "
                            +
                            ReadTextResource.getTextById("TOT_PRICE") +
                            " " +
                            tripPriceData.getTripPrice.data.totalPrice,
                        clickHandlerBtn1: async () => {
                            await priceModalOk.hideAsync();
                            await spinner.showAsync();
                            psgrReservedSeatsDis({
                                type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
                                value: psgrReservedSeatsWithLoc,
                            });

                            await psgrJoinTrasp({
                                variables: {
                                    reqId: +pReqId!,
                                    reservedSeatsByUser: psgrReservedSeatsSt.value,
                                    // price: tripPriceData.getTripPrice.data.totalPrice
                                },
                                onCompleted: async (data) => {
                                    await spinner.hideAsync();
                                    if (data.joinTrasp.success) {
                                        joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("REQ_SENT_SUCCES") });
                                    } else {
                                        if (data.joinTrasp.errorId === "DUPLICATES_ERR")
                                            joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ALRDY_EXIST") });
                                        else if (data.joinTrasp.errorId === "ERR_MAX_JOIN_REQUEST")
                                            joinReqOkModal.setProps({ msg: data.joinTrasp.errorDesc });
                                        else if (data.joinTrasp.errorId === "ERR_TRIP_OVERLAP")
                                            joinReqOkModal.setProps({ msg: data.joinTrasp.errorDesc });
                                        else joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });
                                    }
                                    await joinReqOkModal.showAsync();
                                },
                                onError: async (err) => {
                                    await spinner.hideAsync();
                                    console.log("psgrJoinTrasp mutation is not successfull -abd- err=", err);
                                    joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });
                                    await joinReqOkModal.showAsync();
                                },
                            });
                        },
                        clickHandlerBtn2: async () => {
                            await spinner.hideAsync();
                            await priceModalOk.hideAsync();
                        },
                    }
                );
                await priceModalOk.showAsync();
            },
            onError: async (err) => {
                await spinner.hideAsync();
                console.log("getTripPrice query is not successfull -abd- err=", err);
                joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });
                await joinReqOkModal.showAsync();
            },
        });
    }
    return (
        <>
            <StickyBar id="joinTraspMainStickBar1">
                <FormCmps.FormSubmitBtn
                    id="submitBtn"
                    label={ReadTextResource.getTextById("SAVE")}
                    onOkClickHandler={onSaveHandler}
                ></FormCmps.FormSubmitBtn>
            </StickyBar>

            <TripDtls
                reqDtlType="JoiningDrvrReq"
                reqInfo={getReqDtlData?.searchDrvrsReqs?.dataArr[0]}
            />
            <div className="pb-1 text-center col-lg-12 col-md-12 col-sm-12 bg-info rounded-2">
                {/* <u> */}
                <b>{ReadTextResource.getTextById("ENTR_RESRV_INFO")}</b>
                {/* </u> */}
            </div>
            <FormCmps.ContainerNoFormCmp id="psgrJoinTraspReqId1">
                {/* <FormCmps.LabelComp.JsxComp id="passengerCompanionId1">اذا كان معك ركاب ادخل عددهم:</FormCmps.LabelComp.JsxComp> */}

                <IsCompanions
                    radioRdc={[isCompanionsSt, isCompanionsDis]}
                    id="isCompanionId1"
                    label={ReadTextResource.getTextById("DO_HAVE_COMPANION")}
                    radioDir={"horizontal"}
                    onChange={(e, v) => {
                        console.log("companion count=", companionCountSt.value);

                        if (v === "N") companionCountDis({ type: FormCmps.TextCmp.textActDisTypesT.ValueInput, value: "0" });
                    }}
                >
                    <FormCmps.RadioItem id="inTransRadio" label={ReadTextResource.getTextById("YES")} value="Y"></FormCmps.RadioItem>
                    <FormCmps.RadioItem id="outTransRadio" label={ReadTextResource.getTextById("NO")} value="N"></FormCmps.RadioItem>
                </IsCompanions>
                {isCompanionsSt.value === "Y" && (
                    <div>
                        <CompanionCount
                            id="companionCountId1"
                            label={ReadTextResource.getTextById("NO_OF_COMPANIONS")}
                            inputMode="number"
                            inputRef={companionCountRef}
                            textRdc={[companionCountSt, companionCountDis]}
                            regExpPattern="[0-9]*"
                            validateEmpty
                            valueTrakingOnChange={true}
                            validationsMsg={ReadTextResource.getTextById("ENTER_CORR_NUM")}
                        ></CompanionCount>
                    </div>
                )}

                <PsgrVclSeatSelectorComp
                    id="vclSeatSelectorId1"
                    label={ReadTextResource.getTextById("SLCT_SEAT")}
                    reqId={+pReqId}
                    drvrUserId={+pDrvrUserId}
                    seatRdc={[psgrReservedSeatsSt, psgrReservedSeatsDis]}
                    inputRef={psgrReservedSeatsRef}
                    onChange={() => {
                        psgrReservedSeatsDis({
                            type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                            errorMsg: "",
                        });
                        companionCountDis({
                            type: FormCmps.TextCmp.textActDisTypesT.ErrorMsg,
                            errorMsg: "",
                        });
                    }}
                ></PsgrVclSeatSelectorComp>

                <CollectionLocType
                    radioRdc={[collectionLocTypeSt, collectionLocTypeDis]}
                    id="collectionLocTypeStd1"
                    label={ReadTextResource.getTextById("SRC_LOC")}
                    radioDir={"horizontal"}
                    onChange={() => {
                        setTimeout(() => {
                            collectionLocTypeRef.current?.scrollIntoView({ block: "center" });
                        }, 200);
                    }}
                >
                    <FormCmps.RadioItem
                        id="collectionLocTypeStRadio"
                        label={"من موقع انطلاق الكابتن"}
                        value="drvrLoc"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem
                        id="outTransRadio"
                        label={"من موقعي"}
                        value="psgrLoc"
                    ></FormCmps.RadioItem>
                </CollectionLocType>

                {collectionLocTypeSt.value === "psgrLoc" &&
                    <CollectionLocation
                        id="psgrCollectionLocationId1"
                        locationRdc={[psgrSrcColctLocSt, psgrSrcColctLocDis]}
                        label={ReadTextResource.getTextById("SELECT_UR_LOC") + ":"}
                        inputRef={psgrSrcColctLocRef}
                    ></CollectionLocation>}

                <DropOffLocChoice
                    radioRdc={[dropOffLocChoiceSt, dropOffLocChoiceDis]}
                    id="dropOffLocChoiceId1"
                    label={ReadTextResource.getTextById("DEST_LOC")}
                    radioDir={"horizontal"}
                    onChange={() => {
                        setTimeout(() => {
                            dropOffLocationRef.current?.scrollIntoView({ block: "center" });
                        }, 200);
                    }}
                >
                    <FormCmps.RadioItem
                        id="inTransRadio"
                        label={ReadTextResource.getTextById("DROPOFF_LAST_STOP")}
                        value="drvr"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem
                        id="outTransRadio"
                        label={ReadTextResource.getTextById("I_SET_DROPOFF")}
                        value="psgrLoc"
                    ></FormCmps.RadioItem>
                </DropOffLocChoice>

                {dropOffLocChoiceSt.value === "psgrLoc" && (
                    <DropOffLocation
                        id="psgrDropOffLocId1"
                        locationRdc={[dropOffLocationSt, dropOffLocationStDis]}
                        label={ReadTextResource.getTextById("DEST_LOC")}
                        inputRef={dropOffLocationRef}
                    ></DropOffLocation>
                )}
            </FormCmps.ContainerNoFormCmp>
        </>
    );
}
