import React, { PropsWithChildren, useEffect, useState } from "react";
import { GoogleMap /* useJsApiLoader */, Marker } from "@react-google-maps/api";
// import { userInfoRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { SpinnerComp } from "../commonComp/SpinnerComp";
import { getAccessInfo, getDeviceCurrentLocation, getDirection } from "../../commonSrc/commonFns";
import { Waypoint } from "../../commonSrc/Types";
import { pinDarkBlue1 } from "../../commonSrc/Constants";

/* to be implemented in server-side, or no need to move it to server-side as google can force certian IP addresses
to use the api key */
// const GOOGLE_API_KEY = "AIzaSyDmwt9fU1rTI2d216WS3VF_vmHA3j2Jeyk";

export type Location = google.maps.LatLngLiteral | undefined;

export function clearMarkers(markers: google.maps.marker.AdvancedMarkerElement[]): void {
    markers.forEach((marker) => {
        marker.map = null;//setMap(null);
    });

    while (markers.length > 0) {
        markers.pop();
    }
}

const libs: any[] = ["places"];

export enum MapLocActDisTypesT {
    Location = "LOCATION",
    Map = "MAP",
    Marker = "MARKER",
    ErrorMsg = "ERROR_MSG",
}
export type MapStT = {
    map?: google.maps.Map | null;
    markers?: google.maps.marker.AdvancedMarkerElement[] | null;
    location?: Location;
    getLocationByMarker?: () => Location;
    errorMsg?: string;
};

export type MapActionT = { type: MapLocActDisTypesT } & MapStT;

export type MapDisT = (action: MapActionT) => void;

function GoogleMapDirCmp(
    props: PropsWithChildren<{
        //id is important to set, to get the map in fullscreen successfully
        id: string;
        waypoints?: Waypoint[] | undefined;
        mapLocRdc?: [MapStT, MapDisT | null];
        mapRef?: any;
    }>
) {
    console.log("###First line - GoogleMapDirCmp");

    let mapLocSt: MapStT = {};
    let mapLocDis: MapDisT | null;

    if (props.mapLocRdc) {
        [mapLocSt, mapLocDis] = props.mapLocRdc;
    }

    let currtDeviceLoc: any;
    let watchPositionId: any;
    // let isTrackCenter: boolean = false;
    const [arrowRotation, setArrowRotation] = useState(0);
    //-START- Fns declarations ************************************************************************************************************************************************
    const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null);
    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);
    const [mapSt, setMapSt] = React.useState<google.maps.Map>();
    // const [isTrackCenter, setIsTrackCenter] = useState(true);
    // let isTrackCenter: boolean = true;
    // // const [isCenterChangedByCurLocBtn, setIsCenterChangedByCurLocBtn] = useState(false);
    // let isCenterChangedByCurLocBtn: boolean = false;

    const onUnmount = React.useCallback(function callback(map: any) {
        console.log("unmount map called watchPositionId:", watchPositionId);
        navigator.geolocation.clearWatch(watchPositionId);

        setMapSt(undefined);
    }, []);

    const onLoad = React.useCallback(
        async function callback(map: google.maps.Map) {
            console.log("###onLoad map called");
            map.setOptions({ mapId: "209a84381de4fd18" });
            let isTrackCenter: boolean = true;
            let isCenterChangedByCurLocBtn: boolean = false;

            function registerOnClickCurrLocBtnOnMap(
                getCurrentLocationBtn: HTMLButtonElement,
                markers: google.maps.marker.AdvancedMarkerElement[],
                map: google.maps.Map
            ): void {
                getCurrentLocationBtn.addEventListener("click", async (e) => {
                    e.preventDefault();
                    // isTrackCenter = true;
                    // setIsTrackCenter(true);
                    // setIsCenterChangedByCurLocBtn(true);
                    isTrackCenter = true;
                    isCenterChangedByCurLocBtn = true;
                    console.log("get current location button is clicked, mapLocSt.location:", currtDeviceLoc);

                    try {
                        // const loc = await getDeviceCurrentLocation();
                        // if (loc) {

                        if (currtDeviceLoc) {
                            // mapLocDis?.({
                            //     type: MapLocActDisTypesT.Location,
                            //     location: loc,
                            // });

                            // clearMarkers(markers);

                            // markers.push(
                            //     new google.maps.marker.AdvancedMarkerElement({
                            //         map,
                            //         draggable: true,
                            //         // icon: svgMarker,
                            //         title: "Your Location",
                            //         position: mapLocSt.location,
                            //     })
                            // );

                            map.setCenter(currtDeviceLoc);
                            map.setZoom(12);
                        }
                    } catch (err) {
                        console.log("err in getCurrentPosition=,", err);
                    }
                });
            }

            function registerOnPlacesChangeLsnr(
                searchBox: google.maps.places.SearchBox,
                markers: google.maps.marker.AdvancedMarkerElement[],
                map: google.maps.Map
            ): void {
                searchBox.addListener("places_changed", (e: any) => {
                    // e.preventDefault();
                    const places = searchBox.getPlaces();
                    console.log("getPlaces=", places);

                    if (places?.length == 0) {
                        return;
                    }

                    clearMarkers(markers);

                    // For each place, get the icon, name and location.
                    const bounds = new google.maps.LatLngBounds();

                    places?.forEach((place) => {
                        if (!place.geometry || !place.geometry.location) {
                            console.log("Returned place contains no geometry");
                            return;
                        }

                        const svgMarker = {
                            path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                            fillColor: "blue",
                            fillOpacity: 0.6,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 2,
                            anchor: new google.maps.Point(0, 20),
                        };

                        const icon = {
                            url: place.icon as string,
                            size: new google.maps.Size(80, 80),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(20, 40),
                            scaledSize: new google.maps.Size(35, 50),
                        };
                        // console.log("icon=", icon);
                        // Create a marker for each place.
                        const placeMrker = new google.maps.marker.AdvancedMarkerElement({
                            map,
                            // icon: svgMarker,
                            title: place.name,
                            position: place.geometry.location,
                            content: new google.maps.marker.PinElement(pinDarkBlue1).element,

                        });
                        markers.push(placeMrker);

                        placeMrker.addListener("click", () => {
                            if (place?.geometry?.location) {
                                // setCurrentUserLocation({ lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() });
                                // setCurrentLocationValue({ lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() });
                                mapLocDis?.({
                                    type: MapLocActDisTypesT.Location,
                                    location: { lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() },
                                });

                                clearMarkers(markers);

                                markers.push(
                                    new google.maps.marker.AdvancedMarkerElement({
                                        map,
                                        gmpDraggable: true,
                                        // icon: svgMarker,
                                        title: "Your Location",
                                        position: { lng: place?.geometry?.location.lng(), lat: place?.geometry?.location.lat() },
                                        content: new google.maps.marker.PinElement(pinDarkBlue1).element,

                                    })
                                );
                            }
                        });

                        if (place.geometry.viewport) {
                            // Only geocodes have viewport.
                            bounds.union(place.geometry.viewport);
                        } else {
                            bounds.extend(place.geometry.location);
                        }
                    });
                    map.fitBounds(bounds);
                    // map.setZoom(1);
                });
            }

            function registerOnClickMapLsnr(map: google.maps.Map, markers: google.maps.marker.AdvancedMarkerElement[]): void {
                /*  map.addListener("click", (e: any) => {
                console.log("Google Map on click fired");
    
                // setCurrentLocationValue({ lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number });
                mapLocDis?.({
                    type: MapLocActDisTypesT.Location,
                    location: { lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number },
                });
                map?.panTo({ lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number });
                // Clear out the old markers.
                clearMarkers(markers);
    
                markers.push(
                    new google.maps.marker.AdvancedMarkerElement({
                        map,
                        draggable: true,
                        // icon: svgMarker,
                        title: "Your Location",
                        position: { lat: e.latLng?.lat() as number, lng: e.latLng?.lng() as number },
                    })
                );
                // map?.setZoom(12);
                // map?.setZoom(1);
            }); */
            }

            function createCurrentLocBtnOnMap() {
                const getCurrentLocationBtn = document.createElement("button");
                getCurrentLocationBtn.type = "button";
                getCurrentLocationBtn.style.backgroundColor = "yellow";
                getCurrentLocationBtn.style.color = "black";
                getCurrentLocationBtn.style.border = "none";
                getCurrentLocationBtn.style.outline = "none";
                getCurrentLocationBtn.style.width = "40px";
                getCurrentLocationBtn.style.height = "40px";
                getCurrentLocationBtn.style.borderRadius = "2px";
                getCurrentLocationBtn.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
                getCurrentLocationBtn.style.cursor = "pointer";
                getCurrentLocationBtn.style.marginRight = "10px";
                getCurrentLocationBtn.style.padding = "0px";
                getCurrentLocationBtn.title = "Your Location";
                getCurrentLocationBtn.className = "display-6 bi bi-geo-alt-fill";
                return getCurrentLocationBtn;
            }

            function createExitFullScreenBtnOnMap(mapId: string) {
                const ExistFullScreencBtn = document.createElement("button");
                ExistFullScreencBtn.id = mapId + "FullScrnOnMapBtn";
                ExistFullScreencBtn.type = "button";
                ExistFullScreencBtn.style.backgroundColor = "white";
                ExistFullScreencBtn.style.color = "black";
                ExistFullScreencBtn.style.border = "none";
                ExistFullScreencBtn.style.outline = "none";
                ExistFullScreencBtn.style.width = "40px";
                ExistFullScreencBtn.style.height = "40px";
                ExistFullScreencBtn.style.borderRadius = "2px";
                ExistFullScreencBtn.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
                ExistFullScreencBtn.style.cursor = "pointer";
                ExistFullScreencBtn.style.marginRight = "10px";
                ExistFullScreencBtn.style.padding = "0px";
                ExistFullScreencBtn.title = "Close";
                ExistFullScreencBtn.className = "display-6 bi bi-arrows-fullscreen";

                ExistFullScreencBtn.addEventListener("click", (e) => {
                    console.log("ExitFullScreen btn is clicked");
                    let isFullscreenExcuted = false;
                    document
                        ?.exitFullscreen()
                        .then(() => {
                            // if (!isFullscreenExcuted) {
                            ExistFullScreencBtn.className = "display-6 bi bi-arrows-fullscreen";
                            // ExistFullScreencBtn.scrollIntoView();
                            // setTimeout(() => {
                            document.getElementById(mapId)?.scrollIntoView({ block: "center" });
                            // }, 300);
                            // document.getElementById(mapId)?.scrollIntoView();
                            // }
                        })
                        .catch((err) => {
                            //screen changed to fullscreen
                            isFullscreenExcuted = true;
                            ExistFullScreencBtn.className = "display-6 bi bi-fullscreen-exit";

                            const mapEl = document.getElementById(mapId) as any;
                            if (mapEl?.requestFullscreen) {
                                mapEl?.requestFullscreen();
                            } else if (mapEl?.webkitRequestFullscreen) {
                                /* Safari */
                                mapEl?.webkitRequestFullscreen();
                            } else if (mapEl?.msRequestFullscreen) {
                                /* IE11 */
                                mapEl?.msRequestFullscreen();
                            }
                        });
                    //exist full screen is executed
                });
                return ExistFullScreencBtn;
            }

            async function getBoundBasedOnCountry(bounds: google.maps.LatLngBounds, map: google.maps.Map, countryId: string) {
                const geocoder = new google.maps.Geocoder();
                try {
                    console.log("###countryId in geocode function=", countryId);
                    await geocoder.geocode({ address: countryId, componentRestrictions: { country: countryId } }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK && results?.length && results?.length > 0 && results[0].geometry) {
                            // setCountryBounds(results[0].geometry.bounds);
                            bounds = results[0].geometry.bounds!!;
                            map.fitBounds(results[0].geometry.bounds!!);
                            map.setZoom(7);
                        } else console.log("results[0].geometry.bounds=nothing");
                    });
                } catch (error) {
                    console.error("Error fetching user country:", error);
                }
                return bounds;
            }
            function createInputElement() {
                const input = document.createElement("input") as HTMLInputElement;
                input.id = "inputOfGoogleMapSearchBoxId";
                input.style.boxSizing = "border-box";
                input.style.border = "1px solid red";
                input.placeholder = "Search for a place";
                input.style.width = "240px";
                input.style.height = "32px";
                input.style.padding = "12px";
                input.style.borderRadius = "3px";
                input.style.boxShadow = "0 2px 6px rgba(0; 0; 0; 0.3)";
                input.style.fontSize = "14px";
                // input.style.outline = "";
                input.style.textOverflow = "ellipses";
                // input.style.position = "absolute";
                // input.style.left = "50%";
                input.style.marginLeft = "10px";
                input.style.zIndex = "9999999999";
                return input;
            }

            function calculateArrowRotation(prevLocation: Location, currentLocation: Location) {
                if (!prevLocation || !currentLocation) return 0;

                const prevLatLng = new window.google.maps.LatLng(prevLocation);
                const currentLatLng = new window.google.maps.LatLng(currentLocation);

                const heading = window.google.maps.geometry.spherical.computeHeading(prevLatLng, currentLatLng);

                return heading;
            }

            if ("geolocation" in navigator) {
                let currLocByWatcher = null;
                const locationAllowed = await navigator.permissions.query({ name: "geolocation" });
                if (locationAllowed.state === "denied") {
                    alert("Location is blocked for this app, Please change your settings to allow location access.");
                    //in browser, you can open the location settings to let the user opening the gps location
                    // window.location.href = "app-settings:location";
                    return;
                }
                //if prevLoc is not working in futute, then you can use a ref with state, read about stale-closure
                let prevLoc: any;

                watchPositionId = navigator.geolocation.watchPosition(
                    async function (position) {
                        console.log("inside getCurrentPosition");

                        currLocByWatcher = { lat: position.coords.latitude, lng: position.coords.longitude };
                        // const rot = calculateArrowRotation(mapLocSt.location, loc);
                        const rotation = calculateArrowRotation(prevLoc, currLocByWatcher);
                        console.log("loc in wp:", currLocByWatcher, "PrevLoc:", prevLoc, "ArrowRotation:", rotation);
                        prevLoc = currLocByWatcher;
                        setArrowRotation(rotation);
                        console.log("XXmapLocSt.location:", mapLocSt.location, "curLocSt:", prevLoc);
                        mapLocDis?.({ type: MapLocActDisTypesT.Location, location: currLocByWatcher });
                        currtDeviceLoc = currLocByWatcher;

                        if (isTrackCenter) {
                            map?.panTo(currLocByWatcher);
                            map?.setZoom(16);
                        }

                        // map.setCenter(loc);
                        // map.setZoom(15);
                        // markers[0]?.setPosition(loc);
                    },
                    async (err) => {
                        console.log("err in getCurrentPosition=,", err);
                        console.log("err in getCurrentPosition=,", err);

                        // throw err;
                    },
                    { enableHighAccuracy: true, timeout: 100, maximumAge: 100 }
                );
            } else {
                alert("Geolocation is not supported on this device, please use the map to determine the location");
            }

            const input = createInputElement();
            const searchBox = new window.google.maps.places.SearchBox(input);
            setSearchBox(searchBox);
            const getCurrentLocationBtn = createCurrentLocBtnOnMap();
            const ExistFullScreen = createExitFullScreenBtnOnMap(props.id);

            searchBox.setBounds(currentBounds);
            let markers: google.maps.marker.AdvancedMarkerElement[] = [];

            //to be updated ########
            let bounds = new window.google.maps.LatLngBounds();
            currtDeviceLoc = await getDeviceCurrentLocation();
            if (mapLocDis) mapLocDis?.({ type: MapLocActDisTypesT.Location, location: currtDeviceLoc! });
            else alert("mapLocDis is exist");
            console.log("currtDeviceLoc:", currtDeviceLoc, "mapLocSt.location:", mapLocSt.location);

            if (!currtDeviceLoc) {
                if (getAccessInfo()?.user?.country) bounds = await getBoundBasedOnCountry(bounds, map, getAccessInfo()?.user?.country!!);
                else throw new Error("Error, country is no there abd109");
            } else {
                // markers.push(
                //     new google.maps.marker.AdvancedMarkerElement({
                //         map,
                //         // icon: { size: { height: 10, width: 10, } },
                //         draggable: true,
                //         title: "Your Location",
                //         position: currtDeviceLoc,
                //     })
                // );
                //below zoom and center will be overriden by directions renderer
                // map.setCenter(currtDeviceLoc);
                // map.setZoom(20);
            }

            map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(getCurrentLocationBtn);
            map.controls[google.maps.ControlPosition.LEFT_TOP].push(input);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ExistFullScreen);

            //-START- event listeners
            registerOnClickMapLsnr(map, markers);

            registerOnPlacesChangeLsnr(searchBox, markers, map);

            registerOnClickCurrLocBtnOnMap(getCurrentLocationBtn, markers, map);

            map.addListener("center_changed", () => {
                // isTrackCenter = false;
                if (isCenterChangedByCurLocBtn) {
                    isTrackCenter = true;
                } else isTrackCenter = false;

                // setIsCenterChangedByCurLocBtn(false);
                // isCenterChangedByCurLocBtn = false;
                console.log("center changed");
            });

            map.addListener("drag", () => {
                isCenterChangedByCurLocBtn = false;
            });

            const directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true, map: map });
            // directionsRenderer.setMap(map);


            const wyps = [];//props.waypoints;
            // wyps?.push(currtDeviceLoc);
            // wyps?.push({ location: { lat: 31.092648, lng: 35.696324 }, stopover: true, text: "Captain" });

            if (props.waypoints)
                for (let i = 0; i < props.waypoints.length; i++) {
                    wyps.push({ location: props.waypoints[i].location, stopover: props.waypoints[i].stopover })
                    const icon = document.createElement('div');
                    // icon.innerHTML = '<i class="bi bi-person-arms-up fs-1"> </i>';
                    icon.innerHTML =
                        `<div>
                        <div class="text-center fs-6 fw-bolder" style="color:blue; line-height:0.5">${props.waypoints[i].text}</div>
                        <div class="text-center bi bi-person-standing fw-bolder" style="font-size:40px; color:blue" ></div>
                    </div>`;

                    //custom pin, you can use it as well
                    // const faPin = new google.maps.marker.PinElement({
                    //     glyph: "Passenger",//icon,
                    //     scale: 1.5,
                    //     // glyphColor: '#ff8300',
                    //     // background: '#FFD514',
                    //     // borderColor: '#ff8300',
                    // });

                    const mmm = new google.maps.marker.AdvancedMarkerElement({
                        map: map,
                        // icon: svgMarker,
                        title: "Passenger",
                        position: props.waypoints[i].location,//place.geometry.location,
                        content: icon,//faPin.element,//new google.maps.marker.PinElement(pinDarkBlue1).element,
                        // zIndex:99999
                    });
                }

            // const startIcon = document.createElement('div');
            // startIcon.innerHTML =
            //     `<div>
            //         <div class="fs-6 text-center fw-bolder" style="color:blue; line-height:0.5">Start</div>
            //         <div class="bi bi-geo-alt text-center fw-bolder" style="font-size:40px; color:blue" ></div>
            //     </div>`;

            // new google.maps.marker.AdvancedMarkerElement({
            //     map: map,
            //     // icon: svgMarker,
            //     title: "Passenger",
            //     position: currtDeviceLoc,//place.geometry.location,
            //     content: startIcon
            //     // zIndex:99999
            // });

            const endIcon = document.createElement('div');

            endIcon.innerHTML =
                `<div>
                        <div class="fs-6 text-center fw-bolder" style="color:blue; line-height:0.5">End</div>
                        <div class="bi bi-geo-alt text-center fw-bolder" style="font-size:40px; color:blue" ></div>
                    </div>`;


            new google.maps.marker.AdvancedMarkerElement({
                map: map,
                // icon: svgMarker,
                title: "Passenger",
                position: { lat: 31.092648, lng: 35.696324 },//place.geometry.location,
                content: endIcon
                // zIndex:99999
            });
            const dirRequest = {
                origin: currtDeviceLoc,
                destination: { lat: 31.092648, lng: 35.696324 },
                // destination: {"lat": 31.075828, "lng": 35.695119},//{"lat": 31.092866, "lng": 35.642558},//props.waypoints?.[0].location,
                waypoints: wyps,
                travelMode: google.maps.TravelMode.DRIVING,
                // provideRouteAlternatives: true,
                optimizeWaypoints: true,
            };

            const res = await getDirection(dirRequest);
            if (res) {
                directionsRenderer.setDirections(res);
            }

            map.setZoom(18);
            map.setCenter(currtDeviceLoc!);

            mapLocDis?.({ type: MapLocActDisTypesT.Map, map: map });
            setMapSt(map);
            //-END- event listeners
        },
        [props.id]
    );

    console.log("XXkey:", props.id);

    //-START- JSX Component ************************************************************************************************************************************************
    return /* isLoaded && */ getAccessInfo()?.user?.country ? (
        <div ref={props.mapRef} className="py-3">
            <GoogleMap
                //setting key will force to refresh the google map
                // key={props.id}
                options={{ gestureHandling: "greedy", streetViewControl: false, fullscreenControl: false }}
                id={props.id}
                clickableIcons={true}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                // center={currentLocationValue}
                // zoom={20}
                // tilt={40}
                // heading={90}
                onLoad={onLoad}
                onBoundsChanged={() => {
                    console.log("onBoundsChanged=", mapSt?.getBounds());
                    // map?.fitBounds(map?.getBounds()!!);
                    setCurrentBounds(mapSt?.getBounds() || null);
                    searchBox?.setBounds(mapSt?.getBounds() || null);
                }}
                onUnmount={onUnmount}
            >
                <Marker
                    position={mapLocSt.location!}
                    // position={{ lat: 31.981054, lng: 35.913491 }}
                    // options={rotation}
                    icon={{
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 6,
                        rotation: arrowRotation,
                        // fillColor:"blue",
                        strokeColor: "blue",
                    }}
                />
            </GoogleMap>
        </div>
    ) : (
        <>
            Loading...
            <SpinnerComp size="sm"></SpinnerComp>
            {/* <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> */}
        </>
    );
    //-END- JSX Component ************************************************************************************************************************************************
}

export default React.memo(GoogleMapDirCmp);
// export default GoogleMapDirCmp;
