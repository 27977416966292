import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addOffsetOnLocation, getDeviceCurrentLocation } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { Waypoint } from "../../commonSrc/Types";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { StickyBar } from "../commonComp/StickyBar";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { Button } from "../commonComp/Button";

export function DrvrTraspReqDtl(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrTraspReqDtl Component");
    const { pReqId, pDrvrId } = useParams();

    useEffect(() => {
        // async function g() {
        //     return await getDeviceCurrentLocation();
        // }
        // loc = g();
    }, []);

    const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

    const [pickup, setPickup] = useState(false);

    const [showMap, setShowMap] = useState(false);

    const [psgrNames, setPsgrNames] = useState<any[]>([]);

    async function isFormValid(): Promise<boolean> {
        return true;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
    }

    let loc: any;

    const {
        JsxComp: DrvrMapDirections,
        compReducer: [drvrMapDirectionsSt, drvrMapDirectionsDis],
        compRef: drvrMapDirectionsRef,
    } = FormCmps.MapDirectionsComp.getComp();

    // const {
    //     JsxComp: DrvrJoinedPsgrsComp,
    //     compReducer: [drvrJoinedPsgrsSt, drvrJoinedPsgrsDis],
    //     compRef: drvrJoinedPsgrsRef,
    // } = DrvrJoinedPsgrsCmp.getComp();

    const {
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.DRVR_RDY_REQ_DTL, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
    });

    const [getTraspReqWaypoints, { error: getTraspReqWaypointsErr, data: getTraspReqWaypointsData, loading: getTraspReqWaypointsLoading }] =
        useLazyQuery(getGqlStmt().queries.TRASP_REQ_WAYPOINTS, {
            fetchPolicy: "no-cache",
            variables: {
                reqId: +pReqId!,
            },
            onCompleted: async (data) => {
                let waypoints: Waypoint[] = [];
                (data.getTraspReqJoinedPsgrs.dataArr as { srcLoc: Location; destLoc: Location, user: any }[]).forEach((e, i) => {
                    const psgrName = e.user.userName1Ar ?? e.user.userName1En ?? (dirAndLangRvar().lang === "ar" ? "راكب" : "Passenger")
                    psgrNames.push(e.user);
                    setPsgrNames([...psgrNames]);
                    if (pickup) {
                        if (e.srcLoc) {
                            const numberOfOccurrences = waypoints.filter(
                                (item) => item.location?.lat === e.srcLoc?.lat && item.location?.lng === e.srcLoc?.lng
                            ).length;
                            waypoints.push({
                                location: {
                                    lat: numberOfOccurrences > 0 ? addOffsetOnLocation(e.srcLoc.lat) : e.srcLoc.lat,
                                    lng: e.srcLoc.lng, //+ Math.round((Math.random() * (0.000090 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                },
                                stopover: true,
                                text: psgrName
                            });
                        }
                        if (e.destLoc) {
                            const numberOfOccurrences = waypoints.filter(
                                (item) => item.location?.lat === e.destLoc?.lat && item.location?.lng === e.destLoc?.lng
                            ).length;
                            waypoints.push({
                                location: {
                                    lat: numberOfOccurrences > 0 ? addOffsetOnLocation(e.destLoc.lat) : e.destLoc.lat,
                                    // e.destLoc.lat + Math.round((Math.random() * (0.00009 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                    lng: e.destLoc.lng, //+ Math.round((Math.random() * (0.000090 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                },
                                stopover: true,
                                text: psgrName
                            });
                        }
                    } else {
                        if (e.destLoc) waypoints.push({ location: e.destLoc, stopover: true, text: psgrName });
                    }
                });
                setWaypoints(waypoints);
                console.log("waypoints:", waypoints);
            },
        });

    const [
        tripCompleted,
        { error: tripCompletedErr, data: tripCompletedData, loading: tripCompletedLding },
    ] = useMutation(getGqlStmt().mutations.PSGR_TRIP_COMPLETED, {
        fetchPolicy: "no-cache",
    });


    if (getTraspReqWaypointsLoading || getReqDtlLoading) return <div>loading ...</div>;

    // if (!pReqId || !pDrvrId || isNaN(parseInt(pReqId)) || isNaN(parseInt(pDrvrId)) || getReqDtlErr)
    //     return <div>Invalid request parameters</div>;

    //newly added, not tested
    if (!getReqDtlData || !getReqDtlData?.getDrvrRdyTrips?.success) return <div>{ReadTextResource.getTextById("NO_DATA")!}</div>;

    return (
        <>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            setPickup(true);
                            setPsgrNames([]);
                            await getTraspReqWaypoints();
                            setShowMap(false);
                            setTimeout(() => {
                                setShowMap(true);
                            }, 200);
                        }}
                    >
                        تحميل الركاب ثم بدء الرحلة
                    </button>
                </div>
                {!pickup && (
                    <div className="text-center ms-auto">
                        <button
                            type="button"
                            className="p-2 btn btn-primary"
                            onClick={async () => {
                                setPickup(false);
                                await getTraspReqWaypoints();
                                setShowMap(false);
                                setTimeout(() => {
                                    setShowMap(true);
                                }, 200);
                            }}
                        >
                            بدء الرحلة
                        </button>
                    </div>
                )}
            </StickyBar>
            {showMap && (
                <DrvrMapDirections
                    id={`dirMapDrvrId1_pkup_${pickup}`}
                    waypoints={waypoints}
                    inputRef={drvrMapDirectionsRef}
                    locationRdc={[drvrMapDirectionsSt, drvrMapDirectionsDis]}
                    label="رحلة عمان الكرك"
                ></DrvrMapDirections>
            )}
            <div className="row col-12">{psgrNames.map((e) => {
                return <>
                    <div className="col-6">{e.userName1Ar}</div>
                    <div className="col-6 py-1">
                        <Button
                            id="tripCompleteBtn1"
                            label="الرحلة اكتملت"
                            onOkClickHandler={async () => {
                                const trpCmpltRes = await tripCompleted({
                                    variables: {
                                        tripId: +pReqId!,
                                        psgrUserId: e.userId
                                    }
                                });
                                if (trpCmpltRes.data.psgrTripCompleted.success)
                                    console.log("##success complete");



                            }}></Button>
                    </div >
                </>
            })}</div >
            <TripDtls reqDtlType="DrvrSessionOnly" reqInfo={getReqDtlData?.getDrvrRdyTrips?.dataArr[0]}
                goodsCmp={
                    <GoodsDtlsCmp showApproval={false} pGoodsData={getReqDtlData?.getDrvrRdyTrips?.dataArr[0]?.tripApprovals} />}
            >
            </TripDtls>
        </>
    );
}
