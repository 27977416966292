import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { chattingUsersRVar, receivedMsgRVar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { getAccessInfo } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { User } from "../../commonSrc/Types";

export function AllChat(props: React.PropsWithChildren<{}>) {
    console.log("First line - AllChat Component");
    const navigate = useNavigate();
    useReactiveVar(receivedMsgRVar);
    useReactiveVar(chattingUsersRVar);

    useEffect(() => {
        async function fnUseEfct() {}
        fnUseEfct();
    }, []);

    // if (getUserChatUsersLoading) return <div>Loading...</div>;
    if (chattingUsersRVar().length === 0) return <div>No chats</div>;

    return (
        <div>
            <div>
                {chattingUsersRVar()?.map((e, i) => {
                    return (
                        <div
                            className="mt-1 border border-4 border-info rounded-bottom text-center"
                            style={{cursor:"pointer"}}
                            key={e.userId + "_id"}
                            onClick={() => {
                                if (getAccessInfo()?.user?.driver?.drvrId) navigate("/app/drvrChat", { state: { toUserId: e.userId } });
                                else navigate("/app/psgrChat", { state: { toUserId: e.userId } });
                            }}
                        >
                            {`ChatUser: ${e?.userId} ${e.hasNewMsg ? "New Message" : ""}`}
                        </div>
                    );
                })}
            </div>
            {/* <div
                onClick={() => {
                    if (getAccessInfo()?.user?.driver?.drvrId) navigate("/app/drvrChat", { state: { toUserId: 22 } });
                    else navigate("/app/psgrChat", { state: { toUserId: 22 } });
                }}
            >
                User1
            </div> */}
        </div>
    );
}
