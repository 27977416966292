import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { FormCmps } from "../commonComp/FormComp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { PsgrVclSeatSelector } from "../commonComp/vehicle/PsgrVclSeatSelector";
import { MouseEvent } from "react";
import { getDistanceInfo } from "../../commonSrc/commonFns";
import { Location } from "../commonComp/GoogleMapCmp";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";

export function TakeDecisionOnDrvrReq(props: React.PropsWithChildren<{}>) {
    console.log("First line - TakeDecisionOnDrvrReq Component");
    const navigate = useNavigate();

    const location = useLocation();

    const [psgrJoinTrasp, { error: psgrJoinTraspErr, data: psgrJoinTraspData, loading: psgrJoinTraspLoading }] = useMutation(
        getGqlStmt().mutations.PSGR_JOIN_TRASP,
        {
            fetchPolicy: "no-cache",
        }
    );
    const [psgrAcceptRcvdDrvrGoodsReq, { error: psgrAcceptRcvdDrvrGoodsReqErr, data: psgrAcceptRcvdDrvrGoodsReqDta, loading: psgrAcceptRcvdDrvrGoodsReqLding }] = useMutation(
        getGqlStmt().mutations.PSGR_ACCEPT_RCVD_DRVR_GOODS_REQ,
        {
            fetchPolicy: "no-cache",
        }
    );

    let state = location.state as any;
    let tripId: any;
    let tripInfo: any;
    let acceptanceInfo: any;
    let companionCount = 0;
    if (state) {
        tripId = state.tripId;
        // drvrId = state.drvrId;
        acceptanceInfo = state.acceptanceInfo;
        console.log("acceptanceInfo", acceptanceInfo);

        tripInfo = state.tripInfo;
        companionCount = (tripInfo.fPsgrCompanions ?? 0) + (tripInfo.mPsgrCompanions ?? 0) + 1;

        if (!tripId || !tripInfo || !acceptanceInfo) return <div>Invalid state passed</div>;
    } else return <div>Invalid state passed</div>;

    const {
        JsxComp: DrvrSrcLocation,
        compReducer: [drvrSrcLocationSt, drvrSrcLocationDis],
        compRef: drvrSrcLocationRef,
    } = FormCmps.LocationCmp.getComp(acceptanceInfo?.drvrSrcLoc);

    const {
        JsxComp: DrvrDestLocation,
        compReducer: [drvrDestLocationSt, drvrDestLocationDis],
        compRef: drvrDestLocationRef,
    } = FormCmps.LocationCmp.getComp(acceptanceInfo?.drvrDestLoc);

    const {
        JsxComp: PsgrVclSeatSelectorComp,
        compReducer: [psgrReservedSeatsSt, psgrReservedSeatsDis],
        compRef: psgrReservedSeatsRef,
    } = PsgrVclSeatSelector.getComp();

    console.log("passedState in takeDecision drvrInfo", acceptanceInfo, "tripId", tripId, "tripInfo", tripInfo);

    async function isFormValid(): Promise<boolean> {
        let isPrevFormValid: boolean = true;
        const validationModal = new ModalComp.OkModal({ isPortal: false }, { msg: "Please fill all required fields in red", navTo: "" });
        if (tripInfo?.reqType !== "Goods") {
            if (psgrReservedSeatsSt.value?.length === 0) {
                psgrReservedSeatsDis({ type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg, errorMsg: "select your seat" });

                if (isPrevFormValid) {
                    validationModal.setProps({
                        okClickHandler: () => {
                            psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await validationModal.showAsync();
                    isPrevFormValid = false;
                }
            }
            if (companionCount !== 0) {
                if (companionCount !== PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value)) {
                    psgrReservedSeatsDis({
                        type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                        errorMsg: "seats reserved doesn't match the selected companions",
                    });

                    if (isPrevFormValid) {
                        validationModal.setProps({
                            okClickHandler: () => {
                                psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await validationModal.showAsync();
                        isPrevFormValid = false;
                    }
                } else if (
                    PsgrVclSeatSelector.get_M_F_psgrReservedSeatsCount(psgrReservedSeatsSt.value)[1] <
                    (tripInfo.fPsgrCompanions ?? 0)
                ) {
                    psgrReservedSeatsDis({
                        type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                        errorMsg: "عدد مقاعد الاناث التي حجزتها لا يطابق عدد المرافقين الاناث",
                    });

                    if (isPrevFormValid) {
                        validationModal.setProps({
                            okClickHandler: () => {
                                psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await validationModal.showAsync();
                        isPrevFormValid = false;
                    }
                } else if (
                    PsgrVclSeatSelector.get_M_F_psgrReservedSeatsCount(psgrReservedSeatsSt.value)[0] <
                    (tripInfo.mPsgrCompanions ?? 0)
                ) {
                    psgrReservedSeatsDis({
                        type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                        errorMsg: "عدد مقاعد الذكور التي حجزتها لا يطابق عدد المرافقين الذكور",
                    });

                    if (isPrevFormValid) {
                        validationModal.setProps({
                            okClickHandler: () => {
                                psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                            },
                        });
                        // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                        await validationModal.showAsync();
                        isPrevFormValid = false;
                    }
                }
            } else if (companionCount === 0 && PsgrVclSeatSelector.getPsgrReservedSeatsCount(psgrReservedSeatsSt.value) > 1) {
                psgrReservedSeatsDis({
                    type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                    errorMsg:
                        "You reserved more than one seat, please select only one, if you have companions please select Yes in the companion field above and enter the number of companions",
                });

                if (isPrevFormValid) {
                    validationModal.setProps({
                        okClickHandler: () => {
                            psgrReservedSeatsRef.current?.scrollIntoView({ block: "center" });
                        },
                    });
                    // await ModalComp.showAsync(okModalInstance);//commented recently and not tested
                    await validationModal.showAsync();
                    isPrevFormValid = false;
                }
            }
        }

        return isPrevFormValid;
    }

    async function onAccept() {
        if (!(await isFormValid())) {
            return;
        }

        const joinReqOkModal = new ModalComp.OkModal({ isPortal: false }, { msg: "Sorry, an error occurred" });

        console.log(
            "applicantSrcLoc",
            tripInfo?.applicantSrcLoc ?? acceptanceInfo?.drvrSrcLoc,
            "applicantDestLoc",
            tripInfo?.applicantDestLoc ?? acceptanceInfo?.drvrDestLoc
        );

        if (tripInfo?.reqType === "Goods")
            await psgrAcceptRcvdDrvrGoodsReq({
                variables: {
                    reqId: +tripId,
                    drvrUserId: +(acceptanceInfo?.sourceUserId),
                },
                onCompleted: async (data) => {
                    if (data.psgrAcceptRcvdDrvrGoodsReq.success) {
                        joinReqOkModal.setProps({
                            msg: "Request sent successfully",
                            okClickHandler: () => {
                                navigate("/app/dashboard");
                            },
                        });
                    } else {
                        if (data.psgrAcceptRcvdDrvrGoodsReq.errorId === "DUPLICATES_ERR") joinReqOkModal.setProps({ msg: "Already exist" });
                        else if (data.psgrAcceptRcvdDrvrGoodsReq.errorId === "ERR_MAX_JOIN_REQUEST") joinReqOkModal.setProps({ msg: data.psgrAcceptRcvdDrvrGoodsReq.errorDesc });
                        else if (data.psgrAcceptRcvdDrvrGoodsReq.errorId === "ERR_TRIP_OVERLAP") joinReqOkModal.setProps({ msg: data.psgrAcceptRcvdDrvrGoodsReq.errorDesc });
                        else joinReqOkModal.setProps({ msg: "Sorry, an error occurred, onComplete" });
                    }
                    joinReqOkModal.showAsync();
                },
                onError: async (err) => {
                    console.log("psgrAcceptRcvdDrvrGoodsReq mutation is not successfull -abd- err=", err);
                    joinReqOkModal.setProps({ msg: "Sorry, an error occurred" + err });
                    joinReqOkModal.showAsync();
                },
            });
        else if (tripInfo?.reqType !== "Goods") {

            const psgrSrcToDestDistance = await getDistanceInfo({
                origins: [tripInfo?.applicantSrcLoc ?? acceptanceInfo?.drvrSrcLoc],
                destinations: [tripInfo?.applicantDestLoc ?? acceptanceInfo?.drvrDestLoc],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            });

            const psgrTripEndTime = psgrSrcToDestDistance.duration;

            const psgrReservedSeatsWithLoc = psgrReservedSeatsSt.value?.map((e, i) => {
                const srcLoc = (tripInfo?.applicantSrcLoc ?? acceptanceInfo?.drvrSrcLoc) as Location;
                const destLoc = (tripInfo?.applicantDestLoc ?? acceptanceInfo?.drvrDestLoc) as Location;
                e.srcLoc = { lat: srcLoc?.lat!, lng: srcLoc?.lng! };
                e.destLoc = { lat: destLoc?.lat!, lng: destLoc?.lng! };
                e.tripDuration = psgrTripEndTime;
                e.distance = psgrSrcToDestDistance.distance;
                return e;
            });
            psgrReservedSeatsDis({
                type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
                value: psgrReservedSeatsWithLoc,
            });
            console.log("psgrReservedSeatsSt.value", psgrReservedSeatsSt.value);

            let srcLocV = null;
            let destLocV = null;
            if (acceptanceInfo?.drvrSrcLoc)
                srcLocV = { lat: acceptanceInfo?.drvrSrcLoc.lat, lng: acceptanceInfo?.drvrSrcLoc.lng };
            if (acceptanceInfo?.drvrDestLoc)
                destLocV = { lat: acceptanceInfo?.drvrDestLoc.lat, lng: acceptanceInfo?.drvrDestLoc.lng };


            await psgrJoinTrasp({
                variables: {
                    reqId: +tripId,
                    drvrUserId: +(acceptanceInfo?.sourceUserId),
                    reservedSeatsByUser: psgrReservedSeatsSt.value,
                    drvrSrcLoc: srcLocV,
                    drvrDestLoc: destLocV
                },
                onCompleted: async (data) => {
                    if (data.joinTrasp.success) {
                        joinReqOkModal.setProps({
                            msg: "Request sent successfully",
                            okClickHandler: () => {
                                navigate("/app/dashboard");
                            },
                        });
                    } else {
                        if (data.joinTrasp.errorId === "DUPLICATES_ERR") joinReqOkModal.setProps({ msg: "Already exist" });
                        else if (data.joinTrasp.errorId === "ERR_MAX_JOIN_REQUEST") joinReqOkModal.setProps({ msg: data.joinTrasp.errorDesc });
                        else if (data.joinTrasp.errorId === "ERR_TRIP_OVERLAP") joinReqOkModal.setProps({ msg: data.joinTrasp.errorDesc });
                        else joinReqOkModal.setProps({ msg: "Sorry, an error occurred, onComplete" });
                    }
                    joinReqOkModal.showAsync();
                },
                onError: async (err) => {
                    console.log("psgrJoinTrasp mutation is not successfull -abd- err=", err);
                    joinReqOkModal.setProps({ msg: "Sorry, an error occurred" + err });
                    joinReqOkModal.showAsync();
                },
            });
        }
    }

    return (
        <div>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            await onAccept();
                        }}
                    >
                        {ReadTextResource.getTextById("ACCEPT")}
                    </button>
                </div>
                <div className="text-center">
                    <button type="button" className="p-2 btn btn-danger" onClick={async () => { }}>
                        {ReadTextResource.getTextById("REJECT")}
                    </button>
                </div>
                <div className="text-center me-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            navigate("/app/psgrChat", { state: { toUserId: acceptanceInfo?.sourceUserId } });
                        }}
                    >
                        <i className="bi bi-chat-dots-fill"></i>
                    </button>
                </div>
                
                <div className="text-center ms-auto">
                    <button type="button" className="p-2 btn btn-primary" onClick={async () => { navigate(-1) }}>
                        {ReadTextResource.getTextById("CANCEL")}
                    </button>
                </div>
                
            </StickyBar>


            <div>
                <strong>{" " + ReadTextResource.getTextById("DRIVER") + " "}</strong>
                {acceptanceInfo.sourceUser?.userName}
                <strong>{" " + ReadTextResource.getTextById("PRICE") + " "}</strong>
                {acceptanceInfo?.price}
            </div>


            {acceptanceInfo?.drvrSrcLoc ? (
                <DrvrSrcLocation
                    id="drvrSrcLocationIdDecision01"
                    locationRdc={[drvrSrcLocationSt, drvrSrcLocationDis]}
                    label={<b> موقع انطلاق السائق: </b>}
                    inputRef={drvrSrcLocationRef}
                    readOnly={true}
                ></DrvrSrcLocation>
            ) : (
                <div>
                    <b>موقع انطلاق السائق</b>: من موقع الراكب
                </div>
            )}
            {acceptanceInfo?.drvrDestLoc ? (
                <DrvrDestLocation
                    id="drvrSrcLocationIdDecision01"
                    locationRdc={[drvrDestLocationSt, drvrDestLocationDis]}
                    label={<b> موقع وصول السائق: </b>}
                    inputRef={drvrSrcLocationRef}
                    readOnly={true}
                ></DrvrDestLocation>
            ) : (
                <div>
                    <b>موقع وصول السائق</b>: نفس موقع الراكب
                </div>
            )}
            {tripInfo?.reqType !== "Goods" &&
                <>
                    <div>
                        مجموع الركاب:
                        {companionCount}
                        <br />
                        عدد الذكور المرافقين:
                        {tripInfo.mPsgrCompanions ?? 0}
                        <br />
                        عدد الاناث المرافقين:
                        {tripInfo.fPsgrCompanions ?? 0}
                    </div>

                    <PsgrVclSeatSelectorComp
                        id="TkDecsionVclSeatSelectorId1"
                        label="اختيار المقاعد"
                        reqId={+tripId}
                        drvrUserId={+(acceptanceInfo?.sourceUserId)}
                        seatRdc={[psgrReservedSeatsSt, psgrReservedSeatsDis]}
                        inputRef={psgrReservedSeatsRef}
                        onChange={() => {
                            psgrReservedSeatsDis({
                                type: PsgrVclSeatSelector.SeatActDisTypesT.ErrorMsg,
                                errorMsg: "",
                            });
                            // companionCountDis({
                            //     type: FormCmps.TextCmp.checkboxActDisTypesT.ErrorMsg,
                            //     errorMsg: "",
                            // });
                        }}
                    ></PsgrVclSeatSelectorComp>
                </>}
        </div>

    );
}
