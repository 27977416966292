import { FormEvent, PropsWithChildren, ReactNode } from "react";

export function StickyBar(
    props: PropsWithChildren<{
        id: string;
        submitCancelBtns?: ReactNode;
        visible?: boolean;
        type?: "sticky-top" | "sticky-bottom";
        borderBottom?: boolean;
        classNames?: string;
        onSubmitHandler?: (event: FormEvent<HTMLFormElement>) => void;
    }>
) {
    return (
        <div className={`pb-1 row ${props.type ?? "sticky-top"} ${props.classNames}` }>
            {/* <div className={`pb-3 row sticky-bottom pt-4`}> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div
                            style={{
                                // backgroundColor: "aliceblue",
                                backgroundColor: "white",
                            }}
                            className={`${
                                props.borderBottom === false ? "" : "border-bottom"
                            } d-flex align-items-center justify-content-center justify-content-md-start justify-content-sm-center ps-1 py-2 rounded mb-3`}
                        >
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
