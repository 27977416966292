import { gql, useApolloClient, useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import React, { FormEvent, useEffect, useRef } from "react";
import { getGraphQlError } from "../commonSrc/commonFns";
import { csrfTokenRvar } from "../commonSrc/apolloGQL/ReactiveVars";
import { ModalComp } from "./commonComp/ModalComp";
import { PortalComp } from "./commonComp/PortalComp";
import { getGqlStmt } from "../commonSrc/graphQL/Queries";
import { SpinnerComp } from "./commonComp/SpinnerComp";
import TestComponent from "./TestComponent1";
import { Modal } from "bootstrap";

export default function TestGraphComp() {
    console.log("First line - TestGraphComp Component");

    useEffect(() => {
        console.log("TestGraphComp");
    }, []);

    const csrfT = useReactiveVar(csrfTokenRvar).csrfToken;
    console.log("document.cookie=", document.cookie);

    const accIdRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const usernameRef = useRef<HTMLInputElement>(null);
    let csrfToken = "";

    async function onSubmitHandler(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        // let acc = Number.parseInt((event.target as HTMLFormElement)["accId"].value);

        let acc = Number.parseInt(accIdRef.current?.value as string);
        console.log("acc=", acc);
        // setAccountId(acc);
        // const gqlQuery = JSON.stringify({
        //   query: `query {
        //     getAccount(id:1){
        //       accNameAr
        //     }
        //   }`,
        // });

        //graphql code here using fetch
        // const res = await fetch("localhost:9000/graphql", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: gqlQuery,
        // });

        //   const data = await res.json();
        //   console.log("res.json() is ", data.data.getAccount.accNameAr);
        //   setReturedData(data.data.getAccount.accNameAr);
    }

    const [createUser, { error: error1, data: data1, loading: loading1 }] = useMutation(getGqlStmt().mutations.CREATE_USER, {
        fetchPolicy: "no-cache",
    });

    async function onSubmitHandler2(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        //this is for calling graphql using apollo
        let email = emailRef.current?.value;
        let password = passwordRef.current?.value;
        let username = usernameRef.current?.value as string;
        await createUser({
            variables: {
                email: email,
                password: password,
                username: username,
                // id: +(accIdRef.current?.value||-1),
            },
        });
        console.log("createAccountRefs=", email, password, username, "result=", data1.createUser.success);
        // if (error1.)
    }

    const [getAccountQuery, { error, data, loading, networkStatus }] = useLazyQuery(getGqlStmt().queries.GQL_QUERY, {
        notifyOnNetworkStatusChange: true,
    });

    // console.log("networkStatus=", networkStatus, "data", data, "error", error);

    const client = useApolloClient();

    client.readQuery({
        query: gql`
            query ROOT_QUERY {
                getAccount {
                    accId
                    accNameAr
                }
            }
        `,
        // variables: { id: 5 },
    });

    function fetchFormSubmit(event: FormEvent<HTMLFormElement>) {
        //event.preventDefault();
    }
    // useEffect(() => {
    //   // if (loading)
    //   ModalComp.show(ModalComp.IDs.SPINNER_MODAL);
    //   // else ModalComp.hide(ModalComp.IDs.SPINNER_MODAL)
    // }, []);

    /*   if (loading) {
    return (
      <PortalComp elementId={ModalComp.IDs.ROOT_MODAL_ID}>
        <>
          <div
            className="modal fade show"
            id="SpinnerModalId"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="modalBackdropLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content align-items-center bg-transparent border-0">
                <div className="text-center container d-flex align-items-center justify-content-center h-100 ">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem", color: "mediumblue" }}>
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <div className="text-center" style={{ color: "mediumblue" }}>
                        <span className="">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-backdrop fade show"></div>
        </>
      </PortalComp>
    );

    //<PortalComp elementId={ModalComp.IDs.ROOT_MODAL_ID}>{ModalComp.SpinnerModal().element}</PortalComp>;
  } */

    // if (loading) return <SpinnerComp/>

    if (error) {
        // ModalComp.hide(ModalComp.IDs.SPINNER_MODAL);

        return (
            <>
                <p>Sorry, something went worng!!</p>
                <p>please check below errors :</p>
                {/* <p>          
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </p> */}
                <p>{"" + console.log(error.graphQLErrors) ? "" : ""}</p>
                <p> {"errorId : " + (error.graphQLErrors[0]?.extensions as any)?.errorId}</p>
                <p> {"errorMsg : " + (error.graphQLErrors[0]?.extensions as any)?.errorMsg}</p>
            </>
        );
    }

    //ok
    // const showSpinner = loading && <ModalComp.SpinnerPortalModal.JsxComp autoShow={true}/>;

    //OK
    // const showSpinner = loading && <ModalComp.SpinnerPortalModal.JsxElement autoShow={true} />;
    {
        /*      <div
          className="modal fade show"
          id="SpinnerModalId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="modalBackdropLabel"
          aria-modal="true"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content align-items-center bg-transparent border-0">
              <div className="text-center container d-flex align-items-center justify-content-center h-100 ">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem", color: "mediumblue" }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="text-center" style={{ color: "mediumblue" }}>
                      <span className="">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */
    }
    return (
        <>
            <ModalComp.SpinnerModal.JsxPortalComp isPortal={true} modalIdSuffix="testGQL_spinner" />
            {/* {showSpinner} */}
            <TestComponent></TestComponent>
            <p>Qurey some Gl Account:</p>
            <form onSubmit={onSubmitHandler}>
                <input type="text" name="accId" ref={accIdRef} />
                <input
                    type="button"
                    value="LazyFetch"
                    onClick={async () => {
                        //ok
                        // await ModalComp.showAync(ModalComp.IDs.SPINNER_MODAL);
                        // await ModalComp.showAync(new ModalComp.SpinnerPortalModal());
                        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
                        await spinner.showAsync();
                        // await ModalComp.showAsync(ModalComp.IDs.SPINNER_MODAL);

                        // ModalComp.showFromClass(new ModalComp.SpinnerPortalModalClass({autoShow:true}) );

                        try {
                            // setTimeout(async () => {
                            await getAccountQuery({
                                variables: {
                                    id: Number.parseInt(accIdRef.current?.value as string),
                                    // id: +(accIdRef.current?.value||-1),
                                },
                            }).catch((err0) => {
                                console.log("errYYYYYYYYY=", err0);
                                // ModalComp.hide(ModalComp.IDs.SPINNER_MODAL);
                                spinner.hide();
                            });

                            // ModalComp.hide(ModalComp.IDs.SPINNER_MODAL);
                            spinner.hide();
                            // }, 100);
                        } catch (err) {
                            console.log("errXXXXXXX=", err);
                            // ModalComp.hide(ModalComp.IDs.SPINNER_MODAL);
                            spinner.hide();
                        }
                    }}
                />
                <input
                    type="submit"
                    value="Refetch"
                    onClick={() => {
                        client.refetchQueries({
                            include: ["getAccountName"],
                            onQueryUpdated(observableQuery) {
                                // Logging and/or debugger breakpoints can be useful in development to
                                // understand what client.refetchQueries is doing.
                                console.log(`fetch Retech Button, Examining ObservableQuery ${observableQuery.queryName}`);

                                // Proceed with the default refetching behavior, as if onQueryUpdated
                                // was not provided.

                                return true;
                            },
                        });
                    }}
                />

                <input
                    type="submit"
                    value="WriteQuery"
                    onClick={() => {
                        client.writeQuery({
                            query: gql`
                                query custom($param1: Int) {
                                    customObject(param1: $param1) {
                                        att1
                                        att2
                                    }
                                }
                            `,
                            data: {
                                customObject:
                                    // ...,
                                    {
                                        att1: accIdRef.current?.value,
                                        att2: "att2",
                                    },

                                // getAccount: {
                                //   accId: +(accIdRef.current?.value as string),
                                //   accNameAr: "Updated Account from main App.tsx",
                                // },
                            },
                            variables: { param1: accIdRef.current?.value as string },
                        });
                    }}
                />
            </form>
            <p>{data?.getAccount.accNameAr} </p>
            <form onSubmit={onSubmitHandler2}>
                <input type="text" name="email" placeholder="email" ref={emailRef} />
                <input type="text" name="password" placeholder="password" ref={passwordRef} />
                <input type="text" name="username" placeholder="username" ref={usernameRef} />
                <input
                    type="submit"
                    value="createAccount"
                    // onClick={() => {}}
                />
            </form>
        </>
    );
}
