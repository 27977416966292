import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessInfo } from "../../commonSrc/commonFns";
import NotFound from "./NotFound";

export default function DrvrOnly(props: React.PropsWithChildren<{}>) {
    // useEffect(() => {
    //   sendRefreshToken().then((res) => {
    //     setIsAUthedState(res);
    //   });
    // }, []);

    const nav = useNavigate();
    const location = useLocation();
    const redirectTo = location.pathname;
    // console.log("location in RequireAuth=", location, "ReqAuthTest accessTokenVar=",accessTokenVar());
    if (getAccessInfo()?.user?.userType === "Drvr") return <>{props.children}</>;
    // else nav("/notFound");
    // props.children;
    // return <div>OOOOOOOOOOOOOOOOOOOOOOO</div>
    return <NotFound></NotFound>;
    // return <Navigate to={"/notFound"} replace={false} />;
    // return <></>

    //   nav("/", { replace: true });
}
