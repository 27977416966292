import { useQuery } from "@apollo/client";
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { StickyBar } from "../commonComp/StickyBar";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";

export function AcceptingDrvrTrip(props: React.PropsWithChildren<{}>) {
    console.log("First line - AcceptingDrvrTrip Component");
    const { pReqId, pDrvrUserId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // async function g() {
        //     return await getDeviceCurrentLocation();
        // }
        // loc = g();
    }, []);

    // const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

    // const [pickup, setPickup] = useState(false);

    const [showMap, setShowMap] = useState(false);

    async function isFormValid(): Promise<boolean> {
        return true;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
    }

    let loc: any;

    const {
        JsxComp: DrvrMapDirections,
        compReducer: [drvrMapDirectionsSt, drvrMapDirectionsDis],
        compRef: drvrMapDirectionsRef,
    } = FormCmps.MapDirectionsComp.getComp();

    const {
        JsxComp: DrvrJoinedPsgrsComp,
        compReducer: [drvrJoinedPsgrsSt, drvrJoinedPsgrsDis],
        compRef: drvrJoinedPsgrsRef,
    } = DrvrJoinedPsgrsCmp.getComp();

    const {
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.PSGR_RCV_APR_N_REQ_DTL/* TRASP_REQ_N_APRV_RQUIR_ACTION_FRM_PSGR *//* TRASP_REQ */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
    });

    // const {
    //     error: getTripApprovalsErr,
    //     data: getTripApprovalsData,
    //     loading: getTripApprovalsLoading,
    //     // refetch: reftechCagegories,
    // } = useQuery(getGqlStmt().queries.TRIP_ACCEPTANCE, {
    //     fetchPolicy: "no-cache",
    //     variables: {
    //         tripId: +pReqId!,
    //     },
    // });

    if (/* getTraspReqWaypointsLoading || */ getReqDtlLoading /* || getTripApprovalsLoading */) return <div>loading ...</div>;

    if (!pReqId || isNaN(parseInt(pReqId)) || getReqDtlErr) return <div>Invalid request parameters</div>;

    const DrvrAcceptancesComp = (
        <>
            <div className="pt-1 pb-1 text-center col-lg-12 col-md-12 col-sm-12 bg-info rounded-2 ">
                {/* <u> */}
                <b> {ReadTextResource.getTextById("ACCEPT_REQS_FROM_DRVRS")}</b>
                {/* </u> */}
            </div>
            {getReqDtlData?.getPsgrPndngRcvReqs?.dataArr[0].tripApprovals?.length === 0 ? (
                <div>{ReadTextResource.getTextById("NO_ACCEPTANCE_REQS")}</div>
            ) : (
                getReqDtlData?.getPsgrPndngRcvReqs?.dataArr?.map((reqDtl: any, i: number) => {
                    return reqDtl.tripApprovals?.map((e: any, i: number) => {
                        return (
                            <div className="mb-5 p-3 rounded shadow" key={e.id + i}>
                                <div>
                                    <strong>{" " + ReadTextResource.getTextById("DRIVER") + " "}</strong>
                                    {e.sourceUser?.userName}
                                    <strong>{" " + ReadTextResource.getTextById("PRICE") + " "}</strong>
                                    {e?.price}
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            navigate("/app/takeDecisionOnDrvrReq", {
                                                state: { tripId: pReqId, drvrUserId: pDrvrUserId, acceptanceInfo: e, tripInfo: reqDtl },
                                            });
                                        }}
                                    >
                                        {ReadTextResource.getTextById("SHOW_REQ")}
                                    </button>
                                </div>
                            </div>
                        );
                    })
                })
            )}
        </>
    );

    return (
        <>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                </div>

                <div className="text-center ms-auto">
                    <button
                        type="button"
                        className="px-4 btn btn-danger "
                        onClick={async () => {
                            navigate("/app/dashboard");
                        }}
                    >
                        {ReadTextResource.getTextById("BACK")}
                    </button>
                </div>
            </StickyBar>
            <TripDtls
                reqDtlType="AcceptingDrvrTrip"
                reqInfo={getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]}
                goodsCmp={
                    <GoodsDtlsCmp
                        pGoodsData={getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.tripApprovals}
                        showApproval={false}
                        displayType="AcceptPsgrReq"
                    />}>
            </TripDtls>
            <FormCmps.ContainerNoFormCmp id="psgrTraspReqDtlId1">
                {DrvrAcceptancesComp}
            </FormCmps.ContainerNoFormCmp>
        </>
    );
}
