import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { useNavigate, useParams } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { Button } from "../commonComp/Button";
import NotFound from "../commonComp/NotFound";

export function RateDrvrCmp(props: React.PropsWithChildren<{}>) {
    console.log("First line - RateDrvrCmp Component");
    const { tripId, ratee } = useParams();
    const navigate = useNavigate();

    const [
        rateDrvr,
        { error: rateDrvrErr, data: rateDrvrData, loading: rateDrvrLding },
    ] = useMutation(getGqlStmt().mutations.RATE_DRVR, {
        fetchPolicy: "no-cache",
    });

    const
        { JsxComp: RateDrvr,
            compReducer: [rateDrvrSt, rateDrvrDis],
            compRef: rateDrvrRef
        } = FormCmps.RatingCmp.getComp();

    const
        { JsxComp: Review,
            compReducer: [reviewSt, reviewDis],
            compRef: reviewRef
        } = FormCmps.TextAreaCmp.getComp();

    if (!tripId || !ratee) {
        return <NotFound></NotFound>
    }

    return <div className="container d-flex flex-column align-items-center">
        <div className="my-4 fs-3 ">{ReadTextResource.getTextById("HOW_WAS_TRP")}</div>
        <div >
            <RateDrvr id="rateDrvr" label="rate" ratingRdc={[rateDrvrSt, rateDrvrDis]}></RateDrvr>
        </div>
        <div>{rateDrvrSt.value ?? 0}/5</div>
        <div className="w-100 my-4">
            <Review
                id="review"
                placeHolder={ReadTextResource.getTextById("YOUR_REVIEW")}
                label="review"
                textRdc={[reviewSt, reviewDis]}
                valueTrakingOnChange={true} >

            </Review>
        </div>
        <div className="container d-flex flex-row justify-content-center">
            <Button id="d" label="Submit" onOkClickHandler={async () => {
                const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("THNK_U"), navTo: "/app/notifications" })

                if (!rateDrvrSt.value) {
                    okModal.setProps({ msg: ReadTextResource.getTextById("ADD_RATE_ST"), navTo: null })
                    await okModal.showAsync();
                    return;
                }
                const res = await rateDrvr({
                    variables: {
                        review: reviewSt.value,
                        rateValue: +rateDrvrSt.value ?? 0,
                        ratee: +ratee,
                        tripId: +tripId
                    }
                });

                if (res.data?.rateDrvr?.success) {
                    await okModal.showAsync();
                    // navigate("/app/notifications", { replace: true })
                }
                else if (res.data?.rateDrvr?.errorId === "ERR_RATING_DUPLICATE") {
                    okModal.setProps({ msg: ReadTextResource.getTextById("ALRDY_RATED") });
                    await okModal.showAsync();
                }
                else {
                    okModal.setProps({ msg: ReadTextResource.getTextById("SAVING_ERROR") });
                    await okModal.showAsync();
                    // navigate("/app/notifications", { replace: true })

                }

            }}></Button>
            {/* <Button id="d" label="Cancel"></Button> */}
        </div>

    </div>;
}