import { useReactiveVar } from "@apollo/client";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { logout } from "./commonSrc/commonFns";
import { dirAndLangRvar } from "./commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "./commonSrc/ReadTextResource";
import Login from "./components/Auth/login";
// import { AppDropdown } from "./components/commonComp/AppDropdown";
import { HeaderComp } from "./components/commonComp/HeaderComp";
import IFAuthedShow from "./components/commonComp/IFAuthedShow";
import { ModalComp } from "./components/commonComp/ModalComp";
import { NavComp } from "./components/commonComp/NavComp";
import { PortalComp } from "./components/commonComp/PortalComp";

export function Welcome(props: PropsWithChildren<{}>) {
    console.log("First line - Welcome Component");
    //  const [title, setTitle] = useState("PPPPPPPPP");

    const appMenusData = {
        getAppMenus: [
            {
                menuId: 1,
                menuName: "Dashboard",
                menuTxtResourceId: "DASHBOARD",
                menuParentId: null,
                isLeaf: "Y",
                levelNo: 0,
                companyId: 1,
                navigateTo: "/",
                __typename: "AppMenu",
                children: [],
            },
            {
                menuId: 2,
                menuName: "Contact Us",
                menuTxtResourceId: "CONTACT_US",
                menuParentId: null,
                isLeaf: "Y",
                levelNo: 0,
                companyId: 1,
                navigateTo: "/",
                __typename: "AppMenu",
                children: [],
            },
            {
                menuId: 3,
                menuName: "About Us",
                menuTxtResourceId: "ABOUT_US",
                menuParentId: null,
                isLeaf: "Y",
                levelNo: 0,
                companyId: 1,
                navigateTo: "/",
                __typename: "AppMenu",
                children: [],
            },
            {
                menuId: 5,
                menuName: "Language",
                menuTxtResourceId: "LANG",
                menuParentId: null,
                isLeaf: "N",
                levelNo: 0,
                companyId: 1,
                navigateTo: null,
                __typename: "AppMenu",
                children: [
                    {
                        menuId: 8,
                        menuName: "Arabic",
                        menuTxtResourceId: "AR_LANG",
                        menuParentId: 5,
                        isLeaf: "Y",
                        levelNo: 0,
                        companyId: 1,
                        navigateTo: "/",
                        __typename: "AppMenu",
                        children: [],
                    },
                    {
                        menuId: 9,
                        menuName: "English",
                        menuTxtResourceId: "EN_LANG",
                        menuParentId: 5,
                        isLeaf: "Y",
                        levelNo: 0,
                        companyId: 1,
                        navigateTo: "/",
                        __typename: "AppMenu",
                        children: [],
                    },
                ],
            },
            // {
            //     menuId: 6,
            //     menuName: "Logout",
            //     menuTxtResourceId: "LOGOUT",
            //     menuParentId: null,
            //     isLeaf: "Y",
            //     levelNo: 0,
            //     companyId: 1,
            //     navigateTo: "/",
            //     __typename: "AppMenu",
            //     children: [],
            // },
        ],
    };
    const mobileNavButtonRef = useRef<HTMLDivElement>(null);

    const hideMobileNavPanelHandler = () => {
        mobileNavButtonRef?.current?.classList.add("d-block");
        mobileNavButtonRef?.current?.classList.add("d-md-none");
        const navCompPanel = document.getElementById("mainNavPanel");
        // navCompPanel?.classList.remove("d-none");
        navCompPanel?.classList.add("d-md-block");
        navCompPanel?.setAttribute("innerHtml", "Hide menu");
    };
    const hideNavSubPanelHandler = () => {
        mobileNavButtonRef?.current?.classList.remove("d-block");
        mobileNavButtonRef?.current?.classList.remove("d-md-none");
    };

    return (
        <>
            <PortalComp elementId={ModalComp.ModalRootIDs.NAV_ROOT_MODAL}>
                <NavComp.MobileNavPanel
                    parentProps={ModalComp.ModalRootIDs.NAV_ROOT_MODAL}
                    menus={appMenusData}
                    onHideMenu={hideMobileNavPanelHandler}
                />
            </PortalComp>
            {/* <div>{title}</div> */}
            <HeaderComp.HeaderContainer>
                <HeaderComp.HeaderStart compName="HeaderStart" key={"HeaderStart_welcome"}>
                    <NavComp.MobileNavButton />
                    {/* <div>{ReadTextResource.getTextById("WELCOME")}</div> */}
                </HeaderComp.HeaderStart>

                <HeaderComp.HeaderCenter compName="HeaderCenter">
                    <>
                        <IFAuthedShow notAuthedChildren={<strong className="fs-4">Tranzait</strong>}>
                            <div>Company Name</div>
                        </IFAuthedShow>
                    </>
                </HeaderComp.HeaderCenter>

                <HeaderComp.HeaderEnd compName="HeaderEnd">
                    {/* <div className="p-5"> */}
                    <>
                        {/* <div className="ps-4">{ReadTextResource.getTextById("CONTACT_US")}</div>
                        <div className="ps-4">{ReadTextResource.getTextById("ABOUT_US")}</div> */}
                        {/* <AppDropdown /> */}
                        {/* <IFAuthedShow>
              <div className="ps-4 dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle bg-transparent text-light btn-sm"
                  type="button"
                  id="MyAccountDDM"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="d-sm-inline bi bi-person-fill"></i>
                  <p className="d-sm-none d-md-inline d-none"> {ReadTextResource.getTextById("MY_ACCOUNT")}</p>
                </button>
                <ul className="dropdown-menu" aria-labelledby="MyAccountDDM">
                  <li>
                    <IFAuthedShow>
                      <NavLink
                        onClick={logout}
                        to={"/"}
                        replace={true}
                        className={(isActive) => "dropdown-item" + (!isActive ? " unselected" : "")}
                      >
                        {ReadTextResource.getTextById("LOGOUT")}
                      </NavLink>
                    </IFAuthedShow>
                  </li>                  
                </ul>
              </div>
            </IFAuthedShow> */}
                    </>
                </HeaderComp.HeaderEnd>
            </HeaderComp.HeaderContainer>

            <Outlet
            // context={{setTitle}}
            ></Outlet>
        </>
    );
}
