import { Modal } from "bootstrap";
import React, { PropsWithChildren, ReactElement } from "react";
import { flushSync } from "react-dom";
import { createRoot, Root } from "react-dom/client";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import historyObject from "../../commonSrc/HistoryRouterObject";
import { SpinnerComp } from "./SpinnerComp";
import { PortalComp } from "./PortalComp";
import { refreshRootComponent } from "../../commonSrc/commonFns";
import { blockOtherModalsRVar } from "../../commonSrc/apolloGQL/ReactiveVars";

export namespace ModalComp {
    export enum ModalRootIDs {
        PORTAL_SAVE_STATE_ROOT_MODAL_ID = "PortalSaveStateModalRoot",
        ACTION_ROOT_MODAL_ID = "ActionModalRoot",
        NOACTION_ROOT_MODAL_ID = "NoActionModalRoot",
        PORTAL_NOACTION_ROOT_MODAL_ID = "PortalNoActionModalRoot",
        NAV_ROOT_MODAL = "NavModalRoot",
    }

    export enum ModalIDs {
        OK_MODAL = "OkModalId",
        OK_CANCEL_MODAL = "OkCancelModalId",
        ERROR_MODAL = "submit",
        RESET_PASS_MODAL = "ResetPassModalId",
        SPINNER_MODAL = "SpinnerModalId",
        TWO_BTNS_MODAL = "TwoBtnsModal",
    }

    /** Modals Props**/
    type SpinnerProps = { autoShow?: boolean; firstTimeAccess?: boolean; modalId?: string } | undefined;
    type IsOptionalType = "OPTIONAL" | "MANDATORY";
    export type OkModalProps<T extends IsOptionalType = "OPTIONAL"> = {
        navTo?: string|null;
        title?: string;
        reloadhPage?: boolean;
        refreshRootComponent?: boolean;
        autoHideOnOk?: boolean;
        okClickHandler?: () => void;
    } & (T extends "MANDATORY" ? { msg: string | JSX.Element } : { msg?: string | JSX.Element });

    type GeneralModalPropsT =
        | {
            isPortal: true;
            modalIdSuffix: string;
        }
        | {
            isPortal: false;
            modalIdSuffix?: string;
        };

    export type TwoBtnsModalProps<T extends IsOptionalType = "OPTIONAL"> = {
        msg?: string | JSX.Element | undefined;
        navTo?: string;
        title?: string;
        reloadhPage?: boolean;
        refreshRootComponent?: boolean;
        clickHandlerBtn1?: () => void;
        clickHandlerBtn2?: () => void;
        btn1Label?: string;
        btn2Label?: string;
    } & (T extends "MANDATORY" ? { msg: string | JSX.Element } : { msg?: string | JSX.Element });

    type ResetPassProps = {} | undefined;
    /** **/
    // let testNonExistRootToClearCache: Root = createRoot(document.getElementById("test") as Element);
    let actionRoot: Root = createRoot(document.getElementById(ModalComp.ModalRootIDs.ACTION_ROOT_MODAL_ID) as Element);
    let noActionRoot: Root = createRoot(document.getElementById(ModalComp.ModalRootIDs.NOACTION_ROOT_MODAL_ID) as Element);
    let portalSaveStateActionRoot: Root = createRoot(
        document.getElementById(ModalComp.ModalRootIDs.PORTAL_SAVE_STATE_ROOT_MODAL_ID) as Element
    );
    let portalNoActionRoot: Root = createRoot(document.getElementById(ModalComp.ModalRootIDs.PORTAL_NOACTION_ROOT_MODAL_ID) as Element);
    let navRoot: Root = createRoot(document.getElementById(ModalComp.ModalRootIDs.NAV_ROOT_MODAL) as Element);
    // let root: Root = createRoot(document.getElementById(ModalComp.IDs.ACTION_ROOT_MODAL_ID) as Element);

    export function ModalButton(props: PropsWithChildren<{}>) {
        return (
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={`#${ModalComp.ModalRootIDs.ACTION_ROOT_MODAL_ID}`}
            >
                Launch static backdrop modal
            </button>
        );
    }

    export function ModalBasicContainer(
        props: PropsWithChildren<{ modalId: string; nonStaticModal: boolean | string; autoShow?: boolean }>
    ) {
        let ModalBody!: ReactElement; //= <div />; // = undefined;

        // const childrenWithProps =
        React.Children.map(props.children as any, (child) => {
            // Checking isValidElement is the safe way and avoids a typescript error too.
            if (child.props.compName === "ModalBody") ModalBody = React.cloneElement(child, { ...child.props });

            if (React.isValidElement(child)) {
                // return React.cloneElement(child, { lang: "nnn" });
            }
        });
        if (!ModalBody) console.warn("The ModalBasicContainer custom compponent should have ModalBody child.");

        return (
            <div
                className={`modal fade ${props.autoShow ? "show" : ""} `}
                id={props.modalId}
                //true for non-static, false for static or static for persistant modal
                data-bs-backdrop={props.nonStaticModal} //"static"
                data-bs-keyboard="false"
                tabIndex={-1}
                data-autoshow={props.autoShow}
                aria-labelledby="modalBackdropLabel"
                aria-hidden="true"
                style={{ display: props.autoShow ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content align-items-center bg-transparent border-0">{ModalBody}</div>
                </div>
            </div>
        );
    }

    export function ModalDialogContainer(
        props: PropsWithChildren<{ modalId: string; nonStaticModal: boolean | string; modalTitle: string }>
    ) {
        let ModalBody: ReactElement = <div />; // = undefined;
        let ModalFooter: ReactElement = <div />; // { key: null, type: "", props: null }; // = undefined;

        // const childrenWithProps =
        React.Children.map(props.children as any, (child) => {
            // Checking isValidElement is the safe way and avoids a typescript error too.
            if (child.props.compName === "ModalBody") ModalBody = React.cloneElement(child, { ...child.props });
            else if (child.props.compName === "ModalFooter") ModalFooter = React.cloneElement(child, { ...child.props });

            if (React.isValidElement(child)) {
                // return React.cloneElement(child, { lang: "nnn" });
            }
        });
        if (!ModalBody || !ModalFooter)
            console.warn("The ModalComp custom compponent should have two childs, the ModalBody and ModalFooter");

        return (
            <div
                className="modal fade"
                id={props.modalId}
                //true for non-static, false for static or static for persistant modal
                data-bs-backdrop={props.nonStaticModal} //"static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="modalBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalBackdropLabel">
                                {props.modalTitle}
                            </h5>
                            {props.nonStaticModal === "static" ? (
                                ""
                            ) : (
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            )}
                        </div>

                        <div className="modal-body">{ModalBody} </div>

                        <div className="modal-footer">{ModalFooter}</div>
                    </div>
                </div>
            </div>
        );
    }

    export function ModalBody(props: PropsWithChildren<{ compName: "ModalBody" }>) {
        return <>{props.children}</>;
    }

    export function ModalFooter(props: PropsWithChildren<{ compName: "ModalFooter" }>) {
        return <>{props.children}</>;
    }

    export function getModalInstance(modalId: string) {
        // const m = new Modal(document.getElementById(modalId) as Element);
        return Modal.getOrCreateInstance(document.getElementById(modalId) as Element) as Modal;
    }

    abstract class ModalAbstract<T, K = T> {
        protected props: T;
        protected isPortal: boolean;
        protected modalId: string;

        //below fields to be set from the subclass
        protected JsxElement: JSX.Element;
        protected root: Root;
        protected JsxComp: (props: any) => JSX.Element;
        protected JsxPortalComp: (props: any) => JSX.Element;
        protected JsxPortalElement: JSX.Element;

        /**
         *
         * @param generalModalProps for isPortal param = true, you need to use the modal as normal react component in the return statement
         * of the containing component, becuase the modal will be shown as modal in seperate root. you can directly put the modal in return
         *  statement like below example:
         * <ModalComp.OkModal.JsxPortalComp
         * isPortal={true}
         * ...etc
         * ></ModalComp.OkModal.JsxPortalComp>
         * or if you declare the modal by code, you can do the following:
         * const okModal = new ModalComp.OkModal(
         * { isPortal: true, modalIdSuffix: customModalIdSuffix },
         * ...etc;
         * then in the containing component:
         * return <div>
         * {okModal.getJsxPortalElement()}
         * ...other components
         * </div>
         *
         * @param specificModalProps
         */
        constructor(generalModalProps: GeneralModalPropsT, specificModalProps: T) {
            this.props = specificModalProps;
            this.isPortal = generalModalProps.isPortal;

            /**
             * this should be overriden in the subclass, if not, then the id will begin with unkownModal, which indicate
             * that it should be handled in the modal subclass as per the modal type
             */
            this.modalId = generalModalProps.modalIdSuffix ? "unkownModalId" + generalModalProps.modalIdSuffix : "unkownModalId";
            // this.props["modalId" as keyof typeof this.props];
        }

        getRoot() {
            return this.root;
        }
        setProps(props: K) {
            const serPropsPromise = new Promise((ok) => {
                this.props = { ...this.props, ...props };
                // this.props = { ...props };
                // console.log("final this.props=", this.props);
                this.JsxElement = this.JsxComp(this.props);
                ok("");
            });
            return serPropsPromise;
        }

        getProps(): T {
            return this.props;
        }

        getModalId(): string {
            return this.modalId;
        }

        getJsxElement(): JSX.Element {
            return this.JsxElement;
        }
        getJsxPortalElement(): JSX.Element {
            return this.JsxPortalElement;
        }
        getJsxComp() {
            return this.JsxComp;
        }
        getJsxPortalComp() {
            return this.JsxPortalComp;
        }

        async releaseBlockModalsAsync() {
            blockOtherModalsRVar(false)
        }

        releaseBlockModals() {
            blockOtherModalsRVar(false)
        }
        /**
         *
         * isPortal if true, then your modal should be declared in the component return statement as JsxComp,
         * so that your modal will use all the states and reducer defined in the containing component.
         * keep in mind, when you use isPortal=true, you need to assign a unique modal id for every modal you're using, the modal id
         * will be used to show and hide the modal.
         * here the modal will not use render() method, but it will be shown based on createPortal
         */
        async showAsync(args?: { blockOthersUntilHide?: boolean }) {
            console.log("showAsync called");
            if (blockOtherModalsRVar()) {
                console.log("modals are currently blocked from appearing, you need to call releaseBlockModals() if you want to show them");
                return;
            }

            document.exitFullscreen().catch((err) => {
                console.log("no elements in full screen");
            });
            const showModalAync = new Promise((ok, notOk) => {
                let okModal: Modal;

                try {
                    flushSync(() => {
                        if (!this.isPortal) this.root.render(this.getJsxElement());
                        // const g = createPortal(<>{this.getJsxElement()}</>, document.getElementById(IDs.ROOT_MODAL_ID) as Element);
                    });
                    flushSync(() => {
                        // (document.getElementById(modal.id) as any).show();
                        okModal = Modal.getOrCreateInstance(document.getElementById(this.getModalId()) as Element) as Modal;

                        registerEventLsnr_HideModalOnBackBtn(this.modalId, ok);

                        okModal.show();
                    });
                    //don't add ok("") here, as it should be resolved from within registerEventLsnr_HideModalOnBackBtn function, ok("") is already sent there
                } catch (err) {
                    console.log("showAync error", err);
                    ok("");
                }
            });

            if (args?.blockOthersUntilHide)
                blockOtherModalsRVar(true)
            return showModalAync;
        }

        /**
         *
         * isPortal if true, then your modal should be declared in the component return statement as JsxComp,
         * so that your modal will use all the states and reducer defined in the containing component.
         * keep in mind, when you use isPortal=true, you need to assign a unique modal id for every modal you're using, the modal id
         * will be used to show and hide the modal.
         * here the modal will not use render() method, but it will be shown based on createPortal
         */
        show(args?: { blockOthersUntilHide?: boolean }): void {
            console.log("showcalled");
            if (blockOtherModalsRVar()) {
                console.log("modals are currently blocked from appearing, you need to call releaseBlockModals() if you want to show them");
                return;
            }

            document.exitFullscreen().catch((err) => {
                console.log("no elements in full screen");
            });

            try {
                let modalInstance: Modal; //= (modal as ModalType) ;

                flushSync(() => {
                    // const g = createPortal(<>{this.getJsxElement()}</>, document.getElementById(IDs.ACTION_ROOT_MODAL_ID) as Element);
                    // if (!pJsxElement) this.root.render(this.getJsxElement());
                    if (!this.isPortal) this.root.render(this.getJsxElement());
                    // // this.root.render(g);
                    // if (pJsxElement) this.root.render(pJsxElement);
                });
                flushSync(() => {
                    console.log(this.getModalId());

                    modalInstance = Modal.getOrCreateInstance(document.getElementById(this.getModalId()) as Element) as Modal;

                    registerEventLsnr_HideModalOnBackBtn(this.getModalId());

                    modalInstance.show();
                    document.body.style.overflow = "auto";
                    // document.body.setAttribute("style", document.body.style.overflow );

                    //end solution test
                });

                if (args?.blockOthersUntilHide)
                    blockOtherModalsRVar(true)
            } catch (err) {
                console.log("Modal show error, ", err);
            }
        }

        hide(): void {
            console.log("hide called");
            try {
                const okModal = Modal.getOrCreateInstance(document.getElementById(this.modalId) as Element) as Modal;
                const modalEl: Element = document.getElementById(this.modalId)!!;
                const isModalShown = modalEl?.classList.contains("show");
                const isAutoShow = modalEl?.getAttribute("data-autoshow");
                if (isModalShown) {
                    okModal.hide();
                    // document.getElementById(modalId)?.classList.remove("show");
                    // document.getElementById(modalId)?.style.removeProperty("display");
                    // if (isAutoShow) forceHide(okModal);
                } else {
                    forceHide(okModal, this.modalId);
                }
            } catch (err) {
                //do nothing, just to avoid returning error in case no modal is shown ho hide it.
                //or it could be used to hide the backdrop when the modal has removed by return some react element by some compoenent like if (error) reutrn <someComponent/>

                const bodyChilds = document.body.children;
                for (let i = 0; i < bodyChilds.length; i++) {
                    if (bodyChilds[i].classList.contains("modal-backdrop")) {
                        bodyChilds[i].remove();
                    }
                }
                console.log("no modal is shown to hide", err);
            }
        }

        /**
         * recentely added and have'nt not been tested yet
         * @param modalId
         * @returns
         */
        hideAsync() {
            console.log("hideAsync called");
            const hideModalAync = new Promise(async (ok, notOk) => {
                try {
                    const okModal = Modal.getOrCreateInstance(document.getElementById(this.modalId) as Element) as Modal;
                    const modalEl: Element = document.getElementById(this.modalId)!!;
                    // const okModal = Modal.getInstance(document.getElementById(modalId) as Element) as Modal;
                    const isModalShown = modalEl?.classList.contains("show");
                    const isAutoShow = modalEl?.getAttribute("data-autoshow");
                    if (isModalShown) {
                        okModal.hide();
                        ok("");
                        // document.getElementById(modalId)?.classList.remove("show");
                        // document.getElementById(modalId)?.style.removeProperty("display");
                        // if (isAutoShow) forceHide(okModal);
                    } else {
                        forceHide(okModal, this.modalId);
                        ok("");
                    }
                } catch (err) {
                    //do nothing, just to avoid returning error in case no modal is shown ho hide it.
                    //or it could be used to hide the backdrop when the modal has removed by return some react element by some compoenent like if (error) reutrn <someComponent/>
                    console.log("error in hideAsync", err);
                    try {
                        const bodyChilds = document.body.children;
                        for (let i = 0; i < bodyChilds.length; i++) {
                            if (bodyChilds[i].classList.contains("modal-backdrop")) {
                                bodyChilds[i].remove();
                            }
                        }
                        console.log("no modal is shown to hide", err);
                        ok("");
                    } catch (err) {
                        console.log("error2 in hideAsync", err);
                        ok("");
                    }
                }
            });
            return hideModalAync;
        }
    }

    export class OkModal<T extends OkModalProps<"MANDATORY">> extends ModalAbstract<T, OkModalProps> {
        /**
         * for calling inside tsx files in return statement of react component (to use the modal as react component)
         * @param props modalId should be unique for every modal
         * @returns
         */
        static JsxComp = function (props: OkModalProps<"MANDATORY"> & GeneralModalPropsT) {
            const modalId = "okModalId" + props.modalIdSuffix;
            return (
                <ModalComp.ModalDialogContainer
                    modalId={props.modalIdSuffix ? modalId : ModalComp.ModalIDs.OK_MODAL}
                    nonStaticModal="static"
                    modalTitle={props.title as string}
                >
                    <ModalComp.ModalBody compName="ModalBody">
                        <div className="">{props.msg}</div>
                    </ModalComp.ModalBody>
                    <ModalComp.ModalFooter compName="ModalFooter">
                        <div className="">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    // const okModal = Modal.getOrCreateInstance(document.getElementById(modal.id) as Element) as Modal;
                                    // okModal.hide();
                                    // hide(IDs.OK_MODAL);
                                    if (props.autoHideOnOk === undefined || props.autoHideOnOk)
                                        hide(props.modalIdSuffix ? modalId : ModalIDs.OK_MODAL);

                                    props.okClickHandler?.();
                                    if (props.refreshRootComponent) refreshRootComponent();
                                    if (props.reloadhPage) window.location.reload();
                                    if (props.navTo) historyObject.replace(props.navTo);
                                }}
                            >
                                {ReadTextResource.getTextById("OK")}
                            </button>
                        </div>
                    </ModalComp.ModalFooter>
                </ModalComp.ModalDialogContainer>
            );
        };

        static JsxPortalComp = function (props: OkModalProps<"MANDATORY"> & GeneralModalPropsT) {
            return (
                <PortalComp elementId={ModalRootIDs.PORTAL_SAVE_STATE_ROOT_MODAL_ID}>
                    <OkModal.JsxComp {...props}></OkModal.JsxComp>
                </PortalComp>
            );
        };

        constructor(generalModalProps: GeneralModalPropsT, specificModalProps: T) {
            super(generalModalProps, specificModalProps);
            this.JsxElement = OkModal.JsxComp({ ...this.props, ...generalModalProps });
            this.JsxComp = OkModal.JsxComp;
            this.JsxPortalComp = OkModal.JsxPortalComp;
            this.JsxPortalElement = OkModal.JsxPortalComp({ ...this.props, ...generalModalProps });
            this.modalId = generalModalProps.modalIdSuffix ? "okModalId" + generalModalProps.modalIdSuffix : ModalIDs.OK_MODAL;
            if (generalModalProps.isPortal) this.root = portalSaveStateActionRoot;
            else this.root = actionRoot;
        }
    }

    export class SpinnerModal<T extends SpinnerProps> extends ModalAbstract<SpinnerProps> {
        //for calling inside tsx files
        public static JsxComp = function (props: SpinnerProps & GeneralModalPropsT) {
            const modalId = "spinnerModalId" + props.modalIdSuffix;
            return (
                <>
                    <ModalComp.ModalBasicContainer
                        key={props?.modalId}
                        modalId={props.modalIdSuffix ? modalId : ModalIDs.SPINNER_MODAL}
                        nonStaticModal="static"
                        autoShow={props?.autoShow}
                    >
                        <ModalComp.ModalBody compName="ModalBody">
                            <SpinnerComp firstTimeAccess={props?.firstTimeAccess} />
                        </ModalComp.ModalBody>
                    </ModalComp.ModalBasicContainer>
                    {props?.autoShow && <div className="modal-backdrop fade show"></div>}
                </>
            );
        };

        static JsxPortalComp = function (props: SpinnerProps & GeneralModalPropsT) {
            return (
                <PortalComp elementId={ModalRootIDs.PORTAL_NOACTION_ROOT_MODAL_ID}>
                    <SpinnerModal.JsxComp {...props}></SpinnerModal.JsxComp>
                </PortalComp>
            );
        };

        constructor(generalModalProps: GeneralModalPropsT, specificModalProps?: T) {
            super(generalModalProps, specificModalProps);
            this.JsxElement = SpinnerModal.JsxComp({ ...this.props, ...generalModalProps });
            this.JsxComp = SpinnerModal.JsxComp;
            this.JsxPortalComp = SpinnerModal.JsxPortalComp;
            this.JsxPortalElement = SpinnerModal.JsxPortalComp({ ...this.props, ...generalModalProps });
            this.modalId = generalModalProps.modalIdSuffix ? "spinnerModalId" + generalModalProps.modalIdSuffix : ModalIDs.SPINNER_MODAL;
            if (generalModalProps.isPortal) this.root = portalNoActionRoot;
            else this.root = noActionRoot;
        }
    }

    export class TwoBtnsModal<T extends TwoBtnsModalProps<"MANDATORY">> extends ModalAbstract<
        TwoBtnsModalProps<"MANDATORY">,
        OkModalProps
    > {
        static readonly TWO_BTNS_MODAL_ID_PREFIX = "twoBtnModalId";
        //for calling inside tsx files
        static JsxComp = function (props: TwoBtnsModalProps & GeneralModalPropsT) {
            const modalId = TwoBtnsModal.TWO_BTNS_MODAL_ID_PREFIX + props.modalIdSuffix;
            return (
                <ModalComp.ModalDialogContainer
                    modalId={props.modalIdSuffix ? modalId : ModalIDs.TWO_BTNS_MODAL}
                    nonStaticModal="static"
                    modalTitle={props.title as string}
                >
                    <ModalComp.ModalBody compName="ModalBody">
                        <div className="">{props.msg}</div>
                    </ModalComp.ModalBody>
                    <ModalComp.ModalFooter compName="ModalFooter">
                        <div className="">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    // const okModal = Modal.getOrCreateInstance(document.getElementById(modal.id) as Element) as Modal;
                                    // okModal.hide();
                                    hide(ModalIDs.OK_MODAL);
                                    props.clickHandlerBtn1?.();
                                    if (props.refreshRootComponent) refreshRootComponent();
                                    if (props.reloadhPage) window.location.reload();
                                    if (props.navTo) historyObject.replace(props.navTo);
                                }}
                            >
                                {props.btn1Label ?? ReadTextResource.getTextById("OK")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    // const okModal = Modal.getOrCreateInstance(document.getElementById(modal.id) as Element) as Modal;
                                    // okModal.hide();
                                    // ModalComp.hide(IDs.OK_MODAL);
                                    props.clickHandlerBtn2?.();
                                    if (props.refreshRootComponent) refreshRootComponent();
                                    if (props.reloadhPage) window.location.reload();
                                    if (props.navTo) historyObject.replace(props.navTo);
                                }}
                            >
                                {props.btn2Label ?? ReadTextResource.getTextById("CANCEL")}
                            </button>
                        </div>
                    </ModalComp.ModalFooter>
                </ModalComp.ModalDialogContainer>
            );
        };

        static JsxPortalComp = function (props: TwoBtnsModalProps & GeneralModalPropsT) {
            return (
                <PortalComp elementId={ModalRootIDs.PORTAL_SAVE_STATE_ROOT_MODAL_ID}>
                    <TwoBtnsModal.JsxComp {...props}></TwoBtnsModal.JsxComp>
                </PortalComp>
            );
        };
        constructor(generalModalProps: GeneralModalPropsT, specificModalProps: T) {
            super(generalModalProps, specificModalProps);
            this.JsxElement = TwoBtnsModal.JsxComp({ ...this.props, ...generalModalProps });
            this.JsxComp = TwoBtnsModal.JsxComp;
            this.JsxPortalComp = TwoBtnsModal.JsxPortalComp;
            this.JsxPortalElement = TwoBtnsModal.JsxPortalComp({ ...this.props, ...generalModalProps });
            this.modalId = generalModalProps.modalIdSuffix
                ? TwoBtnsModal.TWO_BTNS_MODAL_ID_PREFIX + generalModalProps.modalIdSuffix
                : ModalIDs.TWO_BTNS_MODAL;
            if (generalModalProps.isPortal) this.root = portalSaveStateActionRoot;
            this.root = actionRoot;
        }
    }

    export class ResetPassModal<T extends ResetPassProps> extends ModalAbstract<T> {
        //for calling inside tsx files
        static readonly RESET_PASS_MODAL_ID = "resetModalId";
        public static JsxComp = function (props: ResetPassProps & GeneralModalPropsT) {
            const modalId = ResetPassModal.RESET_PASS_MODAL_ID + props.modalIdSuffix;

            return (
                <ModalComp.ModalDialogContainer
                    modalId={props.modalIdSuffix ? modalId : ModalIDs.RESET_PASS_MODAL}
                    nonStaticModal="static"
                    modalTitle={ReadTextResource.getTextById("PASS_RESET")}
                >
                    <ModalComp.ModalBody compName="ModalBody">
                        <div className="">{ReadTextResource.getTextById("PASS_RESET_SUCC")}</div>
                    </ModalComp.ModalBody>
                    <ModalComp.ModalFooter compName="ModalFooter">
                        <div className="">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    hide(ModalComp.ModalIDs.RESET_PASS_MODAL);
                                    historyObject.replace("/?relogin=true", { relogin: "true" });
                                }}
                            >
                                {ReadTextResource.getTextById("HOME_PAGE")}
                            </button>
                        </div>
                    </ModalComp.ModalFooter>
                </ModalComp.ModalDialogContainer>
            );
        };

        static JsxPortalComp = function (props: ResetPassProps & GeneralModalPropsT) {
            return (
                <PortalComp elementId={ModalRootIDs.PORTAL_SAVE_STATE_ROOT_MODAL_ID}>
                    <ResetPassModal.JsxComp {...props}></ResetPassModal.JsxComp>
                </PortalComp>
            );
        };

        constructor(generalModalProps: GeneralModalPropsT, specificModalProps: T) {
            super(generalModalProps, specificModalProps);
            this.JsxElement = ResetPassModal.JsxComp({ ...this.props, ...generalModalProps });
            this.JsxComp = ResetPassModal.JsxComp;
            this.JsxPortalComp = ResetPassModal.JsxPortalComp;
            this.JsxPortalElement = ResetPassModal.JsxPortalComp({ ...this.props, ...generalModalProps });
            this.modalId = generalModalProps.modalIdSuffix
                ? ResetPassModal.RESET_PASS_MODAL_ID + generalModalProps.modalIdSuffix
                : ModalIDs.SPINNER_MODAL;
            if (generalModalProps.isPortal) this.root = portalNoActionRoot;
            else this.root = actionRoot;
        }
    }

    function registerEventLsnr_HideModalOnBackBtn(modalId: string, okPromise?: (p: any) => void) {
        document.getElementById(modalId)?.addEventListener(
            "shown.bs.modal",
            (e) => {
                console.log("shown.bs.modal listner XXXXXXXXX");
                //below works as well, but no need for now, as historyObject does the same
                // window.history.pushState(null, "", window.location.href);
                const currPath = historyObject.location.pathname;
                const unlisten = historyObject.listen((e) => {
                    console.log("historyObject listen XXXXXXXX");
                    if (e.action === "POP") {
                        // historyObject.push("#abc");
                        console.log("pop is detected XXXXXXXX=", e.location);
                        historyObject.push(currPath);
                        // window.location.hash = "#ABCD";
                        hide(modalId);
                        // historyObject.push(e.location);
                    }
                    if (e.action === "PUSH") {
                        console.log("push is detected XXXXXXXX", e.location);
                    }
                    unlisten();
                });

                document.getElementById(modalId)?.addEventListener(
                    "hidden.bs.modal",
                    (e) => {
                        console.log("hidden listener");
                        unlisten();
                    },
                    { once: true }
                );
                okPromise?.("");
            },
            { once: true }
        );
    }

    /**
     * generally, use showSync (doesn't require async/await) when you have some actions on the modal that can hide
     * the modal, such as ok button
     *
     * @param modal if param is string, then you should have already rendered the modal on the page (react component)
     * such as inside Portal element.
     * if the param is ModalAbstract then, a new component will be rendered automatically, use this way in code directly
     * such as action click is usefull.
     */
    /* export function show(modal: ModalAbstract<any> | string): void {
        console.log("showcalled");
        document.exitFullscreen().catch((err) => {
            console.log("no elements in full screen");
        });

        try {
            if (typeof modal === "string") {
                const mInstance = getModalInstance(modal);

                registerEventLsnr_HideModalOnBackBtn(modal);

                mInstance.show();
            }

            if (typeof modal === "object") {
                let modalInstance: Modal; //= (modal as ModalType) ;

                flushSync(() => {
                    actionRoot.render(modal.getJsxElement());
                    // const g = createPortal(<>{modal.element}</>, document.getElementById(IDs.ROOT_MODAL_ID) as Element);
                });
                flushSync(() => {
                    modalInstance = Modal.getOrCreateInstance(document.getElementById(modal.getModalId()) as Element) as Modal;

                    registerEventLsnr_HideModalOnBackBtn(modal.getModalId());

                    modalInstance.show();
                    document.body.style.overflow = "auto";
                    // document.body.setAttribute("style", document.body.style.overflow );

                    //end solution test
                });
            }
        } catch (err) {
            console.log("Modal show error, ", err);
        }
    } */

    /**
     * this method requires async/await blocks, it's guaranteed that the modal is shown before hide
     * is called when they are (showAsyc and hide) in the same async block.
     * generally, use showAsync when there is no actions on the modal to hide the modal, such as spinner.
     * @param modal this param behaves same as in show() method,
     * @returns Promise
     */
    /* export async function showAsync(modal: ModalAbstract<any> | string) {
        console.log("showAsync called");
        document.exitFullscreen().catch((err) => {
            console.log("no elements in full screen");
        });
        const showModalAync = new Promise((ok, notOk) => {
            let okModal: Modal;
            if (typeof modal === "string") {
                registerEventLsnr_HideModalOnBackBtn(modal, ok);

                const mInstance = getModalInstance(modal);
                mInstance.show();
            }

            if (typeof modal === "object") {
                try {
                    flushSync(() => {
                        modal.getRoot().render(modal.getJsxElement());
                        // const g = createPortal(<>{modal.JsxComponent()}</>, document.getElementById(IDs.ROOT_MODAL_ID) as Element);
                    });
                    flushSync(() => {
                        // (document.getElementById(modal.id) as any).show();
                        okModal = Modal.getOrCreateInstance(document.getElementById(modal.getModalId()) as Element) as Modal;

                        registerEventLsnr_HideModalOnBackBtn(modal.getModalId(), ok);

                        okModal.show();
                    });
                    //don't add ok("") here, as it should be resolved from within registerEventLsnr_HideModalOnBackBtn function, ok("") is already sent there
                } catch (err) {
                    console.log("showAync error", err);
                }
            }
        });

        return showModalAync;
    } */

    function hide(modalId: string): void {
        console.log("hide called");
        try {
            const okModal = Modal.getOrCreateInstance(document.getElementById(modalId) as Element) as Modal;
            const modalEl: Element = document.getElementById(modalId)!!;
            const isModalShown = modalEl?.classList.contains("show");
            const isAutoShow = modalEl?.getAttribute("data-autoshow");
            if (isModalShown) {
                okModal.hide();
                // document.getElementById(modalId)?.classList.remove("show");
                // document.getElementById(modalId)?.style.removeProperty("display");
                // if (isAutoShow) forceHide(okModal);
            } else {
                forceHide(okModal, modalId);
            }
        } catch (err) {
            //do nothing, just to avoid returning error in case no modal is shown ho hide it.
            //or it could be used to hide the backdrop when the modal has removed by return some react element by some compoenent like if (error) reutrn <someComponent/>

            const bodyChilds = document.body.children;
            for (let i = 0; i < bodyChilds.length; i++) {
                if (bodyChilds[i].classList.contains("modal-backdrop")) {
                    bodyChilds[i].remove();
                }
            }
            console.log("no modal is shown to hide", err);
        }
    }
    /**
     * recentely added and have'nt not been tested yet
     * @param modalId
     * @returns
     */
    async function hideAsync(modalId: string) {
        console.log("hideAsync called");
        const hideModalAync = new Promise(async (ok, notOk) => {
            try {
                const okModal = Modal.getOrCreateInstance(document.getElementById(modalId) as Element) as Modal;
                const modalEl: Element = document.getElementById(modalId)!!;
                // const okModal = Modal.getInstance(document.getElementById(modalId) as Element) as Modal;
                const isModalShown = modalEl?.classList.contains("show");
                const isAutoShow = modalEl?.getAttribute("data-autoshow");
                if (isModalShown) {
                    okModal.hide();
                    ok("");
                    // document.getElementById(modalId)?.classList.remove("show");
                    // document.getElementById(modalId)?.style.removeProperty("display");
                    // if (isAutoShow) forceHide(okModal);
                } else {
                    forceHide(okModal, modalId);
                    ok("");
                }
            } catch (err) {
                //do nothing, just to avoid returning error in case no modal is shown ho hide it.
                //or it could be used to hide the backdrop when the modal has removed by return some react element by some compoenent like if (error) reutrn <someComponent/>
                console.log("error in hideAsync", err);
                try {
                    const bodyChilds = document.body.children;
                    for (let i = 0; i < bodyChilds.length; i++) {
                        if (bodyChilds[i].classList.contains("modal-backdrop")) {
                            bodyChilds[i].remove();
                        }
                    }
                    console.log("no modal is shown to hide", err);
                    ok("");
                } catch (err) {
                    console.log("error2 in hideAsync", err);
                    ok("");
                }
            }
        });
        return hideModalAync;
    }

    /* important comment for forceHide()
     *  it is used when you call showSync which doesn't return promise, in this case if
     *  the hide modal had been called before the modal was actually shown then the modal will hang,
     *  so we need to enforce it to hide.
     *  if you use showAsync which requires async/await blocks, then it's safe to call hide
     *  anytime, becuase it's guaranteed that the modal is shown before hide is called.
     *  generally, use showSync (doesn't require async/await) when you have some actions on the modal such as ok button
     *  and use showAsync when there is no actions on the modal, such as spinner.
     */
    async function forceHide(modal: Modal, modalId: string) {
        console.log("forceHide called");
        try {
            document.getElementById(modalId)?.addEventListener("hidden.bs.modal", (e) => {
                modal.dispose();
            });

            modal.hide();
            //##TO-DO##
            //still there is missing code here, needs to be implemented for all types of modals such as NavModal
            //so it's better to remove all static calls for show and hide and use the ones inside the modal classes instead
            if (modalId === ModalIDs.SPINNER_MODAL) {
                noActionRoot.render(<div />);
            } else {
                /**
                 * until now, no need to render(div) for action roots, as no possibility for successive fast show and hide,
                 * as it requires action from end user to show and hide.
                 * but it requried in no-action modal as there is a possibility for very fast show and hide
                 */
                // actionRoot.render(<div />);
            }
        } catch (err) {
            console.log("forceHide error", err);
        }
    }
}
