import { CSSProperties, PropsWithChildren, Ref } from "react";

export function ButtonGroup(
    props: PropsWithChildren<{
        id: string;
        inputRef?: Ref<any>;
        classNames?: string;
        styles?: CSSProperties | undefined; //{ [key: string]: string };
    }>
) {
    return (
        <div className="col-auto btn-group btn-group-sm" role="group" aria-label="Basic example">
            {props.children}
        </div>
    );
}
