import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDistanceInfo } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { PsgrVclSeatSelector } from "../commonComp/vehicle/PsgrVclSeatSelector";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";

export function PsgrGdsDtls(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrGdsDtls Component");
    const navigate = useNavigate();
    const { pReqId, pDrvrId, pDrvrDestLoc } = useParams();
    console.log("parms", pReqId, pDrvrId);


    //
    const location = useLocation();
    let goodsInfo = (location.state as any)?.goodsInfo;
    /////////////////////
    type goodsArrT = {
        goodWeight: number;
        goodHeight: number;
        goodWidth: number;
        goodLength: number;
        goodNote: string;
        reqId: number;
        destLoc: Location;
        srcLoc: Location;
        tripDuration: number;
        distance: number;
    }[];

    const [goodsArrSt, setGoodsArrSt] = useState<goodsArrT>([]);

    const {
        error: getGdsDtlErr,
        data: getGdsDtlData,
        loading: getGdsDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.GOODS_DTLS, {
        fetchPolicy: "no-cache",
        variables: {
            tripId: +pReqId!,
        },
        onCompleted: async (data) => {
            /* data?.getGdsDtls?.dataArr?.map((v: any, i: number) => {
                // psgrSrcColctLocDis({ type: FormCmps.LocationCmp.LocationActDisTypesT.Location, location: v.psgrSrcLoc });
                // dropOffLocationStDis({ type: FormCmps.LocationCmp.LocationActDisTypesT.Location, location: v.psgrDestLoc });
                v?.tripGoods?.tripGoodsDtl?.map((v: any, i: number) => {
                    goodWeightArrDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
                        rdcKey: i,
                        value: v.goodWeight,
                    });

                    goodLengthArrDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
                        rdcKey: i,
                        value: v.goodLength,
                    });

                    goodWidthArrDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
                        rdcKey: i,
                        value: v.goodWidth,
                    });
                    goodHeightArrDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
                        rdcKey: i,
                        value: v.goodHeight,
                    });
                    goodNoteArrDis({
                        type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
                        rdcKey: i,
                        value: v.goodNote,
                    });


                });

            }); */
        },
    });

    // useEffect(() => {
    //     getGdsDtlData?.getGdsDtls?.dataArr?.map((v: any, i: number) => {
    //         goodWeightArrDis({
    //             type: FormCmps.TextCmp.textActDisTypesT.ValueInput,
    //             rdcKey: (goodWeightArrSt[goodWeightArrSt.length - 1].rdcKey ?? 0) + 1,
    //             value: v.goodWeight,
    //         });
    //     });
    // }, []);    

    if (pReqId === undefined) return <div>Invalid request parameters</div>;

    async function isFormValid(): Promise<boolean> {
        let isPrevFormValid: boolean = true;
        const validationModal = new ModalComp.OkModal(
            { isPortal: false },
            { msg: ReadTextResource.getTextById("FILL_ALL_FLDS"), navTo: "" }
        );

        return isPrevFormValid;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }
    }

    if (getGdsDtlLoading) return <div>Loading ...</div>;

    return (
        <>
            <StickyBar id="addGoodsMainStickBar1">
                <button
                    type="button"
                    className="btn btn-danger px-5 px-md-5 px-sm-5 py-1 py-md-2 py-sm-1"
                    onClick={async () => {
                        console.log("Cancel button clicked");
                        navigate(-1);
                    }}
                >
                    {ReadTextResource.getTextById("BACK")}
                </button>
            </StickyBar>
            <FormCmps.Form id="psgrAddGoodsId1">
                {getGdsDtlData?.getGdsDtls?.dataArr?.length > 0 && <FormCmps.LabelComp.JsxComp id="goodSizeId1">
                    <strong> {ReadTextResource.getTextById("GOOD_SIZE")}</strong>
                </FormCmps.LabelComp.JsxComp>}

                <GoodsDtlsCmp key="psgrGdsDtlId1" showApproval={false} pGoodsData={getGdsDtlData?.getGdsDtls?.dataArr}></GoodsDtlsCmp>
            </FormCmps.Form >
        </>
    );
}
