import { PropsWithChildren, ReactNode } from "react";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";

export namespace HeaderComp {
    export function HeaderStart(props: PropsWithChildren<{ compName: "HeaderStart" }>) {
        return <>{props.children}</>;
    }

    /*    export class HeaderStart {
        public static JsxComp = function (props: PropsWithChildren<{compName?:string}>) {
          props.compName="HeaderStart"
            return <>{props.children}</>;
        };
        constructor(){
          HeaderStart.JsxComp.nameAAA="";
        }
    } */

    export function HeaderEnd(props: PropsWithChildren<{ compName: "HeaderEnd" }>) {
        return <>{props.children}</>;
    }

    export function HeaderCenter(props: PropsWithChildren<{ compName: "HeaderCenter" }>) {
        return <>{props.children}</>;
    }

    export function HeaderContainer(props: PropsWithChildren<{}>) {
        let oneChild = undefined;
        let HeaderStart = undefined;
        let HeaderEnd = undefined;
        let HeaderCenter = undefined;
        if (Array.isArray(props.children)) {
            const childrenArray = props.children as any; // Array<ReactElement>;
            // console.log("HHHHHHHH childrenArray=", childrenArray[0].props.compName, "childrenArray[0]=", childrenArray[0]);
            HeaderStart = childrenArray?.find((element: any) => element.props.compName === "HeaderStart");
            HeaderEnd = childrenArray?.find((element: any) => element.props.compName === "HeaderEnd");
            HeaderCenter = childrenArray?.find((element: any) => element.props.compName === "HeaderCenter");
        } else {
            oneChild = props.children as any;
            HeaderStart = oneChild?.props.compName === "HeaderStart" ? oneChild : "";
            HeaderEnd = oneChild?.props.compName === "HeaderEnd" ? oneChild : "";
            HeaderCenter = oneChild?.props.compName === "HeaderCenter" ? oneChild : "";
        }
        if (!HeaderEnd || !HeaderStart || !HeaderCenter)
            console.warn("The <HeaderComp> custom compponent can have three childs, the HeaderEnd, HeaderStart and HeaderCenter");
        return (
            <>
                <div id="mainHeaderId0" className="contianer-fluid  px-1 bg-primary text-light py-3">
                    <div className="row" dir="ltr">
                        <div>{ReadTextResource.getTextById("HIGWAY_TAXI")}</div>
                    </div>
                    <div className="row align-items-center m-0">
                        <div className="col-4">{HeaderStart}</div>
                        <div className="col-4 text-center">{HeaderCenter}</div>
                        <div className="col-4 text-end d-flex flex-column flex-md-row justify-content-end ">
                            {HeaderEnd}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
