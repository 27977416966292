import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, ServerError, ServerParseError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import jwtDecode from "jwt-decode";
import { blockOtherModalsRVar, csrfTokenRvar, dirAndLangRvar } from "./ReactiveVars";
import { SERVER_NAME } from "../EnvVariables";
// import sendRefreshToken from "../sendRefreshToken";
import { onError } from "@apollo/client/link/error";
// import { useNavigate } from "react-router-dom";
// import { createBrowserHistory } from "history";
import historyObject from "../HistoryRouterObject";
import { RetryLink } from "@apollo/client/link/retry";
import { getAccessInfo, getCsrfToken, logout, setAccessInfo } from "../commonFns";
import { createUploadLink } from "apollo-upload-client";
import { getGqlStmt } from "../graphQL/Queries";
import { Auth, User } from "../Types";
import { log } from "console";
import { ModalComp } from "../../components/commonComp/ModalComp";
console.log("apollo provider in ApolloConfig.ts");

// const uploadLink = createUploadLink({
//   uri: `${SERVER_NAME}:9000/graphql`,
// });

const httpLink = createUploadLink(
    /*It was createHttpLink before*/(() => {
        console.log("This is the FIRST AUTHLINK (HttpLink) ");
        return {
            uri: `${SERVER_NAME}/graphql`,
            credentials: "include",
            // fetchOptions:{"keepalive":true}
        };
    })()
);

const refreshExpiredAccessTokenLink = setContext(
    async (operation) => {
        const fetchAccessToken = new Promise(async (ok, notOk) => {
            const currentAccessToken = getAccessInfo()?.token;

            console.log("##operation in refreshExpiredAccessTokenLink", operation);
            if (!currentAccessToken) {
                ok("");
                return;
            }

            if (operation.operationName === "refreshAccessToken") {
                ok("");
                return;
            }

            const decodedToken: any = jwtDecode(getAccessInfo()?.token as string);

            //check if expired
            if (Date.now() / 1000 > decodedToken.exp) {
                const refreshTokenRes = (
                    await apolloClient.mutate({
                        mutation: getGqlStmt().mutations.RFRESH_ACCESS_TOKEN,
                        fetchPolicy: "no-cache",
                        context: {
                            fetchOptions: {
                                keepalive: true,
                            },
                        },
                    })
                ).data.refreshAccessToken.data as Auth;
                // sendRefreshToken().then((res) => {
                //   if (res) {
                //     ok("");
                //     return;
                //   }
                // });
                if (refreshTokenRes) {
                    setAccessInfo({
                        token: refreshTokenRes.token,
                        username: refreshTokenRes.username,
                        isAuthenticated: refreshTokenRes.isAuthenticated,
                        email: refreshTokenRes.email,
                        user: refreshTokenRes.user as User,
                    });
                    console.log("##getAccessInfo", getAccessInfo());
                    ok("");
                    return;
                } else {
                    ok("");
                    return;
                }
            } else {
                ok("");
                return;
            }
        });
        await fetchAccessToken;
    }
    /******* another way to postpone link until it is done its work inside regular apollo link ********************
    const <<<refreshAccessTokenLink>>> = new ApolloLink((operation, observable) => {
    const p = new Promise((ok, notOk) => {
      setTimeout(() => {
        console.log("this is from apolloLinkTest1");
        ok("");
      }, 5000);
    });
    return fromPromise(
      p.then(() => {
        return toPromise(observable(operation));
      })
    );
    });  
    ****************************************************************************************************************/
);

const authLink = setContext(async (_, { headers, context }) => {
    return {
        headers: {
            ...headers,
            authorization: getAccessInfo()?.token,
            "CSRF-Token": csrfTokenRvar()?.csrfToken,
            keepalive: true,
            lang: dirAndLangRvar().lang
        },
    };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, response, networkError, operation, forward }) => {
    // const nav = useNavigate();
    const networkErrorRef: ServerParseError = networkError as ServerParseError;
    console.log("errorLink");
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            console.log("##err of graphQLErrors", err);

            const exp = err.extensions as any;
            if (exp?.errorId)
                switch (exp.errorId) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case "NOT_AUTHED":
                        // nav("/");
                        console.log("NOT_AUTHED location", historyObject.location);

                        console.log("errorLink NOT_AUTHED");

                        // logout();

                        historyObject.replace("/login?relogin=true", {
                            relogin: "true",
                            redirectTo: (historyObject?.location?.state as any)?.redirectTo,
                        });

                        break;
                    // historyObject.push("/");
                    case "NOT_AUTHORIZED":
                        const m = new ModalComp.OkModal({ isPortal: false }, {
                            msg: "Your account is not active", okClickHandler() {
                                m.releaseBlockModals();
                            },
                        });
                        m.show({ blockOthersUntilHide: true });
                        // historyObject.push("/app/activation");
                        break;
                    default:
                        console.log("errorLink, GraphQL error =", exp.errorId);
                        break;
                    // // Modify the operation context with a new token
                    // const oldHeaders = operation.getContext().headers;
                    // operation.setContext({
                    //   headers: {
                    //     ...oldHeaders,
                    //     authorization: getNewToken(),
                    //   },
                    // });
                    // // Retry the request, returning the new observable
                    // return forward(operation);
                }
            else console.log("errorLink2, GraphQL error =", err);
        }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkErrorRef) {
        console.log(
            "Network error",
            "\n1",
            networkErrorRef.bodyText,
            "\n2",
            networkErrorRef.response,
            "\n3",
            networkErrorRef.statusCode,
            "\n4",
            networkErrorRef.name
        );
    }
});

const retryLink = new RetryLink({
    attempts: async (count, operation, error) => {
        if (error.bodyText !== "INVALID_SEC_TKN" && error.statusCode !== 403) {
            console.log("Retry Link, Don't send CSRF refresh request");
            return false;
        }

        const res = await getCsrfToken();
        if (res) {
            return count === 1;
        }
        return false;
    },
});

const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        // setTimeout(() => {
        //     // const m = new ModalComp.OkModal({ isPortal: false }, { msg: "YYYYYYYYY" });
        //     // m.showAsync();
        // }, 2000);
        historyObject.push("/app/activation");

        console.log("apollo response is:", response)
        return response;
    });

});
export const apolloClient = new ApolloClient({
    link: from([/* responseLink, */ retryLink, errorLink, refreshExpiredAccessTokenLink, authLink, httpLink]),
    connectToDevTools: true /* ##TO-DO## it should be false on production */,
    cache: new InMemoryCache({
        typePolicies: {
            /* GlAccount: {
                keyFields: ["accId"],
                fields: {
                    isActive: {
                        read(_, { variables }) {
                            return variables?.id;
                        },
                    },
                },
            },
            customObject: {
                keyFields: ["att1"],
            }, */
        },
    }),
    // credentials: "include",
});
