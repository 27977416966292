import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import React, { MouseEvent, useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { country_cities } from "../../commonSrc/textResources/country_cities";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { StickyBar } from "../commonComp/StickyBar";
import { Location, MapStT } from "../commonComp/GoogleMapCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { getAccessInfo, getDistanceInfo } from "../../commonSrc/commonFns";

export function DrvrTransportReq(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrTransportReq Component");
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(accessTokenRvar);
    const [currentUserLocation, setCurrentUserLocation] = useState<Location>({ lat: 32.02270516093821, lng: 35.84407458566004 });

    const [tripDuration, setTripDuration] = useState(0);
    const [tripDistance, setTripDistance] = useState(0);
    //--START-- JSX Components Declarations**************************************

    const {
        JsxComp: InOutTrans,
        compReducer: [inOutTransSt, inOutTransStDis],
        compRef: inOutTransStRef,
    } = FormCmps.RadioCmp.getComp("In");

    const {
        JsxComp: ReqType,
        compReducer: [reqTypeSt, reqTypeDis],
        compRef: reqTypeRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: FromCityAutoComplete,
        compReducer: [fromCityAutoCompleteSt, fromCityAutoCompleteDis],
        compRef: fromCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: ToCityAutoComplete,
        compReducer: [toCityAutoCompleteSt, toCityAutoCompleteDis],
        compRef: toCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: FromCountry,
        compReducer: [fromCountrySt, fromCountryDis],
        compRef: fromCountryRef,
        // { code: "JO", label: "Jordan", phone: "+962", suggested: true }
    } = FormCmps.CountryCmp.getComp(getAccessInfo()?.user?.countryByIp);

    const {
        JsxComp: ToCountry,
        compReducer: [toCountrySt, toCountryDis],
        compRef: toCountryRef,
    } = FormCmps.CountryCmp.getComp();

    const {
        JsxComp: TransportDate,
        compReducer: [traspDateSt, traspDateStDis],
        compRef: traspDateStRef,
    } = FormCmps.DateComp.getComp(/* new Date() */);

    const {
        JsxComp: DeliveryTime,
        compReducer: [deliveryTimeSt, deliveryTimeDis],
        compRef: deliveryTimeStRef,
    } = FormCmps.TimeComp.getComp(new Date());

    const {
        JsxComp: ReachPassenger,
        compReducer: [reachPassengerSt, reachPassengerDis],
        compRef: reachPassengerStRef,
    } = FormCmps.CheckboxCmp.getComp();

    const {
        JsxComp: DroppOffType,
        compReducer: [droppOffTypeSt, droppOffTypeDis],
        compRef: droppOffTypeRef,
    } = FormCmps.RadioCmp.getComp();

    const {
        JsxComp: CollectionLocation,
        compReducer: [drvrSrcColctLocSt, drvrSrcColctLocDis],
        compRef: drvrSrcColctLocRef,
    } = FormCmps.LocationCmp.getComp(/* { lat: 31.165375, lng: 35.745947 } */);

    const {
        JsxComp: DropOffLocation,
        compReducer: [dropOffLocationSt, dropOffLocationStDis],
        compRef: dropOffLocationStRef,
    } = FormCmps.LocationCmp.getComp();

    //--END-- JSX Components Declarations****************************************************************

    /* const [getIpInfo, { error: getIpInfoError, data: getIpInfoData, loading: getIpInfoLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_IP_INFO,
        {
            onCompleted(data) {
                console.log("ipInfo=", data);
            },
            fetchPolicy: "no-cache",
        }
    ); */

    useEffect(() => {
        async function a() {
            /* getting current country by ip address when page is loaded or refreshed, it also can be gotten on login and stored on Reactive Var and 
            re-get it on refresh in App.tsx where we refresh the accesss token.
            const ipInfo = await getIpInfo();
            const currentCountryByIp = countries.find((e) => e.code === ipInfo.data.getIpInfo.country_code) as CountryT;
            console.log("gggggg=", ipInfo.data.getIpInfo.country_code, "cccc=", currentCountryByIp);
            fromCountryDis({ type: FormCmps.CountryCmp.countryActDisTypesT.ValueInput, value: currentCountryByIp }); 
            */
        }
        a();
    }, [dirAndLangRvar().lang]);

    // const [location, setLocation] = useState("");

    async function isFormValid(): Promise<boolean> {
        let isFormValid: boolean = true;
        const okModalInstance = new ModalComp.OkModal(
            { isPortal: false },
            {
                msg: ReadTextResource.getTextById("FILL_ALL_FLDS"),
                navTo: undefined, // or just remove it
            }
        );

        isFormValid = await FormCmps.RadioCmp.validate(
            inOutTransSt,
            inOutTransStDis,
            isFormValid,
            okModalInstance,
            inOutTransStRef,
            "Select a value for Transport type"
        );

        isFormValid = await FormCmps.SelectCmp.validate(
            reqTypeSt,
            reqTypeDis,
            isFormValid,
            okModalInstance,
            reqTypeRef,
            "Select a value for Request Type"
        );

        isFormValid = await FormCmps.CountryCmp.validate(fromCountryRef, fromCountryDis, isFormValid, okModalInstance);

        if (inOutTransSt.value === "Out")
            isFormValid = await FormCmps.CountryCmp.validate(toCountryRef, toCountryDis, isFormValid, okModalInstance);

        isFormValid = await FormCmps.AutoCompleteCmp.validate(
            fromCityAutoCompleteRef,
            fromCityAutoCompleteSt,
            fromCityAutoCompleteDis,
            isFormValid,
            okModalInstance
        );

        isFormValid = await FormCmps.AutoCompleteCmp.validate(
            toCityAutoCompleteRef,
            toCityAutoCompleteSt,
            toCityAutoCompleteDis,
            isFormValid,
            okModalInstance
        );

        isFormValid = await FormCmps.DateComp.validate(
            traspDateSt,
            traspDateStDis,
            isFormValid,
            okModalInstance,
            traspDateStRef,
            "please set the transport date and time"
        );

        if (reqTypeSt.value !== "Goods")
            isFormValid = await FormCmps.RadioCmp.validate(
                droppOffTypeSt,
                droppOffTypeDis,
                isFormValid,
                okModalInstance,
                droppOffTypeRef,
                "Select a value for dropoff type"
            );

        isFormValid = await FormCmps.LocationCmp.validate(
            drvrSrcColctLocSt,
            drvrSrcColctLocDis,
            isFormValid,
            okModalInstance,
            drvrSrcColctLocRef
        );

        isFormValid = await FormCmps.LocationCmp.validate(
            dropOffLocationSt,
            dropOffLocationStDis,
            isFormValid,
            okModalInstance,
            dropOffLocationStRef
        );
        /////////////////////////
        return isFormValid;
    }

    const [createDrvrTraspReq, { error: createDrvrTraspReqError, data: createDrvrTraspReqData, loading: createDrvrTraspReqLoading }] =
        useMutation(getGqlStmt().mutations.CREATE_TRANSPORT_REQUEST, {
            fetchPolicy: "no-cache",
            variables: {
                transportReqInput: {
                    drvrSrcLoc: {
                        lat: drvrSrcColctLocSt.location?.lat,
                        lng: drvrSrcColctLocSt.location?.lng,
                    },
                    drvrDestLoc: {
                        lat: dropOffLocationSt.location?.lat,
                        lng: dropOffLocationSt.location?.lng,
                    },
                    canReachPsgr: reachPassengerSt.value,
                    dropoffType: droppOffTypeSt.value, //"AllInOneDest",
                    fromCountry: fromCountrySt.value?.code,
                    toCountry: toCountrySt.value?.code,
                    fromCity: fromCityAutoCompleteSt.value?.id,
                    toCity: toCityAutoCompleteSt.value?.id,
                    // psgrWithCompanions: 0,
                    // reqSrcUserId: 15,
                    reqType: reqTypeSt.value,
                    // reqStatus: "New",
                    traspType: inOutTransSt.value,
                    traspDatetime: traspDateSt.value,
                    tripDuration: tripDuration,
                    distance: tripDistance,
                },
            },
        });

    async function onSave(e: MouseEvent<HTMLButtonElement>) {
        console.log("deliveryDateSt.value=", traspDateSt.value);
        console.log("reachPassengerSt.value=", reachPassengerSt.value);

        e?.preventDefault();
        if (!(await isFormValid())) {
            return;
        }

        const spinner = new ModalComp.SpinnerModal({ isPortal: false });
        await spinner.showAsync();

        const okModalInstance = new ModalComp.OkModal({ isPortal: false }, { msg: "Please fill all required fields ", navTo: "" });

        try {
            const srcToDestDistance = await getDistanceInfo({
                origins: [drvrSrcColctLocSt.location!],
                destinations: [dropOffLocationSt.location!],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            });
            setTripDuration(srcToDestDistance.duration);
            setTripDistance(srcToDestDistance.distance);
            const createTraspReqRes = await createDrvrTraspReq({
                onCompleted: async (data) => {
                    await spinner.hideAsync();
                    if (data?.createTraspReq?.success) {
                        okModalInstance.setProps({
                            msg: (
                                <p>
                                    {ReadTextResource.getTextById("SAVED_SUCCES")} <br />
                                </p>
                            ),
                            //commented temporarily, should navigate to some other page when it's done
                            // navTo: "/",
                        });
                    } else {
                        if (data?.createTraspReq?.errorId === "NO_VCL_DEFINED_FOR_DRVR") {
                            okModalInstance.setProps({
                                msg: data?.createTraspReq?.errorDesc,
                            });
                        } else {
                            console.log("createTransportReq mutation is not successfull -abd-");
                            okModalInstance.setProps({
                                msg: "Sorry, an error occured, please call the administrator " + data?.createTraspReq?.errorDesc,
                            });
                        }
                    }
                    await okModalInstance.showAsync();
                },
                onError: async (err) => {
                    console.log("createTransportReq mutation is not successfull -abd- err=", err);
                    await spinner.hideAsync();
                    okModalInstance.setProps({
                        msg: "Sorry, an error occured, please call the administrator "
                    });
                    await okModalInstance.showAsync();
                },
            });
        } catch (err) {
            //OK
            // const okModal = ModalComp.OkModal;
            await spinner.hideAsync();
            console.log("errrrrrrrrrrrrrrrrrrr2=", err);
            okModalInstance.setProps({ msg: "Sorry1, an error occured, please call the administrator, abd-10" + err });
            await okModalInstance.showAsync();
            return;
        }
    }

    return (
        <>
            <StickyBar id="mainStickBar1">
                <FormCmps.FormSubmitBtn
                    id="submitBtn"
                    label={ReadTextResource.getTextById("SAVE")}
                    onOkClickHandler={onSave}
                ></FormCmps.FormSubmitBtn>
            </StickyBar>
            <FormCmps.ContainerNoFormCmp id="drvrTraspReqId1">
                <FormCmps.LabelComp.JsxComp id="welcomeCaptinId1">
                    <div className="">{ReadTextResource.getTextById("CRT_NEW_DRVR_REQ_HINT")}</div>
                </FormCmps.LabelComp.JsxComp>

                <InOutTrans
                    radioRdc={[inOutTransSt, inOutTransStDis]}
                    id="inOutTransId1"
                    label={ReadTextResource.getTextById("TRANS_TYPE")}
                    radioDir={"horizontal"}
                >
                    <FormCmps.RadioItem
                        id="inTransRadio"
                        label={ReadTextResource.getTextById("IN_COUNTRY")}
                        value="In"
                    ></FormCmps.RadioItem>
                    <FormCmps.RadioItem
                        id="outTransRadio"
                        label={ReadTextResource.getTextById("OUT_COUNTRY")}
                        value="Out"
                    ></FormCmps.RadioItem>
                </InOutTrans>

                <ReqType
                    id="ReqTypeId1"
                    selectRdc={[reqTypeSt, reqTypeDis]}
                    label={ReadTextResource.getTextById("TRANS_TYPE")}
                    selectValues={[
                        { selectLableAndId: ReadTextResource.getTextById("PSGRS_N_GOODS"), valueKey: "Normal" },
                        { selectLableAndId: ReadTextResource.getTextById("GOODS_ONLY"), valueKey: "Goods" },
                    ]}
                    inputRef={reqTypeRef}
                ></ReqType>

                <FromCountry
                    id="fromCoutry1"
                    label={ReadTextResource.getTextById("FROM_COUNTRY")}
                    // defaultValue={!getIpInfoLoading ? countries.find((e) => e.code === getIpInfoData?.getIpInfo?.country_code) : undefined}
                    // defaultValue={userInfoRvar().countryByIp}
                    inputRef={fromCountryRef}
                    validateEmpty={true}
                    countryRdc={[fromCountrySt, fromCountryDis]}
                    onChange={() => {
                        fromCityAutoCompleteDis({
                            type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                            value: "",
                        });
                    }}
                ></FromCountry>

                {inOutTransSt.value === "Out" && (
                    <ToCountry
                        id="toCoutryId1"
                        label={ReadTextResource.getTextById("TO_COUNTRY")}
                        inputRef={toCountryRef}
                        validateEmpty={true}
                        visible={inOutTransSt.value === "Out"}
                        countryRdc={[toCountrySt, toCountryDis]}
                        onChange={() => {
                            toCityAutoCompleteDis({
                                type: FormCmps.AutoCompleteCmp.autoCompleteActDisTypesEnum.ValueInput,
                                value: "",
                            });
                        }}
                    ></ToCountry>
                )}

                <FromCityAutoComplete
                    id="fromCity"
                    label={ReadTextResource.getTextById("FROM_CITY")}
                    inputRef={fromCityAutoCompleteRef}
                    validateEmpty={true}
                    autoCompleteRdc={[fromCityAutoCompleteSt, fromCityAutoCompleteDis]}
                    optionLable={(option) => {
                        return option?.name_ar ? option?.name_ar : option?.name || "";
                    }}
                    inputOptions={
                        // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                        (() => {
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            if (fromCountrySt.value?.code) {
                                const e = country_cities?.find((e) => {
                                    return e.iso2 === fromCountrySt.value?.code; /* userCountrySt.value?.code; */
                                    //|| e.phone_code == ("+" || countryValSt.phone)
                                });
                                if (e) return e.states;
                                else return [];
                            }
                            return [];
                        })()
                    }
                ></FromCityAutoComplete>

                <ToCityAutoComplete
                    id="toCity"
                    label={ReadTextResource.getTextById("TO_CITY")}
                    inputRef={toCityAutoCompleteRef}
                    validateEmpty={true}
                    autoCompleteRdc={[toCityAutoCompleteSt, toCityAutoCompleteDis]}
                    optionLable={(option) => {
                        return option?.name_ar ? option?.name_ar : option?.name || "";
                    }}
                    inputOptions={
                        // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                        (() => {
                            // countryValSt?.phone ? country_cities?.find((e) => e.phone_code === countryValSt.phone)?.states : []
                            if (inOutTransSt.value === "Out") {
                                if (toCountrySt.value?.code) {
                                    const e = country_cities?.find((e) => {
                                        return e.iso2 === toCountrySt.value?.code; //userCountrySt.value?.code;
                                        //|| e.phone_code == ("+" || countryValSt.phone)
                                    });
                                    if (e) return e.states;
                                    else return [];
                                }
                            } else {
                                if (fromCountrySt.value?.code) {
                                    const e = country_cities?.find((e) => {
                                        return e.iso2 === fromCountrySt.value?.code; //userCountrySt.value?.code;
                                        //|| e.phone_code == ("+" || countryValSt.phone)
                                    });
                                    if (e) return e.states;
                                    else return [];
                                }
                            }
                            return [];
                        })()
                    }
                ></ToCityAutoComplete>

                <TransportDate
                    id="deliveryDateId"
                    label={ReadTextResource.getTextById("TRIP_DATETIME")}
                    dateRdc={[traspDateSt, traspDateStDis]}
                ></TransportDate>

                {reqTypeSt.value === "Normal" && (
                    <ReachPassenger
                        id="reachPassengerId"
                        label={ReadTextResource.getTextById("REACH_EACH_PSGR")}
                        checkboxRdc={[reachPassengerSt, reachPassengerDis]}
                    ></ReachPassenger>
                )}

                {reqTypeSt.value === "Normal" && (
                    <DroppOffType
                        radioRdc={[droppOffTypeSt, droppOffTypeDis]}
                        id="droppOffTypeId1"
                        label={ReadTextResource.getTextById("DROP_OFF_TYPE")}
                        radioDir={"horizontal"}
                        inputRef={droppOffTypeRef}
                    >
                        <FormCmps.RadioItem
                            id="allInOndroppOffDestRadio"
                            label={ReadTextResource.getTextById("ALL_IN_1_DEST")}
                            value="AllInOneDest"
                        ></FormCmps.RadioItem>
                        <FormCmps.RadioItem
                            id="eachPsgrInHisDestRadio"
                            label={ReadTextResource.getTextById("EACH_PSGR_IN_DEST")}
                            value="EachInDest"
                        ></FormCmps.RadioItem>
                    </DroppOffType>
                )}

                <div className="row col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-4">
                        <CollectionLocation
                            id="drvrCollectionLocationId1"
                            locationRdc={[drvrSrcColctLocSt, drvrSrcColctLocDis]}
                            label={ReadTextResource.getTextById("SELECT_UR_LOC")}
                            inputRef={drvrSrcColctLocRef}
                        ></CollectionLocation>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-4">
                        <DropOffLocation
                            id="drvrDropOffLocId1"
                            locationRdc={[dropOffLocationSt, dropOffLocationStDis]}
                            label={ReadTextResource.getTextById("SELECT_UR_DEST_LAST_STOP")}
                            inputRef={dropOffLocationStRef}
                        ></DropOffLocation>
                    </div>
                </div>
            </FormCmps.ContainerNoFormCmp>
        </>
    );
}
