import React from "react";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { findCityById } from "../../commonSrc/commonFns";
import { format, formatDistance, parseISO } from "date-fns";


export function ReqListItem(props: React.PropsWithChildren<{ reqDtlInfo: any; embedCmp: React.ReactElement; enableAprvRedBoxCount: boolean }>) {
    console.log("First line - ReqListItem Component");
    return <div className="mb-5 p-3 rounded shadow position-relative" key={"your" + props.reqDtlInfo.reqId}>
        <div>
            <strong>{ReadTextResource.getTextById("REQ_NO")}</strong> {` ${props.reqDtlInfo.reqId}`}
            <strong className="ps-3">{ReadTextResource.getTextById("FROM")}</strong>
            {` ${findCityById(props.reqDtlInfo.fromCountry, props.reqDtlInfo.fromCity)} `}
            <strong>{ReadTextResource.getTextById("TO")}</strong> {` ${findCityById(props.reqDtlInfo.fromCountry, props.reqDtlInfo.toCity)} `}
        </div>
        <div>
            <strong>{ReadTextResource.getTextById("DATE_TIME")}</strong>
            {` ${format(parseISO(props.reqDtlInfo.traspDatetime), "dd-MM-yyyy hh:mm a")} `}
        </div>
        <div>
            <strong>{ReadTextResource.getTextById("REM_TIME")} </strong>
            {` ${formatDistance(parseISO(props.reqDtlInfo.traspDatetime), new Date(), { addSuffix: true })} `}
        </div>
        <strong>{ReadTextResource.getTextById("REQ_STATUS")} </strong>
        {` ${ReadTextResource.getTextById(props.reqDtlInfo.reqStatus.toUpperCase())} `}
        {(props.reqDtlInfo.reqType === "Goods" || props.reqDtlInfo.reqType === "Fs_ALL") && (
            <>
                <strong>{ReadTextResource.getTextById("REQ_TYPE") + " "}</strong>
                {ReadTextResource.getTextById((props.reqDtlInfo.reqType as string)?.toUpperCase() as any)}
            </>
        )}
        {props.reqDtlInfo.reqType === "Goods" && (
            <div className="position-absolute bottom-0 end-0 p-2">
                <i className="bi bi-luggage fs-1 text-info p-3 text-primary"></i>
                {props.reqDtlInfo?.approvalsCount > 0 && props.enableAprvRedBoxCount &&
                    // <i className="bg-danger rounded-circle p-1">
                    <span className="badge text-bg-danger">{"+" + props.reqDtlInfo.approvalsCount}</span>
                    // </i>
                }
            </div>
        )}


        {props.reqDtlInfo.reqType !== "Goods" && props.reqDtlInfo?.approvalsCount > 0 && props.enableAprvRedBoxCount && (
            <> <div className="position-absolute top-0 end-0 p-1">
                <span className="badge text-bg-danger">{"+" + props.reqDtlInfo.approvalsCount}</span>
            </div>
            </>
        )}

        {props.reqDtlInfo.reqType !== "Goods" && (
            <> <div className="position-absolute bottom-0 end-0 p-1">
                <i className="bi bi-car-front-fill fs-1 text-info p-3 text-primary"></i>
            </div>
            </>
        )}

        <div key={"embed" + props.reqDtlInfo.reqId}>
            {props.embedCmp}
        </div>
    </div>
}