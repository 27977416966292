import { PropsWithChildren, useEffect, useState } from "react";
import { SeatSelectionStatus, SeatStatusT, VehicleSeatsT } from "../../../commonSrc/Types";
import { Seat } from "./Seat";
import { VehicleSeatSelector } from "./VehicleSeatSelector";

export function VehicleSeats(
    props: PropsWithChildren<{
        seatsStArr: SeatStatusT[];
        load: number;
        seatRdc?: [VehicleSeatSelector.SeatStT, VehicleSeatSelector.SeatDisT];
        onSelectSeat: (index: number) => void;
    }>
) {
    console.log("First line - VehicleSeats Component");

    const [allSeats, setAllSeats] = useState<any[]>([]);

    const vehicleSeats: VehicleSeatsT[] = [];

    function updateVclSeats(pSeatStatus: SeatSelectionStatus, pSeatRow: number, pSeatNo: number, pSeatId?: number) {
        const seatNoXpositionMap: { [key in number]: string } = { 1: "x1", 2: "x2", 3: "x3" };

        vehicleSeats.find((seatRowObj: any) => {
            if (seatRowObj.y === pSeatRow) {
                // seatNoXpositionMap[pSeatNo];
                if (pSeatStatus === SeatSelectionStatus.SELECTED) {
                    seatRowObj[seatNoXpositionMap[pSeatNo] as keyof typeof seatRowObj] = "Y";
                } else {
                    seatRowObj[seatNoXpositionMap[pSeatNo] as keyof typeof seatRowObj] = "N";
                }
            }
        });

        seatDis({ type: VehicleSeatSelector.SeatActDisTypesT.ValueInput, value: vehicleSeats });

        if (VehicleSeatSelector.getPsgrSeatsCount(vehicleSeats ?? []) === (+props.load ?? 0)) {
            seatDis({ type: VehicleSeatSelector.SeatActDisTypesT.ErrorMsg, errorMsg: "" });
            console.log("$$now they are equal");
        }

        console.log("$$vehicleSeats", vehicleSeats);
    }

    // useEffect(() => {
    let eachThree: JSX.Element[] = [];
    let [eachDiv, setEachDiv] = useState<JSX.Element[]>([]);
    // let eachDiv: JSX.Element[] = [];
    let seatNoInRow = 1;
    let seatRow = 1;

    let seatsSt: VehicleSeatSelector.SeatStT = {} as VehicleSeatSelector.SeatStT;
    let seatDis: VehicleSeatSelector.SeatDisT = () => {};

    if (props.seatRdc) {
        [seatsSt, seatDis] = props.seatRdc;
    }
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        /**
         * to hanle (left and right)-side driving car
         */
        let driverSide = "leftSideCar";
        
        eachDiv = [];
        // setEachDiv(eachDiv);
        props.seatsStArr.map((seat, index) => {
            let seatNo = index + 1;

            eachThree.push(
                <Seat
                    seatId={seatNo}
                    seatRow={seatRow}
                    seatNoInRow={seatNoInRow}
                    key={seatNo}
                    status={seat.status}
                    isEmpty={seatRow === 1 && seatNoInRow === 2}
                    onSelectUnselect={updateVclSeats}
                />
            );
            // }
            seatNoInRow++;

            if (seatNo % 3 === 0 || seatNo === props.seatsStArr.length) {
                console.log("$$eachThree", eachThree);
                vehicleSeats.push({
                    y: vehicleSeats.length + 1 /* seatNoInRow */,
                    x1: vehicleSeats.length === 0 && driverSide === "leftSideCar" ? "D" : "Y",
                    x2: vehicleSeats.length === 0 ? "N" : "Y",
                    x3: vehicleSeats.length === 0 && driverSide === "rightSideCar" ? "D" : "Y",
                });
                eachDiv.push(
                    <div
                        key={eachDiv.length + 1 + props.load /*load added to force refresh the seat on load change */}
                        className="row justify-content-center"
                    >
                        {eachThree.map((v, i) => v)}
                    </div>
                );

                eachThree = [];
                seatRow++;
                seatNoInRow = 1;
            }
        });

        console.log("$$eachDiv", eachDiv);
        console.log("$$vehicleSeats", vehicleSeats);
        setEachDiv(eachDiv);
        seatDis({ type: VehicleSeatSelector.SeatActDisTypesT.ValueInput, value: vehicleSeats });
        setLoading(false);
    }, [props.load]);

    //below works fine as well
    if (loading) return <div>loading</div>;
    return (
        <div dir="ltr" className="">
            {eachDiv.map((v, i) => v)}
            {/* {allSeats.map((v, i) => v)} */}
        </div>
    );
}
