import { useQuery } from "@apollo/client";
import { MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { Waypoint } from "../../commonSrc/Types";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { StickyBar } from "../commonComp/StickyBar";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";

export function PsgrTripDtls(props: React.PropsWithChildren<{}>) {
    console.log("First line - PsgrTripDtls Component");
    const location = useLocation();
    const drvrUserId = (location.state as any).drvrUserId;
    console.log("drvrUserId", drvrUserId);
    const { pReqId, pDrvrUserId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // async function g() {
        //     return await getDeviceCurrentLocation();
        // }
        // loc = g();
    }, []);

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
    }

    let loc: any;

    const {
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.PSGR_RDY_TRIP_DTL/* TRASP_REQ_DTL_N_ACCEPTED_GOODS *//* TRASP_REQ */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
    });



    if (/* getTraspReqWaypointsLoading || */ getReqDtlLoading) return <div>loading ...</div>;

    if (!pReqId || !pDrvrUserId || isNaN(parseInt(pReqId)) || isNaN(parseInt(pDrvrUserId)) || getReqDtlErr)
        return <div>Invalid request parameters</div>;

    return (
        <>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            navigate("/app/psgrChat", { state: { toUserId: drvrUserId } });
                        }}
                    >
                        <i className="bi bi-chat-dots-fill"></i>
                    </button>
                </div>

                <div className="text-center ms-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            navigate("/app/dashboard");
                        }}
                    >
                        رجوع
                    </button>
                </div>
            </StickyBar>
            <TripDtls
                reqDtlType="PsgrReq" reqInfo={getReqDtlData?.getPsgrRdyTrips?.dataArr[0]}
                goodsCmp={<GoodsDtlsCmp
                    showApproval={false}
                    pGoodsData={getReqDtlData?.getPsgrRdyTrips?.dataArr[0]?.tripApprovals} />}>
            </TripDtls>
        </>
    );
}
