import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//////////////
import { PropsWithChildren } from "react";
import { Direction } from "@mui/system/createTheme/";
import { StyledEngineProvider } from "@mui/material";

import { useReactiveVar } from "@apollo/client";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ar } from "date-fns/locale";
import { enUS } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import setDefaultOptions from "date-fns/setDefaultOptions";

// jss.use(rtl());
// Create rtl cache

export function MuiLang(props: PropsWithChildren<{}>) {
    console.log("First line - MuiLang Component");

    const dirAndLang = useReactiveVar(dirAndLangRvar);
    
    const cacheRtl = createCache({
        // key: dirAndLangRvar().direction === "rtl" ? "muirtl" : "muiltr",
        key: dirAndLangRvar().direction === "rtl" ? "rtl" : "ltr",
        stylisPlugins: dirAndLangRvar().direction === "rtl" ? [prefixer, rtlPlugin] : [],
    });

    const theme = createTheme({
        direction: dirAndLangRvar().direction as Direction,
    });
    setDefaultOptions({ locale: dirAndLangRvar().lang === "ar" ? ar : enUS });
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dirAndLangRvar().lang === "ar" ? ar : undefined}>
            <StyledEngineProvider injectFirst>
                <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
                </CacheProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
}
