import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { dividerClasses } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addOffsetOnLocation, getDeviceCurrentLocation, getGraphQlError } from "../../commonSrc/commonFns";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { Waypoint } from "../../commonSrc/Types";
import { FormCmps } from "../commonComp/FormComp";
import { Location } from "../commonComp/GoogleMapDirCmp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { DrvrJoinedPsgrsCmp } from "../commonComp/vehicle/DrvrJoinedPsgrs";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";
import { dirAndLangRvar } from "../../commonSrc/apolloGQL/ReactiveVars";

export function DrvrGdsTkDecision(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrGdsTkDecision Component");
    const { pReqId, pDrvrId } = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        // async function g() {
        //     return await getDeviceCurrentLocation();
        // }
        // loc = g();
    }, []);

    const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

    const [pickup, setPickup] = useState(false);

    const [showMap, setShowMap] = useState(false);

    async function isFormValid(): Promise<boolean> {
        return true;
    }

    async function onSaveHandler(event: MouseEvent<HTMLButtonElement>) {
        event?.preventDefault();
    }
    let loc: any;

    const {
        JsxComp: DrvrMapDirections,
        compReducer: [drvrMapDirectionsSt, drvrMapDirectionsDis],
        compRef: drvrMapDirectionsRef,
    } = FormCmps.MapDirectionsComp.getComp();

    const {
        refetch: getReqDtlReftch,
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.DRVR_PNDNG_RCV_REQ_DTL/* TRASP_REQ_N_PENDING_GOODS */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
        onCompleted: async (data) => {
            // goodsSrcLocDis({
            //     type: FormCmps.LocationCmp.LocationActDisTypesT.Location,
            //     location: data?.getDrvrPendingGoods?.dataArr[0]?.tripGoods[0]?.srcLoc,
            // });
        },
    });

    const [getTraspReqWaypoints, { error: getTraspReqWaypointsErr, data: getTraspReqWaypointsData, loading: getTraspReqWaypointsLoading }] =
        useLazyQuery(getGqlStmt().queries.TRASP_REQ_WAYPOINTS, {
            fetchPolicy: "no-cache",
            variables: {
                reqId: +pReqId!,
            },
            onCompleted: async (data) => {
                let waypoints: Waypoint[] = [];
                (data.getTraspReqJoinedPsgrs.dataArr as { srcLoc: Location; destLoc: Location, user: any }[]).forEach((e, i) => {
                    if (pickup) {
                        if (e.srcLoc) {
                            const numberOfOccurrences = waypoints.filter(
                                (item) => item.location?.lat === e.srcLoc?.lat && item.location?.lng === e.srcLoc?.lng
                            ).length;
                            waypoints.push({
                                location: {
                                    lat: numberOfOccurrences > 0 ? addOffsetOnLocation(e.srcLoc.lat) : e.srcLoc.lat,
                                    lng: e.srcLoc.lng, //+ Math.round((Math.random() * (0.000090 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                },
                                stopover: true,
                                text: e.user.userName1Ar ?? e.user.userName1En ?? dirAndLangRvar().lang === "ar" ? "راكب" : "Passenger"
                            });
                        }
                        if (e.destLoc) {
                            const numberOfOccurrences = waypoints.filter(
                                (item) => item.location?.lat === e.destLoc?.lat && item.location?.lng === e.destLoc?.lng
                            ).length;
                            waypoints.push({
                                location: {
                                    lat: numberOfOccurrences > 0 ? addOffsetOnLocation(e.destLoc.lat) : e.destLoc.lat,
                                    // e.destLoc.lat + Math.round((Math.random() * (0.00009 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                    lng: e.destLoc.lng, //+ Math.round((Math.random() * (0.000090 - 0.000001) + 0.000001) * 1000000) / 1000000,
                                },
                                stopover: true,
                                text: e.user.userName1Ar ?? e.user.userName1En ?? dirAndLangRvar().lang === "ar" ? "راكب" : "Passenger"
                            });
                        }
                    } else {
                        if (e.destLoc) waypoints.push({ location: e.destLoc, stopover: true, text: e.user.userName1Ar ?? e.user.userName1En ?? dirAndLangRvar().lang === "ar" ? "راكب" : "Passenger" });
                    }
                });
                setWaypoints(waypoints);
                console.log("waypoints:", waypoints);
            },
        });

    if (getTraspReqWaypointsLoading || getReqDtlLoading) return <div>loading ...</div>;

    if (!pReqId || !pDrvrId || isNaN(parseInt(pReqId)) || isNaN(parseInt(pDrvrId)) || getReqDtlErr)
        return <div>Invalid request parameters or getReqDtlErr</div>;

    return (
        <>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                    <button
                        type="button"
                        className="p-2 btn btn-primary"
                        onClick={async () => {
                            setPickup(true);
                            await getTraspReqWaypoints();
                            setShowMap(false);
                            setTimeout(() => {
                                setShowMap(true);
                            }, 200);
                        }}
                    >
                        تحميل الركاب ثم بدء الرحلة
                    </button>
                </div>
                {!pickup && (
                    <div className="text-center ms-auto">
                        <button
                            type="button"
                            className="p-2 btn btn-primary"
                            onClick={async () => {
                                setPickup(false);
                                await getTraspReqWaypoints();
                                setShowMap(false);
                                setTimeout(() => {
                                    setShowMap(true);
                                }, 200);
                            }}
                        >
                            بدء الرحلة
                        </button>
                    </div>
                )}
            </StickyBar>

            {showMap && (
                <DrvrMapDirections
                    id={`dirMapDrvrId1_pkup_${pickup}`}
                    waypoints={waypoints}
                    inputRef={drvrMapDirectionsRef}
                    locationRdc={[drvrMapDirectionsSt, drvrMapDirectionsDis]}
                    label="رحلة عمان الكرك"
                ></DrvrMapDirections>
            )}
            <TripDtls
                reqDtlType="DrvrSessionOnly" reqInfo={getReqDtlData?.getDrvrPndngRcvdReqs?.dataArr[0]}
                goodsCmp={
                    <GoodsDtlsCmp
                        showApproval={true}
                        pGoodsData={getReqDtlData?.getDrvrPndngRcvdReqs?.dataArr[0]?.tripApprovals}
                        onAcceptGoods={async () => {
                            const r = await getReqDtlReftch()
                            if (r.data.getDrvrPndngRcvdReqs?.dataArr.length === 0)
                                navigate("/app/drvrSuspendedReqs", { replace: true });
                            // navigate(-1)
                        }}

                    ></GoodsDtlsCmp >
                } >
            </TripDtls >
        </>
    );
}
