import { useReactiveVar } from "@apollo/client";
import React from "react";
import { accessInfoRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { getAccessInfo } from "../../commonSrc/commonFns";

function IFAuthedShow(props: React.PropsWithChildren<{ notAuthedChildren?: any }>) {
    //it's used just to enforce refresh
    useReactiveVar(accessInfoRvar);
    //   const nav = useNavigate();
    //   const location = useLocation();
    //   const redirectTo = location.pathname;
    //   console.log("location in RequireAuth=", location);
    if (getAccessInfo()?.isAuthenticated) return <>{props.children}</>;
    // props.children;
    return <> {props.notAuthedChildren ? props.notAuthedChildren : ""}</>;
    //   nav("/", { replace: true });
}

export default IFAuthedShow;
