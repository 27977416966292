import { PropsWithChildren } from "react";

export function SpinnerComp(props: PropsWithChildren<{ firstTimeAccess?: boolean; size?: string }>) {
    return (
        <>
            <div className="text-center container d-flex align-items-center justify-content-center h-100 ">
                <div className="row justify-content-center">
                    <div className="col-12">
                        {props.firstTimeAccess && (
                            <div className={` ${props.size === "sm" ? "fs-6" : "fs-1"}  pb-5`} style={{ color: "mediumblue" }}>
                                Tranzait
                            </div>
                        )}
                        <div
                            className="spinner-border"
                            style={{
                                width: `${props.size === "sm" ? "1rem" : "5rem"}`,
                                height: `${props.size === "sm" ? "1rem" : "5rem"}`,
                                color: "mediumblue",
                            }}
                            role="status"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>

                        <div className="text-center" style={{ color: "mediumblue" }}>
                            <span className="">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
