import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { SelectValueType } from "../../commonSrc/Types";
import { FormCmps } from "../commonComp/FormComp";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ModalComp } from "../commonComp/ModalComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { NavComp } from "../commonComp/NavComp";
import { getGlAccountsTree } from "../../commonSrc/commonFns";

export function AddGlAccComp() {
    console.log("First line - AddGlAccComp Component");
    const glEntryTypes: SelectValueType = [
        { selectLableAndId: "LEAF", valueKey: "Y" },
        { selectLableAndId: "CATEGORY", valueKey: "N" },
    ];

    const glStatementTypes: SelectValueType = [
        { selectLableAndId: "INCOME_SHEET", valueKey: "Y" },
        { selectLableAndId: "BUDGET", valueKey: "N" },
    ];

    const [glAccountsTree, setGlAccountsTree] = useState<any>();

    // const categoryRef = useRef<HTMLSelectElement>(null);

    const {
        JsxComp: AccountEntryTypeAutoComplete,
        compReducer: [accountEntryTypeAutoCompleteSt, accountEntryTypeAutoCompleteDis],
        compRef: categoryRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: GlStatementAccType,
        compReducer: [glStatementAccTypeSt, glStatementAccTypeDis],
        compRef: glStatementAccTypeRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: GlAccTypeInStatement,
        compReducer: [glAccTypeInStatementSt, glAccTypeInStatementDis],
        compRef: glAccTypeInStatementRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: SelectIsLeaf,
        compReducer: [selectIsLeafSt, selectIsLeafDis],
        compRef: isLeafRef,
    } = FormCmps.SelectCmp.getComp();

    const {
        JsxComp: GlAccNameCmp,
        // compReducer: [selectIsLeafSt, selectIsLeafDis],
        compRef: accNameRef,
    } = FormCmps.TextCmp.getComp();

    const {
        JsxComp: SelectIsBalanceSheet,
        compReducer: [selectIsBalanceSheetSt, selectIsBalanceSheetDis],
        compRef: glStatementTypeRef,
    } = FormCmps.SelectCmp.getComp();

    // const isLeafRef = useRef<HTMLInputElement>(null);
    // const glStatementTypeRef = useRef<HTMLInputElement>(null);
    // const glStatementAccTypeRef = useRef<HTMLInputElement>(null);
    // const glAccTypeInStatementRef = useRef<HTMLInputElement>(null);

    // const accNameRef = useRef<HTMLInputElement>(null);
    const [categoryValueState, setCategoryValueState] = useState<any>(null);

    // const [glStatementAccTypeMasterState, setGlStatementAccTypeMasterState] = useState<any>(null);
    const [glAccTypeInStatement, setGlAccTypeInStatement] = useState<any>(null);

    const [accountNameLabel, setAccountNameLabel] = useState("");

    useEffect(() => {
        const asyncBlock = async () => {
            console.log("useEffect in App##");

            // const glAccTreeData = await getGlAccountsTree(userInfoRvar().companyId);
            // console.log("menusData##=", glAccTreeData.getGlAccountsTree);

            // setGlAccountsTree(glAccTreeData);
        };
        asyncBlock();
        console.log('ReadTextResource.getTextById("GL_ACC_NAME")=', ReadTextResource.getTextById("GL_ACC_NAME"), isLeafRef?.current?.value);
        if (isLeafRef?.current?.value === glEntryTypes[1].valueKey) setAccountNameLabel(ReadTextResource.getTextById("CATEGORY_NAME"));
        else setAccountNameLabel(ReadTextResource.getTextById("GL_ACC_NAME"));
    }, [dirAndLangRvar().direction]);

    const top100Films = undefined;
    //[
    // { label: "The Shawshank Redemption", year: 1994 },
    // { label: "The Godfather", year: 1972 },
    // { label: "The Godfather: Part II", year: 1974 },
    // { label: "The Dark Knight", year: 2008 },
    // { label: "12 Angry Men", year: 1957 },
    // { label: "Schindler's List", year: 1993 },
    // { label: "Pulp Fiction", year: 1994 },
    // { __typename: "GlAccount", accId: 5, accName: "hijk" },
    //];

    const [createGlAcc, { data: createGlAccData, loading: createGlAccLooadin, error: createGlAccError }] = useMutation(
        getGqlStmt().mutations.CREATE_GL_ACC,
        {}
    );

    // console.log("XXXcompanyInfoRvar().companyType##", userInfoRvar().companyType);

    const {
        error: getCategoryError,
        data: getCategoryyData,
        loading: getCategoryLoading,
        refetch: reftechCagegories,
    } = useQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_CATEGOR,
        {
            variables: {
                categoryId: null,
                accMasterid: null,
                isBalanceSheet: glStatementTypeRef.current?.value ?? "Y",
                // companyType: Number.parseInt(userInfoRvar().companyType),
            },
            fetchPolicy: "cache-first",
        }
    );
    // console.log("getCategoryyData##", getCategoryyData);

    const [
        getGlAccTypeDtl,
        { error: getGlAccTypeDtlError, data: getGlAccTypeDtlData, loading: getGlAccTypeDtlLoading, refetch: reftechGlAccTypeDtl },
    ] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_ACC_TYPES_DTL,
        {
            fetchPolicy: "cache-first",
        }
    );

    async function onSubmitHandler(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        console.log("categoryValueState##=", categoryValueState);
        console.log("isLeaf Value##=", isLeafRef.current?.value);
        // console.log("getCompanyInfoVar().companyId##=", userInfoRvar().companyId);
        console.log("categoryRef.current?.value##=", categoryRef.current?.value, "categoryValueState=", categoryValueState);
        console.log("accNameRef.current?.value##=", accNameRef.current?.value);

        const okModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("SAVED_SUCCES") });
        try {
            await createGlAcc({
                variables: {
                    glAccParams: {
                        // companyId: userInfoRvar().companyId,
                        accNameAr: accNameRef.current?.value,
                        isLeaf: isLeafRef.current?.value,
                        parentAcc: categoryValueState?.accId,
                        isBalanceSheet: glStatementTypeRef.current?.value,
                        headerId: glStatementAccTypeSt.value.headerId,
                        typeId: glAccTypeInStatement.typeId,
                    },
                },
            });
        } catch (err) {
            console.log("err=", err);
            okModal.setProps({ msg: ReadTextResource.getTextById("SAVING_ERROR") });
        }
        reftechCagegories();
        // ModalComp.show(OkModal);//commented recently and not tested
        okModal.setProps({
            okClickHandler: () => {
                okModal.hide();
            },
        });
        okModal.show();

        // const glAccTreeData = await getGlAccountsTree(userInfoRvar().companyId);

        // setGlAccountsTree(glAccTreeData);

        // setIsLeafValue({ label: "XXXXX", year: 1900 });
    }

    if (getCategoryLoading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="addGlAccSpinnerId1"
                autoShow={true}
            ></ModalComp.SpinnerModal.JsxPortalComp>
        );

    // if (true) return <ModalComp.SpinnerPortalModal.JsxComp ></ModalComp.SpinnerPortalModal.JsxComp>;

    return (
        <>
            <FormCmps.Form
                id="addGlAccountId"
                submitCancelBtns={
                    <FormCmps.FormSubmitBtn id="submitBtn" label={ReadTextResource.getTextById("SAVE")}></FormCmps.FormSubmitBtn>
                }
                onSubmitHandler={onSubmitHandler}
            >
                <SelectIsLeaf
                    id="isLeaf"
                    label={ReadTextResource.getTextById("GL_ACC_TYPE")}
                    selectValues={glEntryTypes}
                    selectRdc={[selectIsLeafSt, selectIsLeafDis]}
                    defaultValue={glEntryTypes[0].valueKey}
                    inputRef={isLeafRef}
                    onChange={(val) => {
                        console.log("val##", val);
                        if (val.target.value === "N") setAccountNameLabel(ReadTextResource.getTextById("CATEGORY_NAME"));
                        else setAccountNameLabel(ReadTextResource.getTextById("GL_ACC_NAME"));
                    }}
                ></SelectIsLeaf>
                <GlAccNameCmp inputRef={accNameRef} id="glAccNameId" label={accountNameLabel}></GlAccNameCmp>

                <AccountEntryTypeAutoComplete
                    id="accountEntryTypeAutoComplete"
                    label={ReadTextResource.getTextById("ACCOUNT_CATERGOY")}
                    inputOptions={getCategoryyData?.getCategory ?? []}
                    // inputOptions={top100Films??[]}
                    inputRef={categoryRef}
                    autoCompleteRdc={[categoryValueState, setCategoryValueState]}
                ></AccountEntryTypeAutoComplete>

                <SelectIsBalanceSheet
                    id="isBalanceSheet"
                    label={ReadTextResource.getTextById("INCOME_BUDGET_ACC_TYPE")}
                    selectValues={glStatementTypes}
                    defaultValue={glStatementTypes[0].valueKey}
                    inputRef={glStatementTypeRef}
                    selectRdc={[selectIsBalanceSheetSt, selectIsBalanceSheetDis]}
                ></SelectIsBalanceSheet>

                <GlStatementAccType
                    id="glStatementAccType"
                    label={ReadTextResource.getTextById("GL_STATEMENT_TYPE")}
                    inputOptions={getCategoryyData?.getGlAccTypesMaster ?? []}
                    inputRef={glStatementAccTypeRef}
                    autoCompleteRdc={[glStatementAccTypeSt, glStatementAccTypeDis]}
                    onChange={(val) => {
                        console.log("glStatementAccTypeMasterState##=", glStatementAccTypeSt.value);
                        setGlAccTypeInStatement("");
                        getGlAccTypeDtl({
                            variables: {
                                headerId: val.headerId,
                                // companyType: Number.parseInt(userInfoRvar().companyType),
                            },
                        });
                    }}
                ></GlStatementAccType>

                <GlAccTypeInStatement
                    id="glAccTypeInStatement"
                    label={ReadTextResource.getTextById("GL_STATEMENT_ACC_TYPE")}
                    inputOptions={getGlAccTypeDtlData?.getGlAccTypesDtl ?? []}
                    inputRef={glAccTypeInStatementRef}
                    autoCompleteRdc={[glAccTypeInStatementSt, glAccTypeInStatementDis]}
                ></GlAccTypeInStatement>
            </FormCmps.Form>

            <NavComp.NavPanel panelID="GlAccTreeId">
                <NavComp.NavSubPanel
                    compName="NavSubPanel"
                    menus={glAccountsTree?.getGlAccountsTree}
                    readMenuFromTxtRsc={false}
                ></NavComp.NavSubPanel>
                <NavComp.NavMainPanel compName="NavMainPanel">
                    <div>Nothing here</div>
                </NavComp.NavMainPanel>
            </NavComp.NavPanel>
        </>
    );
}
