import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { FormCmps } from "../commonComp/FormComp";
import { ModalComp } from "../commonComp/ModalComp";
import { StickyBar } from "../commonComp/StickyBar";
import { TripDtls } from "../trip/TripDtls";
import { GoodsDtlsCmp } from "../commonComp/GoodsDtlsCmp";
import { getGraphQlError } from "../../commonSrc/commonFns";
import { Location } from "../commonComp/GoogleMapDirCmp";

export function AcceptPsgrReqCmp(props: React.PropsWithChildren<{}>) {
    console.log("First line - AcceptPsgrReqCmp Component");

    const navigate = useNavigate();

    const { pReqId } = useParams();

    const acceptOkModal = new ModalComp.OkModal({ isPortal: false }, { msg: "sorry, an error occurred" });

    const {
        JsxComp: DrvrCollectionLocation,
        compReducer: [drvrSrcColctLocSt, drvrSrcColctLocDis],
        compRef: drvrSrcColctLocRef,
    } = FormCmps.LocationCmp.getComp(/* getReqDtlData?.getTraspReq?.data?.drvrSrcLoc */);

    const {
        JsxComp: DrvrDropOffLocation,
        compReducer: [dropOffLocationSt, dropOffLocationStDis],
        compRef: dropOffLocationStRef,
    } = FormCmps.LocationCmp.getComp(/* getReqDtlData?.getTraspReq?.data?.drvrDestLoc */);

    const {
        error: getReqDtlErr,
        data: getReqDtlData,
        loading: getReqDtlLoading,
        // refetch: reftechCagegories,
    } = useQuery(getGqlStmt().queries.PSGR_PNDNG_REQ_DTL/* TRASP_REQ_N_AUTOACCEPTED_PSGR_GDS *//* TRASP_REQ */, {
        fetchPolicy: "no-cache",
        variables: {
            reqId: +pReqId!,
        },
    });


    const [getGdsDeliveryPrice, { error: getGdsDeliveryPriceErr, data: getGdsDeliveryPriceData, loading: getGdsDeliveryPriceLoading }] =
        useLazyQuery(getGqlStmt().queries.GOODS_DELIVERY_PRICE, {
            fetchPolicy: "no-cache",
        });

    const [getTripPrice, { error: getTripPriceErr, data: getTripPriceData, loading: getTripPriceLoading }] = useLazyQuery(
        getGqlStmt().queries.TRIP_PRICE,
        {
            variables: {
                // reqId: +pReqId!,
                // reservedSeatsByUser: psgrReservedSeatsSt.value,
            },
            fetchPolicy: "no-cache",
        }
    );

    const [acceptPsgrReq, { error: acceptPsgrReqErr, data: acceptPsgrReqData, loading: acceptPsgrReqLoading }] = useMutation(
        getGqlStmt().mutations.ACCEPT_PSGR_REQ,
        {
            fetchPolicy: "no-cache",

        }
    );

    if (getReqDtlLoading || !getReqDtlData) return <div>Loading ...</div>;

    if (!pReqId) return <div>Invalid request</div>;

    if (!getReqDtlData || !getReqDtlData?.getPsgrPndngReqDtl.success) return <div>{ReadTextResource.getTextById("NO_DATA")}</div>;

    const DrvrReqComp = (
        <div className="mt-3">
            <div className="pt-1 pb-1 text-center col-lg-12 col-md-12 col-sm-12 bg-info rounded-2 ">
                {/* <u> */}
                <b> {ReadTextResource.getTextById("ENTER_UR_LOC")}:</b>
                {/* </u> */}
            </div>
            <div className="text-danger col-12 col-sm-12">{ReadTextResource.getTextById("SUITABLE_LOC_DRVR_HINT")}</div>
            <div>
                <DrvrCollectionLocation
                    id="drvrCollectionLocationId1"
                    locationRdc={[drvrSrcColctLocSt, drvrSrcColctLocDis]}
                    label={
                        <div className="text-danger">
                            <b>{ReadTextResource.getTextById("SRC_LOC")} :</b>
                        </div>
                    }
                    inputRef={drvrSrcColctLocRef}
                ></DrvrCollectionLocation>
            </div>
            <div>
                <DrvrDropOffLocation
                    id="drvrDropOffLocId1"
                    locationRdc={[dropOffLocationSt, dropOffLocationStDis]}
                    label={
                        <div className="text-danger">
                            <b> {ReadTextResource.getTextById("DEST_LOC")} :</b>
                        </div>
                    }
                    inputRef={dropOffLocationStRef}
                ></DrvrDropOffLocation>
            </div>
        </div>
    );

    async function isFormValid(): Promise<boolean> {
        let isFormValid: boolean = true;
        const okModalInstance = new ModalComp.OkModal(
            { isPortal: false },
            {
                msg: ReadTextResource.getTextById("FILL_ALL_FLDS"),
                navTo: undefined, // or just remove it
            }
        );

        isFormValid = await FormCmps.LocationCmp.validate(
            drvrSrcColctLocSt,
            drvrSrcColctLocDis,
            isFormValid,
            okModalInstance,
            drvrSrcColctLocRef,
            ReadTextResource.getTextById("SLCT_SRC_LOC")
        );

        isFormValid = await FormCmps.LocationCmp.validate(
            dropOffLocationSt,
            dropOffLocationStDis,
            isFormValid,
            okModalInstance,
            dropOffLocationStRef,
            ReadTextResource.getTextById("SLCT_DEST_LOC")
        );

        //to remove
        return isFormValid;
    }

    return (
        <div>
            <StickyBar id="mainStickBar1">
                <div className="text-center me-auto">
                    {/* <div className="col text-center"> */}
                    <button
                        type="button"
                        className="btn btn-primary px-5"
                        onClick={async () => {
                            if (!(await isFormValid())) return;

                            const spinner = new ModalComp.SpinnerModal({ isPortal: false });
                            const joinReqOkModal = new ModalComp.OkModal({ isPortal: false }, { msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") });

                            if (getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0].reqType === "Goods") {
                                const goods = getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0].tripApprovals?.map((e: any) => {
                                    return e.tripGoods?.tripGoodsDtl[0];
                                });
                                await spinner.showAsync();

                                const goodsWithLoc: any[] = [];
                                goods.map((v: any, i: number) => {
                                    goodsWithLoc.push({
                                        goodWeight: v.goodWeight,
                                        goodHeight: v.goodHeight,
                                        goodLength: v.goodLength,
                                        goodWidth: v.goodWidth,
                                        goodNote: v.goodNote,
                                        reqId: +pReqId!,
                                        destLoc: v.destLoc,
                                        srcLoc: v.srcLoc,
                                        tripDuration: 0/* psgrTripEndTime */,
                                        distance: 0/* psgrSrcToDestDistance.distance */,
                                        // price: tripPriceData?.getGdsDeliveryPrice?.data?.totalPrice
                                    });
                                });

                                await getGdsDeliveryPrice({
                                    variables: {
                                        reqId: +pReqId!,
                                        goods: goodsWithLoc,
                                        drvrSrcLoc: drvrSrcColctLocSt.getLocationByMarker?.(),
                                        drvrDestLoc: dropOffLocationSt.getLocationByMarker?.()
                                    },
                                    onCompleted: async (tripPriceData) => {
                                        await spinner.hideAsync();
                                        if (!tripPriceData?.getGdsDeliveryPrice?.success) {
                                            if (tripPriceData.getGdsDeliveryPrice.errorId === "ERR_TRIP_OVERLAP") {
                                                joinReqOkModal.setProps({ msg: tripPriceData.getGdsDeliveryPrice.errorDesc });
                                                joinReqOkModal.showAsync();
                                                return;
                                            }
                                            joinReqOkModal.setProps({ msg: tripPriceData.getGdsDeliveryPrice.errorDesc });
                                            joinReqOkModal.showAsync();
                                            return;
                                        }

                                        const priceModalOk = new ModalComp.TwoBtnsModal(
                                            { isPortal: false },
                                            {
                                                btn1Label: ReadTextResource.getTextById("OK"),
                                                btn2Label: ReadTextResource.getTextById("CANCEL"),
                                                msg:
                                                    ReadTextResource.getTextById("TOT_PRICE") +
                                                    " " +
                                                    tripPriceData.getGdsDeliveryPrice.data.totalPrice,
                                                clickHandlerBtn1: async () => {
                                                    await priceModalOk.hideAsync();
                                                    await spinner.showAsync();

                                                    // psgrReservedSeatsDis({
                                                    //     type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
                                                    //     value: psgrReservedSeatsWithLoc,
                                                    // });

                                                    await acceptPsgrReq(
                                                        {
                                                            variables: {
                                                                reqId: +pReqId!,
                                                                drvrSrcLoc: drvrSrcColctLocSt?.getLocationByMarker?.(),
                                                                drvrDestLoc: dropOffLocationSt.getLocationByMarker?.(),
                                                            },
                                                            onCompleted: async (data) => {
                                                                await spinner.hideAsync();

                                                                if (data.acceptPsgrReq.success) {
                                                                    acceptOkModal.setProps({
                                                                        msg: ReadTextResource.getTextById("DRVR_WAIT_ACCEPT"),
                                                                        okClickHandler: () => {
                                                                            navigate("/app/dashboard");
                                                                        },
                                                                    });
                                                                    acceptOkModal.showAsync();
                                                                } else if (!data.acceptPsgrReq.success || data.acceptPsgrReq.errorId === "REQ_ALREADY_ASSIGNED_TO_DRVR") {
                                                                    if (data.acceptPsgrReq.errorId === "DUPLICATES_ERR")
                                                                        acceptOkModal.setProps({ msg: ReadTextResource.getTextById("AROV_ALRDY_EXIST") });
                                                                    else
                                                                        acceptOkModal.setProps({ msg: data.acceptPsgrReq.errorDesc });
                                                                    acceptOkModal.showAsync();
                                                                }
                                                            },
                                                            onError: async (err) => {
                                                                await spinner.hideAsync();

                                                                const errorId = getGraphQlError(err).errorId;
                                                                if (errorId === "DUPLICATES_ERR")
                                                                    acceptOkModal.setProps({ msg: ReadTextResource.getTextById("AROV_ALRDY_EXIST") });
                                                                else
                                                                    acceptOkModal.setProps({ msg: "Error" + err });
                                                                acceptOkModal.showAsync();
                                                            },
                                                        }
                                                    );
                                                },
                                                clickHandlerBtn2: async () => {
                                                    await spinner.hideAsync();
                                                    await priceModalOk.hideAsync();
                                                },
                                            }
                                        );
                                        await priceModalOk.showAsync();
                                    },
                                    onError: async (err) => {
                                        await spinner.hideAsync();
                                        console.log("getTripPrice query is not successfull -abd- err=", err);
                                        if (getGraphQlError(err).errorId === "ERR_LIMIT_RATE_REACHED")
                                            joinReqOkModal.setProps({ msg: "لقد قمت بارسال الطلب مرات عديدة، الرجاء المحاولة لاحقا" });
                                        else
                                            joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });

                                        joinReqOkModal.showAsync();
                                    },
                                });
                            }
                            else {
                                //normal join request     
                                await spinner.showAsync();
                           
                                let noOfPsgrs = getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.fPsgrCompanions ?? 0 + getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.mPsgrCompanions ?? 0
                                let reservedSeats = [];
                                if (noOfPsgrs === 0)
                                    noOfPsgrs = 1;

                                let srcLocV = null;
                                let destLocV = null;
                                if (getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantSrcLoc)
                                    srcLocV = { lat: getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantSrcLoc.lat, lng: getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantSrcLoc.lng };
                                if (getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantDestLoc)
                                    destLocV = { lat: getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantDestLoc.lat, lng: getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.applicantDestLoc.lng };

                                for (let index = 0; index < noOfPsgrs; index++) {
                                    reservedSeats.push({
                                        srcLoc: srcLocV,
                                        destLoc: destLocV,
                                        reqId: +pReqId,
                                        y: 0,
                                        x: 0,
                                        gender: "M",
                                        maleBeside: "N",
                                        // tripDuration = 0;//psgrTripEndTime;
                                        // distance = 0;//psgrSrcToDestDistance.distance;
                                        // price = tripPriceData.getTripPrice.data.tripPrice;
                                    });
                                }


                                await getTripPrice({
                                    variables: {
                                        reqId: +pReqId!,
                                        reservedSeatsByUser: reservedSeats,
                                        drvrSrcLoc: drvrSrcColctLocSt.getLocationByMarker?.(),
                                        drvrDestLoc: dropOffLocationSt.getLocationByMarker?.()
                                    },
                                    onCompleted: async (tripPriceData) => {
                                        await spinner.hideAsync();
                                        if (!tripPriceData?.getTripPrice?.success) {
                                            if (tripPriceData.getTripPrice.errorId === "ERR_TRIP_OVERLAP") {
                                                joinReqOkModal.setProps({ msg: tripPriceData.getTripPrice.errorDesc });
                                                joinReqOkModal.showAsync();
                                                return;
                                            }
                                            joinReqOkModal.setProps({ msg: tripPriceData.getTripPrice.errorDesc });
                                            joinReqOkModal.showAsync();
                                            return;
                                        }

                                        const priceModalOk = new ModalComp.TwoBtnsModal(
                                            { isPortal: false },
                                            {
                                                btn1Label: ReadTextResource.getTextById("OK"),
                                                btn2Label: ReadTextResource.getTextById("CANCEL"),
                                                msg:
                                                    ReadTextResource.getTextById("TOT_PRICE") +
                                                    " " +
                                                    tripPriceData.getTripPrice.data.totalPrice,
                                                clickHandlerBtn1: async () => {
                                                    await priceModalOk.hideAsync();
                                                    await spinner.showAsync();

                                                    // psgrReservedSeatsDis({
                                                    //     type: PsgrVclSeatSelector.SeatActDisTypesT.ValueInput,
                                                    //     value: psgrReservedSeatsWithLoc,
                                                    // });

                                                    await acceptPsgrReq(
                                                        {
                                                            variables: {
                                                                reqId: +pReqId!,
                                                                drvrSrcLoc: drvrSrcColctLocSt?.getLocationByMarker?.(),
                                                                drvrDestLoc: dropOffLocationSt.getLocationByMarker?.(),
                                                            },
                                                            onCompleted: async (data) => {
                                                                await spinner.hideAsync();

                                                                if (data.acceptPsgrReq.success) {
                                                                    acceptOkModal.setProps({
                                                                        msg: ReadTextResource.getTextById("DRVR_WAIT_ACCEPT"),
                                                                        okClickHandler: () => {
                                                                            navigate("/app/dashboard");
                                                                        },
                                                                    });
                                                                    acceptOkModal.showAsync();
                                                                } else if (!data.acceptPsgrReq.success || data.acceptPsgrReq.errorId === "REQ_ALREADY_ASSIGNED_TO_DRVR") {
                                                                    if (data.acceptPsgrReq.errorId === "DUPLICATES_ERR")
                                                                        acceptOkModal.setProps({ msg: ReadTextResource.getTextById("AROV_ALRDY_EXIST") });
                                                                    else
                                                                        acceptOkModal.setProps({ msg: data.acceptPsgrReq.errorDesc });
                                                                    acceptOkModal.showAsync();
                                                                }
                                                            },
                                                            onError: async (err) => {
                                                                await spinner.hideAsync();

                                                                const errorId = getGraphQlError(err).errorId;
                                                                if (errorId === "DUPLICATES_ERR")
                                                                    acceptOkModal.setProps({ msg: ReadTextResource.getTextById("AROV_ALRDY_EXIST") });
                                                                else
                                                                    acceptOkModal.setProps({ msg: "Error" + err });
                                                                acceptOkModal.showAsync();
                                                            },
                                                        }
                                                    );
                                                },
                                                clickHandlerBtn2: async () => {
                                                    await spinner.hideAsync();
                                                    await priceModalOk.hideAsync();
                                                },
                                            }
                                        );
                                        await priceModalOk.showAsync();
                                    },
                                    onError: async (err) => {
                                        await spinner.hideAsync();
                                        console.log("getTripPrice query is not successfull -abd- err=", err);
                                        if (getGraphQlError(err).errorId === "ERR_LIMIT_RATE_REACHED")
                                            joinReqOkModal.setProps({ msg: "لقد قمت بارسال الطلب مرات عديدة، الرجاء المحاولة لاحقا" });
                                        else
                                            joinReqOkModal.setProps({ msg: ReadTextResource.getTextById("ERROR_TRY_AGN_CALL_US") + err });

                                        joinReqOkModal.showAsync();
                                    },
                                });
                            }


                            // if (acceptReqData.data.acceptPsgrReq.success) {
                            //     const acceptOkModal = new ModalComp.OkModal(
                            //         { isPortal: false },
                            //         { msg: "تم ارسال طلبك للراكب، الرجاء انتظار الرد" }
                            //     );
                            //     await acceptOkModal.showAsync();
                            // }
                        }}
                    >
                        {ReadTextResource.getTextById("ACCEPT")}
                    </button>
                </div>
                <div className="text-center ms-auto ">
                    <button
                        type="button"
                        className="btn btn-danger px-5"
                        onClick={async () => {
                            navigate("/app/drvrFindTrip");
                        }}
                    >
                        {ReadTextResource.getTextById("CANCEL")}
                    </button>
                </div>
            </StickyBar >
            <TripDtls
                reqDtlType="AcceptingPsgrReq"
                reqInfo={getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]}
                goodsCmp={<GoodsDtlsCmp
                    pGoodsData={getReqDtlData?.getPsgrPndngReqDtl?.dataArr[0]?.tripApprovals}
                    showApproval={false}
                    displayType={"AcceptPsgrReq"}
                ></GoodsDtlsCmp >}>
            </TripDtls>
            {DrvrReqComp}
        </div >
    );
}
