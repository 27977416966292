import React, { MouseEvent, useEffect, useRef, useState } from "react";
import "./App.css";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { getAccessInfo, getAppMenus, logout } from "./commonSrc/commonFns";
import IFAuthedShow from "./components/commonComp/IFAuthedShow";
import { NavComp } from "./components/commonComp/NavComp";
import reactDom from "react-dom";
import { HeaderComp } from "./components/commonComp/HeaderComp";
// import { AppDropdown } from "./components/commonComp/AppDropdown";
import { PortalComp } from "./components/commonComp/PortalComp";
import { DocumentNode, gql, useApolloClient, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { getGqlStmt } from "./commonSrc/graphQL/Queries";
import { accessInfoRvar, chattingUsersRVar, dirAndLangRvar, refreshRvar, swVersion, userNotificationsRVar } from "./commonSrc/apolloGQL/ReactiveVars";
import { RES_SERVER_NAME, SERVER_NAME } from "./commonSrc/EnvVariables";
import { ModalComp } from "./components/commonComp/ModalComp";
import { ReadTextResource } from "./commonSrc/ReadTextResource";
import { log } from "console";
// import headerComStyle from "./components/common/compCss/NavComp.module.css";

function App() {
    console.log("First line - App Component");
    useReactiveVar(chattingUsersRVar);
    useReactiveVar(userNotificationsRVar);
    useReactiveVar(dirAndLangRvar);
    useReactiveVar(swVersion);



    const client = useApolloClient();

    const [compName, setCompName] = useState("");
    // let companyInfo;

    const nav = useNavigate();
    const [appMenusData, setAppMenusData] = useState<any>(null);
    const mobileNavButtonRef = useRef<HTMLDivElement>(null);
    const [numberOfNewMsgs, setNumberOfNewMsgs] = useState(0);

    /* const [getUser, { error: getUserError, data: getUserData, loading: getUserLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_USER,
        {
            onCompleted(data) {
                const user = Object.assign(new User(), data.getUser.data);
                userInfoRvar(user);
                console.log("###userInfoRvar is set");
                // console.log("data=", data);
                console.log("line after calling getUser##=", userInfoRvar(user));
            },
            // variables: {
            //     username: accessTokenRvar().username,
            // },
            fetchPolicy: "no-cache",
        }
    ); */

    /* const [getIpInfo, { error: getIpInfoError, data: getIpInfoData, loading: getIpInfoLoading }] = useLazyQuery(
        // GET_COMPANY,
        getGqlStmt().queries.GET_IP_INFO,
        {
            onCompleted(data) {
                console.log("ipInfo=", data);
            },
            fetchPolicy: "no-cache",
        }
    ); */
    useEffect(() => {
        /*
        this code works if you want to use the response from the promise directly as many as calls as you want
        without depending on the state variable from useQuery hooks 
        companyInfo = client.readQuery({
            query: gql`
                query ROOT_QUERY {
                    getCompany(username: $username)
                }
            `,
            variables: { username: "Com40" },
        }); */

        navigator.serviceWorker.onmessage = (event) => {
            if (event.data && event.data.type === 'NEW_NTF') {
                //   setCount(event.data.count);
                console.log("##NEW_NTF event listner is called", event.data);
                userNotificationsRVar([{ isNtfRead: false, isNewNtf: true, lang: "en", ntcText: event.data.ntfText, notificationId: 1, ntcTitle: "ddd", status: "sent", toUserId: 444 }, ...userNotificationsRVar()])
            }

            if (event.data && event.data.type === 'SW_VERSION') {
                swVersion(event.data.version)
            }
        };
        const asyncBlock = async () => {
            try {
                console.log("###calling await getUser");
                // await getUser();
                // const ipInfo = await getIpInfo();
                // console.log("##ipInfo", ipInfo);
                // const currentCountryByIp = countries.find((e) => e.code === ipInfo.data.getIpInfo.country_code) as CountryT;
                // userInfoRvar({ ...userInfoRvar(), countryByIp: currentCountryByIp });
                // console.log("ffffffffffff=", userInfoRvar());
            } catch (err) {
                console.log("err2##", err);
            }

            console.log("useEffect in App##");

            let menusData = await getAppMenus(1);

            setAppMenusData(menusData);
            // setNumberOfNewMsgs(chattingUsersRVar().filter((e) => e.hasNewMsg).length);
            // console.log("setNumberOfNewMsgs", numberOfNewMsgs);
        };

        asyncBlock();
    }, [dirAndLangRvar().lang, refreshRvar()]);

    const hideMobileNavPanelHandler = () => {
        mobileNavButtonRef?.current?.classList.add("d-block");
        mobileNavButtonRef?.current?.classList.add("d-md-none");
        const navCompPanel = document.getElementById("mainNavPanel");
        // navCompPanel?.classList.remove("d-none");
        navCompPanel?.classList.add("d-md-block");
        navCompPanel?.setAttribute("innerHtml", "Hide menu");
    };
    const hideNavSubPanelHandler = () => {
        mobileNavButtonRef?.current?.classList.remove("d-block");
        mobileNavButtonRef?.current?.classList.remove("d-md-none");
    };

    // if (getCompanyError) return <div>{getCompanyError.message}</div>;

    // if (getCompanyData) return <div>{"AAAAAAAa"}</div>

    // if (getCompanyLoading) return <div>{"LOADING"}</div>;

    return (
        <div className="d-flex flex-column flex-grow-1">
            {/* <HeaderComp.HeaderContainer>
                <HeaderComp.HeaderStart compName="HeaderStart" key={"HeaderStart"}>
                    <NavComp.MobileNavButton ref={mobileNavButtonRef} />
                </HeaderComp.HeaderStart>

                <HeaderComp.HeaderCenter compName="HeaderCenter">
                    <>
                        <IFAuthedShow notAuthedChildren={"CTrip Software"}>
                             <img
                                src={getAccessInfo()?.user?.userPhotoName ? `${RES_SERVER_NAME}${getAccessInfo()?.user?.userPhotoName}` : ""}
                                width={"50px"}
                                height={"50px"}
                            />
                            <div style={{ fontSize: "8px" }}>
                                <div>{getAccessInfo()?.user?.userName1Ar}</div>
                                <div>{getAccessInfo()?.user?.email}</div>
                            </div>
                        </IFAuthedShow>
                    </>
                </HeaderComp.HeaderCenter>

                <HeaderComp.HeaderEnd compName="HeaderEnd">
                    <div className="row">
                        <div className="col-4">
                            <NavLink
                                style={{ color: "white" }}
                                // className="col-auto"
                                to="/app/allChat"
                                state={{ toUserId: getAccessInfo()?.user?.userId }}
                            >
                                <i className="bi bi-chat fs-1 position-relative">
                                    {chattingUsersRVar().filter((e) => e.hasNewMsg).length > 0 && (
                                        <span
                                            className="badge badge-light"
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                color: "white",
                                                borderRadius: "50%",
                                                background: "red",
                                                // opacity: "80%",
                                                fontSize: "x-small",
                                            }}
                                        >
                                            {chattingUsersRVar().filter((e) => e.hasNewMsg).length}
                                        </span>
                                    )}
                                </i>
                            </NavLink>
                        </div>
                        <div className="col-4">
                            <NavLink
                                style={{ color: "white" }}
                                // className="col-auto"
                                to="/app/notifications"
                                state={{ toUserId: getAccessInfo()?.user?.userId }}
                            >
                                <i className="bi bi-bell fs-1 position-relative ">
                                    {2 > 0 && (
                                        <span
                                            className="badge badge-light"
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                color: "white",
                                                borderRadius: "50%",
                                                background: "red",
                                                // opacity: "80%",
                                                fontSize: "x-small",
                                            }}
                                        >
                                            {2}
                                        </span>
                                    )}
                                </i>
                            </NavLink>
                        </div>
                        <div className="col-4">
                            <NavLink
                                style={{ color: "white" }}
                                // className="col-auto"
                                to="/app/dashboard"
                                state={{ toUserId: getAccessInfo()?.user?.userId }}
                            >
                                <i className="bi bi-house-door fs-1 position-relative "></i>
                            </NavLink>
                        </div>
                    </div>
                </HeaderComp.HeaderEnd>
            </HeaderComp.HeaderContainer> */}



            {/* for testing signed images only, to be deleted? */}
            {/* <img
                // src="https://storage.cloud.google.com/gtrip-bucket/private/images/drvr/drvrId12/drvrLic12_d_06-05-2024_084327.jpg?authuser=1"
                src="https://storage.googleapis.com/gtrip-bucket/private/images/users/userId39/userNatIdBk39_d_08-05-2024_083757.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=156577268671-compute%40developer.gserviceaccount.com%2F20240508%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240508T185316Z&X-Goog-Expires=60&X-Goog-SignedHeaders=host&X-Goog-Signature=6bb4674d8d98126b355b8425a70ad0a37ab0782d6b3b2eb2c323300d6c95c0e01657bf0eee632ca1ec330c33da95ee593ebd88ac50eb22bbe48822bf897a90d67e1d8bac3a76075bd6fb5cd48ce4595b5b652e2c999639ef3e5ec6235fd38a1266ebf82c29cf79251668a1f1640d3ed7b0c26b4e973b38b07a30d88a16a091c6afb2c8143a3fbb2910294f95574bdd4a2ea748a4f0da7379b5263474da4e360e70ce195f84712a3247677bbf4af1e2f1f871ec33cf3641e42fb7d5afe5bbaf91066d757466725d28b2908c3bc4427465322a31015bd3e2ba383e436ed737975ab12ae456047c35d94f9f3035b193f6eb98e7150db9d5620a6cd111a1600b2fc2"
                width={"50px"}
                height={"50px"}
            /> */}
            <div id="mainHeaderId0" className="contianer-fluid px-1 bg-primary text-light py-1">
                <div className="row m-0">
                    <div className="col-auto"
                        style={{
                            fontWeight: "bold",
                            fontSize: "x-large",
                            fontStyle: "italic",
                            color: "yellow"
                        }}>
                        {ReadTextResource.getTextById("HIGWAY_TAXI") + " " + accessInfoRvar()?.user?.userName1Ar ?? "" + " " + accessInfoRvar()?.user?.userName1En ?? ""}
                    </div>
                    <div className="col-auto ms-auto text-end"><NavComp.MobileNavButton ref={mobileNavButtonRef} /></div>
                </div>
                <div className="row align-items-center m-0">
                    <div className="col-4 text-center">
                        <>
                            <IFAuthedShow notAuthedChildren={"CTrip Software"}>
                                {/* <img
                                src={getAccessInfo()?.user?.userPhotoName ? `${RES_SERVER_NAME}${getAccessInfo()?.user?.userPhotoName}` : ""}
                                width={"50px"}
                                height={"50px"}
                            />
                            <div style={{ fontSize: "8px" }}>
                                <div>{getAccessInfo()?.user?.userName1Ar}</div>
                                <div>{getAccessInfo()?.user?.email}</div>
                            </div> */}
                            </IFAuthedShow>
                        </>
                    </div>
                    <div className="col-12 text-center d-flex flex-column">
                        <div className="row">
                            <div className="col-4">
                                <NavLink
                                    style={{ color: "white" }}
                                    // className="col-auto"
                                    to="/app/allChat"
                                    state={{ toUserId: getAccessInfo()?.user?.userId }}
                                >
                                    <i className="bi bi-chat fs-1 position-relative">
                                        {chattingUsersRVar().filter((e) => e.hasNewMsg).length > 0 && (
                                            <span
                                                className="badge badge-light"
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    background: "red",
                                                    // opacity: "80%",
                                                    fontSize: "x-small",
                                                }}
                                            >
                                                {chattingUsersRVar().filter((e) => e.hasNewMsg).length}
                                            </span>
                                        )}
                                    </i>

                                    {/* {`Chat ${chattingUsersRVar().some((e) => e.hasNewMsg === true) ? "New" : ""}`} */}
                                </NavLink>
                            </div>
                            <div className="col-4">
                                <NavLink
                                    style={{ color: "white" }}
                                    // className="col-auto"
                                    to="/app/notifications"
                                    state={{ toUserId: getAccessInfo()?.user?.userId }}
                                >
                                    <i className="bi bi-bell fs-1 position-relative ">
                                        {userNotificationsRVar().filter((e) => e.isNewNtf).length > 0 && (
                                            <span
                                                className="badge badge-light"
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    color: "white",
                                                    borderRadius: "50%",
                                                    background: "red",
                                                    // opacity: "80%",
                                                    fontSize: "x-small",
                                                }}
                                            >
                                                {userNotificationsRVar().filter((e) => e.isNewNtf).length}
                                            </span>
                                        )}
                                    </i>
                                </NavLink>
                            </div>
                            <div className="col-4">
                                <NavLink
                                    style={{ color: "white" }}
                                    // className="col-auto"
                                    to="/app/dashboard"
                                    state={{ toUserId: getAccessInfo()?.user?.userId }}
                                >
                                    <i className="bi bi-house-door fs-1 position-relative "></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* this is the OffCanvas content for MobileNavPanel*/}
            <PortalComp elementId={ModalComp.ModalRootIDs.NAV_ROOT_MODAL}>
                <NavComp.MobileNavPanel
                    parentProps={ModalComp.ModalRootIDs.NAV_ROOT_MODAL}
                    menus={appMenusData}
                    onHideMenu={hideMobileNavPanelHandler}
                />
            </PortalComp>

            <NavComp.NavPanel panelID="mainNavPanel">
                <NavComp.NavSubPanel
                    compName="NavSubPanel"
                    menus={appMenusData?.getAppMenus}
                    readMenuFromTxtRsc={true}
                    onHideMenu={() => hideNavSubPanelHandler()}
                ></NavComp.NavSubPanel>
                <NavComp.NavMainPanel compName="NavMainPanel">
                    <Outlet />
                </NavComp.NavMainPanel>
            </NavComp.NavPanel>
        </div>
    );
}

export default App;
