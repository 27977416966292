import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FormCmps } from "../commonComp/FormComp";
import { dirAndLangRvar /* userInfoRvar */ } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ReadTextResource } from "../../commonSrc/ReadTextResource";
import { useNavigate } from "react-router-dom";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { getAccessInfo, } from "../../commonSrc/commonFns";
import { parseISO, addMinutes } from "date-fns";
import { Button } from "../commonComp/Button";


export function DrvrDashboardComp(props: React.PropsWithChildren<{}>) {
    console.log("First line - DrvrMainComp Component");
    const navigate = useNavigate();
    //don't remove the call to useReactiveVar, as it is used to refresh this component when the language is changed from any other componenet
    // useReactiveVar(companyInfoRvar);
    // useReactiveVar(dirAndLangRvar);

    const {
        JsxComp: InOutTrans,
        compReducer: [inOutTransSt, inOutTransStDis],
        compRef: inOutTransStRef,
    } = FormCmps.RadioCmp.getComp("In");

    const {
        JsxComp: FromCityAutoComplete,
        compReducer: [fromCityAutoCompleteSt, fromCityAutoCompleteDis],
        compRef: fromCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: ToCityAutoComplete,
        compReducer: [toCityAutoCompleteSt, toCityAutoCompleteDis],
        compRef: toCityAutoCompleteRef,
    } = FormCmps.AutoCompleteCmp.getComp();

    const {
        JsxComp: FromCountry,
        compReducer: [fromCountrySt, fromCountryDis],
        compRef: fromCountryRef,
        // { code: "JO", label: "Jordan", phone: "+962", suggested: true }
    } = FormCmps.CountryCmp.getComp(getAccessInfo()?.user?.countryByIp);

    const {
        JsxComp: ToCountry,
        compReducer: [toCountrySt, toCountryDis],
        compRef: toCountryRef,
    } = FormCmps.CountryCmp.getComp();

    const {
        JsxComp: FromDatetime,
        compReducer: [fromDatetimeSt, fromDatetimeStDis],
        compRef: fromDatetimeStRef,
    } = FormCmps.DateComp.getComp(parseISO(addMinutes(new Date(), 15).toISOString()));

    const {
        JsxComp: ToDatetime,
        compReducer: [toDatetimeSt, toDatetimeStDis],
        compRef: toDatetimeStRef,
    } = FormCmps.DateComp.getComp(/* new Date() */);

    const [fetchType, setFetchType] = useState("today");

    const [
        getPsgrTraspReq,
        // refetch: refetchPsgrsTraspReqs,
        { refetch: refetchPsgrsTraspReqs, error: searchPsgrsReqsErr, data: searchPsgrsReqsData, loading: searchPsgrsReqsLoading },
    ] = useLazyQuery(getGqlStmt().queries.SEARCH_PSGRS_REQS, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {

    }, [dirAndLangRvar().lang]);



    return (
        <>
            {/* <div className="d-flex flex-column align-items-center text-center"> */}
            <div className="align-content-center d-flex flex-column flex-lg-row flex-md-row flex-wrap justify-content-lg-around justify-content-md-around">
                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/drvrTraspReq");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/DrvrAddTrip2.png")`,
                            width: "124px",
                            height: "85px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div className=" mt-3 rounded-4 text-center" style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}>
                        {ReadTextResource.getTextById("ADD_TRIP")}
                    </div>
                </div>

                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5 "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/drvrTrips");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/NewTrip3.png")`,
                            width: "120px",
                            height: "85px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div className=" mt-3 rounded-4 text-center" style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}>
                        {ReadTextResource.getTextById("DRVR_TRIPS")}
                    </div>
                </div>

                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/drvrSuspendedReqs");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/taxiCorrect.png")`,
                            width: "75px",
                            height: "62px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div
                        className=" mt-3 rounded-4  text-center"
                        style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}
                    >
                        {ReadTextResource.getTextById("DRVR_SUSPENDED_REQS")}
                    </div>
                </div>

                <div
                    className="d-flex flex-column align-items-center mb-4 shadow p-3 rounded-5 "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/app/drvrFindTrip");
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url("/FindOnEarth4.png")`,
                            width: "105px",
                            height: "85px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                    <div className=" mt-3 rounded-4 text-center" style={{ color: "rgb(97 119 14)", fontSize: "medium", fontWeight: "500" }}>
                        {ReadTextResource.getTextById("DRVR_SEARCH_TRIP")}
                    </div>
                </div>
            </div>
        </>
    );
}
