import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import App from "../../App";
import RequireAuth from "./RequireAuth";
import Login from "../Auth/login";
import { Welcome } from "../../Welcome";
import { TestComp1 } from "./testComponentsTemp/testComp1";
import ForgotPass from "../Auth/ForgotPass";
import ResetPass from "../Auth/ResetPass";
import AccountActivation from "../Auth/AccountActivation";
import { SpinnerComp } from "./SpinnerComp";
import { ModalComp } from "./ModalComp";
import { TestLazyQ } from "./testComponentsTemp/TestLazyQ";
import { UserCrud } from "../Auth/UserCrud";
import { getGqlStmt } from "../../commonSrc/graphQL/Queries";
import { apolloClient } from "../../commonSrc/apolloGQL/ApolloConfig";
import NotFound from "./NotFound";
import { DrvrRoutes } from "./DrvrRoutes";
import { PsgrRoutes } from "./PsgrRoutes";
import { getIDbChattingUsers } from "../../commonSrc/indexedDb/fns";
import { getAccessInfo, setAccessInfo } from "../../commonSrc/commonFns";
import { Auth, CountryT, User } from "../../commonSrc/Types";
import { countries, CrudTypes } from "../../commonSrc/Constants";
import { useJsApiLoader } from "@react-google-maps/api";
import { socket } from "../../Socket";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { accessInfoRvar, chattingUsersRVar, dirAndLangRvar, refreshRvar } from "../../commonSrc/apolloGQL/ReactiveVars";
import { ErrorBoundary } from "./ErrorBoundary";

// const GOOGLE_API_KEY = "AIzaSyDmwt9fU1rTI2d216WS3VF_vmHA3j2Jeyk";

const GOOGLE_API_KEY = "AIzaSyDEIOf1Zv5zxGp2zAmbs80IMQyAjlwyG5s";
const libs: any[] = ["places", "geometry", "marker"];

export const AppRoutes = () => {
    console.log("First line - AppRoutes Component");
    useReactiveVar(dirAndLangRvar);
    /**
     * don't remove below accessInfoRvar, it is used in case the user is not authed, then he loges in, the psgr and drvr routes
     * needs to be refreshed in this case. another solution if you need to remove the accessInfoRvar from here is to
     * add the login component before <AppRoutes> component in the index.tsx file (not tested), so that the login will wrap the
     * <AppRoutes>, so when the login is refreshed, the <AppRoutes> will get refreshed as well
     */
    useReactiveVar(accessInfoRvar);

    const [loading, setLoading] = useState(true);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: libs,
        // version:"3.56"
    });

    const [
        getUserChatUsers,
        { refetch: refetchUserChatUsers, error: getUserChatUsersErr, data: getUserChatUsersData, loading: getUserChatUsersLoading },
    ] = useLazyQuery(getGqlStmt().queries.USER_CHAT_USERS, {
        fetchPolicy: "no-cache",
        variables: {},
        onCompleted: (data) => { },
    });

    useEffect(() => {
        console.log("useEffect of AppRoutes component");

        async function refreshTokenOnRefreshPage() {
            try {
                const refreshTokenRes = (
                    await apolloClient.mutate({
                        mutation: getGqlStmt().mutations.RFRESH_ACCESS_TOKEN,
                        fetchPolicy: "no-cache",
                        context: {
                            fetchOptions: {
                                keepalive: true,

                            },
                        },
                    })
                ).data.refreshAccessToken.data as Auth;

                // console.log(
                //     "##refreshTokenRes In AppRoutes",
                //     refreshTokenRes,
                //     "refreshTokenRes.toekn",
                //     refreshTokenRes.token,
                //     "refreshTokenRes.isAuthenticated",
                //     refreshTokenRes.isAuthenticated
                // );
                if (refreshTokenRes.isAuthenticated) {
                    let currentCountryByIp;
                    try {
                        const ipInfoQuery = await apolloClient.mutate({
                            mutation: getGqlStmt().queries.GET_IP_INFO,
                            errorPolicy: "all",
                        });
                        console.log("$$ipInfoQuery=", ipInfoQuery);

                        currentCountryByIp = countries.find((e) => e.code === ipInfoQuery.data.getIpInfo.data.country_code) as CountryT;
                        console.log("$$currentCountryByIp=", currentCountryByIp);
                    } catch (err) {
                        console.log("##error in getting GET_IP_INFO - abd107", err);
                    }

                    setAccessInfo({
                        token: refreshTokenRes.token,
                        username: refreshTokenRes.username,
                        isAuthenticated: refreshTokenRes.isAuthenticated,
                        email: refreshTokenRes.email,
                        user: { ...(refreshTokenRes.user as User), countryByIp: currentCountryByIp as CountryT },
                    });

                    if (!socket.connected) {
                        socket.auth = { jwtToken: getAccessInfo()?.token };
                        const s = socket.connect();
                        // if (s.connected) console.log("just connected to socket");
                        // else console.log("socket connection failed");
                    } else console.log("already connected to socket");

                    const userChattingUserRes = await getUserChatUsers();
                    console.log("data chatuser:", userChattingUserRes.data?.getUserChatUsers?.dataArr);

                    if (chattingUsersRVar().length === 0) {
                        chattingUsersRVar([...userChattingUserRes?.data?.getUserChatUsers?.dataArr]);

                        // const localStorageUsers: User[] = JSON.parse(localStorage.getItem("chattingUsers") ?? "");
                        const localStorageUsers: User[] = await getIDbChattingUsers();

                        localStorageUsers.map((els) => {
                            if (els.hasNewMsg) {
                                chattingUsersRVar().map((e) => (e.userId === els.userId ? (e.hasNewMsg = true) : ""));
                            }
                        });
                    }
                }
                setLoading(false);
            } catch (err) {
                console.log("useEffect error of AppRoutes component", err);

                setLoading(false);
            }
        }
        refreshTokenOnRefreshPage();
    }, [
        /** 18May24 dependency refresh was added below in order to refresh chattingUsersRVar for AllChat.tsx
         * which was showing "No Chats" while user already has chats.
         * what happens is that when opening the app for the first time, the useEffect above will get called and while no
         * access token is there the chattingUsersRVar won't get updated.
         * after a user login, it won't get called again unless we add some dependecy 
          */
        getAccessInfo()?.isAuthenticated
    ]);

    if (loading)
        return (
            <ModalComp.SpinnerModal.JsxPortalComp
                isPortal={true}
                modalIdSuffix="appRoutes"
                modalId="appRoutesSpinner"
                autoShow={true}
                firstTimeAccess={true}
            />
        );
    // <SpinnerComp firstTimeAccess={true}/>;

    return (
        <Routes>
            <Route path="/" element={<Welcome />}>
                <Route
                    path="/"
                    element={getAccessInfo()?.isAuthenticated ? <Navigate to="app/dashboard" replace></Navigate> : <Login />}
                // element={
                //     <RequireAuth>
                //         <Navigate to="app/dashboard" replace></Navigate>
                //     </RequireAuth>
                // }
                ></Route>

                {/* <Route path="/app/favicon.ico" element={<Welcome />} /> */}
                <Route path="/forgotPass" element={<ForgotPass />} />
                <Route path="/resetPass" element={<ResetPass />} />
                <Route path="/testLazyQ" element={<TestLazyQ />} />
                <Route
                    path="/newUser"
                    element={
                        <React.Suspense
                            fallback={
                                <ModalComp.SpinnerModal.JsxPortalComp
                                    isPortal={true}
                                    modalIdSuffix="newUserSpinner"
                                    modalId="NewUser"
                                    autoShow={true}
                                />
                            }
                        >
                            <UserCrud crudType={CrudTypes.CREATE} />
                        </React.Suspense>
                    }
                />
                <Route path="/spinnerTest" element={<SpinnerComp />} />
                <Route path="/activateAccount" element={<AccountActivation />} />
            </Route>
            <Route path="testComp1" element={<TestComp1 />} />
            <Route path="/login" element={getAccessInfo()?.isAuthenticated ? <Navigate to="/app/dashboard" replace></Navigate> : <Login />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route
                path="/testComp1"
                element={
                    <div>
                        <TestComp1 />
                    </div>
                }
            />
            <Route
                path="/app/*"
                element={
                    <RequireAuth>
                        <App />
                    </RequireAuth>
                }
            >
                <Route
                    path="*"
                    element={
                        (accessInfoRvar()?.user?.userType === "Psgr" && <PsgrRoutes></PsgrRoutes>) ||
                        (accessInfoRvar()?.user?.userType === "Drvr" && <DrvrRoutes></DrvrRoutes>) ||
                        <div>access info is unkown, not psg nor drvr</div>
                    }
                ></Route>
            </Route>
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
};
